import { tCrm as t } from '@sales-i/utils';

export const USE_CURRENCY = 'USE_CURRENCY';

export const generateInteractionFields = [
  {
    stage: t('settings'),
    sections: [
      {
        sectionTitle: t('allocation'),
        fields: [
          {
            label: t('allocate_to'),
            id: 'allocated_to',
            type: 'select',
            options: [
              {
                text: t('loading'),
                value: 0
              }
            ],
            minLength: 1,
            maxLength: 40,
            placeholder: '',
            required: true,
            disabled: false,
            errorMessage: t('please_enter_a_valid_customer_name'),
            twoway: true
          },
        ]
      },
      {
        sectionTitle: t('number_duration'),
        fields: [
          {
            label: t('interaction_type'),
            id: 'interaction_type',
            type: 'select',
            options: [
              {
                text: t('loading'),
                value: 0
              }
            ],
            required: true,
            errorMessage: t('please_enter_interaction_type'),
            twoway: true,
            value: 0
          },
          {
            label: t('number_of_interactions'),
            id: 'number_of_interactions',
            type: 'number',
            value: 1,
            min: 1,
            max: 100,
            required: true,
            errorMessage: t('please_enter_a_number_between_1_and_100'),
            twoway: true
          },
          {
            label: t('interaction_duration_mins'),
            id: 'duration_of_each_interaction',
            type: 'select',
            value: 5,
            min: 5,
            max: 90,
            options: [
              { text: '5', value: 5 },
              { text: '10', value: 10 },
              { text: '15', value: 15 },
              { text: '20', value: 20 },
              { text: '25', value: 25 },
              { text: '30', value: 30 },
              { text: '35', value: 35 },
              { text: '40', value: 40 },
              { text: '45', value: 45 },
              { text: '50', value: 50 },
              { text: '55', value: 55 },
              { text: '60', value: 60 },
              { text: '65', value: 65 },
              { text: '70', value: 70 },
              { text: '75', value: 75 },
              { text: '80', value: 80 },
              { text: '85', value: 85 },
              { text: '90', value: 90 },
            ],
            required: true,
            disabled: false,
            twoway: true
          },
          {
            label: t('time_between_interactions_mins'),
            id: 'mins_between_interactions',
            type: 'select',
            value: 5,
            min: 5,
            max: 120,
            options: [
              { text: '5', value: 5 },
              { text: '10', value: 10 },
              { text: '15', value: 15 },
              { text: '20', value: 20 },
              { text: '25', value: 25 },
              { text: '30', value: 30 },
              { text: '35', value: 35 },
              { text: '40', value: 40 },
              { text: '45', value: 45 },
              { text: '50', value: 50 },
              { text: '55', value: 55 },
              { text: '60', value: 60 },
              { text: '65', value: 65 },
              { text: '70', value: 70 },
              { text: '75', value: 75 },
              { text: '80', value: 80 },
              { text: '85', value: 85 },
              { text: '90', value: 90 },
              { text: '95', value: 95 },
              { text: '100', value: 100 },
              { text: '105', value: 105 },
              { text: '110', value: 110 },
              { text: '115', value: 115 },
              { text: '120', value: 120 },
            ],
            required: true,
            disabled: false,
            errorMessage: t('please_input_greater_value_than_duration'),
            twoway: true
          },
        ]
      },
      {
        sectionTitle: t('exclusions'),
        fields: [
          {
            // Set label to value of entityType
            // label: `Exclude ${entityType} contacted within`,
            label: t('exclude_prospects_contacted_within'),
            id: 'exclude_recently_contacted_days',
            type: 'select',
            options: [
              {
                text: t('number_days', { number: 7 }),
                value: 7,
              },
              {
                text: t('number_days', { number: 14 }),
                value: 14,
              },
              {
                text: t('number_days', { number: 30 }),
                value: 30,
              },
              {
                text: t('number_days', { number: 60 }),
                value: 60,
              },
              {
                text: t('number_days', { number: 90 }),
                value: 90,
              },
              {
                text: t('number_days', { number: 120 }),
                value: 120,
              },
              {
                text: t('number_days', { number: 180 }),
                value: 180,
              },
              {
                text: t('number_days', { number: 365 }),
                value: 365,
              },
            ],
            minLength: 1,
            maxLength: 40,
            placeholder: '',
            disabled: false,
            errorMessage: t('please_select_value'),
            twoway: true
          },
        ]
      },
    ],
  },

  {
    stage: t('adding_opportunites'),
    sections: [
      {
        sectionTitle: t('opportunities'),
        fields: [
          {
            label: t('value_greater_than'),
            id: 'opportunity_min',
            value: 0,
            type: 'number',
            min: 0,
            placeholder: USE_CURRENCY,
            errorMessage: t('please_enter_a_number'),
            twoway: true
          },
          {
            label: t('value_less_than'),
            id: 'opportunity_max',
            value: 100000000000,
            type: 'number',
            min: 0,
            placeholder: USE_CURRENCY,
            errorMessage: t('please_enter_a_number_greater_than_min'),
            twoway: true
          },
          {
            label: t('include_opportunities'),
            id: 'opportunity_include',
            value: true,
            type: 'checkbox',
            required: false,
            twoway: true
          },
        ]
      }
    ],
  },

  // {
  //   stage: t('profile_completion_status'),
  //   sections: [
  //     {
  //       sectionTitle: t('profile_completion_status'),
  //       fields: [
  //         {
  //           id: 'complete_less_than_33',
  //           name: 'profile_completion_status',
  //           label: t('33'),
  //           type: 'radio',
  //         },
  //         {
  //           id: 'complete_33_to_66',
  //           name: 'profile_completion_status',
  //           label: t('33_66'),
  //           type: 'radio',
  //         },
  //         {
  //           id: 'complete_greater_than_66',
  //           name: 'profile_completion_status',
  //           label: t('66'),
  //           type: 'radio',
  //         },
  //         {
  //           id: 'all',
  //           name: 'profile_completion_status',
  //           label: t('all'),
  //           type: 'radio',
  //           value: true,
  //         },
  //       ]
  //     }
  //   ],
  // },

  {
    stage: t('location_of_prospects'),
    sections: [
      {
        sectionTitle: t('location_of_prospects'),
        fields: [
          {
            label: t(),
            id: 'search_location_type',
            type: 'filter',
            value:{
              text: t('everywhere'),
              value: 'none',
            },
            options: [
              {
                text: t('everywhere'),
                value: 'none',
              },
              {
                text: t('zip_postcode'),
                value: 'postcode',
              },
              {
                text: t('town_city'),
                value: 'town',
              },
            ],
            required: false,
            twoway: true
          },
          {
            label: t('prospect_location'),
            id: 'search_location',
            type: 'text',
            minLength: 1,
            maxLength: 40,
            placeholder: '',
            required: false,
            disabled: false,
            errorMessage: t('Please enter a valid location'),
            twoway: true
          },
        ]
      }
    ]
  },

  {
    stage: t('add_an_interaction_note'),
    sections: [
      {
        sectionTitle: t('interaction_note'),
        fields: [
          {
            label: t('note'),
            id: 'description',
            type: 'textarea',
            rows: 5,
            minLength: 1,
            maxLength: 280,
            placeholder: '',
            disabled: false,
            errorMessage: t('Please enter a valid note'),
            twoway: true
          },
        ]
      }
    ]
  },
];
