<template>
  <div 
    class="targets-chip-container"
    :style="cssVars"
  >
    <CustomChip
      ref="chip"
      class="targets-chip"
      :class="chipClasses"
      :title="processedValue"
    >
      <IconBase
        v-if="difference != 0"
        icon-name="arrow-up"
        :icon-color="iconColor"
        height="16"
        width="16"
        class="chip-arrow-icon"
        :class="mainClass"
      />
      {{ `${processedValue} (${processedPercentage}%)` }}
      <ToolTip
        :content="tooltipContent"
        :icon-size="20"
        append-to="parent"
        placement="right"
      />
    </CustomChip>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, h } from 'vue';
import { CustomChip, IconBase, ToolTip } from '@sales-i/dsv3';
import useCurrency from '@/shared/composables/useCurrency';
import { tCrm as t } from '@sales-i/utils';

// setup
const { cPrice, cNumber } = useCurrency();

const tooltipContent = {
  render: () =>
    h('div', { class: 'tooltip-container' } , [
      h('p', t('title_fieldname', {
        fieldname: tooltipValues.value.name
      }
      )),
      h('p', { class: 'description' }, t('description_fieldname', {
        fieldname: tooltipValues.value.description
      })),
      h('p', t('value_fieldname', {
        fieldname: tooltipValues.value.value
      }
      ))
    ])
};

// props
const props = defineProps({
  targetValues: {
    type: Object,
    default: () => ({}),
  },
  targetInfo: {
    type: Array,
    default: () => [],
  },
  // Prop to display as a chip at top of the table
  isRowValue: {
    type: Boolean,
    default: true
  }
});

const chip = ref(null);
const tooltipValues = ref({
  name: null,
  description: null,
  value: null
});
const measurementType = ref(undefined);

onMounted(() => {
  if (!props.targetInfo || !props.targetInfo.length || !props.targetValues.id) return;
  const targetId = props.targetValues.id;
  const targetInfoItem = props.targetInfo.find(item => item.id === targetId);
  if (targetInfoItem) {
    measurementType.value = targetInfoItem.measurement_type;
    tooltipValues.value = {
      name: targetInfoItem.name,
      description: targetInfoItem.description,
      value: targetInfoItem.value
    };
  }
});

// computed
const difference = computed(() => props.targetValues.difference);
const processedValue = computed(() => measurementType.value === 'Quantity' ? cNumber(difference.value, 0) : cPrice(difference.value));
const processedPercentage = computed(() => cNumber(props.targetValues.perc_change));
const mainClass = computed(() => difference.value > 0 ? 'green' : 'red');
const iconColor = computed(() => props.isRowValue ? 'var(--colour-utility-white)' : (difference.value > 0 ? 'var(--colour-data-de-york-label)' : 'var(--colour-data-mandy-label)'));
const cssVars = computed(() => ({ '--chip-width': `${chip.value && chip.value.$el.clientWidth}px` }));
const chipClasses = computed(() => {
  let classes = {
    [mainClass.value]: true,
    [`${mainClass.value}-background`]: !props.isRowValue,
    'row-type': props.isRowValue,
  };
  return classes;
});
</script>

<style lang="scss" scoped>
.targets-chip-container {
  min-width: var(--chip-width);
}

.chip.targets-chip {
  position: absolute;
  transform: translateX(-50%);
  box-shadow: none;
  background: none;
  white-space: nowrap;
  left: 50%;
  margin: 0;
  font-size: var(--font-size-small);
  bottom: 0;
  top: unset;
  padding: 0 var(--spacing-2);
  color: var(--colour-utility-white);
  z-index: 1;

    button {
      margin-left: var(--spacing-half);
    }

  &.row-type {
    color: var(--colour-utility-white);
    &.red {
      color: var(--colour-data-mandy-label);
    }
    &.green { 
      color: var(--colour-data-de-york-label);
    }

    .chip-arrow-icon {
      &.red {
        background-color: var(--colour-data-mandy-label);
        transform: rotate(180deg);
      }
      &.green {
        background: var(--colour-data-de-york-label);
      }
    }
  }

  &.green-background {
    background: var(--colour-data-de-york-label);
  }
  &.red-background {
    background: var(--colour-data-mandy-label);
  }

  .chip-arrow-icon {
    background: var(--colour-utility-white);
    border-radius: 50%;
    margin-right: var(--spacing-half);
  }
}

:deep(.tooltip-container) {
  font-weight: var(--font-weight-regular);
  
  .description {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 300px;
  }
}

:deep(.tippy-box) {
  background-color: var(--colour-utility-black);
  color: var(--colour-utility-white);
  font-size: var(--font-size-small);

  .tippt-content {
    padding : var(--spacing-1);
  }

  .tippy-arrow {
    color: var(--colour-utility-black);
  }
}
</style>
