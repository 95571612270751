import useCalendar from '@/intelligence/composables/useCalendar';
import useShared from '@/intelligence/composables/useShared';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';

export function useDates({ store = useStore(), updateDatesOnMount = false } = {}) {
  const { fetchDates } = useCalendar({ store });
  const { applyDate } = useShared({ store });

  onMounted(async() => {
    if (updateDatesOnMount) await updateDates();
  });

  const defaultDates = {
    date_from: 'today/y',
    date_to: 'today',
    date_from2: 'today/y-1y',
    date_to2: 'today-1y',
  };
  const dates = ref(defaultDates);
  const dateParams = ref(null);

  async function updateDates(dateObject = defaultDates) {
    dates.value = dateObject;
    const dateValues = await fetchDates(dates.value);
    applyDate(dateValues);
    dateParams.value = dateValues;
  }

  return {
    dates,
    dateParams,
    updateDates
  };
}