<template>
  <div class="targets">
    <div class="container container--fluid relative padded">
      <div class="parent">
        <div class="spacer" />
        <div class="encouragement-headings">
          <p class="period-heading">
            {{ t('period') }} 10
          </p>
          <p class="shoutout-heading">
            {{ t('you_re_nearly_there') }} !
          </p>
        </div>
        <div class="user-blurb">
          <p class="welcome-message">
            {{
              t('name_welcome_to_sales_ai', {
                name: $store.state.userDetails.data.firstName,
              })
            }}
          </p>
          <p class="current-date">
            {{ formatDate(new Date(), 'medium') }}
          </p>
        </div>
      </div>
      <EarningsBar />
    </div>
  </div>
</template>

<script>
import EarningsBar from './EarningsBar.vue';
import { dates, tCrm as t } from '@sales-i/utils';

export default {
  name: 'TargetsBanner',
  components: {
    EarningsBar,
  },
  methods: { t, formatDate: dates.format },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.parent {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-2);

  flex-direction: column-reverse;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    flex-direction: initial;
  }
}

.spacer {
  flex: 1;
}

.targets {
  background-color: var(--colour-data-de-york-background);
  margin-bottom: var(--spacing-12);
  padding-top: var(--spacing-3);
}

.encouragement-headings {
  text-align: center;
}
.user-blurb {
  align-items: center;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    align-items: flex-end;
  }
}

.welcome-message {
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-medium);
}

.current-date {
  font-size: var(--font-size-small);
}

.period-heading {
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-semibold);
}

.shoutout-heading {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-semibold);
}
</style>
