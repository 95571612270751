<template>
  <CRMCard
    :title="item.customer_name"
    :links="getLinks()"
    @view="goToCustomer"
  >
    <template #info>
      <p>
        {{ t('prod_group_level_1', { prod_group_level_1: item.prod_group_level_1_desc }) }}.
      </p>
      <p>
        {{ t('prod_group_level_2_count', { prod_group_level_2_count: item.count }) }}.
      </p>
    </template>
  </CRMCard>
</template>

<script setup>
import { CRMCard } from '@sales-i/dsv3';
import { baseUrl, customersArea as area, productGapSection } from '@/crm/router/urlBits';
import { tCrm as t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  }
});

const getLinks = () => {
  return [
    {
      text: t('view'),
      id: props.item.entity_id,
      emit: 'view',
    },
  ];
};
const goToCustomer = ($event) => {
  navigateToUrl(`${baseUrl}/${area}/${$event.id}/${productGapSection}`);
};
</script>