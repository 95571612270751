<template>
  <div class="data-wrapper">
    <section>
      <TableWrapper
        table-height="510px"
        :offset-y="offset"
        container="div"
        enable-lazy-load
        disable-shades
        :no-more-results-available="noMoreResultsAvailable"
        @set-offset="loadMoreData"
      >
        <div
          v-if="valueRows.length"
          class="data-head"
        >
          <div
            v-for="(thead, index) in tableHeads"
            :key="index"
            :class="{ active: sortHeader === thead.key }"
          >
            <CustomButton
              :id="index"
              purpose="transparent"
              icon-name="arrow-down"
              icon-color="var(--colour-utility-black)"
              :icon-width="16"
              :icon-height="16"
              class="sort-btn"
              :class="sortOption"
              @click="applySort(thead.key)"
            >
              {{ thead.name || thead.period }}
            </CustomButton>
          </div>
        </div>
        <div class="data-rows">
          <div
            v-for="(row, i) in valueRows"
            :key="i"
            class="data-row"
            :class="{ 'no-details': row.interactionsList && !row.interactionsList.length }"
          >
            <div class="head">
              <div
                v-for="(thead, index) in tableHeads"
                :key="index"
              >
                <IconButton
                  v-if="index === 0 && row.interactionsList && row.interactionsList.length"
                  class="toggle-btn"
                  icon-name="chevron-down"
                  icon-color="'var(--colour-utility-action)'"
                  purpose="transparent"
                  icon-only
                  :icon-size="20"
                  @click.stop="toggleDataRow"
                />
                <component
                  :is="index === 0 && ['C', 'P'].includes(row.type) ? 'a' : 'span'"
                  v-if="thead.name"
                  :href="getLink(row)"
                  target="_blank"
                  :title="row[thead.key]"
                  :class="{
                    title: thead.key === 'name',
                    negative: parseInt(row[thead.key]) < 0,
                  }"
                >
                  {{ getCellValue(row, thead) }}
                </component>
                <span v-else>
                  {{ getFormattedNumber(row.periods[thead.periodIdx].total_interactions_in_period) }}
                </span>
              </div>
            </div>
            <div
              v-if="row.interactionsList"
              class="details"
            >
              <div
                v-for="(interaction, interactionIndex) in row.interactionsList"
                :key="interactionIndex"
                class="details-row"
              >
                <div>{{ interaction.type }}</div>
                <div>{{ interaction.totalInteractions }}</div>
                <div
                  v-for="(interactionAmount, iaKey) in interaction.periods"
                  :key="iaKey"
                >
                  {{ interactionAmount || 0 }}
                </div>
              </div>
            </div>
          </div>
          <NoResults
            v-if="valueRows && !valueRows.length"
            class="no-results"
          />
        </div>
      </TableWrapper>
    </section>
  </div>
</template>

<script>
import { CustomButton, IconButton } from '@sales-i/dsv3';
import { baseUrl as crmBaseUrl, customersArea, prospectsArea } from '@/crm/router/urlBits';
import { mapActions, mapGetters, mapState } from 'vuex';
import NoResults from '@/intelligence/components/Shared/NoResults';
import TableWrapper from '@/shared/components/Tables/TableWrapper';
import { APPLY_OFFSET } from '@/intelligence/store/actionType';
import { currency } from '@sales-i/utils';

export default {
  components: {
    CustomButton,
    NoResults,
    IconButton,
    TableWrapper,
  },
  props: {
    currentSort: {
      type: String,
      default: '',
    },
    reportId: {
      type: Number,
      default: 1,
    },
    reportType: {
      type: String,
      default: '',
      required: true,
    },
    sortOption: {
      type: String,
      default: 'desc',
    },
  },
  emits: ['applyNewSort', 'loadMoreData'],
  data() {
    return {
      valueRows: [],
      noMoreResultsAvailable: false,
      offset: 0,
      pageSize: 100,
      tableHeads: [],
    };
  },
  computed: {
    ...mapGetters({
      getReportData: 'intelligence/shared/getReportData',
      getInputData: 'intelligence/shared/getInputData',
    }),
    ...mapState({
      cs: state => state.userDetails.cs,
      dataLoading: state => state.intelligence.shared.loading,
    }),
    inputData() {
      return this.getInputData(this.reportType);
    },
    latestData() {
      return this.getReportData() || [];
    },
    periods() {
      return this.latestData.length
        ? this.latestData[0].periods.map((object, i) => {
          return {
            ...object,
            key: `period:${object.period}`,
            periodIdx: i,
          };
        })
        : [];
    },
    sortHeader() {
      return this.currentSort ? this.currentSort : this.inputData.defaultSortHeader;
    },
  },
  watch: {
    latestData(newData) {
      if (newData) {
        this.valueRows = [...this.valueRows, ...this.convertReportData(newData)];
        this.checkForMoreResults();
      }
    },
  },
  mounted() {
    this.valueRows = this.convertReportData(this.latestData);
    this.tableHeads = this.inputData ? [...this.inputData.visualInput, ...this.periods] : [];
    this.checkForMoreResults();
  },
  methods: {
    ...mapActions({
      applyOffset: `intelligence/shared/${APPLY_OFFSET}`,
    }),
    applySort(headerKey) {
      this.$emit('applyNewSort', headerKey);
    },
    getCellValue(row, cell) {
      switch (cell.key) {
      case 'total_interactions':
        return this.getFormattedNumber(row[cell.key]);
      case 'total_sales':
      case 'average_sales_per_interaction':
      case 'total_sales_ytd':
        return currency.price(row[cell.key], this.cs);
      default:
        return row[cell.key];
      }
    },
    getFormattedNumber(sum) {
      return Number(sum).toLocaleString('en-US', {
        maximumFractionDigits: 2,
      });
    },
    toggleDataRow(event) {
      const rowElement = event.target.closest('.data-row');
      if (rowElement.classList.contains('no-details')) return;
      rowElement.classList.toggle('active');
    },
    getLink(row) {
      const area = row.type === 'P' ? prospectsArea : customersArea;
      return `${crmBaseUrl}/${area}/${row.id}`;
    },
    loadMoreData() {
      if (!this.dataLoading) {
        this.offset += this.pageSize;
        this.applyOffset(this.offset);
        this.$emit('loadMoreData');
      }
    },
    checkForMoreResults() {
      this.noMoreResultsAvailable = this.pageSize > this.latestData.length;
    },
    convertReportData(data) {
      const newData = [];

      data.forEach((row, index) => {
        newData[index] = {
          ...row,
          interactionsList: [],
        };

        if (row.total_interactions === 0) return;

        row.periods.forEach((period, periodIndex) => {
          if (period.total_interactions_in_period === 0) return;

          period.interaction_types.forEach(interaction => {
            // looking for interaction in interactionsList to avoid duplicating it
            const existingInteraction = newData[index].interactionsList.find(
              x => x.type === interaction.interaction_type
            );

            if (!existingInteraction) {
              // creating interaction
              const periods = new Array(row.periods.length);
              periods[periodIndex] = interaction.interaction_count;
              newData[index].interactionsList.push({
                type: interaction.interaction_type || '-',
                totalInteractions: interaction.interaction_count || 0,
                periods,
              });
            } else {
              // update existing interaction
              existingInteraction.periods[periodIndex] = interaction.interaction_count;
              existingInteraction.totalInteractions += interaction.interaction_count;
            }
          });
        });
      });

      return newData;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

$firstCellWidthMobile: 160px;
$firstCellWidthDesktop: 260px;
$cellWidthMobile: 100px;
$cellWidthDesktop: 200px;

.data-rows {
  display: flex;
  flex-direction: column;
}

.data-wrapper {
  font-size: var(--font-size-small);
  overflow: auto;
  max-height: 510px;
  width: 100%;
  position: relative;

  section {
    width: 100%;
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    font-size: var(--font-size-5);
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.data-head {
  background: var(--colour-panel-action);
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;

  > div {
    width: auto;
    display: flex;
    align-items: center;
    position: relative;
    min-width: $cellWidthMobile;
    background: inherit;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      flex-basis: $cellWidthDesktop;
      min-width: $cellWidthDesktop;
    }

    &:first-of-type {
      min-width: $firstCellWidthMobile;
      @media #{map-get($display-breakpoints, 'md-and-up')} {
        min-width: $firstCellWidthDesktop;
      }
    }

    &.active {
      .button {
        text-decoration: none;
        color: var(--colour-utility-black);
      }

      .sort-btn {
        &.asc {
          &:deep(.icon) {
            transform: rotate(180deg);
          }
        }

        &:deep(.icon) {
          display: block;
        }
      }
    }

    .button,
    span {
      color: var(--colour-utility-action);
      font-weight: var(--font-weight-semibold);
      text-decoration: underline;
      text-align: left;
      padding: var(--spacing-1);

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        padding: var(--spacing-4) var(--spacing-2);
      }
    }

    .sort-btn {
      &:deep(.icon) {
        display: none;
        position: absolute;
        right: var(--spacing-half);
        top: var(--spacing-half);
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);

        @media #{map-get($display-breakpoints, 'md-and-up')} {
          right: var(--spacing-1);
        }
      }
    }

    &:deep(.button-container) {
      margin-left: 0;
    }
  }
}

.head,
.details-row {
  display: flex;
  background: var(--colour-utility-white);

  div {
    padding: var(--spacing-1);
    width: auto;
    display: flex;
    align-items: center;
    min-width: $cellWidthMobile;
    background: inherit;
    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: $cellWidthMobile;
    }

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding: var(--spacing-1) var(--spacing-2);
      flex-basis: $cellWidthDesktop;
      min-width: $cellWidthDesktop;

      .title {
        width: $cellWidthDesktop;
      }
    }

    &:first-of-type {
      min-width: $firstCellWidthMobile;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        min-width: $firstCellWidthDesktop;
      }
    }
  }
}

.head {
  & > div:first-of-type {
    position: relative;
    padding-left: var(--spacing-5);
    max-width: 100px;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      max-width: initial;
    }
  }

  &:deep(.icon-button > div) {
    margin-left: 0;
  }
}

.details-row {
  width: 100%;

  div {
    &:first-of-type {
      padding-left: calc($firstCellWidthMobile + var(--spacing-1));
      flex-basis: calc($firstCellWidthMobile + $cellWidthMobile);
      min-width: calc($firstCellWidthMobile + $cellWidthMobile);

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        padding-left: calc($firstCellWidthDesktop + var(--spacing-2));
        flex-basis: calc($firstCellWidthDesktop + $cellWidthDesktop);
        min-width: calc($firstCellWidthDesktop + $cellWidthDesktop);
      }
    }

    &:nth-of-type(2) {
      flex-basis: calc(5 * $cellWidthMobile);
      min-width: calc(5 * $cellWidthMobile);

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        flex-basis: calc(5 * $cellWidthDesktop);
        min-width: calc(5 * $cellWidthDesktop);
      }
    }
  }
}

.no-results {
  padding: var(--spacing-3) 0;
}

.toggle-btn {
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-colour);
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--colour-utility-white);
  position: absolute;
  top: var(--spacing-half);
  left: var(--spacing-1);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    top: 6px;
  }
}

.data-row {
  border: 1px solid var(--colour-utility-white);
  border-radius: 0;

  .details {
    display: none;
  }

  .head {
    background: var(--colour-utility-white);
  }

  &:nth-of-type(2n) {
    border: 1px solid var(--colour-panel-g-2);

    .head {
      background: var(--colour-panel-g-2);
    }
  }

  &.active {
    margin-bottom: var(--spacing-1);
    border-radius: var(--border-radius-1);

    .head {
      background: var(--colour-utility-white);
      border-top-left-radius: var(--border-radius-1);
      border-top-right-radius: var(--border-radius-1);

      > div {
        border-top: 1px solid var(--colour-brand-puerto-rico);

        &:first-of-type {
          border-left: 1px solid var(--colour-brand-puerto-rico);
          border-top-left-radius: var(--border-radius-1);
        }

        &:last-of-type {
          border-right: 1px solid var(--colour-brand-puerto-rico);
          border-top-right-radius: var(--border-radius-1);
        }
      }
    }
    .details {
      display: block;
      border-bottom-left-radius: var(--border-radius-1);
      border-bottom-right-radius: var(--border-radius-1);

      .details-row {
        > div {
          &:first-of-type {
            border-left: 1px solid var(--colour-brand-puerto-rico);
          }

          &:last-of-type {
            border-right: 1px solid var(--colour-brand-puerto-rico);
          }
        }

        &:last-of-type {
          > div {
            border-bottom: 1px solid var(--colour-brand-puerto-rico);

            &:first-of-type {
              border-bottom-left-radius: var(--border-radius-1);
            }

            &:last-of-type {
              border-bottom-right-radius: var(--border-radius-1);
            }
          }
        }
      }
    }

    .toggle-btn {
      transform: rotate(180deg);
      background-color: var(--colour-utility-black);

      &:deep(svg) {
        fill: var(--colour-utility-white);
      }
    }
  }
}
.negative {
  color: var(--colour-data-mandy-label);
}
</style>
