<template>
  <div>
    <EnquiryNav
      :report-type="navigationData.urlCode"
      :is-date-filter-active="isDateFilterActive"
      :dates="dateParams"
      hide-drill
      hide-filters
      is-snapshot
      disable-nav
      @toggle-date-filter="toggleDateFilter"
    />
    <DatePickerView
      v-if="isDateFilterActive"
      :key="reportRefreshCounter"
      class="container"
      :report-type="snapshotRef"
      @set-active-date="setActiveDate"
    />
    <TwoColumnLayout
      v-show="!isDateFilterActive"
      title="customer"
      :description="navigationData.description"
    >
      <template #sidebar>
        <div
          ref="sidebar"
          class="sidebar-menu"
        >
          <CustomSelect
            class="snapshot-select"
            :placeholder="t('select_analysis_field')"
            icon-align="right"
            :value="analysisFieldRef"
            :items="analysisFieldList"
            grouped
            group-field="area"
            label-field="title"
            key-field="id"
            @on-change="onAnalysisFieldChange"
          />
          <CustomSelect
            class="snapshot-select"
            :placeholder="t('select_property')"
            icon-align="right"
            :disabled="!analysisFieldRef || !propertyList.length"
            :value="propertyRef"
            :items="propertyList"
            label-field="value"
            key-field="id"
            @on-change="onPropertyChange"
          />
          <CustomSelect
            :disabled="!propertyRef"
            :label="t('snapshot_page')"
            class="snapshot-select"
            :placeholder="t('select')"
            icon-align="right"
            :value="snapshotRef"
            :items="snapshotList"
            @on-change="goToReport"
          />
          <ExportSnapshotByAnalysisField
            v-if="isSnapshotVisible"
            :disabled="!propertyRef"
            :title="t('export_snapshot')"
            :date-params="fetchedDates"
            :snapshot-bubble-id="analysisFieldRef"
            :snapshot-bubble-name="propertyName"
            :snapshot-bubble-value="propertyRef"
          />
        </div>
      </template>
      <template #content>
        <p 
          v-if="!analysisFieldRef" 
          class="get-started-text"
        >
          <strong>&lt;</strong>
          {{ t('get_started_by_selecting_an_analysis_field') }}
        </p>
        <p 
          v-if="!propertyRef && analysisFieldRef" 
          class="get-started-text select-property-text"
        >
          <strong>&lt;</strong>
          {{ t('now_lets_select_a_property_from_that_analysis_field') }}
        </p>
        <template v-if="propertyRef && analysisFieldRef">
          <h2 class="snapshot-title">
            {{ analysisFieldName }} > {{ propertyName }}
          </h2>
          <router-view
            v-if="isSnapshotVisible"
            :key="reportRefreshCounter"
            :report-type="snapshotRef"
          />
          <BufferImage
            v-else
            color="black"
            float="center"
            class="loading-spinner"
          />
        </template>
      </template>
    </TwoColumnLayout>
  </div>
</template>

<script setup>
import { ref, computed, watch, onBeforeUnmount, onMounted, onBeforeMount, } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { tCrm as t } from '@sales-i/utils';
import { BufferImage, CustomSelect } from '@sales-i/dsv3';
import {
  SNAPSHOT_BY_ANALYSIS_FIELD,
  SNAPSHOT_REPORT_LIST,
  SNAPSHOT_SUMMARY,
  PRODUCT_GROUP_PURCHASE_ANALYSIS_PIECHART,
  PRODUCT_GROUP_PURCHASE_ANALYSIS,
} from '@/intelligence/store/data/reportTypes';
import TwoColumnLayout from '@/shared/components/Layout/TwoColumnLayout.vue';
import DatePickerView from '@/intelligence/components/Date/DatePickerView.vue';
import EnquiryNav from '@/intelligence/components/EnquiryReport/EnquiryNav/EnquiryNav.vue';
import ExportSnapshotByAnalysisField from '@/intelligence/components/SnapshotReport/ExportSnapshotByAnalysisField.vue';
import { inputSets, snapshotReportSet } from '@/intelligence/store/data/reportsInput';
import isRolldate from '@/intelligence/store/utils/isRolldate';
import { useMq } from 'vue3-mq';
import useEnquiries from '@/intelligence/composables/useEnquiries';
import useShared from '@/intelligence/composables/useShared';
import useCalendar from '@/intelligence/composables/useCalendar';
import useTemplateReport from '@/intelligence/composables/useTemplateReport';

const store = useStore();
const mq = useMq();
const vroute = useRoute();

const { bubbleData, bubbleValues, fetchBubbleData, fetchBubbleValues } = useEnquiries({ store });
const { reportRefreshCounter, 
  refreshReport, applyDate, setLoading, applyOffset } = useShared({ store });
const { fetchedDates, fetchDates } = useCalendar({ store });
const { setSnapshotBubbleValue, setSnapshotBubbleId } = useTemplateReport({ store });

const isDateFilterActive = ref(false);
const isSnapshotVisible = ref(false);
const dateParams = ref({});
const sidebar = ref(null);

const snapshotRef = ref(PRODUCT_GROUP_PURCHASE_ANALYSIS);
const analysisFieldRef = ref(null);
const propertyRef = ref(null);
const propertyList = ref([]);

const navigationData = computed(() => inputSets[SNAPSHOT_BY_ANALYSIS_FIELD]);
const analysisFieldList = computed(() => {
  if (!bubbleData.value) return [];
  return bubbleData.value.filter(bubble => bubble.type === 'analysis_field');
});
const propertyName = computed(() => {
  const property = propertyList.value.find(el => el.id === propertyRef.value);
  return property ? property.value : '';
});

const analysisFieldName = computed(() => {
  const analysisField = analysisFieldList.value.find(el => el.id === analysisFieldRef.value);
  return analysisField ? analysisField.title : '';
});

const snapshotList = computed(() => {
  return SNAPSHOT_REPORT_LIST.reduce((result, report) => {
    if (report === SNAPSHOT_SUMMARY || report === PRODUCT_GROUP_PURCHASE_ANALYSIS_PIECHART) return result;
    const text = getSnapshotTitle(report);

    result.push({
      value: report,
      text,
    });

    return result;
  }, []);
});

watch(() => vroute.path, () => {
  const { name } = vroute;
  snapshotRef.value = name;
});

onBeforeMount(async() => {
  const dateFromQuery = vroute.query;
  if (dateFromQuery.date_from && dateFromQuery.date_to) {
    dateParams.value = {
      date_from: dateFromQuery.date_from,
      date_to: dateFromQuery.date_to
    };
  }
});

onMounted(async() => {
  if (!bubbleData.value) await fetchBubbleData();
  if (!Object.values(dateParams.value).length) {
    await processDates({
      date_from: 'today/y',
      date_to: 'today',
    });
  } else {
    await processDates(dateParams.value);
  }
});

onBeforeUnmount(() => {
  setSnapshotBubbleValue('');
  setSnapshotBubbleId('');
});

const onAnalysisFieldChange = async (value) => {
  setSnapshotBubbleId(value);
  await generatePropertyList(value);
  analysisFieldRef.value = value;
};

const generatePropertyList = async (bubbleId) => {
  propertyRef.value = null;
  propertyList.value = [];
  await fetchBubbleValues([bubbleId]);
  propertyList.value = bubbleValues.value;
};

const onPropertyChange = (value) => {
  setSnapshotBubbleValue(value);
  goToReport(snapshotRef.value);
  propertyRef.value = value;
};

const goToReport = async(reportType) => {
  isSnapshotVisible.value = false;
  applyOffset(0);
  setLoading(true);
  snapshotRef.value = reportType;
  refreshReport();
  isSnapshotVisible.value = true;
};

const getSnapshotTitle = (reportType) => {
  return snapshotReportSet[reportType].title;
};
const setActiveDate = async(dateObject) => {
  applyOffset(0);
  setLoading(true);

  await processDates(dateObject);

  if (['xs', 'sm'].includes(mq.current)) {
    window.scrollTo(0, sidebar.value.getBoundingClientRect().bottom - 65);
  }

  refreshReport();
  isDateFilterActive.value = false;
};
const processDates = async(dateObject) => {
  if (isRolldate(dateObject)) {
    dateObject.date_from2 = `${dateObject.date_from}-1y`;
    dateObject.date_to2 = `${dateObject.date_to}-1y`;
    await fetchDates(dateObject);
  }
  dateParams.value = dateObject;

  applyDate(fetchedDates.value);
};
const toggleDateFilter = () => {
  isDateFilterActive.value = !isDateFilterActive.value;
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

:deep(.sidebar-before-header) {
  display: flex;
  flex-direction: column;
}

:deep(.area.customer .container) {
  padding-bottom: 100px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-bottom: var(--spacing-2);
  }
}

:deep(.date-filter.container) {
  margin-bottom: 88px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-bottom: var(--spacing-3);
  }
}

.container {
  margin-top: var(--spacing-3);
  margin-bottom: var(--spacing-3);
}

.sidebar-menu {
  .snapshot-select {
    margin-bottom: var(--spacing-2);
  }
}

.today-container {
  display: inline-flex;

  .edit-note-container {
    margin-right: var(--spacing-1);
    display: flex;
  }
}

.snapshot-title {
  text-align: center;
  text-decoration: underline;
  margin: 0 auto var(--spacing-3);
  color: var(--colour-utility-action);
  text-decoration: none;
}

:deep(.menu-filter .filter-select__selection) {
  white-space: initial;
}

.get-started-text {
  margin: var(--spacing-1) var(--spacing-2);

  strong {
    margin-right: var(--spacing-1);
  }

  &.select-property-text {
    position: relative;
    top: 60px;
  }
}
</style>
