<template>
  <AddEditProspect
    :title="t('adding_a_prospect')"
    :saved="saved"
    :fields="fields"
    @submit="save"
    @edit="edit"
    @close="close"
  />
</template>

<script>
import AddEditProspect from '@/crm/components/Prospect/AddEditProspect.vue';
import { mapState, mapActions } from 'vuex';
import { GET_FIELDS, CLEAR_FIELDS, CLEAR_BY_ID, POST_BY_ENTITY } from '@/shared/store/actionType';
import { tCrm as t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';
import { baseUrl, prospectsArea } from '@/crm/router/urlBits';

export default {
  name: 'AddProspectView',
  components: {
    AddEditProspect,
  },
  emits: ['closeModal'],
  data() {
    return {
      prospectId: this.$route.params.id,
      saved: false,
    };
  },
  computed: {
    ...mapState({
      entity: state => state.system.entity,
      prospect: state => state.crm.prospects.selected,
      fields: state => state.crm.prospects.fields,
    }),
  },
  mounted() {
    this.getProspectFields();
  },
  unmounted() {
    this.clearFields();
    this.clearById();
    this.clearProspectById();
  },
  methods: {
    t,
    ...mapActions({
      getProspectFields: `crm/prospects/${GET_FIELDS}`,
      clearFields: `crm/prospects/${CLEAR_FIELDS}`,
      clearProspectById: `crm/prospects/${CLEAR_BY_ID}`,
      clearById: `crm/contacts/${CLEAR_BY_ID}`,
      postProspect: `crm/prospects/${POST_BY_ENTITY}`,
    }),
    async save(payload, setSubmitting) {
      setSubmitting(true);
      let { id } = this.$route.params;
      await this.postProspect({
        id: id,
        entity: this.entity,
        payload: payload,
      });

      setSubmitting(false);
      if (this.prospect.loaded) {
        this.getProspectFields(id);
        this.saved = true;
      }
    },
    close() {
      navigateToUrl(`${baseUrl}/${prospectsArea}`);
    },
    edit() {
      let { id } = this.prospect.data;
      navigateToUrl(`${baseUrl}/${prospectsArea}/${id}/edit`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
