<template>
  <div
    v-if="items && items.length"
    class="tabs"
    :class="appliedMqClasses"
    :style="cssVars"
  >
    <div
      role="tablist"
      class="tabs-bar container container--fluid"
    >
      <div
        class="slide-group__wrapper"
        :class="{ padded: breakpoints.mdAndUp.includes(mq.current) }"
      >
        <div
          ref="tabscontent"
          class="slide-group__content"
        >
          <div
            v-for="(item, index) in items"
            :id="item.title"
            :key="index"
            :ref="`tab-${index}`"
            tabindex="0"
            :aria-selected="index === selected ? true : null"
            role="tab"
            class="tab"
            :class="{ 'tab--active': index === selected, disabled: item.disabled }"
            @click.stop="navClicked(index, item)"
            @keypress.space="navClicked(index, item)"
          >
            <slot
              :item="item"
              :index="index"
              :icon-color="iconColor"
              :icon-siz="iconSize"
            >
              <IconBase
                v-if="item.showIcon"
                :icon-name="item.icon"
                :icon-color="iconColor"
                :width="iconSize"
                :height="iconSize"
                class="mr-1"
                :disabled="item.disabled"
              />
              {{ t(item.title) }}
              <div 
                v-if="item.amount"
                class="indicator-container"
              >
                <span>{{ item.amount }}</span>
              </div>
            </slot>
          </div>
        </div>
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
import { IconBase } from '@sales-i/dsv3';
import breakpoints from '@/shared/utils/breakpoints';
import { tCrm as t } from '@sales-i/utils';

const FONT_SIZE = 'var(--font-size-3)';
const TAB_PADDING = 'var(--spacing-1) var(--spacing-3)';
const TAB_INDENT = '0px';
// TODO: rewrite this component to CAPI

export default {
  name: 'Tabs',
  components: { IconBase },
  inject: ['mq'],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedIndex: {
      type: Number,
      default: 0,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    twoway: {
      type: Boolean,
      default: true,
    },
    iconColor: {
      type: String,
      default: 'var(--colour-utility-base)',
    },
    iconSize: {
      type: Number,
      default: 24,
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
    forceSameView: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['navClicked', 'tabSelected'],
  data() {
    return {
      breakpoints,
      selected: this.selectedIndex,
      scrollAmount: 0,
      elementToScroll: 'tabscontent',
    };
  },
  computed: {
    cssVars() {
      return {
        '--tab-font-size': this.styles.fontSize ? this.styles.fontSize : FONT_SIZE,
        '--tab-padding': this.styles.tabPadding ? this.styles.tabPadding : TAB_PADDING,
        '--tab-indent': this.styles.indent ? this.styles.indent : TAB_INDENT,
      };
    },
    appliedMqClasses() {
      return this.forceSameView ? {} : this.mq;
    },
  },
  watch: {
    selectedIndex(value) {
      if (this.twoway) this.selected = value;
    },
  },
  unmounted() {
    window.removeEventListener('resize', this.onWindowResize);
  },
  mounted() {
    window.addEventListener('resize', this.onWindowResize);
  },
  methods: {
    t,
    onWindowResize() {
      this.scrollAmount = 0;
    },
    navClicked(index, item) {
      if (item.disabled) return;
      this.$emit('navClicked', {
        index,
        item,
      });
      this.selected = index;
      this.$emit('tabSelected', this.$refs[`tab-${index}`][0].id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.slide-group__wrapper {
  position: relative;
  contain: content;
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}

.slide-group__content {
  display: flex;
  flex: 1 0 auto;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  overflow: hidden;
  flex-grow: 1;
  flex-basis: 0;

  background: linear-gradient(to right, var(--colour-panel-action) 0%, rgba(255, 255, 255, 0.2)),
    linear-gradient(to right, rgba(255, 255, 255, 0.2), var(--colour-panel-action) 100%) 0 100%,
    linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2)) 0 100%;

  background-repeat: no-repeat;
  background-size: 20px 100%, 20px 100%, 20px 100%, 20px 100%;
  background-position: 0 0, 100%, 0 0, 100%;
  background-attachment: local, local, scroll, scroll;

  overflow: auto;
  transition: all 0.2s;
  position: relative;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    background: initial;
  }
}

.tabs {
  border-bottom: 2px solid var(--colour-panel-g-16);
  margin-bottom: var(--spacing-4);

  .tabs-bar {
    height: auto;
    .tab {
      &:first-child {
        margin-left: var(--tab-indent);
      }
      align-items: center;
      cursor: pointer;
      display: flex;
      flex: 0 1 auto;
      justify-content: center;
      max-width: 360px;
      outline: none;
      text-decoration: none;
      user-select: none;
      border-radius: 0;
      color: var(--colour-utility-base) !important;
      font-size: var(--tab-font-size);
      font-weight: var(--font-weight-medium);
      border-bottom: 3px solid transparent;
      padding: var(--tab-padding);
      line-height: normal;
      // NEW-TEMPLATE
      margin-right: 14px;
      //

      &:hover {
        background-color: var(--colour-panel-g-2);
        transition: 0.5s ease;
      }

      &.tab--active {
        color: var(--colour-utility-black) !important;
        border-bottom: 3px solid var(--colour-utility-black);
        transition: border-color 1s ease;
        &:hover {
          background-color: var(--colour-panel-g-24);
          transition: 0.5s ease;
        }
      }

      &.disabled {
        color: var(--colour-panel-g-24);
        pointer-events: none;
      }
    }
  }

  &.sm,
  &.xs {
    display: none;
    @media (min-height: 500px) {
      display: block;
    }
    position: fixed;
    z-index: 100;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: 0px;
    padding: 0px;
    .tabs-bar {
      height: auto;
      background-color: var(--colour-panel-action) !important;
      padding: 0;
      .slide-group__wrapper {
        display: flex;
        align-items: center;
        .tab {
          flex-grow: 1;
          height: 40px;
          font-size: var(--font-size-5);
          padding: var(--spacing-1);
          margin: var(--spacing-half);
          border-bottom: none !important;
          background-color: var(--colour-utility-white);
          border-radius: 100px;
          color: var(--colour-brand-deluge) !important;
          &.tab--active {
            background-color: var(--colour-utility-black);
            color: var(--colour-utility-white) !important;
            transition: background-color 1s ease;
          }

          z-index: -1;

          @media #{map-get($display-breakpoints, 'xs-only')} {
            font-size: var(--font-size-small);
            padding: var(--spacing-1) var(--spacing-2);
          }

        }
      }
      
      .slide-group__content {
        overflow-x: auto;
        padding: var(--spacing-3) var(--spacing-2);
        z-index: 1;
      }
    }
  }

  .indicator-container {
    color: var(--colour-data-mandy-label);
    background: var(--colour-data-mandy-light);
    font-size: var(--font-size-small);
    padding: var(--spacing-half);
    border-radius: 50%;
    height: 20px;
    width: 20px;
    text-align: center;
    position: relative;
    bottom: var(--spacing-1);
    left: var(--spacing-1);
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
