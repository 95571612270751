<template>
  <div>
    <InteractionTimescaleForm
      class="form-content"
      :title="t('do_you_need_to_schedule_a_follow_up_interaction')"
      :is-generate="false"
      :is-open-follow-up="true"
      :enabled-schedules="['schedule']"
      :date-time="currentlyEditing.dateTime"
      :start-hours="startHours"
      :start-minutes="startMinutes"
      :duration-hours="durationHours"
      :duration-minutes="durationMinutes"
      @set-date="setDate"
    />
    <div class="form-actions">
      <CustomButton
        v-scroll-to-top
        purpose="action"
        @click="onFinishButtonClick"
      >
        {{ buttonLabel }}
      </CustomButton>
    </div>
  </div>
</template>
<script>
import { CustomButton } from '@sales-i/dsv3';
import { CLEAR_CURRENTLY_EDITING, SET_DATE, SET_RELATED_TO } from '@/crm/store/actionType';
import { mapActions, mapGetters, mapState } from 'vuex';
import InteractionTimescaleForm from '@/crm/components/Calendar/InteractionTimescaleForm.vue';
import { tCrm as t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits';

export default {
  name: 'AddInteractionFollowUp',
  components: {
    InteractionTimescaleForm,
    CustomButton,
  },
  emits: ['stageUpdate'],
  computed: {
    ...mapState({
      currentlyEditing: state => state.crm.interactions.currentlyEditing,
    }),
    ...mapGetters({
      startHours: 'crm/interactions/startHours',
      startMinutes: 'crm/interactions/startMinutes',
      durationHours: 'crm/interactions/durationHours',
      durationMinutes: 'crm/interactions/durationMinutes',
    }),
    buttonLabel() {
      return this.currentlyEditing.dateTime ? t('continue') : t('finish');
    },
  },
  watch: {
    // if date is set it's already Stage 1 of adding new interaction.
    'currentlyEditing.dateTime'(value) {
      if (value) this.$emit('stageUpdate', 'Timescale');
    },
  },
  mounted() {
    this.$emit('stageUpdate', 'Schedule follow up');
    if (this.currentlyEditing.backdated) {
      this.setDate({ schedule: 'backdated' });
    }
  },
  methods: {
    t,
    ...mapActions({
      setRelatedInteraction: `crm/interactions/${SET_RELATED_TO}`,
      setDate: `crm/interactions/${SET_DATE}`,
      clearCurrentlyEditing: `crm/interactions/${CLEAR_CURRENTLY_EDITING}`,
    }),
    async onFinishButtonClick() {
      if (this.currentlyEditing.dateTime) {
        await this.setRelatedInteraction(this.$route.params.id);
        navigateToUrl(`${baseUrl}/${interactionsArea}/add/details?clean=false`);
      } else {
        this.clearCurrentlyEditing();
        navigateToUrl(`${baseUrl}/${interactionsArea}`);
      }
    },
  },
};
</script>
