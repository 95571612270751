<template>
  <CRMCard
    :id="id"
    :on-click="() => emit('open')"
    :title="title"
    :links="sectionLinks"
  >
    <template #info>
      {{ description }}
    </template>
  </CRMCard>
</template>

<script setup>
import { CRMCard } from '@sales-i/dsv3';
import { computed } from 'vue';

const props = defineProps({
  description: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  cardPurpose: {
    type: String,
    default: 'reversed',
  },
  icon: {
    type: Boolean,
    default: false,
  },
  linkText: {
    type: String,
    default: '',
  },
  sectionLinks: {
    type: Array,
    default: () => []
  }
});
const emit = defineEmits(['open']);
const id = computed(() => {
  const refinedTitle = props.title.replace(/ /g, '-').toLowerCase();
  return `card-${refinedTitle}`;
});
</script>

<style lang="scss" scoped>
</style>
