<template>
  <div
    :class="`customer-details ${mq.current}`"
    class="container container--fluid bg"
  >
    <!-- FOR DEMO PURPOSES, REMOVE AFTER -->
    <div
      v-if="isNapa && tripwires.length > 0"
      class="col col-12 padded"
    >
      <h3>Alerts for this Customer</h3>
      <p>What tripwires has this customer triggered in the most recent alert reports</p>
      <p
        v-for="item, index in tripwires"
        :key="item"
        :class="`tripwire ${getRagStatus(index)}`"
      >
        <strong>{{ item }}.</strong>
        &nbsp;
        <a
          :href="downloadCampaignFlyerUrl"
          target="_blank"
        >Download Campaign Flyer</a>
      </p>
    </div>
    <!-- END -->
    <div class="padded">
      <div class="row">
        <div
          class="col col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4"
          :class="{
            'pr-4': mq.current !== 'xs',
            'mb-3': breakpoints.mdAndDown.includes(mq.current),
          }"
        >
          <CustomerProspectDetails :customer="customerDetails" />
        </div>
        <!-- the id here is primarily for automated testing -->
        <div
          id="crm-customers-default-contact-section"
          class="col col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4"
          :class="{
            'pr-4': breakpoints.lgAndUp.includes(mq.current),
            'mb-3': breakpoints.mdAndDown.includes(mq.current),
          }"
        >
          <DefaultContact
            entity-type="customer"
            :entity-name="customerDetails.name"
            :entity-id="id"
          />
        </div>
        <div class="col col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
          <SchemaLinks :items="customerDetailsLinks" />
        </div>
      </div>
      <EnquiriesDataForCustomer :customer-name="customerDetails.name" />
    </div>
  </div>
</template>

<script>
import breakpoints from '@/shared/utils/breakpoints';
import CustomerProspectDetails from '@/crm/components/CustomerProspect/CustomerProspectDetails.vue';
import SchemaLinks from '@/crm/components/SchemaLinks/SchemaLinks.vue';
import DefaultContact from '@/crm/components/Contacts/DefaultContact.vue';
import { baseUrl, opportunitiesArea } from '@/crm/router/urlBits';
import { APPLY_NEW_FILTER } from '@/intelligence/store/actionType';
import { SET_ITEMS } from '@/shared/store/actionType';
import EnquiriesDataForCustomer from './EnquiriesDataForCustomer.vue';
import { tCrm as t } from '@sales-i/utils';

// FOR DEMO PURPOSES, REMOVE AFTER
import useJwt from '@/shared/composables/useJwt';
import axios from 'axios';
const { isNapa } = useJwt();
// END

import { mapActions, mapGetters, mapState } from 'vuex';
import { SNAPSHOT_SUMMARY } from '@/intelligence/store/data/reportTypes';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'CustomerDetailsView',
  components: {
    CustomerProspectDetails,
    SchemaLinks,
    DefaultContact,
    EnquiriesDataForCustomer,
  },
  inject: ['mq'],
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    sectionsTitles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      breakpoints,
      iconColor: 'black',
      desktopSize: ['xl', 'lg', 'md'],
      mobileSize: ['sm', 'xs'],
      // FOR DEMO PURPOSES, REMOVE AFTER
      tripwires: [],
      isNapa: false,
      // END
    };
  },
  computed: {
    ...mapState({
      customerDetails: state => {
        return state.crm.customers.selected.data;
      },
    }),
    ...mapGetters({
      isDemo: 'system/isDemo',
    }),
    downloadCampaignFlyerUrl() {
      return 'https://demous-images.sell-smart.com/tenants/00902939-d9b1-4bbd-80f5-9d2cc8e586f7/flyer.pdf';
    },
    customerDetailsLinks() {
      return [
        {
          title: t('comprehensive_snapshot'),
          description: t('an_overview_of_helpful_information'),
          func: this.openSnapshotSummary,
        },
        {
          title: t('opportunities'),
          description: t('view_all_opportunities'),
          to: {
            path: this.getLink(),
            query: {
              filter: this.customerDetails.record_type,
              search: this.customerDetails.name,
            },
          },
        },
      ];
    },
  },
  watch: {
    $route() {
      this.updateCustomerDetailsLinks();
    },
  },
  async mounted() {
    this.updateCustomerDetailsLinks();
    // FOR DEMO PURPOSES, REMOVE AFTER
    await this.getCustomerTripwires();
    this.isNapa = await isNapa();
    // END
  },
  methods: {
    ...mapActions({
      applyNewFilter: `intelligence/templateReport/${APPLY_NEW_FILTER}`,
      setAlerts: `alerts/${SET_ITEMS}`,
    }),
    getLink() {
      return `${baseUrl}/${opportunitiesArea}/?status=open&type=missed&filter=customer&search=${this.customerDetails.name}`;
    },
    // FOR DEMO PURPOSES, REMOVE AFTER
    getRagStatus(index) {
      switch (index) {
      case 0:
        return 'red';
      case 1:
        return 'amber';
      default:
        return 'green';
      }
    },
    async getCustomerTripwires() {
      try {
        const $axios = axios.create();
        const tripwires = await $axios({
          method: 'get',
          url: 'https://demous-images.sell-smart.com/tenants/00902939-d9b1-4bbd-80f5-9d2cc8e586f7/tripwires.txt',
        });

        this.tripwires = tripwires.data.split('\n');
      } catch (e) {
        // The error doesn't matter
      }
    },
    // END
    openSnapshotSummary() {
      this.applyNewFilter(['snapshotBubbleValue', this.customerDetails.name]);
      this.applyNewFilter(['snapshotBubbleValueId', this.customerDetails.account_number]);
      const route = this.$router.resolve({ name: SNAPSHOT_SUMMARY });
      navigateToUrl(route.href);
    },
    updateCustomerDetailsLinks() {
      // this doesn't seem wise to do it like this,
      // but assuming we are pulling a language pack
      // in the future, we will still need them as variables.
      this.customerDetailsLinks[1].description = t('view_all_opportunities_for_entity', {
        entity: this.customerDetails.name,
      });
      this.customerDetailsLinks[1].path = `${baseUrl}/${opportunitiesArea}?filter=customer&search=${this.customerDetails.name}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background-color: var(--colour-panel-g-2);
  flex: 1;
}

.customer-details {
  .card-list {
    border-radius: var(--border-radius-1);
  }
  &.xs {
    .card-list {
      border-radius: unset;
      box-shadow: unset;
      margin-right: calc(var(--spacing-2) * -1) !important;
      margin-left: calc(var(--spacing-2) * -1) !important;
    }
  }
}

.md,
.lg,
.xl {
  .customer-contact-details {
    margin-left: var(--spacing-2) !important;
  }
}

// FOR DEMO PURPOSES, REMOVE AFTER
.tripwire {
  color: white;
  border-radius: var(--border-radius-1);
  padding: var(--spacing-1);
  margin: var(--spacing-1);

  &.red {
    background-color: var(--colour-data-mandy-background);
    color: var(--colour-data-mandy-label);
  }
  &.amber {
    background-color: var(--colour-data-barberry-background);
    color: var(--colour-data-barberry-label);
  }
  &.green {
    background-color: var(--colour-data-de-york-background);
    color: var(--colour-data-de-york-label);
  }
}
// END
</style>
