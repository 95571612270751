<template>
  <div class="expand-container">
    <TabSwitch
      v-if="isExpanded && enableSwitch"
      class="switch"
      :selected-value="visibleDataType"
      :items="switchItems"
      @switch-selected="switchSelected"
    />
    <div class="expand-btn">
      <CustomButton
        round
        icon-only
        :icon-color="iconColor"
        :icon-name="icon"
        :icon-width="20"
        :icon-height="20"
        :width="48"
        :height="48"
        class="expand-button"
        :class="{ rotate: isExpanded }"
        @on-click="toggleExpandTable"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { TabSwitch, CustomButton } from '@sales-i/dsv3';
import { tCrm as t } from '@sales-i/utils';
import useShared from '@/intelligence/composables/useShared';

const props = defineProps({
  iconColor: {
    type: String,
    default: 'var(--colour-utility-action)',
  },
  enableSwitch: {
    type: Boolean,
    default: false,
  },
  visibleDataType: {
    type: String,
    default: 'data',
    validator(value) {
      return ['data', 'graph'].includes(value);
    },
  },
});

const emit = defineEmits(['fullScreenDataTypeChange']);

const store = useStore();
const { expanded, setExpanded } = useShared({ store });

const switchItems = computed(() => [
  {
    title: t('data'),
    value: 'data',
  },
  {
    title: t('graph'),
    value: 'graph',
  },
]);
const icon = computed(() => (expanded.value ? 'close-line' : 'expand'));

const toggleExpandTable = () => setExpanded(!expanded.value);
const switchSelected = (dataType) => {
  if (props.visibleDataType !== dataType.value) 
    emit('fullScreenDataTypeChange', dataType.value);
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.expand-container {
  display: flex;
  align-items: center;
  column-gap: var(--spacing-2);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    display: none;
  }
}

.expand-btn {
  .button.action {
    background-color: var(--colour-panel-action);
    box-shadow: none;

    svg {
      fill: var(--colour-utility-action);
    }
  }
}

.expand-button.rotate {
  transform: rotate(45deg);
}

// .expand-btn {
//   height: 48px;
//   width: 48px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: var(--colour-utility-action);
//   border-radius: 50%;

//   &:hover,
//   &:focus,
//   &:focus-within {
//     background: var(--colour-utility-focus);
//   }

//   .expand-button {
//     transform: rotate(45deg);
//     display: flex;
//     justify-content: center;
//     padding: 0;
//     box-shadow: none;
//     background: transparent;

//     &.no-rotate {
//       transform: none;
//     }
//   }
// }

.switch {
  height: 32px;
}

.expand-button {
  .expanded {
    display: flex;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      right: var(--spacing-2);
    }
  }
}
</style>
