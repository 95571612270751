<template>
  <div class="roles-form-container">
    <div class="flow">
      <CustomCheckbox
        v-for="checkbox in checkboxes"
        :id="checkbox.id"
        :key="checkbox.id"
        :label="checkbox.label"
        :value="checkedRoles[checkbox.label] || false"
        @input="setRoles($event, checkbox)"
      />
    </div>
    <div class="roles-info-panel">
      <h3>{{ t('Apply a role') }}</h3>
      <p>{{ t('Using the tickboxes, select 1 or more roles to apply to your user') }}</p>
    </div>
  </div>
  <ButtonGroup class="form-actions">
    <template v-if="true">
      <CustomButton
        v-for="action in actions"
        :key="action.label"
        :label="action.label"
        :disabled="action.disabled"
        :purpose="action.isSubmit ? 'action' : action.purpose || 'action'"
        :small="action.small || false"
        :class="`action ${valid ? '' : 'disabled'}`"
        :type="action.isSubmit ? 'submit' : 'button'"
        @on-click="
          event => {
            action.onClick(event);
          }
        "
      />
    </template>
    <template v-if="false">
      <BufferImage :size="40" />
    </template>
  </ButtonGroup>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import { BufferImage, CustomButton, CustomCheckbox } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { SET_USER_ROLES } from '@/admin/store/actionType';
import { GET_ALL, POST_BY_ENTITY, PUT_BY_ID } from '@/shared/store/actionType';
import { baseUrl, companyArea, permissionsSection, usersSection } from '@/admin/router/urlBits';
import { navigateToUrl } from 'single-spa';

const emit = defineEmits(['stageUpdate']);

const store = useStore();
const vroute = useRoute();

const userRoles = computed(() => store.state.admin.users.currentlyEditing.roles);

const setUserRoles = userRoles => store.dispatch(`admin/users/${SET_USER_ROLES}`, userRoles);
const addUser = () => store.dispatch(`admin/users/${POST_BY_ENTITY}`);
const editUser = params => store.dispatch(`admin/users/${PUT_BY_ID}`, params);

const checkedRoles = ref({});
const rolesForSubmission = ref([]);
const valid = ref(false);

const roleList = computed(() => store.state.admin.pbac.roles.data);
const getRoles = params => store.dispatch(`admin/pbac/${GET_ALL}`, params);

const checkboxes = computed(() => roleList.value.map(role => ({
  label: role.name,
  id: role.id,
  type: 'checkbox',
  required: false,
  disabled: false,
})));

const showDataRestrictionsButton = true;


const actions = ref([
  {
    label: t('Complete'),
    purpose: 'reversed', 
    small: true,
    onClick: () => {
      if (valid.value) {
        setUserRoles(rolesForSubmission.value);
        if (vroute.params.addedit === 'edit' && vroute.params.id) {
          editUser({ id: vroute.params.id });
        } else {
          addUser();
        }
        navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${usersSection}`);
      }
    },
    display: true,
    isSubmit: false
  },
  ...(showDataRestrictionsButton ? [{
    label: vroute.params.addedit === 'edit' ? t('Update data restrictions') : t('Add data restrictions'),
    small: true,
    onClick: () => {
      if (valid.value) {
        setUserRoles(rolesForSubmission.value);
        if (vroute.params.addedit === 'edit' && vroute.params.id) {
          navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${usersSection}/${vroute.params.id}/edit/data-restrictions`);
        } else {
          navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${usersSection}/add/data-restrictions`);
        }
      }
    },
    display: true,
    isSubmit: true,
  }] : [])
]);

const setRoles = (isChecked, checkbox) => {
  rolesForSubmission.value = rolesForSubmission.value.filter(r => r.id !== checkbox.id);
  if (isChecked) {
    checkedRoles.value[checkbox.label] = true;
    rolesForSubmission.value.push({
      id: checkbox.id,
      name: checkbox.label ?? checkbox.name,
    });
  } else {
    delete checkedRoles.value[checkbox.label];
  }
  valid.value = Object.keys(checkedRoles.value).length > 0;
};

const setAllRoles = (newRoles) => {
  checkedRoles.value = {};
  newRoles.forEach(val => { checkedRoles.value[val.name] = true; });
  newRoles.forEach(r => setRoles(true, r));
  valid.value = Object.keys(checkedRoles.value).length > 0;
};

onMounted(() => {
  emit('stageUpdate', 'Role');
  setAllRoles(userRoles.value);
  getRoles({
    offset: 0,
    limit: 20,
    sort: 'name:asc'
  });
});

watch(() => userRoles.value, (to, from) => {
  if (to.length !== from.length) {
    setAllRoles(to);
  }
});
</script>

<style lang="scss" scoped>
.roles-form-container {
  display: flex;
  > div {
    padding: var(--spacing-2);
  }
  .roles-info-panel {
    flex: 1;
    background-color: var(--colour-panel-alert-base);
    border-radius: var(--border-radius-1);
    min-height: 200px;
    margin-left: var(--spacing-5);
  }

  :deep(.checkbox) {
    margin-bottom: var(--spacing-1);
  }
}

.form-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: var(--spacing-2);
  background-color: var(--colour-panel-action);
  @media (min-height: 500px) {
    &.sticky-actions {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      margin-left: 0;
      width: 100%;
    }
  }
}
</style>
