<template>
  <div class="snapshot-summary-container flow">
    <!-- Product group purchase analysis -->
    <section
      v-if="hasAccessToProductGroupPurchaseAnalysis"
      class="analysis-chart-container"
      :class="{ 'no-results': !isPurchaseAnalysisDataAvailable }"
    >
      <div v-if="!analysisGraphLoading && isPurchaseAnalysisDataAvailable">
        <h2>{{ t('purchase_analysis') }}</h2>
        <LineBarChart
          :report-id="purchaseAnalysisId"
          class="purchase-analysis-chart"
        />
      </div>
      <NoResults v-if="!analysisGraphLoading && !isPurchaseAnalysisDataAvailable" />
      <BufferImage
        v-if="analysisGraphLoading"
        color="black"
        float="center"
        class="loading-spinner"
      />
    </section>

    <!-- Interaction history -->
    <InteractionHistory
      v-if="hasAccessToInteractions"
      :customer-object="customerObject"
      :last-interaction="lastInteraction"
      :missed-opportunities="missedOpportunities"
    />

    <!-- Trends -->
    <section
      v-if="hasAccessToTrends"
      class="trends-container"
      :class="{ 'no-results': !reportId && !dataLoading }"
    >
      <div v-if="!dataLoading">
        <h2>{{ t('performance') }}</h2>
        <MultilineChart 
          :chart-data="chartData" 
          :format-func="cPrice"
          :legend-labels="legendLabels"
        />
      </div>
      <NoResults v-if="!reportId && !dataLoading" />
      <BufferImage
        v-if="dataLoading"
        color="black"
        float="center"
        class="loading-spinner"
      />
    </section>

    <!-- Potential opportunities -->
    <div
      v-if="hasAccessToPotentialOpportunities"
      class="potential-container"
    >
      <h2>{{ t('potential') }}</h2>
      <p>
        {{ potentialOpportunities?.length || 0 }}
        {{ t('selling_suggestions_for_this_interaction') }}
      </p>
      <SummaryOpportunityCard
        v-for="(item, index) in potentialOpportunities"
        :key="index"
        :item="item"
        :title="`${t('you_could_sell')}:`"
        class="opportunity-card"
      >
        <template #info>
          <TogglableProductsLabel
            :opportunity-item="item"
            :opportunity-type="'POTENTIAL'"
            enable-product-code
          />
        </template>
      </SummaryOpportunityCard>
    </div>

    <ReportFooter
      class="report-footer"
      :report-type="CUSTOMER_SNAPSHOT"
      :interaction-id="customerObject.interactionId"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { tCrm as t } from '@sales-i/utils';
import { BufferImage } from '@sales-i/dsv3';
import LineBarChart from '@/intelligence/components/SnapshotReport/Charts/LineBarChart';
import InteractionHistory from '@/intelligence/components/SnapshotReport/Summary/InteractionHistory';
import SummaryOpportunityCard from '@/intelligence/components/SnapshotReport/Summary/SummaryOpportunityCard.vue';
import MultilineChart from '@/intelligence/components/Dashboard/Elements/MultilineChart';
import { CUSTOMER_SNAPSHOT, PRODUCT_GROUP_PURCHASE_ANALYSIS } from '@/intelligence/store/data/reportTypes';
import NoResults from '@/intelligence/components/Shared/NoResults';
import ReportFooter from '@/intelligence/components/ReportFooter/ReportFooter';
import TogglableProductsLabel from '@/intelligence/components/SnapshotReport/TogglableProductsLabel.vue';
import usePermissions from '@/shared/composables/usePermissions';
import useShared from '@/intelligence/composables/useShared';
import { useOpportunity } from '@/crm/composables/useOpportunity';
import { useInteraction } from '@/crm/composables/useInteraction';
import useTemplateReport from '@/intelligence/composables/useTemplateReport';
import useLastInvoice from '@/intelligence/composables/useLastInvoice';
import useTrends from '@/intelligence/composables/useTrends';
import useCurrency from '@/shared/composables/useCurrency';

const store = useStore();
const { 
  hasAccessToTrends,
  hasAccessToProductGroupPurchaseAnalysis,
  hasAccessToInteractions,
  hasAccessToPotentialOpportunities, 
} = usePermissions({ store });

const props = defineProps({
  customerObject: {
    type: Object,
    default: () => ({}),
  },
});

//trends data id
const reportId = ref(null);

const { requestParameters, getReportData, fetchReportData } = useShared({ store });
const { getOpportunitiesWithProducts } = useOpportunity({ store });
const { interactions, fetchLastCustomerInteraction } = useInteraction({ store });
const { fetchBubbleIds, saveBubbleData } = useTemplateReport({ store });
const { fetchLastInvoice } = useLastInvoice({ store });
const { cPrice } = useCurrency({ store });
const { dataLoading, chartData, legendLabels, loadData } = useTrends({ reportId, store });
  
const missedOpportunities = ref([]);
const potentialOpportunities = ref([]);
const analysisGraphLoading = ref(true);
const purchaseAnalysisId = ref(null);

const purchaseAnalysisData = computed(() => getReportData(purchaseAnalysisId.value) || []);
const isPurchaseAnalysisDataAvailable = computed(() => purchaseAnalysisData.value.length > 0);
const lastInteraction = computed(() => interactions.value.all.data?.[0] || {});

onMounted(async () => {
  if (!requestParameters.value.productBubbleId) 
    await fetchBubbleIds();
  saveBubbleData();

  fetchLastCustomerInteraction(props.customerObject.id);
  fetchLastInvoice({ customer_id: props.customerObject.id });
  fetchOpportunities();

  renderAnalysisChart();
  // trends
  reportId.value = await loadData();
});

async function fetchOpportunities() {
  missedOpportunities.value = await getOpportunitiesWithProducts({
    type: ['MISSED'],
    sort: 'decision_date:desc',
    entity_id: props.customerObject.id,
  });
  potentialOpportunities.value = await getOpportunitiesWithProducts({
    type: ['POTENTIAL'],
    sort: 'value:desc',
    entity_id: props.customerObject.id,
  });
}

async function renderAnalysisChart() {
  purchaseAnalysisId.value = await fetchReportData({
    reportType: PRODUCT_GROUP_PURCHASE_ANALYSIS,
    customParams: { 
      snapshotBubbleValueId: [`"${props.customerObject.account_number}"`]
    },
  });
  analysisGraphLoading.value = false;
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.snapshot-summary-container {
  position: relative;
  margin: 0 auto;
  max-width: 900px;
}

.report-footer {
  margin-top: 0;
}
.loading-spinner {
  position: absolute;
  top: calc(50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}

section {
  position: relative;
}

h2 {
  text-align: center;
  font-size: var(--font-size-3);
}

p {
  text-align: center;
  font-size: var(--font-size-small);
  margin-bottom: var(--spacing-2);
}

.analysis-chart-container,
.trends-container,
.potential-container {
  width: 100%;
  border-radius: var(--border-radius-1);
  position: relative;
  background: var(--colour-panel-g-0);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);
  padding: var(--spacing-2) var(--spacing-1);
}

.no-results {
  padding: 0;
  min-height: 350px;
}

.analysis-chart-container {
  margin-top: 0;
}

.heading-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: var(--spacing-1);
  overflow: hidden;
  margin-bottom: var(--spacing-4);
}

.purchase-analysis-chart {
  margin-bottom: 0;
}

.trends-chart-container {
  margin-top: var(--spacing-2);
  margin-bottom: 0;
}

.potential-container {
  padding-left: 0;
  padding-right: 0;

  .opportunity-card {
    border-radius: 0;
    box-shadow: none;

    :deep(.card-title) {
      font-size: var(--font-size-4);
      font-weight: var(--font-weight-regular);
      margin-bottom: var(--spacing-1);
    }

    strong {
      margin-left: var(--spacing-2);
    }
  }
}
</style>
