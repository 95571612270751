<template>
  <div v-if="permissions.read">
    <SignPost
      :heading="t('enquiries')"
      :description="t('drill_into_your_sales_data_with_ease')"
      color="var(--colour-utility-action)"
      background-color="var(--colour-panel-action)"
      :links="enquiriesLinks"
    />
    <SignPost
      :heading="t('insights')"
      :description="t('better_understand_your_sales_data_with_helpful_insights')"
      color="var(--colour-data-puerto-rico-dark)"
      background-color="var(--colour-panel-performance)"
      :links="insightsLinks"
    />
    <SignPost
      :heading="t('alerts')"
      :description="t('setup_alerts_to_stay_informed_on_what_you_care_about')"
      color="var(--colour-data-turmeric-label)"
      background-color="var(--colour-data-turmeric-light)"
      :links="alertsLinks"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { tCrm as t } from '@sales-i/utils';
import SignPost from '@/admin/views/SignPost.vue';
import usePermissions from '@/shared/composables/usePermissions';
import { intelligence_enquiries } from '@/shared/store/data/policies';
import { alertBuilderScope, alertsScope, baseUrl, enquiryScope, insightsScope } from '@/intelligence/router/urlBits';
import { FULL_PICTURE, MATRIX, SALES_VS_GAPS, TRENDS, VARIANCE } from '@/intelligence/store/data/reportTypes';
import { REPORT_AREA_CUSTOMER, REPORT_AREA_PERFORMANCE, REPORT_AREA_PRODUCT } from '@/intelligence/store/data/areas';

const { getPermissions } = usePermissions();
const permissions = getPermissions(intelligence_enquiries);

const enquiriesLinks = computed(() => {
  const arr = [];
  arr.push({
    title: t('full_picture'),
    description: t('see_the_full_picture'),
    href: `${baseUrl}/${enquiryScope}/${FULL_PICTURE}?cc=0`,
  });
  arr.push({
    title: t('variance'),
    description: t('gauge_variance_between_two_periods_in_up_to_four_k_p_is_at_once'),
    href: `${baseUrl}/${enquiryScope}/${VARIANCE}?cc=0`,
  });
  arr.push({
    title: t('matrix'),
    description: t('two_dimensions_are_better_than_one'),
    href: `${baseUrl}/${enquiryScope}/${MATRIX}?cc=0`,
  });
  arr.push({
    title: t('trends'),
    description: t('compare_year_on_year_trends'),
    href: `${baseUrl}/${enquiryScope}/${TRENDS}?cc=0`,
  });
  arr.push({
    title: t('sales_vs_gaps'),
    description: t('analyse_gaps_in_your_sales_over_time'),
    href: `${baseUrl}/${enquiryScope}/${SALES_VS_GAPS}?cc=0`,
  });
  return arr;
});

const insightsLinks = computed(() => {
  const arr = [];
  arr.push({
    title: t('performance'),
    description: t('reports_to_better_understand_sales_performance'),
    href: `${baseUrl}/${insightsScope}/${REPORT_AREA_PERFORMANCE}`,
  });
  arr.push({
    title: t('products'),
    description: t('see_which_products_are_selling_well'),
    href: `${baseUrl}/${insightsScope}/${REPORT_AREA_PRODUCT}`,
  });
  arr.push({
    title: t('customer'),
    description: t('reports_that_enable_you_to_sell_smarter_to_your_customers'),
    href: `${baseUrl}/${insightsScope}/${REPORT_AREA_CUSTOMER}`,
  });
  return arr;
});

const alertsLinks = computed(() => {
  const arr = [];
  arr.push({
    title: t('alerts'),
    description: t('view_previously_created_alerts'),
    href: `${baseUrl}/${alertsScope}`,
  });
  arr.push({
    title: t('alert_builder'),
    description: t('setup_alerts_for_sales_c_r_m_and_product_data'),
    href: `${baseUrl}/${alertsScope}/${alertBuilderScope}`,
  });
  return arr;
});
</script>

<style lang="scss" scoped>
.enquiries-baseview-container {
  padding: var(--spacing-4);
  > div {
    border-radius: var(--border-radius-1);
  }
}
</style>
