import { getAllMutations, getAllActions } from './getAll';
import { getChildrenMutations, getChildrenActions } from './getChildren';
import { getByIdMutations, getByIdActions  } from './getById';
import { getSectionMutations, getSectionActions  } from '@/shared/store/modules/customSections/getCustomSection';
import { putByIdMutations, putByIdActions  } from '@/shared/store/modules/customSections/putCustomSection';
import { getContactsMutations, getContactsActions, getContactsGetters } from './getContacts';
import { getAddressesMutations, getAddressesActions } from './getAddresses';
import { getCustomerEmailsMutations, getCustomerEmailsActions } from './customerEmails';
import { tCrm as t } from '@sales-i/utils';

// initial state
const state = () => ({
  id: undefined,
  all: {
    data: [],
    fields: [],
    percentage: 0,
    loaded: false,
    loading: false,
  },
  selected: {
    data: {},
    loaded: false,
    loading: false,
  },
  customSection: {
    data: {},
    loaded: false,
    loading: false,
  },
  children: {
    data: [],
    loading: false,
    loaded: false,
    isRolledUp: false,
  },
  contacts: {
    data: [],
    loaded: false,
    loading: false,
  },
  customerEmails: {
    viewEmails: false,
    onlyUnread: false,
    summary: [],
    summaryLoading: false,
  },
  addresses: {
    data: [],
    loaded: false,
    loading: false,
  },
  loaded: false,
  loading: false,
  entity: 'customers',
  sorting: [
    {
      text: t('a_z_name'),
      value: 'name:asc',
    },
    {
      text: t('z_a_name'),
      value: 'name:desc',
    },
    {
      text: t('a_z_account_number'),
      value: 'account_number:asc',
    },
    {
      text: t('z_a_account_number'),
      value: 'account_number:desc',
    },
    {
      text: t('date_created_oldest_first'),
      value: 'created_date:asc',
    },
    {
      text: t('date_created_newest_first'),
      value: 'created_date:desc',
    },
    {
      text: t('date_updated_oldest_first'),
      value: 'updated_date:asc',
    },
    {
      text: t('date_updated_newest_first'),
      value: 'updated_date:desc',
    },
  ],
});

// getters
const getters = {
  ...getContactsGetters
};

// mutations
const mutations = {
  ...getAllMutations,
  ...getChildrenMutations,
  ...getByIdMutations,
  ...getContactsMutations,
  ...getSectionMutations,
  ...getAddressesMutations,
  ...putByIdMutations,
  ...getCustomerEmailsMutations
};

// actions
const actions = {
  ...getAllActions,
  ...getChildrenActions,
  ...getByIdActions,
  ...getContactsActions,
  ...getSectionActions,
  ...getAddressesActions,
  ...putByIdActions,
  ...getCustomerEmailsActions
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
