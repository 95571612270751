import {
  PUT_BY_ID,
  PUT_BY_ID_LOADING,
  PUT_BY_ID_ERROR,
  POST_BY_ENTITY,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
// import mapContactPayload from '@/crm/store/utils/mapContactPayload';

// mutations
export const putByIdMutations = {
  [PUT_BY_ID]: (state, data) => {
    state.selected.data = data;
    state.selected.updated = true;
    state.selected.loading = false;
    state.selected.loaded = true;
  },
  [PUT_BY_ID_LOADING]: (state) => {
    state.selected.loading = true;
    state.selected.loaded = false;
  },
  [PUT_BY_ID_ERROR]: (state) => {
    state.selected.updated = false;
    state.selected.loading = false;
    state.selected.loaded = false;
  },
};

// actions
export const putByIdActions = {
  [PUT_BY_ID]: async ({ dispatch, commit, state: { selected } }, params) => {
    const { prospect_id, payload } = params;
    const addressId = selected.data.addresses.length > 0 ? selected.data.addresses[0].id : false;
    const defaultContactId = selected.data.default_contact_id;
    commit(PUT_BY_ID_LOADING);
    let customerAddress = {
      address_line_1: payload.customer_address_line_1,
      address_line_2: payload.customer_address_line_2,
      address_line_3: payload.customer_address_line_3,
      address_line_4: payload.customer_address_line_4,
      town: payload.customer_town,
      county: payload.customer_county,
      post_code: payload.customer_post_code,
      country: payload.customer_country
    };
    if (addressId) {
      customerAddress.id = addressId;
    }
    let customer = {
      // this ensures any data not changed as part of the form is retained (created_date, default_contact_id etc)
      ...selected.data,
      record_type: 'prospect',
      id: payload.id,
      name: payload.name,
      tel_1: payload.customer_tel_1,
      tel_2: payload.customer_tel_2,
      email: payload.customer_email,
      fax: payload.customer_fax,
      www: payload.customer_www,
      description: payload.details_description,
      addresses: [customerAddress],
    };
    try {
      const data = await httpRequest('put', `prospects/${prospect_id}`, customer, { version: '2', });
      commit(PUT_BY_ID, {
        ...data,
        ...customer
      });

      let saveContact = false;
      let contact = {
        first_name: payload.contact_first_name,
        surname: payload.contact_surname,
        mobile_phone: payload.contact_mobile_phone,
        business_phone: payload.contact_business_phone,
        office_phone: payload.contact_office_phone,
        home_phone: payload.contact_home_phone,
        email: payload.contact_email,
        fax: payload.contact_fax,
        is_default_contact: true,
        address_line_1: payload.contact_address_line_1,
        address_line_2: payload.contact_address_line_2,
        address_line_3: payload.contact_address_line_3,
        address_line_4: payload.contact_address_line_4,
        town: payload.contact_town,
        county: payload.contact_county,
        post_code: payload.contact_post_code,
        country: payload.contact_country
      };

      Object.keys(contact).forEach(key => {
        if (contact[key] && !['is_default_contact'].includes(key)) {
          saveContact = true;
        }
      });

      if (defaultContactId) {
        await dispatch(`crm/contacts/${PUT_BY_ID}`,{
          entity: 'prospects',
          entity_id: data.id,
          contact_id: defaultContactId,
          payload: contact
        },{
          root:true
        });
      } else if (saveContact) {
        await dispatch(`crm/contacts/${POST_BY_ENTITY}`, {
          entity: 'prospects',
          id: data.id,
          payload: contact
        },{
          root: true
        });
      }
      return data;
    } catch (error) {
      commit(PUT_BY_ID_ERROR);
    }
  }
};
