<template>
  <form>
    <div class="field">
      <input
        v-model="bubbleQuery"
        class="input"
        type="text"
        tabindex="0"
        :aria-label="t('search_for_dimensions')"
        @input="handleSearch"
        @keydown.enter.prevent="handleSearch"
      >
      <CustomButton
        class="search-button"
        purpose="transparent"
        icon-name="search"
        icon-color="var(--colour-utility-black)"
        icon-only
        :icon-width="22"
        :icon-height="22"
      />
    </div>
    <CustomButton
      v-if="!isMobile"
      key="all-areas"
      class="area-link"
      purpose="text"
      :class="{ 'active': activeArea === 'All' }"
      :label="t('all')"
      :disabled="activeArea === 'All'"
      @click="selectArea('All')"
    />
    <template
      v-for="(area, index) in bubbleAreas"
      :key="`area-${index}`"
    >
      <CustomButton
        v-if="!isMobile"
        purpose="text"
        class="area-link"
        :class="{ 'active': activeArea === area, 'uppercase': area === 'Crm' }"
        :label="area"
        :disabled="activeArea === area"
        @click="selectArea(area)"
      />
    </template>
  </form>
</template>

<script setup>
import { ref } from 'vue';
import { CustomButton } from '@sales-i/dsv3';
import { tCrm as t } from '@sales-i/utils';
import breakpoints from '@/shared/utils/breakpoints';

defineProps({
  bubbleAreas: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['searchBubbles', 'areaSelected']);

const bubbleQuery = ref('');
const activeArea = ref('All');
const isMobile = ref(window.screen.width < breakpoints.breakpoints.md);

function handleSearch() {
  emit('searchBubbles', bubbleQuery.value.trim());
}

function selectArea(area) {
  activeArea.value = area;
  emit('areaSelected', area);
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

form {
  background-color: var(--colour-panel-action);
  padding: var(--spacing-2);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    max-width: 192px;
  }

  :deep(button) {
    font-weight: var(--font-weight-bold);
  }
}

.field {
  position: relative;
  margin: var(--spacing-1) 0;
}

.input {
  background: var(--colour-panel-g-0);
  box-shadow: 0 0 var(--spacing-half) var(--shadow-spread) var(--colour-panel-g-16);
  border-radius: var(--spacing-4);
  font-size: var(--font-size-small);
  line-height: var(--spacing-2);
  font-weight: var(--font-weight-regular);
  padding-left: var(--spacing-5);
}

.search-button {
  position: absolute;
  top: 50%;
  left: var(--spacing-1);
  transform: translateY(-50%);
}

.area-link {
  margin-bottom: var(--spacing-1);
  
  &.active {
    color: var(--colour-utility-black);
    text-decoration: none;
    font-weight: var(--font-weight-regular);
  }
}

.uppercase {
  text-transform: uppercase;
}
</style>
