import { tCrm as t } from '@sales-i/utils';
export default [{
  stage: 'Add a post',
  fields: [
    {
      label: t('post_title'),
      id: 'title',
      type: 'text',
      maxLength: 50,
      placeholder: '',
      value: '',
      required: true,
      disabled: false,
      errorMessage: t('please_enter_a_post_title'),
    },
    {
      label: t('post'),
      id: 'text',
      type: 'mentionable',
      maxLength: 280,
      placeholder: '',
      value: '',
      required: true,
      disabled: false,
      errorMessage: t('please_enter_details_of_your_post'),
    },
    {
      label: t('add_a_tag'),
      id: 'tags',
      type: 'tag',
      placeholder: '',
      tagProps: {
        entity: 'post',
      },
      required: false,
      disabled: false,
    },
  ],
},];
