<template>
  <ColumnsWithActionsLayout class="prospect-details">
    <template #highlight>
      <div>
        <ProspectAddressDetails
          :loading="prospectLoading"
          :address-details="prospectDetails"
          @edit-prospect="edit"
        />
      </div>
      <div>
        <DefaultContact
          entity-type="prospect"
          :entity-name="prospectDetails.name"
          :entity-id="id"
        />
        <MergeProspect :entity-id="id" />
      </div>
      <div>
        <SchemaLinks :items="prospectDetailsLinks" />
      </div>
      <router-view @submit="refreshProspect" />
    </template>
  </ColumnsWithActionsLayout>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import ColumnsWithActionsLayout from '@/shared/components/Layout/ColumnsWithActionsLayout.vue';
import ProspectAddressDetails from '@/crm/components/Prospect/ProspectAddressDetails.vue';
import SchemaLinks from '@/crm/components/SchemaLinks/SchemaLinks.vue';
import MergeProspect from '@/crm/components/Prospect/MergeProspect.vue';
import DefaultContact from '@/crm/components/Contacts/DefaultContact.vue';
import { baseUrl, opportunitiesArea } from '@/crm/router/urlBits';
import { tCrm as t } from '@sales-i/utils';

defineProps({
  id: {
    type: [String, Number],
    default: '',
  },
});

const emit = defineEmits(['editProspect', 'refreshProspect']);

const store = useStore();

const prospectDetails = computed(() => store.state.crm.prospects.selected.data);
const prospectLoading = computed(() => store.state.crm.prospects.selected.loading);

const prospectDetailsLinks = computed(() => [
  {
    title: t('opportunities'),
    description: t('view_all_opportunities'),
    to: {
      path: `${baseUrl}/${opportunitiesArea}`,
      query: {
        filter: prospectDetails.value.record_type,
        search: prospectDetails.value.name,
      },
    },
  },
]);

const edit = () => {
  emit('editProspect');
};

const refreshProspect = () => {
  emit('refreshProspect');
};
</script>

<style lang="scss" scoped></style>