<template>
  <Tabs
    twoway
    :items="tabs"
    :selected-index="activeIndex"
    @nav-clicked="navClicked"
  />
  <section v-show="activeIndex == 0">
    <CustomerOverview
      v-if="isCustomerLoaded && dateParams"
      :dates="dates"
      :date-params="dateParams"
      :children="customerChildren"
      :include-child-customer-data="includeChildCustomerData"
      @refresh-parent="handleRefresh"
      @date-updated="updateDates"
      @go-to-enquiry="goToEnquiryTab"
      @go-to-variance-product="goToVarianceProduct"
    />
  </section>
  <section
    v-show="activeIndex === 1"
    class="enquiries"
  >
    <BubbleSelection
      v-if="!isReportView"
      :report-type="reportType"
      :query-substitute="enquiryParams"
      @go-to-report="goToReport"
      @change-report="resetReport"
    />
    <EnquiryReportView
      v-else
      :key="enquiryReportRefresh"
      :report-type="reportType"
      :query-substitute="enquiryParams"
      @update-query="updateEnquiryParams"
      @reset-report="resetReport"
    />
  </section>
  <section
    v-show="activeIndex === 2"
    class="mx-3"
  >
    <div class="enquiry-links-container bg-blue-light">
      <h2>{{ t('missed_and_potential_opportunities') }}</h2>
      <h4>{{ t('recommended_products_for_your_customer_base') }}</h4>
    </div>
    <Opportunities
      v-if="selectedCustomer.name"
      class="opportunities-container"
      hidden-header
      :customer-id="selectedCustomer.id"
      is-rolled-up
    />
  </section>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import CustomerOverview from '@/crm/views/Customers/CustomerOverview.vue';
import EnquiryReportView from '@/intelligence/components/EnquiryReport/EnquiryReportView.vue';
import BubbleSelection from '@/intelligence/components/Bubble/BubbleSelection.vue';
import Tabs from '@/shared/components/Tabs/Tabs';
import { FULL_PICTURE, VARIANCE } from '@/intelligence/store/data/reportTypes';
import { tCrm as t } from '@sales-i/utils';
import { useDates } from '@/intelligence/composables/useDates';
import { useCustomer } from '@/crm/composables/useCustomer';
import useShared from '@/intelligence/composables/useShared';
import Opportunities from '@/intelligence/views/Opportunities/Opportunities.vue';

// setup
const store = useStore();
const vroute = useRoute();

// constants
const tabs = [
  { title: t('insights'), query: 'insights' },
  { title: t('enquiries'), query: 'enquiries' },
  { title: t('opportunities'), query: 'opportunities' },
];

const { customerChildren, selectedCustomer, getCustomerById, getCustomerId, getCustomerChildren } = useCustomer({
  store,
});
const { dates, dateParams, updateDates } = useDates({ updateDatesOnMount: true });

// data
const activeIndex = ref(0);
const isCustomerLoaded = ref(false);
const isReportView = ref(false);
const reportType = ref(FULL_PICTURE);
const enquiryParams = ref({});
const enquiryReportRefresh = ref(0);
const customerId = ref(null);
const includeChildCustomerData = ref(false);

// computed
const customerAccountId = computed(() => vroute.params.id);

// actions
const { clearReportData } = useShared({ store });

// lifecycle hooks
onMounted(async () => {
  customerId.value = await getCustomerId(customerAccountId.value);
  await getCustomerById({ id: customerId.value });
  await getCustomerChildren({ id: customerId.value, rolldown: includeChildCustomerData.value });
  isCustomerLoaded.value = true;
});

function navClicked({ index }) {
  activeIndex.value = index;
}
function goToEnquiryTab(enquiry) {
  reportType.value = enquiry;
  updateEnquiryParams();
  isReportView.value = false;
  activeIndex.value = 1;
}
function goToReport(params) {
  updateEnquiryParams(params);
  isReportView.value = true;
}
function resetReport(params) {
  isReportView.value = false;
  reportType.value = params.reportType;
  updateEnquiryParams(params.query);
}
function updateEnquiryParams(params = {}) {
  enquiryParams.value = params;
}
function goToVarianceProduct(queryParams) {
  isReportView.value = false;
  clearReportData();
  reportType.value = VARIANCE;
  enquiryReportRefresh.value += 1;
  goToReport(queryParams);
  activeIndex.value = 1;
}
async function handleRefresh(value) {
  isCustomerLoaded.value = false;
  await getCustomerChildren({ id: customerId.value, rolldown: value });
  includeChildCustomerData.value = value;
  isCustomerLoaded.value = true;
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.opportunities-container {
  margin: 0 var(--spacing-3);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin: 0 var(--spacing-2);
  }
}

.enquiry-links-container {
  background-color: var(--colour-panel-alert-base);
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-bottom: var(--spacing-3);
  padding-top: var(--spacing-3);
  margin-bottom: var(--spacing-4);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding-bottom: var(--spacing-3);
    margin: 0 0 var(--spacing-4) 0;
  }

  h4 {
    font-weight: var(--font-weight-regular);
  }
}
</style>
