import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import useShared from '@/intelligence/composables/useShared';
import usePermissions from '@/shared/composables/usePermissions';
import { intelligence_enquiries } from '@/shared/store/data/policies';
import { TRENDS } from '@/intelligence/store/data/reportTypes';
import { tCrm as t } from '@sales-i/utils';

export default function useTrends({ reportId, store = useStore() } = {}) {
  const { getPermissions } = usePermissions({ store });
  const { getReportData, fetchReportData, clearReportData } = useShared({ store });
  
  const reportData = computed(() => getReportData(reportId.value));
  const dataLoading = ref(false);
  const legendLabels = computed(() => [
    t(reportData.value?.headings?.[1]),
    t(reportData.value?.headings?.[2]),
  ]);
  const subtitle = computed(() => `${legendLabels.value?.[0]} - ${legendLabels.value?.[1]}`);

  const isReportDataAvailable = computed(() => reportData.value?.rows?.some(row => 
    row.value_1 !== 0 || row.value_2 !== 0 || row.value_variance !== 0
  ));

  const chartData = computed(() => {
    return reportData.value?.rows?.map(row => ({
      name: row.period,
      value1: row.value_1,
      value2: row.value_2,
    })).sort((a, b) => a.name - b.name);
  });

  function loadData(customParams) {
    if (!getPermissions(intelligence_enquiries).read) return;
    dataLoading.value = true;
    const newReportId = fetchReportData({ reportType: TRENDS, customParams });
    dataLoading.value = false;
    return newReportId;
  }

  return {
    isReportDataAvailable,
    reportData,
    chartData,
    dataLoading,
    legendLabels,
    subtitle,
    loadData,
    clearReportData,
  };
}