<template>
  <div class="intro-wrapper flow">
    <IconBase
      :width="64"
      :height="64"
      icon-name="check"
      class="icon"
      icon-color="var(--colour-data-de-york)"
    />

    <h2>{{ dataTypeTitle }}</h2>
    <!-- Sales filters -->
    <section
      v-if="formattedSalesFilter.length > 0"
    >
      <div class="flow">
        <p class="filter-content">
          {{ t('you_will_be_notified_of_customers_whose') }}
          <template
            v-for="(item, index) in formattedSalesFilter"
            :key="index"
          >
            <span v-if="index !== 0">{{ item.type }}</span> {{ item.field }} {{ item.operator }} {{ item.value }}&nbsp;
          </template>
        </p>
      </div>
    </section> 

    <!-- CRM filters -->
    <section
      v-if="formattedCRMFilters.length > 0"
    >
      <div class="flow">
        {{ t('you_will_be_notified_of_customers') }} 
        <p class="filter-content">
          <template
            v-for="(item, index) in formattedCRMFilters"
            :key="index"
          >
            <span v-if="index !== 0">{{ item.clause }}</span> {{ item.field }} {{ item.value }}
          </template>
        </p>
      </div>
    </section>

    <!-- Product filters -->
    <section
      v-if="productFilterData?.length > 0"
    >
      <div class="flow">
        <p class="filter-content">
          {{ t('you_will_be_notified_of_customers') }} 
          <template
            v-for="(item, index) in productFilterData"
            :key="index"
          >
            <!-- eslint-disable-next-line -->
            <template v-if="index !== 0">&nbsp;{{ (item.operator) }}</template>

            {{ item.status }} {{ item.level }}
          </template>
        </p>
      </div>
    </section>

    <CustomerCount />
  </div>

  <div class="data-types">
    <CustomCard
      v-if="isEditMode || !isSalesData"
      purpose="reversed"
      class="details-container"
    >
      <div class="card-details flow">
        <h3>{{ salesActionText }}</h3>
        <p>
          {{
            t('now_connect_some_data_on_customers_who_are_up_or_down_in_key_metrics_in_currencysymbol_or_percentage_terms', {
              cs
            })
          }}.
        </p>

        <CustomButton
          purpose="action"
          small
          @click="goToSalesData"
        >
          {{ salesActionText }}
        </CustomButton> 
      </div>
    </CustomCard>

    <CustomCard
      v-if="isEditMode || !isCRMData"
      purpose="reversed"
      class="details-container"
    >
      <div class="card-details flow">
        <h3>{{ crmActionText }}</h3>
        <p>{{ t('now_connect_some_data_on_customer_interactions_locations_and_contacts') }}.</p>

        <CustomButton
          purpose="action"
          small
          @click="goToCRMData"
        >
          {{ crmActionText }}
        </CustomButton>
      </div>
    </CustomCard>

    <CustomCard
      v-if="isEditMode || !isProductData"
      purpose="reversed"
      class="details-container"
    >
      <div class="card-details flow">
        <h3>{{ productActionText }}</h3>
        <p>{{ t('now_connect_some_data_on_products_your_customers_are_buying_or_not_buying') }}.</p>

        <CustomButton
          purpose="action"
          small
          @click="goToProductData"
        >
          {{ productActionText }}
        </CustomButton>
      </div>
    </CustomCard>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useRoute, } from 'vue-router';
import { useStore } from 'vuex';
import { tCrm as t } from '@sales-i/utils';
import { capitalize } from '@/shared/utils/strings';
import { CustomButton, CustomCard, IconBase } from '@sales-i/dsv3';
import CustomerCount from '@/intelligence/components/AlertBuilder/AlertWizard/CustomerCount.vue';
import useCurrency from '@/shared/composables/useCurrency';
import useFilterSummary from '@/intelligence/composables/useFilterSummary';
import { useAlertBuilder } from '@/intelligence/composables/useAlertBuilder'; 
import { navigateToUrl } from 'single-spa';

const emit = defineEmits(['refreshCount']);

const vroute = useRoute();
const store = useStore();

let { cs } = useCurrency();
const { formattedSalesFilter, formattedCRMFilters } = useFilterSummary();
const { isEditMode, alertBuilderModalBaseUrl, productFilterData } = useAlertBuilder({ store, vroute });

const CRMData = computed(() => store.state.intelligence.queryBuilder.customerFilters.filterClauses);
const salesData = computed(() => store.state.intelligence.queryBuilder.salesFilters);
const allProductsData = computed(() => store.getters['intelligence/queryBuilder/getAllProducts']);

const isSalesData = computed(() => salesData.value.length > 0);
const isCRMData = computed(() => CRMData.value.length > 0);
const isProductData = computed(() => allProductsData.value.length > 0);

const dataTypeTitle = computed(() => vroute.query.sourcePage?.toLowerCase() === 'crm' 
  ? t('crm_data_added') 
  : t('name_data_added', { name: capitalize(vroute.query.sourcePage?.replace(/-/g, ' '))})
);

const salesActionText = computed(() => isSalesData.value ? t('edit_sales_data') : t('add_sales_data'));
const crmActionText = computed(() => isCRMData.value ? t('edit_crm_data') : t('add_crm_data'));
const productActionText = computed(() => isProductData.value ? t('edit_product_data') : t('add_product_data'));

onMounted(() => {
  refreshCount();
});

function goToSalesData() {
  navigateToUrl(`${alertBuilderModalBaseUrl.value}/sales-data`);
}

function goToCRMData() {
  navigateToUrl(`${alertBuilderModalBaseUrl.value}/crm-data`);
}

function goToProductData() {
  navigateToUrl(`${alertBuilderModalBaseUrl.value}/product-data`);
}

function refreshCount() {
  emit('refreshCount');
}
</script>

<style lang="scss" scoped>
.intro-wrapper {
  --flow-space: var(--spacing-1);
}

.data-types {
  background-color: var(--colour-panel-alert-base);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 18rem));
  gap: var(--spacing-3);
  justify-content: center;
  padding-block: var(--spacing-8);
  padding-inline: var(--spacing-3);

  // Lord, forgive me
  margin-bottom: calc(var(--spacing-2) * -1);

}
  .card-details {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;    
  }

    h3 {
      font-size: var(--font-size-3);
      font-weight: var(--font-weight-semibold);
    }
</style>
