<template>
  <div class="opportunity-stages">
    <h2>{{ t('stage') }} {{ stageIndex + 1 }} of {{ stages.length }}</h2>
    <h1>{{ stageText }}</h1>

    <div class="pills">
      <Tooltip
        v-for="(stage, index) in stages || []"
        :key="'w' + stage.id"
        class="pill-wrap"
        :text="`${stage.name} (${stage.score || 0}%)`"
      >
        <template #text>
          {{ `${stage.name} (${stage.score || 0}%)` }}
        </template>

        <div
          v-show="index > 0"
          class="div"
        >
          &ndash;
        </div>
        <div
          :class="{ active: stage.id == opportunity.stage_id, first: index == 0 }"
          class="pill"
        />
        <div
          v-show="index < stages.length - 1"
          class="div"
        >
          &ndash;
        </div>
      </Tooltip>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { tCrm as t } from '@sales-i/utils';
import Tooltip from '@/shared/components/Tooltip.vue';
import { useOppStages } from '@/crm/composables/useOppStages';

defineProps({
  opportunity: {
    type: Object,
    default: () => ({}),
  },
  stageIndex: {
    type: Number,
    default: 0,
  },
  stageText: {
    type: String,
    default: '',
  }
});

const { stages, fetchStages } = useOppStages();

const store = useStore();

onMounted(() => {
  if (!stages.value.length && !store.state.crm.opportunities.stages.loading) fetchStages();
});
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.opportunity-stages {
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;

  h1 {
    font-size: var(--font-size-3);
    line-height: 1.75rem;
    font-weight: var(--font-weight-bold);
  }
  h2 {
    font-size: var(--font-size-body);
    line-height: 1.5rem;
    color: var(--colour-utility-base);
  }

  .pills {
    display: flex;
    align-items: center;
    line-height: 2rem;
    user-select: none;
    flex-flow: row wrap;
    justify-content: center;
    margin-right: var(--spacing-4);
    margin-left: var(--spacing-4);
    margin-top: var(--spacing-2);

    .pill-wrap {
      display: flex;
      align-items: center;
      margin-right: -3px;
      user-select: none;
    }

    .pill {
      min-width: 1.6rem;
      min-height: 1.6rem;
      background: var(--colour-utility-action);
      border-radius: 100px;
      border: 1px solid white;
      box-shadow: 0 0 1px 0px white inset, 0 0 1px 0px white;
      margin: 0 2px;
    }

    .active {
      display: flex;
      background: black;
      border-radius: 100px;
      width: 2rem;
      height: 2rem;
      border: 0.5rem solid var(--colour-utility-focus);
    }

    .first {
      background: var(--colour-panel-g-16);
    }

    span {
      color: var(--colour-panel-g-56);
      width: auto;
      padding: var(--spacing-1) var(--spacing-2);
      white-space: nowrap;
      overflow: hidden;
    }

    .div {
      font-size: 1rem;
      font-weight: 400;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .pills {
      font-size: var(--font-size-small);

      .pill {
        min-width: 1rem;
        min-height: 1rem;
      }

      .active {
        width: 1.2rem;
        height: 1.2rem;
        border: 0.3rem solid var(--colour-utility-focus);
      }
    }
  }
}
</style>
