import {
  phoneRegex,
  nameRegex,
  emailRegex,
  addressLineRegex,
  townRegex,
  postCodeRegex,
  wwwRegex,
} from '@/shared/regex/form';
import { countryList } from '@/shared/constants/countries/countries';
import { tGlobal as t } from '@sales-i/utils';

export const prospectData = [
  {
    section: 'Customer',
    fields: [
      {
        label: t('company_name'),
        id: 'customer',
        type: 'text',
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: false,
        disabled: false,
        errorMessage: t('please_enter_a_valid_customer_name'),
      },
      {
        label: t('telephone'),
        id: 'tel_1',
        type: 'text',
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: false,
        disabled: false,
        regex: phoneRegex,
        errorMessage: t('please_enter_a_valid_phone_number'),
      },
      {
        label: t('alt_telephone'),
        id: 'tel_2',
        type: 'text',
        min: 1,
        max: 40,
        placeholder: '',
        required: false,
        disabled: false,
        regex: phoneRegex,
        errorMessage: t('please_enter_a_valid_phone_number'),
      },
      {
        label: t('email'),
        id: 'email',
        type: 'email',
        min: 3,
        max: 50,
        placeholder: '',
        required: false,
        disabled: false,
        regex: emailRegex,
        errorMessage: t('please_enter_a_valid_email'),
      },
      {
        label: t('fax'),
        id: 'fax',
        type: 'text',
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: false,
        disabled: false,
        regex: phoneRegex,
        errorMessage: t('please_enter_a_valid_fax_number'),
      },
      {
        label: t('address_line_1'),
        id: 'address_line_1',
        type: 'text',
        minLength: 1,
        maxLength: 80,
        placeholder: '',
        required: false,
        disabled: false,
        isAddress: true,
        regex: addressLineRegex,
        errorMessage: t('please_enter_a_valid_address_line_1'),
      },
      {
        label: t('address_line_2'),
        id: 'address_line_2',
        type: 'text',
        minLength: 1,
        maxLength: 80,
        placeholder: '',
        required: false,
        disabled: false,
        isAddress: true,
        regex: addressLineRegex,
        errorMessage: t('please_enter_a_valid_address_line_2'),
      },
      {
        label: t('address_line_3'),
        id: 'address_line_3',
        type: 'text',
        minLength: 1,
        maxLength: 80,
        placeholder: '',
        required: false,
        disabled: false,
        isAddress: true,
        regex: addressLineRegex,
        errorMessage: t('please_enter_a_valid_address_line_3'),
      },
      {
        label: t('address_line_4'),
        id: 'address_line_4',
        type: 'text',
        minLength: 1,
        maxLength: 80,
        placeholder: '',
        required: false,
        disabled: false,
        isAddress: true,
        regex: addressLineRegex,
        errorMessage: t('please_enter_a_valid_address_line_4'),
      },
      {
        label: t('town'),
        id: 'town',
        type: 'text',
        minLength: 1,
        maxLength: 80,
        placeholder: '',
        required: false,
        disabled: false,
        isAddress: true,
        regex: townRegex,
        errorMessage: t('please_enter_a_valid_town'),
      },
      {
        label: t('county'),
        id: 'county',
        type: 'text',
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: false,
        disabled: false,
        isAddress: true,
        regex: townRegex,
        errorMessage: t('please_enter_a_valid_county'),
      },
      {
        label: t('postcode'),
        id: 'post_code',
        type: 'text',
        minLength: 1,
        maxLength: 12,
        placeholder: '',
        required: false,
        disabled: false,
        isAddress: true,
        regex: postCodeRegex,
        errorMessage: t('please_enter_a_valid_post_code'),
      },
      {
        label: t('country'),
        id: 'country',
        type: 'select',
        options: countryList,
        required: false,
        disabled: false,
        isAddress: true,
      },
      {
        label: t('web_address'),
        id: 'www',
        type: 'text',
        required: false,
        disabled: false,
        regex: wwwRegex,
        errorMessage: t('a_valid_url_requires_a_name_and_domain'),
      },
    ],
  },
  {
    section: 'Contact',
    fields: [
      {
        label: t('default_contact_first_name'),
        id: 'first_name',
        type: 'text',
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: false,
        disabled: false,
        regex: nameRegex,
        errorMessage: t('please_enter_a_valid_first_name'),
      },
      {
        label: t('default_contact_surname'),
        id: 'surname',
        type: 'text',
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: false,
        disabled: false,
        regex: nameRegex,
        errorMessage: t('please_enter_a_valid_surname'),
      },
      {
        label: t('mobile_phone'),
        id: 'mobile_phone',
        type: 'text',
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: false,
        disabled: false,
        regex: phoneRegex,
        errorMessage: t('please_enter_a_valid_mobile_number'),
      },
      {
        label: t('business_phone'),
        id: 'business_phone',
        type: 'text',
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: false,
        disabled: false,
        regex: phoneRegex,
        errorMessage: t('please_enter_a_valid_business_number'),
      },
      {
        label: t('office_phone'),
        id: 'office_phone',
        type: 'text',
        min: 1,
        max: 40,
        placeholder: '',
        required: false,
        disabled: false,
        regex: phoneRegex,
        errorMessage: t('please_enter_a_valid_office_number'),
      },
      {
        label: t('home_phone'),
        id: 'home_phone',
        type: 'text',
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: false,
        disabled: false,
        regex: phoneRegex,
        errorMessage: t('please_enter_a_valid_home_number'),
      },
      {
        label: t('email'),
        id: 'email',
        type: 'email',
        min: 3,
        max: 50,
        placeholder: '',
        required: false,
        disabled: false,
        regex: emailRegex,
        errorMessage: t('please_enter_a_valid_email'),
      },
      {
        label: t('fax'),
        id: 'fax',
        type: 'text',
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: false,
        disabled: false,
        regex: phoneRegex,
        errorMessage: t('please_enter_a_valid_fax_number'),
      },
      {
        label: t('address_line_1'),
        id: 'address_line_1',
        type: 'text',
        minLength: 1,
        maxLength: 80,
        placeholder: '',
        required: false,
        disabled: false,
        isAddress: true,
        regex: addressLineRegex,
        errorMessage: t('please_enter_a_valid_address_line_1'),
      },
      {
        label: t('address_line_2'),
        id: 'address_line_2',
        type: 'text',
        minLength: 1,
        maxLength: 80,
        placeholder: '',
        required: false,
        disabled: false,
        isAddress: true,
        regex: addressLineRegex,
        errorMessage: t('please_enter_a_valid_address_line_2'),
      },
      {
        label: t('address_line_3'),
        id: 'address_line_3',
        type: 'text',
        minLength: 1,
        maxLength: 80,
        placeholder: '',
        required: false,
        disabled: false,
        isAddress: true,
        regex: addressLineRegex,
        errorMessage: t('please_enter_a_valid_address_line_3'),
      },
      {
        label: t('address_line_4'),
        id: 'address_line_4',
        type: 'text',
        minLength: 1,
        maxLength: 80,
        placeholder: '',
        required: false,
        disabled: false,
        isAddress: true,
        regex: addressLineRegex,
        errorMessage: t('please_enter_a_valid_address_line_4'),
      },
      {
        label: t('town'),
        id: 'town',
        type: 'text',
        minLength: 1,
        maxLength: 80,
        placeholder: '',
        required: false,
        disabled: false,
        isAddress: true,
        regex: townRegex,
        errorMessage: t('please_enter_a_valid_town'),
      },
      {
        label: t('county'),
        id: 'county',
        type: 'text',
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: false,
        disabled: false,
        isAddress: true,
        regex: townRegex,
        errorMessage: t('please_enter_a_valid_county'),
      },
      {
        label: t('postcode'),
        id: 'post_code',
        type: 'text',
        minLength: 1,
        maxLength: 12,
        placeholder: '',
        required: false,
        disabled: false,
        isAddress: true,
        regex: postCodeRegex,
        errorMessage: t('please_enter_a_valid_post_code'),
      },
      {
        label: t('country'),
        id: 'country',
        type: 'select',
        options: countryList,
        required: false,
        disabled: false,
        isAddress: true,
      },
    ],
  },
  {
    section: 'Details',
    fields: [
      {
        label: t('explain_what_this_prospect_relates_to'),
        id: 'description',
        type: 'textarea',
        rows: 5,
        placeholder: '',
        required: false,
        disabled: false,
      },
    ],
  },
];