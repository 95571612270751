<template>
  <div>
    <!-- Form fields -->
    <div
      v-for="(section, sectionIndex) in formData"
      :key="sectionIndex"
    >
      <div class="flow">
        <h3 class="fw-sbld">
          {{ t(section.sectionTitle) }}
        </h3>

        <div class="profile-form-wrapper">
          <div
            v-for="(field, fieldIndex) in section.fields"
            :key="`${fieldIndex}_${field.value}`"
            class="field"
          >
            <Field
              v-bind="field"
              :ref="field.id"
              :key="`radio_${field.id}_${field.value}`"
              v-model="formData[sectionIndex].fields[fieldIndex].value"
              :class="field.id"
              @valid="handleRadioValid(field.id)"
              @keydown="handleKeydown"
              @keypress="handleKeypress"
              @keyup="handleKeyup"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="form-actions">
      <CustomButton
        v-scroll-to-top
        :label="t('continue')"
        :class="`action ${valid ? '' : 'disabled'}`"
        @on-click="goNextStep"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { baseUrl as crmBaseUrl, interactionsArea } from '@/crm/router/urlBits';
import { useGenerateInteractionValidation } from './composables/useGenerateInteractionValidation';
import { pluralise, tCrm as t } from '@sales-i/utils';
import { CustomButton } from '@sales-i/dsv3';
import Field from '@/shared/components/Form/Field.vue';

const props = defineProps({
  entityType: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(['stageUpdate']);
const vroute = useRoute();

const selectedIndex = ref(2);
const profile_completion_status = ref('all');
const nextStep = ref(`${crmBaseUrl}/${interactionsArea}/${vroute.params.entityPath}/location-of-${pluralise.string(props.entityType)}`);

const flatFields = computed(() => {
  let entity_percent_complete_min = 0;
  let entity_percent_complete_max = 100;
  switch (profile_completion_status.value) {
  case 'complete_less_than_33':
    entity_percent_complete_max = 33;
    break;
  case 'complete_33_to_66':
    entity_percent_complete_min = 33;
    entity_percent_complete_max = 66;
    break;
  case 'complete_greater_than_66':
    entity_percent_complete_min = 66;
    break;
  }
  return {
    profile_completion_status: profile_completion_status.value,
    entity_percent_complete_min,
    entity_percent_complete_max,
  };
});

const {
  formData,
  handleKeydown,
  handleKeypress,
  handleKeyup,
  goNextStep,
  requestData,
} = useGenerateInteractionValidation(emit, selectedIndex, nextStep, { flatFields });

onMounted(() => {
  // restore value
  profile_completion_status.value = requestData.profile_completion_status ? requestData.profile_completion_status : 'all';

  emit('stageUpdate', 'Profile completion status');
});

const handleRadioValid = value => {
  profile_completion_status.value = value;
};
</script>

<style lang="scss" scoped>
.profile-form-wrapper {
  display: flex;
  gap: var(--spacing-4);
}
</style>
