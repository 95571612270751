export const customerHeadings = [
  {
    text: 'customer_name',
    value: 'links',
  },
  {
    text: 'account_number',
    value: 'account_number',
  },
  {
    text: 'address',
    value: 'address',
  },
  {
    text: 'created_date',
    value: 'created_date',
  },
  {
    text: 'tags',
    value: 'tags',
  },
];

export const prospectHeadings = [
  {
    text: 'prospect_name',
    value: 'name',
  },
  {
    text: 'created_date',
    value: 'created_date',
  },
  {
    text: 'tags',
    value: 'tags',
  },
  {
    text: 'links',
    value: 'links',
  },
];