<template>
  <div style="position: relative">
    <CustomCard
      v-if="loaded && contact && contact.id"
      purpose="reversed"
      footer-color="grey"
    >
      <template #header>
        <div class="flex align-center mb-1">
          <IconBase
            icon-name="user-circle"
            icon-color="black"
            :height="46"
            :width="46"
          />
          <CustomRibbon
            type="action"
            rounded
          >
            {{ contact.is_default_contact ? t('default_contact') : t('contact') }}
          </CustomRibbon>
        </div>

        <h3 class="fw-sbld">
          {{ contact.full_name }}
        </h3>

        <div class="flex contact-icons">
          <RagChip
            v-if="false"
            :value="contact.percent_complete"
            suffix="%"
            :green="67"
            :amber="33"
            small
          />
          <CustomButton
            v-if="contact.mobile_phone"
            icon
            :href="getTelephoneLink(contact.mobile_phone)"
            :width="40"
            :height="40"
          >
            <span class="visually-hidden">{{ t('telephone') }}</span>
            <IconBase
              icon-name="phone"
              :width="24"
              :height="24"
            />
          </CustomButton>
          <ViewEmailsSwitches
            v-if="isShowMail"
            :is-show-only-unread="false"
            :is-view-emails="isViewEmails"
            :disabled="loading"
            @update:is-view-emails="handleSetViewEmails($event, [contact])"
          />
        </div>
      </template>
      <ContactEmails
        v-if="isShowMail && isViewEmails"
        v-model:expanded="expanded"
        :contact="item"
      />
      <LoadingOverlay
        v-if="loading"
        style="border-radius: var(--border-radius-1)"
      />
      <div
        v-else
        class="default-contact--body"
      >
        <dl :class="`detail-grid flow ${mq.current}`">
          <div
            v-for="(item, index) in displayFields"
            :key="index"
            class="dl-wrap flow"
          >
            <dt>{{ t(item.label) }}:</dt>
            <dd v-if="/email/iu.test(item.label)">
              <a :href="getMailtoLink(item.value)">{{ item.value }}</a>
            </dd>
            <dd v-else-if="/telephone/iu.test(item.label)">
              <a :href="getTelephoneLink(item.value)">{{ item.value }}</a>
            </dd>
            <dd v-else-if="/created/iu.test(item.label)">
              {{ dates.format(item.value) }}
            </dd>
            <dd
              v-else-if="/web/iu.test(item.label)"
              class="website"
            >
              <a
                :href="item.value"
                target="_blank"
                rel="noopener noreferrer"
              >{{ item.value }}</a>
            </dd>
            <dd v-else-if="item.label === 'address'">
              <Address
                :key="index"
                :value="item.value"
              />
            </dd>
            <dd v-else>
              {{ item.value }}
            </dd>
          </div>
        </dl>
      </div>
      <template
        v-if="showActions && canUpdateContacts"
        #footer
      >
        <ButtonGroup>
          <CustomButton
            small
            :label="t('update_profile')"
            @on-click="updateContact"
          />
        </ButtonGroup>
      </template>
    </CustomCard>
    <CustomCard
      v-if="loaded && contact && !contact.id"
      purpose="reversed"
      footer-color="grey"
    >
      <template #header>
        <div class="flex align-center">
          <IconBase
            icon-name="user-circle"
            icon-color="black"
            :height="46"
            :width="46"
          />
          <CustomRibbon
            type="action"
            rounded
          >
            {{ t('default_contact') }}
          </CustomRibbon>
        </div>
      </template>
      <h2 class="text-3 fw-sbld">
        {{ t('no_default_contact') }}
      </h2>
      <template
        v-if="showActions && canCreate"
        #footer
      >
        <ButtonGroup>
          <CustomButton
            small
            :label="t('add_a_contact')"
            @on-click="addContact"
          />
        </ButtonGroup>
      </template>
    </CustomCard>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useMq } from 'vue3-mq';
import { CustomCard, IconBase, CustomButton, LoadingOverlay, RagChip, CustomRibbon } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import mapCustomerContactDetails from '@/shared/utils/mapCustomerContactDetails';
import Address from '@/shared/components/Map/Address.vue';
import usePermissions from '@/shared/composables/usePermissions';
import { contacts } from '@/shared/store/data/policies';
import { dates, tCrm as t } from '@sales-i/utils';
import ContactEmails from '@/shared/components/Mail/ContactEmails.vue';
import useMail from '@/shared/composables/useMail';
import useMailAuth from '@/shared/composables/useMailAuth';
import ViewEmailsSwitches from '@/shared/components/Mail/ViewEmailsSwitches.vue';

const mq = useMq();

const props = defineProps({
  contact: {
    type: Object,
    default: () => {},
  },
  loaded: {
    type: Boolean,
    default: true,
  },
  entityType: {
    type: [String, Number],
    default: 'customer',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  showActions: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(['add', 'update']);

const { isValid } = useMailAuth();
const { expanded, isViewEmails, handleSetViewEmails } = useMail();

const isShowMail = computed(() => props.contact.email && isValid.value);

const displayFields = computed(() => mapCustomerContactDetails(props.contact));
const { hasAccess } = usePermissions();
const canCreate = computed(() => hasAccess(contacts, 'create'));
const canUpdateContacts = computed(() => hasAccess(contacts, 'update'));

function addContact() {
  emit('add');
}

function updateContact() {
  emit('update');
}

/**
 * These functions were the subject of the review by SonarCloud, because a\
 * person could potentially inject something into the item.value and it would
 * be executed by the application, unescaped by Vue
 */
function getTelephoneLink(phone) {
  return `tel:${phone}`;
}

function getMailtoLink(email) {
  return `mailto:${email}`;
}
</script>

<style lang="scss" scoped>
.svg-container {
  z-index: 1;
}

.ribbon {
  margin-left: calc(var(--spacing-1) * -1);
}

.default-contact--body {
  margin: var(--spacing-2) calc(var(--spacing-3) * -1) var(--spacing-3);
  padding: var(--spacing-3) var(--spacing-3) 0;
  border-top: 2px solid var(--colour-panel-g-4);
}

.contact-icons {
  align-items: center;
  line-height: 0;
  margin-top: var(--spacing-1);
  gap: var(--spacing-2);
}

:deep(.c-card--body) {
  flex: 1 0 auto;
}
</style>
