<template>
  <div class="chips-container">
    <RagChip
      abbr
      :currency="currency"
      :prefix="cs"
      :value="props.stage?.potential_value"
      :green="20000"
      :amber="9000"
      small
    />
    <p class="total-opportunities">
      {{
        t('number_opportunities', { number: props.stage?.count })
      }}
    </p>
  </div>
</template>

<script setup>
import { RagChip } from '@sales-i/dsv3';
import useCurrency from '@/shared/composables/useCurrency';
import { tCrm as t } from '@sales-i/utils';

let { cs, currency } = useCurrency();

const props = defineProps({
  stage: {
    type: Object,
    default: () => ({}),
  },
});
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.chips-container {
  display: inline-flex;
}

.chips-container {
  gap: var(--spacing-1);
  align-items: center;
  margin-left: auto;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    gap: var(--spacing-2);
  }
}

.total-opportunities {
  font-size: var(--font-size-small);
}
</style>
