<template>
  <div class="flow">
    <h3>{{ t('are_there_any_specific_contacts_you_want_to_target') }}</h3>
    <div class="form-field-grid">
      <Field
        :id="`job-title-${filter.id}`"
        required
        :error-message="t('please_enter_a_job_title')"
        type="text"
        :value="filter.value"
        :label="t('job_title')"
        @input="updateFilter('value', $event)"
      />
    </div>
  </div>
</template>

<script setup>
import Field from '@/shared/components/Form/Field.vue';
import { tCrm as t } from '@sales-i/utils';

const props = defineProps({
  filter: {
    type: Object,
    default: undefined
  },
});

const emit = defineEmits(['update:filter']);

function updateFilter(field, value) {
  emit('update:filter', {
    id: props.filter.id,
    [field]: value,
  });
}
</script>