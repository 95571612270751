<template>
  <section>
    <NoResults
      v-if="noResults && !loading"
      class="no-result"
    />
    <BufferImage
      v-if="loading && !currentStage.length"
      color="var(--colour-utility-black)"
      float="center"
      class="loading-spinner"
    />
    <Accordion
      v-for="(stage, index) of stagesData"
      :key="stage.stage_id"
      ref="accordionRef"
      :title="stage.stage_name"
      accordion-size="big"
      @toggle-accordion="toggleVisibility(index)"
    >
      <template #header>
        <StageTotals
          :stage="stage"
          class="flex-end"
        />
      </template>
      <template #content>
        <div class="stage-content">
          <BufferImage
            v-if="loading"
            color="black"
            float="center"
            class="loading-spinner"
          />
          <BarChart
            v-if="!loading && chartData?.[stage?.stage_id]?.length"
            :key="refreshKey"
            :stage="stage"
            :chart-id="stage.stage_id"
            :chart-data="chartData?.[stage?.stage_id] || []"
          >
            <template #title>
              {{
                t('stage_number_name', {
                  number: index + 1,
                  name: stage.stage_name,
                })
              }}
            </template>
            <template #filters>
              <ChartFilters
                v-if="!loading"
                :stage-id="stage?.stage_id"
                :filters="filters"
                :values="
                  chartParams[stage?.stage_id] || {
                    entity: inputData.defaultChartSortOption1,
                    order: inputData.defaultChartSortOption2,
                  }
                "
                @update:filter="updateChart($event, stage?.stage_id)"
              />
            </template>
          </BarChart>
          <OpportunityTable
            v-if="!loading"
            :key="loading" 
            :table-data="tableData?.[stage?.stage_id]"
          />
          <ReportFooter
            v-if="!loading"
            ref="footerSection"
            :report-type="SALES_OPPORTUNITY"
            :request-params="{
              ...vroute.query,
              ...chartParams[stage?.stage_id],
              stageId: stage?.stage_id,
              opportunityType,
            }"
          />
        </div>
      </template>
    </Accordion>
  </section>
</template>

<script setup>
import { ref, computed, nextTick, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { tCrm as t } from '@sales-i/utils';
import { SALES_OPORTUNITY_ENTITY, SALES_OPORTUNITY_ORDER_FILTER } from '@/intelligence/store/data/filters';
import { SALES_OPPORTUNITY } from '@/intelligence/store/data/reportTypes';
import Accordion from '@/shared/components/Accordion/Accordion.vue';
import ChartFilters from './ChartFilters.vue';
import { BufferImage } from '@sales-i/dsv3';
import StageTotals from '@/intelligence/components/SalesOpportunity/StageTotals.vue';
import BarChart from '@/intelligence/components/SalesOpportunity/BarChart';
import OpportunityTable from '@/intelligence/components/SalesOpportunity/OpportunityTable';
import ReportFooter from '@/intelligence/components/ReportFooter/ReportFooter';
import NoResults from '@/intelligence/components/Shared/NoResults';
import useShared from '@/intelligence/composables/useShared';
import useCalendar from '@/intelligence/composables/useCalendar';
import usePerformanceInsight from '@/intelligence/composables/usePerformanceInsight';

const props = defineProps({
  opportunityType: {
    type: String,
    default: 'open',
  },
});

const store = useStore();
const currentStage = ref([]);
const chartParams = ref({});
const vroute = useRoute();

const { getInputData } = useShared({ store });
const { fetchedDates } = useCalendar({ store });
const { stagesData, tableData, chartData, loading, fetchTableData, fetchChartData } = usePerformanceInsight({ store });

const accordionRef = ref(null);

const inputData = computed(() => getInputData(SALES_OPPORTUNITY));
const filters = computed(() => ({ entity: SALES_OPORTUNITY_ENTITY, order: SALES_OPORTUNITY_ORDER_FILTER }));
const noResults = computed(() => !stagesData.value?.length);
const refreshKey = computed(() => JSON.stringify({ opportunityType: props.opportunityType, ...fetchedDates.value }));

watch(() => ({ opportunityType: props.opportunityType, ...fetchedDates.value }), async (newVal, oldVal) => {
  if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;
  currentStage.value?.forEach(async i => {
    const stageId = stagesData.value[i].stage_id;
    await fetchTableData({ stageId, opportunityType: props.opportunityType, ...fetchedDates.value });
    await fetchChartData({ stageId, ...chartParams.value[stageId], opportunityType: props.opportunityType, ...fetchedDates.value });
  });
});

const toggleVisibility = async (i) => {
  // if visible remove from currentStage (hide)
  if (currentStage.value.includes(i)) {
    currentStage.value = currentStage.value.filter(item => item !== i);
    return;
  }
  currentStage.value.push(i);
  const entity = inputData.value.defaultChartSortOption1;
  const order = inputData.value.defaultChartSortOption2;
  const stageId = stagesData.value[i].stage_id;
  await fetchTableData({ stageId, opportunityType: props.opportunityType, ...fetchedDates.value });
  await updateChart({ entity, order }, stageId);
  await nextTick(() => {
    const el = accordionRef.value[i].$el;
    if (el) el.scrollIntoView({ behavior: 'smooth' });
  });
};

const updateChart = async ({ entity, order }, stageId) => {
  chartParams.value[stageId] = { entity, order };
  await fetchChartData({ 
    entity, 
    order, 
    stageId, 
    opportunityType: props.opportunityType, 
    ...fetchedDates.value 
  });
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.no-results {
  padding: var(--spacing-2) 0;
}
</style>
