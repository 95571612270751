<template>
  <div class="customer-alerts">
    <div class="alert-content">
      <div
        v-if="mainAlert.length"
        class="alert-container"
      >
        <IconBase 
          icon-name="warning" 
          icon-color="var(--colour-brand-mandy)"
          class="icon-container"
          :height="48"
          :width="48"
        />
        <div class="links-container">
          <p class="alert-text">
            {{ t('appears_in_alert') }}
          </p>
          <CustomButton
            v-if="mainAlert.length"
            :key="0"
            purpose="text"
            :small="true"
            @on-click="openModal(mainAlert[0].query_name, mainAlert[0].id, props.customerDetails?.id)"
          >
            {{ mainAlert[0].query_name.split('|~~|')[0] }}
          </CustomButton>
          <CustomButton
            v-if="mainAlert.length > 1 && !showMoreMainAlerts"
            class="show-more"
            purpose="text"
            :small="true"
            @click="toggleShowMoreMainAlerts"
          >
            {{ t('show_more') }}
          </CustomButton>
        </div>
        <CustomModal
          v-if="showMoreMainAlerts"
          @close-modal="toggleShowMoreMainAlerts"
        >
          <template #title>
            <h1 class="fw-sbld text-2">
              {{ t('remaining_alerts') }}
            </h1>
          </template>
          <div
            v-for="(alert, index) in mainAlert.slice(1)"
            :key="index"
            class="parent-modal"
          >
            <CustomButton
              purpose="text"
              :small="true"
              @on-click="openModal(alert.query_name, alert.id, props.customerDetails?.id)"
            >
              {{ alert.query_name.split('|~~|')[0] }}
            </CustomButton>
          </div>
        </CustomModal>
      </div>

      <div
        v-if="childAlerts.length"
        class="alert-container"
      >
        <IconBase 
          icon-name="warning" 
          icon-color="var(--colour-brand-mandy)"
          class="icon-container"
          :height="48"
          :width="48"
        />
        <div class="links-container">
          <p class="alert-text">
            {{ t('child_accounts') }} ({{ childAlerts.length }}) {{ t('appears_in_alert') }}
          </p>
          <CustomButton
            v-if="childAlerts.length"
            :key="0"
            purpose="text"
            :small="true"
            @on-click="openModal(childAlerts[0].query_name, childAlerts[0].id, props.customerDetails?.id, true)"
          >
            {{ childAlerts[0].query_name }}
          </CustomButton>
          <CustomButton
            v-if="childAlerts.length > 1 && !showMoreChildAlerts"
            class="show-more"
            purpose="text"
            :small="true"
            @click="toggleShowMoreChildAlerts"
          >
            {{ t('show_more') }}
          </CustomButton>
        </div>
      </div>

      <CustomModal
        v-if="showMoreChildAlerts"
        @close-modal="toggleShowMoreChildAlerts"
      >
        <template #title>
          <h1 class="fw-sbld text-2">
            {{ t('remaining_alerts') }}
          </h1>
        </template>
        <div
          v-for="(alert, index) in childAlerts.slice(1)"
          :key="index"
          class="parent-modal"
        >
          <CustomButton
            purpose="text"
            :small="true"
            @on-click="openModal(alert.query_name, alert.id, props.customerDetails?.id, true)"
          >
            {{ alert.query_name }}
          </CustomButton>
        </div>
      </CustomModal>
    </div>
    <SavedAlertModal 
      v-if="showModal"
      :selected-query-id="activeAlert"
      :customer-details="customerDetails"
      :children-id="childrenIds"
      :children-names="childrenNames"
      :has-child-alerts="hasChildAlerts"
      :alert-name="alertName"
      :kpis-template="kpisTemplate"
      @close-modal="closeModal"
    />
  </div>
</template>
  
<script setup>
// CustomerSavedAlets
import { computed, ref } from 'vue';
import { IconBase, CustomButton, CustomModal } from '@sales-i/dsv3';
import { tCrm as t } from '@sales-i/utils';
import SavedAlertModal from '@/crm/views/Customers/SavedAlertModal.vue';

const props = defineProps({
  customerAlerts: {
    type: Object,
    default: () => ({}),
  },
  customerDetails: {
    type: Object,
    default: () =>({}),
  },
  childrenIds: {
    type: Array,
    default: () => []
  },
  childrenNames : {
    type: Array,
    default: () => []
  }
});

defineEmits(['closeModal', 'closeParentModal']);

const showModal = ref(false);
const activeAlert = ref(0);
const activeCustomer = ref(0);
const hasChildAlerts = ref(false);
const alertName = ref('');
const showMoreMainAlerts = ref(false);
const showMoreChildAlerts = ref(false);

// Computed
const mainAlert = computed(() => {
  return props.customerAlerts?.saved_queries ?? null;
});
const childAlerts = computed(() => {
  return props.customerAlerts?.child_saved_queries ?? [];
});

const openModal = (name, id, customerId, childAlerts = false) => {
  showModal.value = true;
  activeAlert.value = id;
  activeCustomer.value = customerId;
  hasChildAlerts.value = childAlerts;
  alertName.value = name;
};
const closeModal = () => {
  showModal.value = false;
};
const toggleShowMoreMainAlerts = () => {
  showMoreMainAlerts.value = !showMoreMainAlerts.value;
};
const toggleShowMoreChildAlerts = () => {
  showMoreChildAlerts.value = !showMoreChildAlerts.value;
};
</script>
  
<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.alert-content {
  background-color: var(--colour-data-mandy-background);
  border-radius: var(--border-radius-1);
  margin: var(--spacing-2);
  padding-bottom: var(--spacing-2);
  display: flex;
  align-items: flex-start;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
   flex-direction: column;
  }
}

.alert-text {
  color: var(--colour-data-mandy);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-4);
  margin-bottom: var(--spacing-half);
}
.alert-container {
  display: flex;
  align-items: center;
  padding: var(--spacing-2);
  .icon-container {
    align-self: flex-start;
  }
  .links-container {
    margin-left: var(--spacing-2);
    button {
      font-weight: var(--font-weight-semibold);
    }
  }
}

:deep(.modal.full) {
  bottom: initial;
  height: auto;
  .content-wrapper {
    padding-bottom: 0;
  }
}

.parent-modal {
  padding: 0 var(--spacing-3);
  button {
    font-weight: var(--font-weight-semibold);
  }
}
</style>
  