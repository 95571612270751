<template>
  <div class="container">
    <div class="page-title">
      <IconLogo
        width="139px"
        icon-name="logo"
      />
      <h2>
        {{ t('sorry_we_re_currently_undergoing_some_scheduled_maintenance_we_ll_be_back_with_you_as_soon_as_possible') }}
      </h2>
      <h3>
        {{ t('in_the_meantime_why_not_beat_your_own_record') }}
      </h3>
    </div>
    <div
      :class="{
        'game-details': true,
        expanded: expanded,
      }"
    >
      <div class="controls">
        <CustomButton
          small
          :disabled="gameRunning"
          @click="startGame"
        >
          {{ gameStartText }}
        </CustomButton>
        <span v-if="gameRunning || gameEnded">
          {{
            t('score_number', {
              number: score,
            })
          }}
        </span>
        <IconButton
          v-if="expanded"
          :label="t('close')"
          class="close-button"
          icon-name="close-line"
          purpose="transparent"
          icon-only
          :icon-size="18"
          @click.stop="collapseExpandedView"
        />
      </div>
      <canvas
        v-show="(isMobileView && expanded) || !isMobileView"
        ref="snakeboard"
        class="snakeboard"
        :width="gameBoardWidth"
        :height="gameBoardHeight"
      />
      <div
        v-if="expanded"
        class="arrow-keys"
      >
        <IconButton
          icon-name="caret-up"
          purpose="transparent"
          icon-only
          :icon-size="48"
          @click.stop="goDirection('up')"
        />
        <div class="middle-keys">
          <IconButton
            icon-name="caret-left"
            purpose="transparent"
            icon-only
            :icon-size="48"
            @click.stop="goDirection('left')"
          />
          <IconButton
            icon-name="caret-right"
            purpose="transparent"
            icon-only
            :icon-size="48"
            @click.stop="goDirection('right')"
          />
        </div>
        <IconButton
          icon-name="caret-down"
          purpose="transparent"
          icon-only
          :icon-size="48"
          @click.stop="goDirection('down')"
        />
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import { tCrm as t } from '@sales-i/utils';
import breakpoints from '@/shared/utils/breakpoints';
import { IconButton, CustomButton } from '@sales-i/dsv3';
import IconLogo from '@/shared/assets/image/logo/IconLogo.vue';

const board_border = '#5C5C5C';
const board_background = '#FFFFFF';
const originalSnakePosition = [
  { x: 200, y: 200 },
  { x: 190, y: 200 },
  { x: 180, y: 200 },
  { x: 170, y: 200 },
  { x: 160, y: 200 },
];
const snake_col = '#CAC7DF';
const snake_border = '#7360A4';
const swipeDetail = {
  sX: 0,
  sy: 0,
  eX: 0,
  ey: 0,
};

export default {
  name: 'MaintenanceView',
  components: {
    CustomButton,
    IconButton,
    IconLogo,
  },
  data() {
    return {
      changingDirection: false,
      dx: 10,
      dy: 0,
      expanded: false,
      foodX: null,
      foodY: null,
      gameEnded: false,
      gameRunning: false,
      gameStartText: t('start_game'),
      score: 0,
      snake: [...originalSnakePosition],
      snakeboardCtx: null,
      swipeDetail: {
        ...swipeDetail,
      },
    };
  },
  computed: {
    gameBoardHeight() {
      return 400;
    },
    gameBoardWidth() {
      return this.isMobileView ? window.screen.width - 20 : 400;
    },
    isMobileView() {
      return window.screen.width < breakpoints.breakpoints.md;
    },
  },
  watch: {},
  mounted() {},
  unmounted() {
    document.removeEventListener('keydown', this.changeDirection);
    document.removeEventListener('touchstart', this.touchStart);
    document.removeEventListener('touchmove', this.touchMove);
    document.removeEventListener('touchend', this.touchEnd);
  },
  methods: {
    t,
    changeDirection(event) {
      const LEFT_KEY = 37;
      const RIGHT_KEY = 39;
      const UP_KEY = 38;
      const DOWN_KEY = 40;

      if (this.changingDirection) return;
      this.changingDirection = true;
      let direction = '';
      switch (event.keyCode) {
      case LEFT_KEY:
        direction = 'left';
        break;
      case UP_KEY:
        direction = 'up';
        break;
      case RIGHT_KEY:
        direction = 'right';
        break;
      case DOWN_KEY:
        direction = 'down';
        break;
      }
      this.goDirection(direction);
    },
    clearCanvas() {
      //  Select the colour to fill the drawing
      this.snakeboardCtx.fillStyle = board_background;
      //  Select the colour for the border of the canvas
      this.snakeboardCtx.strokestyle = board_border;
      // Draw a "filled" rectangle to cover the entire canvas
      this.snakeboardCtx.fillRect(0, 0, this.$refs.snakeboard.width, this.$refs.snakeboard.height);
      // Draw a "border" around the entire canvas
      this.snakeboardCtx.strokeRect(0, 0, this.$refs.snakeboard.width, this.$refs.snakeboard.height);
    },
    collapseExpandedView() {
      this.expanded = false;
    },
    drawFood() {
      this.snakeboardCtx.fillStyle = '#B8E2E3';
      this.snakeboardCtx.strokestyle = '#3DB6B3';
      this.snakeboardCtx.fillRect(this.foodX, this.foodY, 10, 10);
      this.snakeboardCtx.strokeRect(this.foodX, this.foodY, 10, 10);
    },
    drawSnake() {
      // Draw each part
      this.snake.forEach(this.drawSnakePart);
      // this.snakeboardCtx.drawImage(this.$refs.logo.$el.children[0].children[0], 10, 10);
    },
    drawSnakePart(snakePart) {
      // Set the colour of the snake part
      this.snakeboardCtx.fillStyle = snake_col;
      // Set the border colour of the snake part
      this.snakeboardCtx.strokestyle = snake_border;
      // Draw a "filled" rectangle to represent the snake part at the coordinates
      // the part is located
      this.snakeboardCtx.fillRect(snakePart.x, snakePart.y, 10, 10);
      // Draw a border around the snake part
      this.snakeboardCtx.strokeRect(snakePart.x, snakePart.y, 10, 10);
    },
    genFood() {
      this.foodX = this.randomFood(0, this.$refs.snakeboard.width - 10);
      this.foodY = this.randomFood(0, this.$refs.snakeboard.height - 10);
      this.snake.forEach(part => {
        const hasEaten = part.x == this.foodX && part.y == this.foodY;
        if (hasEaten) {
          this.genFood();
        }
      });
    },
    goDirection(direction) {
      const goingUp = this.dy === -10;
      const goingDown = this.dy === 10;
      const goingRight = this.dx === 10;
      const goingLeft = this.dx === -10;

      if (direction === 'left' && !goingRight) {
        this.dx = -10;
        this.dy = 0;
      }

      if (direction === 'up' && !goingDown) {
        this.dx = 0;
        this.dy = -10;
      }

      if (direction === 'right' && !goingLeft) {
        this.dx = 10;
        this.dy = 0;
      }

      if (direction === 'down' && !goingUp) {
        this.dx = 0;
        this.dy = 10;
      }
    },
    hasGameEnded() {
      for (let i = 4; i < this.snake.length; i++) {
        const COLLIDED = this.snake[i].x === this.snake[0].x && this.snake[i].y === this.snake[0].y;
        if (COLLIDED) {
          return true;
        }
      }
      const hitLeftWall = this.snake[0].x < 0;
      const hitRightWall = this.snake[0].x > this.$refs.snakeboard.width - 10;
      const hitToptWall = this.snake[0].y < 0;
      const hitBottomWall = this.snake[0].y > this.$refs.snakeboard.height - 10;

      return hitLeftWall || hitRightWall || hitToptWall || hitBottomWall;
    },
    moveSnake() {
      const head = { x: this.snake[0].x + this.dx, y: this.snake[0].y + this.dy };
      this.snake.unshift(head);
      const hasEatenFood = this.snake[0].x === this.foodX && this.snake[0].y === this.foodY;
      if (hasEatenFood) {
        // Increase score
        this.score += 10;
        // Generate new food location
        this.genFood();
      } else {
        // Remove the last part of snake body
        this.snake.pop();
      }
    },
    randomFood(min, max) {
      return Math.round((Math.random() * (max - min) + min) / 10) * 10;
    },
    runGame() {
      if (this.hasGameEnded()) {
        this.snake = [...originalSnakePosition];
        this.gameRunning = false;
        this.gameEnded = true;
        this.gameStartText = t('play_again');
        return;
      }
      this.changingDirection = false;
      setTimeout(() => {
        this.clearCanvas();
        this.drawFood();
        this.moveSnake();
        this.drawSnake();
        this.runGame();
      }, 100);
    },
    startGame() {
      if (this.isMobileView) {
        this.expanded = true;
      }
      this.gameRunning = true;
      this.gameEnded = false;
      this.score = 0;
      this.snakeboardCtx = this.$refs.snakeboard.getContext('2d');
      this.runGame();
      this.genFood();
      document.addEventListener('keydown', this.changeDirection);
      document.addEventListener('touchstart', this.touchStart);
      document.addEventListener('touchmove', this.touchMove);
      document.addEventListener('touchend', this.touchEnd);
    },
    touchStart(event) {
      const t = event.touches[0];
      this.swipeDetail.sX = t.screenX;
      this.swipeDetail.sY = t.screenY;
    },
    touchMove(event) {
      event.preventDefault();
      const t = event.touches[0];
      this.swipeDetail.eX = t.screenX;
      this.swipeDetail.eY = t.screenY;
    },
    touchEnd() {
      if (this.changingDirection) return;
      this.changingDirection = true;
      const min_x = 30; //min x swipe for horizontal swipe
      const max_x = 30; //max x difference for vertical swipe
      const min_y = 50; //min y swipe for vertical swipe
      const max_y = 60; //max y difference for horizontal swipe
      let direction = '';
      //horizontal detection
      if (
        (this.swipeDetail.eX - min_x > this.swipeDetail.sX || this.swipeDetail.eX + min_x < this.swipeDetail.sX) &&
        this.swipeDetail.eY < this.swipeDetail.sY + max_y &&
        this.swipeDetail.sY > this.swipeDetail.eY - max_y &&
        this.swipeDetail.eX > 0
      ) {
        if (this.swipeDetail.eX > this.swipeDetail.sX) {
          direction = 'right';
        } else {
          direction = 'left';
        }
      }
      //vertical detection
      else if (
        (this.swipeDetail.eY - min_y > this.swipeDetail.sY || this.swipeDetail.eY + min_y < this.swipeDetail.sY) &&
        this.swipeDetail.eX < this.swipeDetail.sX + max_x &&
        this.swipeDetail.sX > this.swipeDetail.eX - max_x &&
        this.swipeDetail.eY > 0
      ) {
        if (this.swipeDetail.eY > this.swipeDetail.sY) {
          direction = 'down';
        } else {
          direction = 'up';
        }
      }

      if (direction !== '') {
        this.goDirection(direction);
      }
      this.swipeDetail = { ...swipeDetail };
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  padding: var(--spacing-2);
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
  > * {
    margin-bottom: var(--spacing-2);
  }
}
.game-details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: var(--spacing-2);
  gap: var(--spacing-2);
  .controls {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: var(--spacing-2);
  }
  &.expanded {
    position: fixed;
    top: 60px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--colour-panel-g-2);
    justify-content: flex-start;
    .controls {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
    .arrow-keys {
      position: relative;
      top: calc(var(--spacing-2) * -1);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 60%;
      .middle-keys {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        position: relative;
        top: calc(var(--spacing-2) * -1);
        margin-bottom: calc(var(--spacing-4) * -1);
      }
    }
  }
}
</style>
