<template>
  <CustomCard
    purpose="reversed"
    class="assignees"
  >
    <div class="pb-2">
      <h3 class="title">
        {{ t('assignees') }}
      </h3>
      <div
        v-for="(item, index) in items"
        :key="index"
        class="assignee"
        :class="{ highlight: item.id === selectedValue }"
      >
        <slot
          name="item"
          :item="item"
          :index="index"
          :selected="selectedValue"
        >
          <p>{{ `- ${item.display_name}` }}</p>
        </slot>
      </div>
    </div>
  </CustomCard>
</template>

<script>
import { CustomCard } from '@sales-i/dsv3';
import { tCrm as t } from '@sales-i/utils';

export default {
  name: 'AssigneesComponent',
  components: {
    CustomCard,
  },
  props: {
    selectedValue: {
      type: String,
      default: undefined,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: { t }
};
</script>

<style lang="scss" scoped>
.assignees {
  height: auto;
  padding: 0;

  .title {
    padding: var(--spacing-2) var(--spacing-3);
    font-weight: var(--font-weight-semibold);
  }

  .assignee {
    padding: var(--spacing-half) var(--spacing-1);
    margin-top: 0;

    p {
      margin-left: var(--spacing-4);
    }
  }
  .highlight {
    background-color: var(--colour-panel-alert-base);
    font-weight: var(--font-weight-semibold);
  }
}
</style>
