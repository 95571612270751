<template>
  <TreemapChart
    v-if="colorScale && chartData"
    :chart-data="processedChartData"
    :format-func="abbr.float"
    :heat-map-key="heatMapKey"
    :color-func="colorFunc"
    :depth="depth"
  >
    <template #tooltip="{ tooltipData }">
      <p>{{ tooltipData?.data?.name }}</p>
      <p>{{ t('value') }}: {{ abbr.float(tooltipData?.value) }}</p>
      <p v-if="'variance_sum' in (tooltipData?.data || {})">
        {{ t('variance') }}: {{ abbr.float(tooltipData?.data?.variance_sum) }}
      </p>
    </template>
  </TreemapChart>
</template>

<script setup>
import TreemapChart from '@/intelligence/components/Dashboard/Elements/TreemapChart.vue';
import * as d3 from 'd3';
import { computed, ref } from 'vue';
import { tCrm as t, abbr } from '@sales-i/utils';

const props = defineProps({
  chartData: {
    type: Object,
    default: () => ({}),
  },
  field: {
    type: String,
    default: 'sales_value'
  },
  heatMapKey: {
    type: String,
    default: 'variance'
  },
  depth: {
    type: Number,
    default: 2
  }
});

const minValue = ref(+Infinity);
const maxValue = ref(-Infinity);
const minColour = getComputedStyle(document.documentElement).getPropertyValue('--colour-data-mandy-dark');
const maxColour = getComputedStyle(document.documentElement).getPropertyValue('--colour-data-de-york-dark');

const colorScale = computed(() => {
  let colorFunc = null;

  if (minValue.value > 0) colorFunc = d3.scaleLinear()
    .domain([0, maxValue.value]) 
    .range(['white', maxColour])
    .interpolate(d3.interpolateRgb);
  else if (maxValue.value < 0) colorFunc = d3.scaleLinear()
    .domain([minValue.value, 0]) 
    .range([minColour, 'white'])
    .interpolate(d3.interpolateRgb);
  else colorFunc = d3.scaleLinear()
    .domain([minValue.value, 0, maxValue.value]) 
    .range([minColour, 'white', maxColour])
    .interpolate(d3.interpolateRgb);

  // if value exceeds max or min set border colour
  return (value) => {
    if (value >= maxValue.value) return maxColour;
    if (value <= minValue.value) return minColour;
    return colorFunc(value);
  };
});

const colorFunc = (d) => {
  const fillColour = colorScale.value(d.data[props.heatMapKey+'_sum'] || 0);
  return {
    area: fillColour,
    border: d3.color(fillColour).darker(0.5),
    text: d.data[props.heatMapKey+'_sum'] < 0 ? 'var(--colour-data-mandy-label)' : 'var(--colour-data-de-york-label)'
  };
};

function sumFieldInHierarchy(node, fieldKey) {
  // If the node is a leaf, return its fieldKey value
  if (!node.children?.length) {
    node[fieldKey+'_sum'] = node[fieldKey] || 0;
    return node[fieldKey] || 0;
  }

  // If the node has children, sum the fieldKey values of the children
  let sum = 0.0;
  for (let child of node.children) {
    sum += sumFieldInHierarchy(child, fieldKey);
  }

  // Assign the sum to this node
  node[fieldKey+'_sum'] = sum;
  return sum;
}

function convertToHierarchy(data, metricKey) {
  // Helper function to find or create a child node
  function findOrCreateChild(node, name) {
    let child = node.children.find(c => c.name === name);
    if (!child) {
      child = { name, children: [] };
      node.children.push(child);
    }
    return child;
  }

  const root = { name: `${t('total')} ${props.chartData.headings.values[0]}`, children: [] };

  data.rows.forEach(row => {
    const level1Name = row.dimensions[0].dimensions[0];
    const level2Name = row.dimensions[1]?.dimensions[0] || '';
    const metricValue = parseFloat(row[metricKey].values[0]); 
    const varianceValue = parseFloat(row[metricKey].variance);

    let level1Node = findOrCreateChild(root, level1Name);
    let level2Node = level2Name ? findOrCreateChild(level1Node, level2Name) : level1Node;

    // Aggregate
    if (level2Node.value) {
      level2Node.value += metricValue;
      level2Node.variance += varianceValue;
    } else {
      level2Node.value = metricValue;
      level2Node.variance = varianceValue;
    }

    minValue.value = Math.min(minValue.value, level2Node[props.heatMapKey]);
    maxValue.value = Math.max(maxValue.value, level2Node[props.heatMapKey]);
  });

  return root;
}

const processedChartData = computed(() => {
  let res = convertToHierarchy(props.chartData, props.field);
  sumFieldInHierarchy(res, props.heatMapKey);
  props.heatMapKey != 'value' && sumFieldInHierarchy(res, 'value');
  const value = props.chartData.summary?.[props.field]?.values[0] - res.value_sum;
  const variance = props.chartData.summary?.[props.field]?.variance - res.variance_sum;
  res.children.push({
    name: t('other'),
    value, value_sum: value, variance, variance_sum: variance,
  });
  return res;
});
</script>