import { dates } from '@sales-i/utils';
import {
  CUSTOMER_DECREASING_SPEND_PERIOD,
  CUSTOMER_DORMANT_YTD,
  CUSTOMER_DOWN_ON_SPEND,
  CUSTOMER_LAST_COMPLETE_PERIODS_BUT_NOT_BOUGHT,
  CUSTOMER_NOT_BUYING_TOP_PRODUCTS,
  CUSTOMER_TOP_NUMBER_OF_ACCOUNTS_DOWN,
  CUSTOMER_WHO_SPENT_IN_PREVIOUS,
  CUSTOMER_WITH_ZERO_SPEND,
  FULL_PICTURE,
  QUERY_BUILDER,
  MATRIX,
  MARKET_PENETRATION,
  SALES_VS_GAPS,
  TRENDS,
  VARIANCE,
  TOP_PRODUCT_GROUPS_BY_PURCHASE_VALUE,
  TOP_PRODUCT_GROUPS_BY_QUANTITY_PURCHASED,
  TOP_PRODUCTS_BY_PURCHASE_VALUE,
  TOP_PRODUCTS_BY_PURCHASE_QUANTITY,
  PRODUCTS_PURCHASED_FREQUENTLY,
  MONTHLY_PURCHASES_BY_VALUE,
  MONTHLY_PURCHASES_BY_QUANTITY,
  LATEST_PURCHASES_BY_VALUE,
  LATEST_PURCHASES_BY_QUANTITY,
  PURCHASES_VS_GAPS_BY_PURCHASE_VALUE,
  PURCHASES_VS_GAPS_BY_PURCHASE_QUANTITY,
  VARIANCE_ENQUIRY_BY_PURCHASE_VALUE,
  VARIANCE_ENQUIRY_BY_QUANTITY,
  AIML_PRODUCT_TRENDS,
  PRODUCT_GROUP_PURCHASE_ANALYSIS,
  ACCOUNTS_ANALYSIS,
  TRANSACTIONS_ANALYSIS,
  INTERACTION_OUTCOMES,
  INTERACTION_OUTCOMES_DRILL,
  INTERACTION_SPEND_SUMMARY,
  PRODUCT_GROUP_PURCHASE_ANALYSIS_PIECHART,
  CUSTOMER_ACTIVITY,
  HIGHEST_MARGIN_CUSTOMER,
  LOWEST_MARGIN_CUSTOMER,
  HIGHEST_REVENUE_CUSTOMER,
  LOWEST_REVENUE_CUSTOMER,
  TEMPLATE_REPORT_TYPES_USING_SALES_REP_FILTER
} from '@/intelligence/store/data/reportTypes';
import { inputSets } from '@/intelligence/store/data/reportsInput';
import { REPORTS_SORT_ASCENDING_KEY, REPORTS_SORT_DESCENDING_KEY } from '@/intelligence/store/data/apiInput';
import { BUBBLE_CALENDAR_ASC } from '@/intelligence/store/data/bubbles';

export const getRequestParameters = (state, getters) => ([reportType, customParams]) => {
  const baseParams = {...state.requestParameters, ...customParams};
  const drillsQuery = getters.getDrillsAsQuery([reportType, baseParams.drills]);

  const inputData = inputSets[reportType];
  const yearToDate = new Date(new Date(new Date().setMonth(0)).setDate(1));
  const today = new Date();
  const dateMinusYear = (date) => new Date(new Date(date).setFullYear(new Date(date).getFullYear()-1));
  
  !baseParams.currentSortHeader && (baseParams.currentSortHeader = inputData.defaultSortHeader);
  !baseParams.currentSortOption && (baseParams.currentSortOption = inputData.defaultSortOption);
  !baseParams.date_from && (baseParams.date_from = yearToDate);
  !baseParams.date_to && (baseParams.date_to = today);
  !baseParams.date_from2 && (baseParams.date_from2 = dateMinusYear(baseParams.date_from));
  !baseParams.date_to2 && (baseParams.date_to2 = dateMinusYear(baseParams.date_to));
  !baseParams.report_type && (baseParams.report_type = 'customer');

  const {
    xAxis,
    yAxis,
    dim1,
    dim2,
    date_from,
    date_to,
    date_from2,
    date_to2,
    periods,
    percent_from,
    percent_to,
    prev_year_from,
    prev_year_to,
    products,
    offset,
    offsetX,
    limit,
    limitX,
    currentSortOption,
    currentSortHeader,
    filters,
    filtersOR,
    salesFilter,
    salesFilterOR,
    crmFilter,
    crmFilterOR,
    drills,
    user,
    interaction_type,
    interaction_outcome,
    snapshotBubbleValue,
    snapshotBubbleValueId,
    snapshotBubbleId,
    productBubbleId,
    next_action,
    report_type,
    analysis_field_id,
    analysis_field_value_id,
    measure,
    drill_type,
    isAlertBuilderVariance,
    showGaps,
  } = baseParams;

  switch (reportType) {
  case CUSTOMER_DOWN_ON_SPEND:
  case CUSTOMER_TOP_NUMBER_OF_ACCOUNTS_DOWN:
    return `
      percent_from: ${percent_from}
      percent_to: ${percent_to}
      offset: ${offset}
      limit: ${limit}
      sort: "${currentSortHeader}:${currentSortOption}"
      filters: [${filters}]
    `;
  case CUSTOMER_WHO_SPENT_IN_PREVIOUS:
    return `
      prev_year_from: ${prev_year_from}
      prev_year_to: ${prev_year_to}
      offset: ${offset}
      limit: ${limit}
      sort: "${currentSortHeader}:${currentSortOption}"
      filters: [${filters}]
    `;
  case CUSTOMER_WITH_ZERO_SPEND:
    return `
      periods: ${periods}
      offset: ${offset}
      limit: ${limit}
      sort: "${currentSortHeader}:${currentSortOption}"
      filters: [${filters}]
    `;
  case CUSTOMER_DORMANT_YTD:
    // FYI: in local env there might be an empty table due to testing data
    return `
      current_date: "${dates.forReportApi(date_to)}"
      offset: ${offset}
      limit: ${limit}
      sort: "${currentSortHeader}:${currentSortOption}"
      filters: [${filters}]
    `;
  case CUSTOMER_LAST_COMPLETE_PERIODS_BUT_NOT_BOUGHT:
  case CUSTOMER_DECREASING_SPEND_PERIOD:
    return `
      periods: ${periods}
      offset: ${offset}
      limit: ${limit}
      sort: "${currentSortHeader}:${currentSortOption}"
      filters: [${filters}]
    `;
  case CUSTOMER_NOT_BUYING_TOP_PRODUCTS:
    return `
      periods: ${periods}
      products: ${products}
      offset: ${offset}
      limit: ${limit}
      sort: "${currentSortHeader}:${currentSortOption}"
      filters: [${filters}]
    `;
  case MATRIX: {
    const drillsObjectsKeys = Object.keys(drills);
    const lastDrillId = drillsObjectsKeys.length ?
      drills[drillsObjectsKeys[drillsObjectsKeys.length - 1]].dimensions[0].id
      : undefined;
    // Determine x-axis order based on the Calendar bubbles of xAxis, for rest x-axis should be in descending order
    const xAxisOrder = BUBBLE_CALENDAR_ASC.includes(xAxis) ? REPORTS_SORT_ASCENDING_KEY : REPORTS_SORT_DESCENDING_KEY;
    
    return `
      date_from: "${dates.forReportApi(date_from)}"
      date_to: "${dates.forReportApi(date_to)}"
      x: {
        key: "${xAxis}",
        offset: ${offsetX},
        limit: ${limitX},
        order: "${xAxisOrder}"
      }
      y: {
        key: "${lastDrillId ? lastDrillId : yAxis}",
        offset: ${offset},
        limit: ${limit},
        order: "${currentSortOption}"
      }
      drills: ${drillsQuery}
      filters: [${filters}]
      measure: "${measure}"
    `;
  }
  case MARKET_PENETRATION: {
    return `
      date_from: "${dates.forReportApi(date_from)}"
      date_to: "${dates.forReportApi(date_to)}"
      x: {
        offset: ${offsetX},
        limit: ${limitX}
      }
      y: {
        offset: ${offset},
        limit: ${limit},
        order: "${currentSortOption}"
      }
      filters: [${filters}]
    `;
  }
  case SALES_VS_GAPS: {
    const drillsObjectsKeys = Object.keys(drills);

    const lastDrillId = drillsObjectsKeys.length ?
      drills[drillsObjectsKeys[drillsObjectsKeys.length - 1]].dimensions[0].id
      : undefined;

    // need to build either one or two y axis dimensions, remove falsey values
    let yDims = [dim1, dim2].filter((d) => d);

    // if there is a last drill id, we only want to show that
    if (lastDrillId) {
      yDims = [lastDrillId];
    }

    return `
      date_from: "${dates.forReportApi(date_from)}"
      date_to: "${dates.forReportApi(date_to)}"
      x: {
        key: "${xAxis}",
        offset: ${offsetX},
        limit: ${limitX}
      }
      y: [
        ${yDims.map((dim) => `
          "${dim}"
        `).join(',')}
      ]
      metadata: {
        measure: "${measure}"
        sort_col: "${currentSortHeader}"
        sort_order: "${currentSortOption}"
        limit: 100
        offset: ${offset}
      }
      drills: ${drillsQuery}
      filters: [${filters}]
      showGaps: ${showGaps}
    `;
  }
  case TRENDS:
    return `
      enquiry: {
        date_range_1: {
          from: "${dates.forReportApi(date_from)}",
          to: "${dates.forReportApi(date_to)}",
          complete_periods_only: false
        },
        date_range_2: {
          from: "${dates.forReportApi(date_from2)}",
          to: "${dates.forReportApi(date_to2)}",
          complete_periods_only: false
        },
        ${snapshotBubbleValue ?
    `filters: [
          {
            id: "${snapshotBubbleId}"
            selected_values: ["${snapshotBubbleValue}"]
          }
        ]` : ''}
      }
      sort: "period:asc",
    `;
  case VARIANCE:
    return `
      enquiry: {
        date_range_1: {
          from: "${dates.forReportApi(date_from)}",
          to: "${dates.forReportApi(date_to)}",
          complete_periods_only: false
        },
        date_range_2: {
          from: "${dates.forReportApi(date_from2)}",
          to: "${dates.forReportApi(date_to2)}",
          complete_periods_only: false
        },
        drills: ${drillsQuery}
        filters: [${filters}]
      }
      metadata: {
        sort: "${currentSortHeader}:${currentSortOption}",
        offset: ${offset},
        limit: ${limit},
      }
    `;
  case FULL_PICTURE:
    return `
      enquiry: {
        date_range: {
          from: "${dates.forReportApi(date_from)}",
          to: "${dates.forReportApi(date_to)}"
          complete_periods_only: false
        },
        drills: ${drillsQuery},
        filters: [${filters}],
        include_targets: true,
      }
      metadata: {
        sort: "${currentSortHeader}:${currentSortOption}",
        offset: ${offset},
        limit: ${limit},
      }
    `;
  case QUERY_BUILDER:
    return `
      enquiry: {
        date_range: {
          from: "${dates.forReportApi(date_from)}",
          to: "${dates.forReportApi(date_to)}"
          complete_periods_only: false
        },
        ${isAlertBuilderVariance ? (`
        date_range_2: {
          from: "${dates.forReportApi(date_from2)}",
          to: "${dates.forReportApi(date_to2)}"
          complete_periods_only: false
        },
        `) : ''}
        drills: ${drillsQuery}
        and_filters: {
          ${filters !== undefined ? `filters: [${filters}]` : ''}
          ${salesFilter !== undefined ? `overall_filter: [${salesFilter}]` : ''}
          ${crmFilter !== undefined && crmFilter !== '' ? `crm_filter: ${crmFilter}` : ''}
        }
        or_filters: {
          ${filtersOR !== undefined ? `filters: [${filtersOR}]` : ''}
          ${salesFilterOR !== undefined ? `overall_filter: [${salesFilterOR}]` : ''}
          ${crmFilterOR !== undefined && crmFilterOR !== '' ? `crm_filter: ${crmFilterOR}` : ''}
        }
      }
      metadata: {
        sort: "${currentSortHeader}:${currentSortOption}",
        offset: ${offset},
        limit: ${limit},
      }
    `;
  case AIML_PRODUCT_TRENDS:
    return `
      enquiry: {
        date_range: {
          from: "${dates.forReportApi(date_from)}",
          to: "${dates.forReportApi(date_to)}",
        }
        product_1: "${xAxis}"
        product_2: "${yAxis}"
      }
    `;
  case TOP_PRODUCT_GROUPS_BY_PURCHASE_VALUE:
  case TOP_PRODUCTS_BY_PURCHASE_VALUE:
    return `
      date_from: "${dates.forReportApi(date_from)}"
      date_to: "${dates.forReportApi(date_to)}"
      sort: "revenue:desc"
      limit: 10
      filters: [
        {
          id: "${snapshotBubbleId}"
          selected_values: ["${snapshotBubbleValue}"]
        }
      ]
    `;
  case TOP_PRODUCT_GROUPS_BY_QUANTITY_PURCHASED:
  case TOP_PRODUCTS_BY_PURCHASE_QUANTITY:
    return `
      date_from: "${dates.forReportApi(date_from)}"
      date_to: "${dates.forReportApi(date_to)}"
      sort:"units_sold:desc"
      limit: 10
      filters: [
        {
          id: "${snapshotBubbleId}"
          selected_values: ["${snapshotBubbleValue}"]
        }
      ]
    `;
  case PRODUCTS_PURCHASED_FREQUENTLY: {
    const selectedValuesQuery = Array.isArray(snapshotBubbleValue)
      ? snapshotBubbleValue.map(value => `"${value}"`).join(', ')
      : `"${snapshotBubbleValue}"`;
    return `
      date_range: {
        from: "${dates.forReportApi(date_from)}"
        to: "${dates.forReportApi(date_to)}"
      }
      metadata: {
        sort: "count:desc"
        offset: 0
        limit: 10
      }
      filters: [
          {
            id: "${snapshotBubbleId}"
            selected_values: [${selectedValuesQuery}]
          }
      ]
    `;
  }
  case PRODUCT_GROUP_PURCHASE_ANALYSIS_PIECHART:
  case PRODUCT_GROUP_PURCHASE_ANALYSIS:
    return `
      date_range: {
        from: "${dates.forReportApi(date_from)}"
        to: "${dates.forReportApi(date_to)}"
      }
      metadata: {
        sort: "${currentSortHeader === undefined ? 'total' : currentSortHeader}:${currentSortOption === undefined ? 'desc' : currentSortOption}"
        offset: 0
        limit: 10
      }
      filters: [
        ${!snapshotBubbleValueId ? `
        {
          id: "${snapshotBubbleId}"
          selected_values: ["${snapshotBubbleValue}"]
        }
        ` : ''}
      ]
      customers: [${snapshotBubbleValueId}]
    `;
  case MONTHLY_PURCHASES_BY_VALUE: {
    return `
        enquiry: {
          date_range_1: {
            from: "${dates.forReportApi(date_from)}"
            to: "${dates.forReportApi(date_to)}"
            complete_periods_only: false
          },
          date_range_2: {
            from: "${dates.forReportApi(date_from2)}"
            to: "${dates.forReportApi(date_to2)}"
            complete_periods_only: false
          },
          filters: [
            {
              id: "${snapshotBubbleId}"
              selected_values: ["${snapshotBubbleValue}"]
            }
          ]
        }
        sort: "period:asc",
      `;
  }
  case MONTHLY_PURCHASES_BY_QUANTITY:
    return `
      enquiry: {
        date_range_1: {
          from: "${dates.forReportApi(date_from)}"
          to: "${dates.forReportApi(date_to)}"
          complete_periods_only: false
        },
        date_range_2: {
          from: "${dates.forReportApi(date_from2)}"
          to: "${dates.forReportApi(date_to2)}"
          complete_periods_only: false
        },
        filters: [
          {
            id: "${snapshotBubbleId}"
            selected_values: ["${snapshotBubbleValue}"]
          }
        ]
        type: "quantity"
      }
      sort: "period:asc",
    `;
  case LATEST_PURCHASES_BY_VALUE:
    return `
      date_range: {
        from: "${dates.forReportApi(date_from)}"
        to: "${dates.forReportApi(date_to)}"
      }
      metadata: {
        sort: "value:desc"
        offset: 0
        limit: 10
      }
      filters: [
          {
            id: "${snapshotBubbleId}"
            selected_values: ["${snapshotBubbleValue}"]
          }
      ]
    `;
  case LATEST_PURCHASES_BY_QUANTITY:
    return `
      date_range: {
        from: "${dates.forReportApi(date_from)}"
        to: "${dates.forReportApi(date_to)}"
      }
      metadata: {
        sort: "quantity:desc"
        offset: 0
        limit: 10
      }
      filters: [
        {
          id: "${snapshotBubbleId}"
          selected_values: ["${snapshotBubbleValue}"]
        }
      ]
    `;
  case PURCHASES_VS_GAPS_BY_PURCHASE_VALUE:
    return `
      date_from: "${dates.forReportApi(date_from)}"
      date_to: "${dates.forReportApi(date_to)}"
      x: {
        key: "period",
        offset: 0,
        limit: 100
      }
      y: ["${productBubbleId}"]
      metadata: {
        measure: "value"
        sort_col: "total"
      }
      filters: [
        {
          id: "${snapshotBubbleId}"
          selected_values: ["${snapshotBubbleValue}"]
        }
      ]
    `;
  case PURCHASES_VS_GAPS_BY_PURCHASE_QUANTITY:
    return `
      date_from: "${dates.forReportApi(date_from)}"
      date_to: "${dates.forReportApi(date_to)}"
      x: {
        key: "period",
        offset: 0,
        limit: 100
      }
      y: ["${productBubbleId}"]
      metadata: {
        measure: "quantity"
        sort_col: "total"
        limit: 100
        offset: 0
      }
      filters: [
        {
          id: "${snapshotBubbleId}"
          selected_values: ["${snapshotBubbleValue}"]
        }
      ]
    `;
  case VARIANCE_ENQUIRY_BY_PURCHASE_VALUE:
    return `
      enquiry: {
        date_range_1: {
          from: "${dates.forReportApi(date_from)}"
          to: "${dates.forReportApi(date_to)}"
          complete_periods_only: false
        },
        date_range_2: {
          from: "${dates.forReportApi(date_from2)}"
          to: "${dates.forReportApi(date_to2)}"
          complete_periods_only: false
        },
        filters: [
          {
            id: "${snapshotBubbleId}"
            selected_values: ["${snapshotBubbleValue}"]
          }
        ]
        drills: [
          {
            dimensions: [
              {
                id: "${productBubbleId}",
              }
            ]
          }
        ]
      }
      metadata: {
        sort: "sales_value_1:desc",
        offset: ${offset},
        limit: 20
      }
    `;
  case VARIANCE_ENQUIRY_BY_QUANTITY:
    return `
      enquiry: {
        date_range_1: {
          from: "${dates.forReportApi(date_from)}"
          to: "${dates.forReportApi(date_to)}"
          complete_periods_only: false
        },
        date_range_2: {
          from: "${dates.forReportApi(date_from2)}"
          to: "${dates.forReportApi(date_to2)}"
          complete_periods_only: false
        },
        filters: [
          {
            id: "${snapshotBubbleId}"
            selected_values: ["${snapshotBubbleValue}"]
          }
        ]
        drills: [
          {
            dimensions: [
              {
                id: "${productBubbleId}",
              }
            ]
          }
        ]
      }
      metadata: {
        sort: "quantity_1:desc",
        offset: ${offset},
        limit: 20
      }
    `;
  case ACCOUNTS_ANALYSIS:
    return `
      parent: "SAL100"
      date_range: {
        from: "${dates.forReportApi(date_from)}"
        to: "${dates.forReportApi(date_to)}"
      }
      metadata: {
        sort: "value:desc"
        offset: 0
        limit: 5
      }
      filters: []
    `;
  case TRANSACTIONS_ANALYSIS:
    return `
      date_range: {
        from: "${dates.forReportApi(date_from)}"
        to: "${dates.forReportApi(date_to)}"
      }
      metadata: {
        sort: "value:desc"
        offset: 0
        limit: 5
      }
      filters: [
        {
          id: "${snapshotBubbleId}"
          selected_values: ["${snapshotBubbleValue}"]
          filter_type: "INCLUDE"
        }
      ]
    `;
  case INTERACTION_OUTCOMES:
    return `
      date_range: {from: "${dates.forReportApi(date_from)}", to: "${dates.forReportApi(date_to)}"}
      sort: "${currentSortHeader}:${currentSortOption}"
      offset: ${offset}
      limit: ${limit || 100}
      user: [${Object.values(user).filter(id => typeof id === 'string').map(id => `"${id}"`)}]
      interaction_type_id: [${interaction_type}]
      interaction_outcome_id: [${interaction_outcome}]
      next_action_id: [${next_action}]
    `;
  case INTERACTION_OUTCOMES_DRILL:
    return `
      date_range: {from: "${dates.forReportApi(date_from)}", to: "${dates.forReportApi(date_to)}"}
      sort: "${currentSortHeader}:${currentSortOption}"
      offset: ${offset}
      limit: 100
      user: [${Object.values(user).filter(id => typeof id === 'string').map(id => `"${id}"`)}]
      interaction_type_id: [${interaction_type}]
      interaction_outcome_id: []
      next_action_id: [${next_action}]
      drill_type: "${drill_type}"
    `;
  case INTERACTION_SPEND_SUMMARY:
    return `
      ${analysis_field_id && analysis_field_value_id ? 
    `analysis_field_id: ${analysis_field_id}
      analysis_field_value_id: [${analysis_field_value_id}]`
    : ''}
      date_range: {
        from: "${dates.forReportApi(date_from)}"
        to: "${dates.forReportApi(date_to)}"
      }
      user: [${Object.values(user).filter(id => typeof id === 'string').map(id => `"${id}"`)}]
      interaction_type_id: [${interaction_type}]
      report_type: "${report_type}"
      offset: ${offset}
      limit: ${limit ?? 100}
      sort: "${currentSortHeader}:${currentSortOption}"
    `;
  case CUSTOMER_ACTIVITY:
    return `
      date_range_1: {
        from: "${dates.forReportApi(date_from)}",
        to: "${dates.forReportApi(date_to)}",
      },
      date_range_2: {
        from: "${dates.forReportApi(date_from2)}",
        to: "${dates.forReportApi(date_to2)}",
      },
      offset: ${offset},
      limit: ${limit},
      sort: "${currentSortHeader}:${currentSortOption}",
      filters: [${filters}]
    `;
  case HIGHEST_MARGIN_CUSTOMER:
  case LOWEST_MARGIN_CUSTOMER:
  case HIGHEST_REVENUE_CUSTOMER:
  case LOWEST_REVENUE_CUSTOMER:
  case TEMPLATE_REPORT_TYPES_USING_SALES_REP_FILTER.find(type => type === reportType):
    return `
      date_from: "${dates.forReportApi(date_from)}"
      date_to: "${dates.forReportApi(date_to)}"
      offset: ${offset}
      limit: ${limit}
      sort: "${currentSortHeader}:${currentSortOption}"
      filters: [${filters}]
    `;
  default:
    return `
      date_from: "${dates.forReportApi(date_from)}"
      date_to: "${dates.forReportApi(date_to)}"
      offset: ${offset}
      limit: ${limit}
      sort: "${currentSortHeader}:${currentSortOption}"
    `;
  }
};
