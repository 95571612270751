<template>
  <div class="filter">
    <div class="filter-type">
      <TabSwitch
        v-if="showTabSwitch"
        class="tab-switch"
        :items="clauseOptions"
        :selected-value="selectedValue"
        @switch-selected="handleFilterSwitch($event)"
      />
      <slot />
    </div>

    <div class="actions">
      <IconButton
        icon-name="edit"
        purpose="transparent"
        icon-color="var(--colour-utility-action)"
        variant="round"
        :icon-size="24"
        @click="openEditAlertModal(selectedQueryId)"
      />
      <IconButton
        v-if="showDeleteIcon"
        icon-name="trash"
        purpose="transparent"
        icon-color="var(--colour-utility-action)"
        variant="round"
        :icon-size="24"
        @click="removeFilter(id, filterSection, entity, productType)"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { IconButton, TabSwitch } from '@sales-i/dsv3';
import { tCrm as t } from '@sales-i/utils';
import {
  UPDATE_CUSTOMER_FILTER_IN_QUERY,
  UPDATE_SALES_FILTER_IN_QUERY,
  UPDATE_PRODUCT_FILTER_IN_QUERY,
  REMOVE_SALES_FILTER_FROM_QUERY,
  REMOVE_CUSTOMER_FILTER_FROM_QUERY,
  REMOVE_PRODUCT_FROM_QUERY
} from '@/intelligence/store/actionType';
import {
  SET_CONFIRMATION_MODAL,
  PATCH_BY_ENTITY
} from '@/shared/store/actionType';
import { QUERY_BUILDER } from '@/intelligence/store/data/reportTypes';
import {
  alertBuilderScope,
  alertsScope
} from '@/intelligence/router/urlBits';
import { navigateToUrl } from 'single-spa';
import { AND_OR_OPTIONS } from '@/intelligence/store/data/filters';
import { useAlertBuilder } from '@/intelligence/composables/useAlertBuilder';

const store = useStore();
const { alertBuilderBaseUrl } = useAlertBuilder({ store });

// Tabswitch
const clauseOptions = computed(() => AND_OR_OPTIONS);

const selectedValue = computed(() => props.type || props.clause || props.operator);
const props = defineProps({
  clause: {
    type: String,
    default: '',
  },
  operator: {
    type: String,
    default: '',
  },
  entity: {
    type: String,
    default: '',
  },
  filterSection: {
    type: String,
    default: '',
  },
  id: {
    type: Number,
    default: 0,
  },
  index: {
    type: Number,
    default: 0,
  },
  productType: {
    type: String,
    default: '',
  },
  showDeleteIcon: {
    type: Boolean,
    default: true,
  },
  showTabSwitch: {
    type: Boolean,
    default: true,
  },
  type: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['onChange']);

const updateCustomerFilterInQuery = (params) => store.dispatch(`intelligence/queryBuilder/${UPDATE_CUSTOMER_FILTER_IN_QUERY}`, params);
const updateSalesFilterInQuery = (params) => store.dispatch(`intelligence/queryBuilder/${UPDATE_SALES_FILTER_IN_QUERY}`, params);
const updateProductFilterInQuery = (params) => store.dispatch(`intelligence/queryBuilder/${UPDATE_PRODUCT_FILTER_IN_QUERY}`, params);

// Edit filter
function openEditAlertModal(selectedQueryId) {
  const alertBuilerPath = `${alertBuilderBaseUrl.value}/${alertsScope}/${selectedQueryId}/${alertBuilderScope}`;

  // Route the user to the first page in the alertbuilder they have data for
  if (props.filterSection === 'sales'){
    navigateToUrl(`${alertBuilerPath}/sales-data`);
  } else if (props.filterSection === 'crm') {
    navigateToUrl(`${alertBuilerPath}/crm-data`);
  } else if (props.filterSection === 'products') {
    navigateToUrl(`${alertBuilerPath}/product-data`);
  }
}

// Delete filter
const savedQuery = computed(() => store.state.intelligence.savedQuery.data);
const patchByEntity = (params) => store.dispatch(`intelligence/savedQuery/${PATCH_BY_ENTITY}`, params );
const removeSalesFilterFromQuery = (params) => store.dispatch(`intelligence/queryBuilder/${REMOVE_SALES_FILTER_FROM_QUERY}`, params);
const removeCustomerFilterFromQuery = (params) => store.dispatch(`intelligence/queryBuilder/${REMOVE_CUSTOMER_FILTER_FROM_QUERY}`, params);
const removeProductFromQuery = (product) => store.dispatch(`intelligence/queryBuilder/${REMOVE_PRODUCT_FROM_QUERY}`, product);
const showConfirmationModal = params => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);

const selectedQueryId = computed(() => store.getters['intelligence/savedQuery/getQueryById']);

// Get the filters
const selectedSalesFilters = computed(() => store.getters['intelligence/queryBuilder/getSalesFilters']);
const selectedCrmFilters = computed(() => store.getters['intelligence/queryBuilder/getCrmFilters']);
const selectedProducts = computed(() => store.getters['intelligence/queryBuilder/getAllProducts']);

// Get the data clauses from the store in readines to pass along with the saveAlert
const getSalesDataClause = computed(() => store.state.intelligence.enquiry.salesDataClause);
const getCrmDataClause = computed(() => store.state.intelligence.enquiry.crmDataClause);
const getProductDataClause = computed(() => store.state.intelligence.enquiry.productDataClause);

const filters = computed(() => {
  return {
    salesFilters: selectedSalesFilters.value,
    crmFilters: selectedCrmFilters.value,
    productFilters: selectedProducts.value,
  };
});

const dataClauses = computed(() => {
  return {
    salesDataClause: getSalesDataClause.value,
    crmDataClause: getCrmDataClause.value,
    productDataClause: getProductDataClause.value,
  };
});

const getRequestParameters = (params) => store.getters['intelligence/shared/getRequestParameters'](params);

async function removeFilter(id, filterSection, entity) {
  const removeFunctions = {
    'sales': async() => await removeSalesFilterFromQuery(id),
    'crm': async() => await removeCustomerFilterFromQuery({ id, entity }),
    'products': async() => await removeProductFromQuery({ id })
  };

  const removeFunction = removeFunctions[filterSection];

  if (!removeFunction) {
    console.error('Invalid filter section provided.');
    return;
  }

  showConfirmationModal({
    message: t('do_you_really_want_to_delete_this_filter'),
    updatedMessage: t(
      'You have deleted this filter',
      'you_have_deleted_this_filter'
    ),
    updateFunction: async () => {
      try {
        await removeFunction();
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    finishFunction: updateFilters
  });
}

async function handleFilterSwitch($event) {
  if (props.filterSection === 'crm') {
    await updateCustomerFilterInQuery({
      id: props.id,
      clause: $event.value,
    });
  } else if (props.filterSection === 'sales') {
    await updateSalesFilterInQuery({
      id: props.id,
      type: $event.value,
    });
  } else {
    await updateProductFilterInQuery({
      id: props.id,
      operator: $event.value,
    });
  }
  await new Promise(resolve =>
    setTimeout(resolve, 1000)
  );
  updateFilters();
  emit('onChange');
}

async function updateFilters() {
  // Get the latest filters from the store
  const queryParams = getRequestParameters([QUERY_BUILDER]);

  await patchByEntity([
    savedQuery.value.id,
    {
      id: savedQuery.value.results[0].id,
      query_arguments: `querybuilder (${queryParams})||||${JSON.stringify(filters.value)}~~~~${JSON.stringify(dataClauses.value)}`,
    }
  ]);
}
</script>

<style lang="scss" scoped>
.filter {
  --flow-space: var(--spacing-3);
  background-color: var(--colour-panel-alert-base);
  border-radius: var(--border-radius-1);
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-2);
  justify-content: space-between;
  padding: var(--spacing-1) var(--spacing-2) var(--spacing-1) var(--spacing-3);
  // padding-top: var(--spacing-3);

  p {
    font-size: var(--font-size-4);
  }
}

.filter-type {
  align-self: center;
}

.filter-type:has(.tab-switch) {
  padding-top: var(--spacing-3);
} 


:deep(.tab-switch) {
  position: absolute;
  margin-top: calc(var(--spacing-5) * -1);

}
.actions {
  display: flex;
}
</style>
