<template>
  <div class="widget-settings">
    <CustomModal
      :title="`${widgetName} - ${t('settings')}`"
      show-modal
      :show-unload-warning="isFormDirty"
      :min-width="300"
      @close-modal="closeModal"
    >
      <template #default>
        <div class="px-3">
          <h4 
            v-if="show.limit && show.isChartShown && show.isTableShown"
            class="mb-1"
          >
            {{ t('general_settings') }}
          </h4>
          <CustomInput
            v-if="show.limit"
            class="mr-2"
            :label="t('limit')"
            type="number"
            :min="1"
            :max="100"
            :value="settings.limit"
            @input="handleFieldInput($event, 'limit', true)"
          />
          <CustomCheckbox
            v-if="show.isChartShown"
            :label="t('show_chart')"
            class="mb-2"
            :value="settings.isChartShown"
            @input="handleFieldInput($event, 'isChartShown')"
          />
          <CustomCheckbox
            v-if="show.isTableShown"
            :label="t('show_table')"
            :value="settings.isTableShown"
            @input="handleFieldInput($event, 'isTableShown')"
          />
          <h4 
            v-if="show.showValues"
            class="mt-3 mb-1"
          >
            {{ t('chart_settings') }}
          </h4>
          <CustomCheckbox
            v-if="show.showValues"
            :label="t('show_values')"
            :value="settings.showValues"
            @input="handleFieldInput($event, 'showValues')"
          />
        </div>
      </template>
      <template #footer>
        <CustomButton
          small
          class="mx-half"
          :disabled="!isSaveButtonEnabled"
          @click="saveSettings"
        >
          {{ t('save') }}
        </CustomButton>
        <CustomButton
          class="mx-half"
          small
          @click="closeModal"
        >
          {{ t('cancel') }}
        </CustomButton>
      </template>
    </CustomModal>
  </div>
</template>

<script setup>
// WidgetSettings
import { CustomModal, CustomButton, CustomInput, CustomCheckbox } from '@sales-i/dsv3';
import { computed, onMounted, ref, } from 'vue';
import { isEqualObjects, tCrm as t } from '@sales-i/utils';
import { TEMPLATE_REPORT_TYPES, TRENDS } from '@/intelligence/store/data/reportTypes';

const props = defineProps({
  widgetName: {
    type: String,
    default: '',
  },
  widgetType: {
    type: String,
    default: '',
  },
  settings: {
    type: Object,
    default: () => ({}),
  }
});

const emit = defineEmits(['close', 'save']);
const settings = ref({ ...props.settings });
const initialFormValues = ref({});
const isFormDirty = computed(() => !isEqualObjects(initialFormValues.value, settings.value));
const show = computed(() => {
  if (TEMPLATE_REPORT_TYPES.includes(props.widgetType)) {
    return {
      limit: true,
      isChartShown: true,
      isTableShown: true,
      showValues: true
    };
  }
  else if (TRENDS === props.widgetType) {
    return {
      isChartShown: true,
      isTableShown: true,
    };
  }
  return {
    limit: true
  };
});

const isSaveButtonEnabled = computed(() => {
  return !!settings.value.limit;
});

onMounted(() => {
  initialFormValues.value = { ...settings.value };
});

function closeModal() {
  emit('close');
}
function saveSettings() {
  emit('save', settings.value);
}
function handleFieldInput(e, fieldName, numeric = false) {
  settings.value[fieldName] = numeric ? +e : e;
}
</script>

<style lang="scss" scoped></style>
