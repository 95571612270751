<template>
  <CustomCard
    v-if="permissions.read && item && item.id"
    class="interaction-card p-0"
    purpose="reversed"
  >
    <div class="head">
      <div
        v-if="chip.value !== undefined"
        class="chip-container"
      >
        <RagChip
          v-bind="chip"
          :currency="currency"
        />
      </div>
      <h3 class="fw-sbld">
        {{ props.item.interaction_type_name }}
      </h3>
      <div class="subtitle my-half">
        <span>
          <IconBase
            :width="22"
            :height="22"
            icon-name="calendar"
            class="icon"
          />
          {{ dates.format(props.item.date_start) }}
        </span>
        <span v-if="contactName">
          <IconBase
            :width="22"
            :height="22"
            icon-name="phone"
            class="icon"
          />
          {{ contactName }}
        </span>
        <span>
          <IconBase
            :width="22"
            :height="22"
            icon-name="user"
            class="icon"
          />
          {{ props.item.user_name }}
        </span>
      </div>
    </div>
    <div class="note">
      <IconBase
        :width="22"
        :height="22"
        icon-name="notification"
        class="icon mr-1"
      />
      <p>{{ props.item.description || t('no_interaction_note') }}</p>
    </div>
    <div
      v-if="props.item.purpose_name || props.item.outcome_name || props.item.ideal_outcome_name"
      class="fields"
    >
      <span v-if="props.item.purpose_name">
        {{ t('purpose') }}: <span class="value">{{ props.item.purpose_name }}</span>
      </span>
      <span v-if="props.item.ideal_outcome_name">
        {{ t('ideal_outcome') }}:
        <span class="value">{{ props.item.ideal_outcome_name }}</span>
      </span>
      <span v-if="props.item.outcome_name">
        {{ t('outcome') }}:
        <span class="value">{{ props.item.outcome_name }}</span>
      </span>
    </div>
    <div class="cta-container">
      <slot name="cta">
        <CustomButton
          purpose="text"
          class="btn fw-sbld"
          small
          @click="handleCtaClick"
        >
          {{ props.ctaTitle || t('open') }}
        </CustomButton>
      </slot>
    </div>
  </CustomCard>
</template>

<script setup>
// InteractionDropdownCard
import { CustomButton, CustomCard, IconBase, RagChip } from '@sales-i/dsv3';
import useCurrency from '@/shared/composables/useCurrency';
import { computed } from 'vue';
import { dates, tCrm as t } from '@sales-i/utils';

import usePermissions from '@/shared/composables/usePermissions';
import { interactions } from '@/shared/store/data/policies';
const { getPermissions } = usePermissions();
const permissions = getPermissions(interactions);

const { cs, currency } = useCurrency();

const chip = computed(() => ({
  value: value.value,
  prefix: cs,
  abbr: true,
  /** todo */
  green: 15000,
  amber: 7000,
  small: true,
}));

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  ctaTitle: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['ctaClick']);

const value = computed(() => {
  if (
    !props.item.relationships ||
    !props.item.relationships.opportunities ||
    !props.item.relationships.opportunities.length
  ) {
    return undefined;
  }
  return props.item.relationships.opportunities.reduce((prev, curr) => prev + curr.value || 0.0, 0.0);
});

const contactName = computed(() => {
  let opps = (props.item.relationships || {}).opportunities || [];
  let contacts = (props.item.relationships || {}).contacts || [];
  if (contacts.length) return contacts[0].full_name || '';
  if (opps.length) return opps[0].contact_name;
  return '';
});

function handleCtaClick() {
  emit('ctaClick', props.item);
}
</script>

<style lang="scss" soped>
.interaction-card {
  .head {
    padding: var(--spacing-2);
    padding-bottom: 0;
    position: relative;
    .chip-container {
      position: absolute;
      right: var(--spacing-2);
      top: var(--spacing-2);
      min-width: 50px;

      div {
        float: right;
      }
    }
  }
  .subtitle {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    font-size: var(--font-size-small);
    span {
      display: flex;
      align-items: center;
      margin-right: var(--spacing-half);
    }
  }
  .note {
    display: flex;
    flex-flow: row nowrap;
    padding: var(--spacing-2);
    background-color: var(--colour-panel-g-8);
    margin-top: 0;
    max-height: 5rem;
    overflow-y: auto;
    flex-grow: 1;
    p {
      margin-top: 2px;
      font-style: italic;
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-small);
    }
  }
  .fields {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 0 var(--spacing-2);
    margin-top: var(--spacing-1);
    span {
      font-size: var(--font-size-small);
      margin-right: var(--spacing-1);
      color: var(--colour-utility-base);
      .value {
        color: var(--colour-utility-black);
      }
    }
  }
  .cta-container {
    padding: var(--spacing-2);
    padding-top: 0;
    display: flex;
    justify-content: flex-end;
    flex: 1;
    flex-grow: 1;
    align-items: flex-end;
  }
}
</style>
