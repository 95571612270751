<template>
  <div class="multi-search">
    <div>
      <!-- TODO: replace with searchInput for consistency -->
      <label :for="id">{{ label }}</label>
      <div class="item-search">
        <IconBase
          icon-name="search"
          icon-color="var(--colour-utility-black)"
          class="icon"
          height="32"
          width="32"
          @click="focus"
        />

        <input
          :id="id"
          ref="searchinput"
          :value="searchQuery"
          name="search"
          type="text"
          class="search-input"
          :aria-label="placeholder"
          :placeholder="placeholder"
          @input="handleSearchInput"
        >

        <IconButton
          icon-name="close-line"
          purpose="reversed"
          class="close"
          tabindex="0"
          variant="round"
          @click.prevent="clearSearch"
        />
      </div>
    </div>

    <template v-if="items?.length">
      <div class="item-listing">
        <ul
          ref="listContainerRef"
          class="rendered-list"
        >
          <ListItem
            v-for="{ item, disabled, checked } in listItems"
            :key="item[props.keyField]"
            :item="item"
            @on-change="toggleCheck(item)"
          >
            <slot
              name="item"
              :item="item"
              :checked="checked"
              :disabled="disabled"
            >
              {{ item[captionKey] }}
            </slot>
          </ListItem>
        </ul>
        <BufferImage
          v-show="loading"
          :size="40"
          float="center"
        />
        <div class="actions">
          <CustomButton
            :disabled="!checkedItems.length"
            purpose="action"
            small
            @click="handleAddItems"
          >
            <slot name="bntCaption">
              {{ t('add_to_selection') }}
            </slot>
          </CustomButton>
        </div>
      </div>
    </template>

    <div class="selection-container">
      <div
        v-for="item in selectedItems"
        :key="item[props.keyField]"
        class="selection"
      >
        <IconButton
          icon-name="close-line"
          purpose="reversed"
          class="close"
          @click.stop="handleDeleteItem(item)"
        />
        <p>
          <slot
            name="selected"
            :item="item"
          >
            {{ item[captionKey] }}
          </slot>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onUnmounted, ref, watch } from 'vue';
import { debounce, tCrm as t, uuid } from '@sales-i/utils';
import { BufferImage, CustomButton, IconBase, IconButton } from '@sales-i/dsv3';
import ListItem from '@/intelligence/components/AlertBuilder/ListItem.vue';

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  items: {
    type: Array,
    default: () => [],
  },
  selectedItems: {
    type: Array,
    default: () => [],
  },
  placeholder: {
    type: String,
    default: '',
  },
  keyField: {
    type: String,
    default: 'id',
  },
  captionKey: {
    type: String,
    default: 'name',
  },
});

const emit = defineEmits(['update:items', 'search', 'clearSearch', 'update:selectedItems', 'loadPage']);

const id = uuid('search-input-');
const scrollThreshold = 50;
const searchQuery = ref('');
const listContainerRef = ref(null);
const checkedItems = ref([]);

const listItems = computed(() => props.items.map(item => ({
  item, // preserving original item
  checked: checkedItems.value.some(x => x[props.keyField] === item[props.keyField]),
  disabled: props.selectedItems.some(x => x[props.keyField] === item[props.keyField]),
})));

const clearSearch = () => {
  emit('update:items', []);
  checkedItems.value = [];
  searchQuery.value = '';
  emit('clearSearch');
};

const handleSearch = () => {
  emit('search', searchQuery.value);
};

const handleDeleteItem = (item) => {
  emit('update:selectedItems', props.selectedItems.filter(x => x[props.keyField] !== item[props.keyField]));
};

const toggleCheck = (item) => {
  const itemIndex = checkedItems.value.findIndex(x => x[props.keyField] === item[props.keyField]);
  if (itemIndex >= 0) {
    checkedItems.value.splice(itemIndex, 1);
  } else {
    checkedItems.value.push(item);
  }
};

const handleAddItems = () => {
  const checkedForSelected = checkedItems.value.filter(
    x => !props.selectedItems.some(y => y[props.keyField] === x[props.keyField])
  );
  emit('update:selectedItems', [
    ...props.selectedItems,
    ...checkedForSelected,
  ]);
  clearSearch();
};

const handleSearchInput = (event) => {
  searchQuery.value = event.target.value;
};

const handleScroll = debounce(function() {
  const container = listContainerRef.value;
  if (container.scrollHeight - container.scrollTop - container.clientHeight < scrollThreshold) {
    emit('loadPage', props.items?.length);  
  }
});

watch(
  () => listContainerRef.value,
  () => {
    if (listContainerRef.value) {
      listContainerRef.value.addEventListener('scroll', handleScroll);
    }
  }
);

watch(
  () => searchQuery.value,
  debounce(() => {
    if (searchQuery.value) {
      handleSearch();
    } else {
      clearSearch();
    }
  }, 200)
);

onUnmounted(() => {
  if (listContainerRef.value) {
    listContainerRef.value.removeEventListener('scroll', handleScroll);
  }
});
</script>

<style lang="scss" scoped>
label {
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-semibold);
  margin-block-end: 0;
}

.item-search {
  align-items: center;
  background-color: var(--colour-panel-g-0);
  border-radius: 900px;
  border: 2px solid transparent;
  box-shadow: inset 0 0 0 1px var(--border-utility-base);
  display: inline-flex;
  flex-flow: row nowrap;
  font-weight: var(--font-weight-medium);
  min-width: calc(var(--spacing-12) * 2);
  padding: var(--spacing-half) var(--spacing-1);
  position: relative;
  overflow: hidden;
  width: 100%;

  &:focus-within:not([disabled]) {
    border: 2px solid var(--colour-utility-checked);
    box-shadow: 0px 0px 0px var(--spacing-half) var(--colour-utility-focus);
    outline: 2px solid transparent;
  }
  .close {
    cursor: pointer;
    height: 40px;
    width: 40px;
    position: absolute;
    top: 0;
    right: var(--spacing-1);
    padding: 0;
    margin: 0;
    &.wide {
      right: 245px;
    }
  }
}


.search-input,
.search-input:focus {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  outline: none;
  padding-left: var(--spacing-1);
}

.item-listing {
  display: flex;
  flex-direction: column;
  max-height: 320px;
}

.rendered-list {
  overflow-y: scroll;
  flex: 10;
}

.actions {
  background-color: var(--colour-panel-action);
  display: flex;
  flex: 1;
  justify-content: center;
  padding: var(--spacing-2);
  width: 100%;
}

.selection-container {
  .selection {
    display: flex;
    align-items: flex-start;
    p {
      padding-block: var(--spacing-half)
    }
  }
}
</style>