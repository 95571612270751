/* eslint-disable no-unused-vars */
import {
  phoneRegex,
  nameRegex,
  emailRegex,
  addressLineRegex,
  townRegex,
  postCodeRegex
} from '@/shared/regex/form';
import { salutationList } from '@/shared/constants/customerDetails/salutations';
import { countryList } from '@/shared/constants/countries/countries';
import { tGlobal as t } from '@sales-i/utils';
export const contactData = (compulsoryFields = [], nonCompulsoryFields = []) => {
  let fields = [
    {
      stage: t('key_fields'),
      fields: [
        {
          label: t('customer'),
          id: 'company',
          type: 'text',
          value: '',
          required: false,
          disabled: false,
        },
        {
          label: t('title'),
          id: 'salutation',
          type: 'select',
          options: salutationList,
          value: '',
          required: true,
          disabled: false,
          errorMessage: t('please_select_a_title'),
        },
        {
          label: t('first_name'),
          id: 'first_name',
          type: 'text',
          min: 1,
          max: 40,
          placeholder: '',
          required: true,
          disabled: false,
          regex: nameRegex,
          errorMessage: t('please_enter_a_valid_first_name'),
        },
        {
          label: t('surname'),
          id: 'surname',
          type: 'text',
          minLength: 1,
          maxLength: 40,
          placeholder: '',
          required: true,
          disabled: false,
          regex: nameRegex,
          errorMessage: t('please_enter_a_valid_last_name'),
        },
        {
          label: t('preferred_name'),
          id: 'preferred_name',
          type: 'text',
          minLength: 1,
          maxLength: 80,
          placeholder: '',
          required: false,
          disabled: false,
          regex: nameRegex,
          errorMessage: t('please_enter_a_valid_preferred_name'),
        },
        {
          label: t('email'),
          id: 'email',
          type: 'email',
          min: 3,
          max: 50,
          placeholder: '',
          required: false,
          disabled: false,
          regex: emailRegex,
          errorMessage: t('please_enter_a_valid_email'),
        },
        {
          label: t('mobile_phone'),
          id: 'mobile_phone',
          type: 'text',
          minLength: 1,
          maxLength: 40,
          placeholder: '',
          required: false,
          disabled: false,
          regex: phoneRegex,
          errorMessage: t('please_enter_a_valid_mobile_number'),
        },
      ],
    },
    {
      stage: t('secondary_fields'),
      fields: [
        {
          label: t('job_title'),
          id: 'job_title',
          type: 'text',
          maxLength: 40,
          placeholder: '',
          required: false,
          disabled: false,
          errorMessage: t('please_enter_a_valid_job_title'),
        },
        {
          label: t('business_phone'),
          id: 'business_phone',
          type: 'text',
          minLength: 1,
          maxLength: 40,
          placeholder: '',
          required: false,
          disabled: false,
          regex: phoneRegex,
          errorMessage: t('please_enter_a_valid_business_number'),
        },
        {
          label: t('office_phone'),
          id: 'office_phone',
          type: 'text',
          min: 1,
          max: 40,
          placeholder: '',
          required: false,
          disabled: false,
          regex: phoneRegex,
          errorMessage: t('please_enter_a_valid_office_number'),
        },
        {
          label: t('home_phone'),
          id: 'home_phone',
          type: 'text',
          minLength: 1,
          maxLength: 40,
          placeholder: '',
          required: false,
          disabled: false,
          regex: phoneRegex,
          errorMessage: t('please_enter_a_valid_home_number'),
        },
        {
          label: t('fax'),
          id: 'fax',
          type: 'text',
          minLength: 1,
          maxLength: 40,
          placeholder: '',
          required: false,
          disabled: false,
          regex: phoneRegex,
          errorMessage: t('please_enter_a_valid_fax_number'),
        },
        {
          label: t('default_contact'),
          id: 'is_default_contact',
          type: 'switch',
          minLength: 1,
          maxLength: 40,
          placeholder: '',
          required: false,
          disabled: false,
          value: false
        },
        {
          label: t('marketing_contact'),
          id: 'is_marketing',
          type: 'switch',
          minLength: 1,
          maxLength: 40,
          placeholder: '',
          required: false,
          disabled: false,
          value: false
        },
        {
          label: t('address_line_1'),
          id: 'address_line_1',
          type: 'text',
          minLength: 1,
          maxLength: 80,
          placeholder: '',
          required: false,
          disabled: false,
          isAddress: true,
          regex: addressLineRegex,
          errorMessage: t('please_enter_a_valid_address_line_1'),
        },
        {
          label: t('address_line_2'),
          id: 'address_line_2',
          type: 'text',
          minLength: 1,
          maxLength: 80,
          placeholder: '',
          required: false,
          disabled: false,
          isAddress: true,
          regex: addressLineRegex,
          errorMessage: t('please_enter_a_valid_address_line_2'),
        },
        {
          label: t('address_line_3'),
          id: 'address_line_3',
          type: 'text',
          minLength: 1,
          maxLength: 80,
          placeholder: '',
          required: false,
          disabled: false,
          isAddress: true,
          regex: addressLineRegex,
          errorMessage: t('please_enter_a_valid_address_line_3'),
        },
        {
          label: t('address_line_4'),
          id: 'address_line_4',
          type: 'text',
          minLength: 1,
          maxLength: 80,
          placeholder: '',
          required: false,
          disabled: false,
          isAddress: true,
          regex: addressLineRegex,
          errorMessage: t('please_enter_a_valid_address_line_4'),
        },
        {
          label: t('town'),
          id: 'town',
          type: 'text',
          minLength: 1,
          maxLength: 80,
          placeholder: '',
          required: false,
          disabled: false,
          isAddress: true,
          regex: townRegex,
          errorMessage: t('please_enter_a_valid_town'),
        },
        {
          label: t('county'),
          id: 'county',
          type: 'text',
          minLength: 1,
          maxLength: 40,
          placeholder: '',
          required: false,
          disabled: false,
          isAddress: true,
          regex: townRegex,
          errorMessage: t('please_enter_a_valid_county'),
        },
        {
          label: t('post_code'),
          id: 'post_code',
          type: 'text',
          minLength: 1,
          maxLength: 12,
          placeholder: '',
          required: false,
          disabled: false,
          isAddress: true,
          regex: postCodeRegex,
          errorMessage: t('please_enter_a_valid_post_code'),
        },
        {
          label: t('country'),
          id: 'country',
          type: 'select',
          options: countryList,
          required: false,
          disabled: false,
          isAddress: true,
        },
        {
          label: t('contact_preference_email'),
          id: 'contact_preference_email',
          type: 'checkbox',
          value: false,
          required: false,
          disabled: false,
          isContact: true,
        },
        {
          label: t('contact_preference_post'),
          id: 'contact_preference_post',
          type: 'checkbox',
          value: false,
          required: false,
          disabled: false,
          isContact: true,
        },
        {
          label: t('contact_preference_phone'),
          id: 'contact_preference_phone',
          type: 'checkbox',
          value: false,
          required: false,
          disabled: false,
          isContact: true,
        },
        {
          label: t('contact_preference_sms'),
          id: 'contact_preference_sms',
          type: 'checkbox',
          value: false,
          required: false,
          disabled: false,
          isContact: true,
        },
      ],
    },
  ];
  compulsoryFields.forEach((field, fieldIndex) => {
    fields[0].fields[fieldIndex] = {
      ...fields[0].fields[fieldIndex],
      ...field,
    };
  });

  nonCompulsoryFields.forEach((field, fieldIndex) => {
    fields[1].fields[fieldIndex] = {
      ...fields[1].fields[fieldIndex],
      ...field,
    };
  });

  return fields;
};

export const customerContactData = contactData();
