<template>
  <CustomCard
    :id="id"
    class="crm-card"
    :purpose="cardPurpose"
    :on-click="() => $emit('open')"
  >
    <div class="flow">
      <section>
        <IconBase
          v-if="icon"
          icon-name="notes"
          icon-color="var(--colour-utility-white)"
          :height="58"
        />
        <header>
          <h3>{{ title }}</h3>
          <p>{{ description }}</p>
        </header>
        <p class="cta">
          <CustomButton
            v-if="linkText != null"
            purpose="text"
            @click="$emit('open')"
          >
            {{ linkText || t('open_insight') }}
          </CustomButton>
          <slot name="cta" />
        </p>
        <slot />
      </section>
    </div>
  </CustomCard>
</template>

<script>
import { CustomButton, CustomCard, IconBase } from '@sales-i/dsv3';
import { tCrm as t } from '@sales-i/utils';

export default {
  name: 'CardInsights',
  components: {
    CustomCard,
    IconBase,
    CustomButton,
  },
  props: {
    description: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    cardPurpose: {
      type: String,
      default: 'reversed',
    },
    icon: {
      type: Boolean,
      default: false,
    },
    linkText: {
      type: String,
      default: '',
    },
  },
  emits: ['open'],
  computed: {
    classes() {
      return {
        ['icon-button']: true,
        [this.purpose]: true,
        disabled: this.disabled,
      };
    },
    hasInfoSlot() {
      return this.$slots.info;
    },
    id() {
      return `card-${this.title.replace(/[\s&/#,()[\]]/g, '-').toLowerCase()}`;
    }
  },
  methods: {
    t,
    linkAction(event, link) {
      let emit = link.emit || link.text.replace(/ /gi, '-');
      this.stopProp(event);
      this.$emit(emit, link);
    },
    stopProp(event) {
      if (event) {
        event.stopPropagation();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

header {
  flex: 1;
  margin-bottom: var(--spacing-1);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-bottom: var(--spacing-3);
  }

  p {
    font-size: var(--font-size-small);
    line-height: 1.3;
    margin-bottom: 0;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      font-size: var(--font-size-4);
    }
  }
}

.crm-card {
  box-shadow: none;
  justify-content: space-between;

  .links {
    text-align: right;
  }

  button {
    color: var(--colour-brand-deluge);
    font-weight: var(--font-weight-semibold);
    color: var(--colour-utility-action);
    text-decoration: underline;
  }
}

.c-card--body .flow>.flow {
  height: 100%;

  :deep(section) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}

.c-card :deep(svg) {
  border-radius: 50%;
  color: white;
  padding: var(--spacing-1);
  height: 48px;
  width: 48px;
}

h3 {
  font-weight: var(--font-weight-semibold);
  margin-bottom: var(--spacing-1);
}

.cta {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semibold);
  display: flex;
  justify-content: flex-end;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    font-size: var(--font-size-5);
  }
}

// Section-specific styles
.performance h3,
.insights-wrapper--performance h3 {
  color: var(--colour-data-puerto-rico-dark);
}

.people h3,
.insights-wrapper--people h3 {
  color: var(--colour-data-puerto-rico-dark);
}

.products h3,
.insights-wrapper--products h3 {
  color: var(--colour-data-viking-label);
}

.customer h3,
.insights-wrapper--customer h3 {
  color: var(--colour-data-turmeric-label);
}
</style>
