<template>
  <ColumnsWithActionsLayout
    :loading="!(loaded && item && !loading)"
    :buttons="buttons"
  >
    <template #highlight>
      <CardStack v-if="item.entity && item.entity.id">
        <CRMCard
          :key="item.entity.id"
          :title="computedEntityName"
          :currency="currency"
          :chips="getChips(item.entity)"
          :links="getCardLinks(item.entity)"
          :class="{ 'b-radius-0': item.contact.id }"
          icon-name="organisation"
          icon-position="top"
          :on-click="
            () =>
              onItemClick({
                id: item.entity.id,
                action: 'open',
                type: item.entityType,
              })
          "
          @open="
            onItemClick({
              id: item.entity.id,
              action: 'open',
              type: item.entityType,
            })
          "
        >
          <template #info>
            {{
              !item.entity.account_number || item.entity.account_number === 'null'
                ? t('prospect')
                : item.entity.account_number
            }}
          </template>
          <template #tags>
            <Tag
              :id="item.entity.id"
              :entity="item.entityType"
            />
          </template>
        </CRMCard>

        <CRMCard
          v-if="item.contact && item.contact.id"
          :key="item.contact.id"
          :title="item.contact.full_name"
          :currency="currency"
          :chips="getChips(item.contact)"
          :links="getCardLinks(item.contact)"
          class="t-radius-0"
          :class="{ 'mail-overflow': isShowMail }"
          icon-name="user-circle"
          icon-position="top"
          show-footer
          @click.stop="isShowMail ? undefined : 
            onItemClick({
              id: item.contact.id,
              action: 'open',
              type: 'contact',
            })"
          @open="
            onItemClick({
              id: item.contact.id,
              action: 'open',
              type: 'contact',
            })
          "
        >
          <template #info>
            <ReplaceSpaceWithNBSPComponent :text="item.entity.name" />
          </template>
          <template
            v-if="item.contact.email"
            #icons
          >
            <CustomButton
              icon-name="email"
              icon-color="var(--colour-utility-action)"
              icon-only
              round
              small
              purpose="neutral"
              :disabled="summaryLoading"
              :icon-width="20"
              :icon-height="20"
              @on-click="handleSetViewEmails(true, [item.contact], false)"
            />
          </template>
          <template
            v-if="isShowMail"
            #footer
          >
            <ContactEmails
              v-model:expanded="expanded"
              :contact="item.contact"
            />
          </template>
        </CRMCard>
      </CardStack>
      <div class="description">
        <h3 v-if="item.description">
          {{ t('interaction_note') }}:
        </h3>
        <h3 v-else>
          {{ t('no_interaction_note') }}
        </h3>
        <p>
          {{ item.description }}
        </p>
      </div>

      <CustomCard
        purpose="reversed"
        class="complete-interactions-wrapper"
      >
        <InteractionsAddOpps
          :item="item"
          small
        />
      </CustomCard>
    </template>

    <div
      v-if="item.entityType !== 'prospect'"
      class="insight p-3"
    >
      <img
        src="@/shared/assets/image/crm/insight_graph.svg"
        alt=""
      >
      <div class="insight-text">
        <h2>
          {{ t('sell_smart_with_insights') }}
        </h2>
        <p v-if="item.entity && item.entity.name">
          {{ t('supercharge_this_interaction_with_insights_into_the_performance_of') }}&nbsp;<span class="fw-sbld">{{
            item.entity.name
          }}</span>
        </p>
        <CustomButton
          purpose="action"
          @on-click="openInsights"
        >
          {{ t('open_customer_snapshot') }}
        </CustomButton>
      </div>
      <img
        src="@/shared/assets/image/crm/insight_bars.svg"
        alt=""
      >
    </div>
  </ColumnsWithActionsLayout>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { CRMCard, CustomButton, CustomCard } from '@sales-i/dsv3';
import InteractionsAddOpps from '@/crm/views/Interactions/InteractionsAddOpps.vue';
import Tag from '@/shared/components/Tags/Tag.vue';
import CardStack from '@/shared/components/List/CardStack.vue';
import ReplaceSpaceWithNBSPComponent from '@/shared/components/ReplaceSpaceWithNBSP.vue';
import ColumnsWithActionsLayout from '@/shared/components/Layout/ColumnsWithActionsLayout.vue';
import { replaceSpaceWithNBSP } from '@/shared/utils/replaceSpaceWithNBSP';
import { SET_CONFIRMATION_MODAL, DELETE_BY_ID, SET_ROUTE } from '@/shared/store/actionType';
import { SET_DATE } from '@/crm/store/actionType';
import { APPLY_NEW_FILTER } from '@/intelligence/store/actionType';
import usePermissions from '@/shared/composables/usePermissions';
import { interactions } from '@/shared/store/data/policies';
import { baseUrl, contactsSection, customersArea, interactionsArea } from '@/crm/router/urlBits';
import { SNAPSHOT_SUMMARY } from '@/intelligence/store/data/reportTypes';
import useCurrency from '@/shared/composables/useCurrency';
import { pluralise, tCrm as t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';
import ContactEmails from '@/shared/components/Mail/ContactEmails.vue';
import useMail from '@/shared/composables/useMail';

defineProps({
  isOpenFollowUp: {
    type: Boolean,
    default: false,
  },
});

const { cs } = useCurrency();
const store = useStore();
const vroute = useRoute();
const vrouter = useRouter();

const { isValid, isViewEmails, handleSetViewEmails } = useMail();

const isShowMail = computed(() => item.value.contact && item.value.contact.email && isValid?.value && isViewEmails?.value);

const currency = computed(() => store.state.userDetails.currencyName);
const loading = computed(() => store.state.crm.interactions.selected.loading);
const loaded = computed(() => store.state.crm.interactions.selected.loaded);
const computedEntityName = computed(() => replaceSpaceWithNBSP(item.value.entity.name));
const hasAccess = computed(() => {
  const { hasAccess } = usePermissions();
  return hasAccess;
});
const canCreateInteractions = computed(() => hasAccess.value(interactions, 'create'));
const canUpdate = computed(() => hasAccess.value(interactions, 'update'));
const canDelete = computed(() => hasAccess.value(interactions, 'delete'));
const item = computed(() => store.getters['crm/interactions/item']);
const buttons = computed(() => {
  let links = [];
  if (canUpdate.value) {
    links.push({
      visible: item.value.outcome === '',
      label: t('edit_interaction'),
      small: true,
      purpose: 'reversed',
      clickFunc: onEditClick,
    });
  }
  if (canDelete.value) {
    links.push({
      visible: true,
      label: t('delete_interaction'),
      small: true,
      purpose: 'reversed',
      clickFunc: onDeleteInteractionClick,
    });
  }
  if (canCreateInteractions.value) {
    links.push({
      visible: true,
      label: t('add_a_follow_up'),
      small: true,
      purpose: 'reversed',
      clickFunc: onFollowUpClick,
    });
  }
  return links;
});

const setConfirmationModal = (params) => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);
const deleteInteraction = (id) => store.dispatch(`crm/interactions/${DELETE_BY_ID}`, id);
const setDate = (params) => store.dispatch(`crm/interactions/${SET_DATE}`, params);
const setRoute = (params) => store.dispatch(`system/${SET_ROUTE}`, params);
const applyNewFilter = (params) => store.dispatch(`intelligence/templateReport/${APPLY_NEW_FILTER}`, params);

function onEditClick() {
  navigateToUrl(`${baseUrl}/${interactionsArea}/${vroute.params.id}/edit`);
}

function onFollowUpClick() {
  setDate({ dateTime: undefined });
  navigateToUrl(`${baseUrl}/${interactionsArea}/add?clean=false&isOpenFollowUp=true`);
}

function onItemClick(data) {
  if (data.type === 'contact') {
    setRoute({
      success: vroute.fullPath,
      cancel: vroute.fullPath,
    });
    navigateToUrl(`${baseUrl}/${customersArea}/${item.value.entity.id}/${contactsSection}/${data.id}`);
  } else if (data.action === 'open') {
    setRoute({
      success: vroute.fullPath,
      cancel: vroute.fullPath,
    });
    navigateToUrl(`${baseUrl}/${pluralise.string(data.type)}/${data.id}`);
  } else {
    throw Error('NOT IMPLEMENTED', data);
  }
}

function onDeleteInteractionClick() {
  setConfirmationModal({
    message: t('do_you_really_want_to_delete_interaction_id', { id: item.value.id }),
    updatedMessage: t('you_have_deleted_interaction_id', { id: item.value.id }),
    updateFunction: async () => {
      try {
        await deleteInteraction(item.value.id);
        navigateToUrl(`${baseUrl}/${interactionsArea}`);
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  });
}

function openInsights() {
  applyNewFilter(['snapshotBubbleValue', item.value.entity.name]);
  applyNewFilter(['snapshotBubbleValueId', item.value.entity.account_number]);
  const route = vrouter.resolve({ name: SNAPSHOT_SUMMARY });
  navigateToUrl(route.href);
}

function getChips(item, type = '') {
  if (type === 'opportunity') {
    return [
      {
        value: item.value,
        prefix: cs,
        abbr: true,
        green: 150000,
        amber: 75000,
      },
      {
        value: item.probability,
        suffix: '%',
        green: 67,
        amber: 33,
      },
    ];
  }
  return [];
}

function getCardLinks(item, type = '') {
  if (type === 'opportunity') {
    return [
      {
        text: t('open'),
        id: item.id,
        action: 'open',
        emit: 'open',
      },
    ];
  }
  return [
    {
      text: t('open'),
      id: item.id,
      action: 'open',
      emit: 'open',
    },
    {
      text: item.mobile_phone ? t('call') : '',
      type: 'a',
      href: `tel:${item.mobile_phone}`,
    },
    {
      text: item.email ? t('email') : '',
      type: 'a',
      href: `mailto:${item.email}`,
    },
  ];
}

</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.complete-interactions-wrapper {
  padding-block-start: var(--spacing-1);
  padding-inline: 0;
  margin: 0;
  padding-bottom: 0;
  
  :deep(> .c-card--body > .flow:first-of-type) {
    position: relative;
  }
}

h3 {
  font-weight: var(--font-weight-semibold);
}
.more-btn {
  font-size: var(--font-size-small);
  text-decoration: underline;
  font-weight: var(--font-weight-medium);
}
.no-opportunity {
  flex: 1;
  height: auto;
  max-height: 400px;
  :deep(.c-card--body) {
    flex-grow: 1;
  }
}
.opp-card {
  height: auto;
}
.description {
  background-color: var(--colour-utility-white);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-colour);
  border-radius: var(--border-radius-1);
  overflow: hidden;
  overflow-wrap: break-word;
  padding: var(--spacing-3);

  h3 {
    font-weight: var(--font-weight-semibold);
    margin-bottom: var(--spacing-1);
  }

  p {
    font-size: var(--font-size-4);
  }
}

.insight {
  min-width: 100%;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: var(--border-radius-1);
  margin-bottom: var(--spacing-4);
  background-color: var(--colour-data-jagged-ice);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--colour-data-jagged-ice);
  img {
    max-height: 150px;
    margin: var(--spacing-2);
    @media #{map-get($display-breakpoints, 'md-and-down')} {
      display: none;
    }
  }
  .insight-text {
    display: flex;
    flex-flow: column;
    align-items: center;

    p {
      font-size: var(--font-size-3);
      margin: var(--spacing-1);
      line-height: var(--spacing-4);
      max-width: 560px;
    }
  }
}

.mail-overflow {
  overflow-y: auto;
}
</style>
