<template>
  <CustomModal
    :title="title"
    show-modal
    :show-unload-warning="isFormDirty"
    :max-width="800"
    @close-modal="closeModal"
  >
    <CustomForm
      v-if="fields.length > 0"
      :formdata="fields"
      :actions="actions"
      :api-errors="contact.errors"
      :saved="saved"
      :entity="entity"
      @show-unload-warning="handleIsFormDirty"
    >
      <template #savedContent>
        <div
          v-if="saved"
          :class="{
            complete: true,
          }"
        >
          <div class="icon-container">
            <IconBase
              icon-name="download"
              icon-color="#c69a09"
              class="icon"
              :height="44"
              :width="44"
            />
          </div>
          <h2>{{ t('saved') }}</h2>
          <h2 class="header">
            {{ contact.data.first_name }} {{ contact.data.surname }}
          </h2>
          <h4
            v-if="false"
            class="subheader"
          >
            {{
              t('contact_profile_complete', { percentage: contact.data.percent_complete })
            }}
          </h4>
        </div>
      </template>
    </CustomForm>
    <template v-if="fields.length === 0">
      <div class="modal-loader">
        <BufferImage :size="100" />
      </div>
    </template>
  </CustomModal>
</template>

<script>
import CustomForm from '@/shared/components/Form/Form.vue';
import { BufferImage, CustomModal, IconBase } from '@sales-i/dsv3';
import { mapState, mapActions } from 'vuex';
import { CLEAR_FIELDS } from '@/shared/store/actionType';
import { tCrm as t } from '@sales-i/utils';

export default {
  name: 'AddEditContact',
  components: {
    CustomModal,
    CustomForm,
    IconBase,
    BufferImage,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    fields: {
      type: Array,
      default: () => [],
    },
    saved: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closeModal', 'close', 'submit', 'edit'],
  data() {
    return {
      selectedIndex: 0,
      submittedFields: {},
      isFormDirty: false,
    };
  },
  computed: {
    ...mapState({
      contact: state => state.crm.contacts.selected,
      entity: state => state.system.entity,
    }),
    actions() {
      return [
        {
          label: t('edit_details'),
          purpose: 'reversed',
          small: true,
          onClick: this.editDetails,
          display: this.saved,
        },
        {
          label: t('save'),
          purpose: 'reversed',
          small: true,
          onClick: this.save,
          display: !this.saved,
          isSubmit: true,
        },
        {
          label: t('im_done'),
          purpose: 'action',
          small: true,
          onClick: this.closeModal,
          display: this.saved,
        },
      ];
    },
  },
  methods: {
    t,
    ...mapActions([`crm/contacts/${CLEAR_FIELDS}`]),
    closeModal() {
      this[`crm/contacts/${CLEAR_FIELDS}`]();
      this.$emit('close');
      this.isFormDirty = false;
    },
    async save(payload, setSubmitting) {
      this.submittedFields = payload;
      this.$emit('submit', payload, setSubmitting);
    },
    editDetails() {
      this.$emit('edit');
    },
    handleIsFormDirty(value) {
      this.isFormDirty = value;
    }    
  },
};
</script>

<style lang="scss" scoped>
.modal-loader {
  min-width: 300px;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    min-width: 600px;
  }
}
</style>
