<template>
  <div class="form-grid flow interaction-timescale-form">
    <h3 class="fw-sbld">
      {{ !dateTime ? t(title) : t(titleSelected) }}
    </h3>

    <!--
    Shows the timescale selection screen.
    the interactionId ensures that you don't see this flash up when viewing the timescale confirmation screen
    -->
    <div
      v-if="!startHours && !startMinutes && !interactionId && !isOpenFollowUp"
      class="three-col-equal-height"
    >
      <template
        v-for="sch in enabledSchedules"
        :key="sch"
      >
        <div v-if="!(sch == 'backdated' && isGenerate)">
          <CustomCard
            purpose="reversed"
            class="timescale-selection"
          >
            <h4 class="fw-sbld">
              {{ scheduleTitles[sch] }}
            </h4>
            <p class="mt-0 text-sm">
              {{ scheduleDescriptions[sch] }}
            </p>
            <div class="cta-wrapper">
              <CustomButton
                type="button"
                purpose="text"
                small
                tabindex="-1"
                @click.prevent="showModal(sch)"
              >
                {{ t('select') }}
              </CustomButton>
            </div>
          </CustomCard>
        </div>
      </template>
    </div>

    <!-- If the user is in the complete interaction flow show an separate button -->
    <div v-if="isOpenFollowUp && !startHours && !startMinutes">
      <CustomButton
        type="button"
        purpose="action"
        small
        tabindex="0"
        @click.prevent="showModal(sch)"
      >
        {{ t('add_a_follow_up') }}
      </CustomButton>
    </div>

    <!-- Shows the timescale confirmation screen -->
    <div
      v-if="startHours && startMinutes"
      class="three-col-equal-height"
    >
      <CustomCard
        purpose="reversed"
        class="timescale-selection"
      >
        <h4 class="fw-sbld">
          {{ startHours }}:{{ startMinutes }}
        </h4>
        <p class="mt-0 text-sm">
          {{ formatDate(dateTime, 'medium') }}
        </p>
        <div class="cta-wrapper">
          <CustomButton
            type="button"
            purpose="text"
            small
            tabindex="-1"
            @click.prevent="showModal(selectedSchedule)"
          >
            {{ t('select') }}
          </CustomButton>
          <CustomButton
            v-if="isResetEnabled"
            type="button"
            purpose="text"
            small
            tabindex="-1"
            @click.prevent="resetDate"
          >
            {{ t('reset') }}
          </CustomButton>
        </div>
      </CustomCard>
    </div>

    <AddInteractionDatePickerModal
      v-if="isModalVisible"
      :is-generate="isGenerate"
      :schedule="selectedSchedule"
      :start-time="dateTime"
      :duration="duration"
      @close-modal="closeAddInteractionDatePickerModal"
      @select="selectDateTime"
    />
  </div>
</template>
<script>
import { CustomCard, CustomButton } from '@sales-i/dsv3';
import AddInteractionDatePickerModal from '@/crm/components/Calendar/AddInteraction/AddInteractionDatePickerModal.vue';
import { dates, tCrm as t } from '@sales-i/utils';

export default {
  name: 'InteractionTimescaleForm',
  components: {
    AddInteractionDatePickerModal,
    CustomButton,
    CustomCard,
  },
  props: {
    interactionId: {
      type: [String, Number],
      default: '',
    },
    title: {
      type: String,
      default: 'select_timescale',
    },
    titleSelected: {
      type: String,
      default: 'selected_timescale',
    },
    isGenerate: {
      type: Boolean,
      default: false,
    },
    isOpenFollowUp: {
      type: Boolean,
      default: false,
    },
    schedule: {
      type: String,
      default: '',
    },
    dateTime: {
      type: [Date, Boolean],
      default: false,
    },
    startHours: {
      type: String,
      default: '',
    },
    startMinutes: {
      type: String,
      default: '',
    },
    duration: {
      type: Object,
      default: () => ({}),
    },
    isResetEnabled: {
      type: Boolean,
      default: false,
    },
    resetSignal: {
      type: Number,
      default: 0
    },
  },
  emits: ['setDate', 'resetDate', 'requestReset'],
  data() {
    return {
      isModalVisible: false,
      selectedSchedule: this.schedule,
      localStartHours: this.startHours,
      localStartMinutes: this.startMinutes,
    };
  },
  computed: {
    enabledSchedules() {
      return this.isOpenFollowUp 
        ? ['schedule'] 
        : ['backdated', 'schedule'];
    },
    scheduleTitles() {
      return {
        today: t('today'),
        schedule: t('schedule'),
        backdated: t('log')
      };
    },
    scheduleDescriptions() {
      return {
        today: t('set_up_an_interaction_for_later_today'),
        schedule: t('set_up_an_interaction_for_the_future'),
        backdated: t('add_an_interaction_that_has_already_taken_place')
      };
    }
  },
  watch: {
    startHours(newVal) {
      this.localStartHours = newVal;
    },
    startMinutes(newVal) {
      this.localStartMinutes = newVal;
    },
    resetSignal() {
      this.resetForm();
    }
  },
  methods: {
    t,
    formatDate: dates.format,
    showModal(schedule) {
      this.selectedSchedule = schedule;
      this.isModalVisible = true;
    },
    resetDate() {
      this.$emit('resetDate');
    },
    closeAddInteractionDatePickerModal() {
      this.isModalVisible = false;
    },
    selectDateTime(dateDetails) {
      this.closeAddInteractionDatePickerModal();
      let dt = dateDetails.date;
      dt.setHours(dateDetails.startHours);
      dt.setMinutes(dateDetails.startMinutes);
      dt.setSeconds(0);
      let result = {
        date: dt,
        duration: {
          hours: dateDetails.durationHours,
          minutes: dateDetails.durationMinutes,
        },
        schedule: dateDetails.schedule,
        backdated: dateDetails.schedule === 'backdated',
      };
      this.$emit('setDate', result);
    },
    resetForm() {
      this.localStartHours = '';
      this.localStartMinutes = '';
      this.isModalVisible = false;
      this.selectedSchedule = '';
      this.$emit('requestReset');
    },
  },
};
</script>

<style lang="scss" scoped>
.timescale-selection {
  padding: var(--spacing-2);
}

.cta-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--spacing-1);

  button {
    margin-left: var(--spacing-1);
    font-weight: var(--font-weight-semibold);
  }
}
</style>
