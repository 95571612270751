<template>
  <div
    class="container dashboard"
  >
    <slot name="header" />
    <div class="padded pt-0 dashboard-content">
      <div class="flex align-center mb-2">
        <slot name="title">
          <h3>
            {{ t('my_dashboards') }}
          </h3>
        </slot>
        <div
          v-if="dashboards?.length"
          tabindex="0"
          role="button"
          class="title mb-0"
          @click.stop="toggleDashboard"
          @keydown.space="toggleDashboard"
        >
          <IconBase
            class="toggle-btn"
            :class="isVisible ? 'rotate' : ''"
            icon-name="chevron-down"
            icon-color="var(--colour-utility-action)"
            width="32"
            height="32"
          />
        </div>
      </div>
      <template v-if="isVisible">
        <DashboardsNav
          :items="dashboards"
          :selected="selectedDashboard"
          @select="handleDashboardSelect"
          @delete="showDeleteDashboardModal"
          @create="handleDashboardCreate"
          @edit="handleDashboardEdit"
        />
        <DashboardLayout
          :class="[selectedDashboard?.category || DEFAULT_DASHBOARD_CATEGORY]"
          :columns="columns"
          :items="widgets"
          :expanded-id="expandedId"
          @reorder="handleReorder"
          @drag-over="dropItem = $event"
          @drag-leave="dropItem = null"
        >
          <template #item="{ item }">
            <Widget
              :id="`widget_${item.id}`"
              :item="item"
              :is-expanded="expandedId === item.id"
              :class="{ 'is-drop': dropItem?.id === item.id }"
              @delete="showDeleteWidgetModal"
              @edit="handleWidgetEdit"
              @view="pushToWidgetUrl"
              @expand="handleWidgetExpand($event, item.id)"
              @patch="patchWidget"
              @show-settings="handleWidgeSettingsEdit"
            />
          </template>
          <template #empty>
            {{ t('add_a_widget_to_this_dashboard_from_any_insight') }}
          </template>
        </DashboardLayout>
      </template>
    </div>
    <Teleport to="#modal-teleport-target">
      <AddEditWidget
        v-if="isEditWidgetModal && selectedWidget"
        :item="selectedWidget"
        :dashboards="dashboards"
        @close="handleCloseWidgetModal"
        @save="handleSaveWidget"
      />
      <AddEditDashboard
        v-if="isDashboardModal"
        :item="selectedDashboard"
        @close="handleCloseDashboardModal"
        @save="handleSaveDashboard"
      />
      <WidgetSettings
        v-if="isWidgetSettingsModal && selectedWidget"
        :widget-name="selectedWidget.name"
        :widget-type="selectedWidget.reportType"
        :settings="selectedSettings"
        @close="handleCloseWidgetSettings"
        @save="handleSaveWidgetSettings"
      />
    </Teleport>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { tCrm as t } from '@sales-i/utils';
import DashboardsNav from '@/intelligence/components/Dashboard/DashboardsNav.vue';
import DashboardLayout from '@/intelligence/components/Dashboard/DashboardLayout.vue';
import AddEditWidget from '@/intelligence/components/Dashboard/AddEditWidget.vue';
import AddEditDashboard from '@/intelligence/components/Dashboard/AddEditDashboard.vue';
import { useRouter } from 'vue-router';
import Widget from '@/intelligence/components/Dashboard/Widget.vue';
import breakpoints from '@/shared/utils/breakpoints';
import { useMq } from 'vue3-mq';
import { IconBase } from '@sales-i/dsv3';
import { useDashboard } from '@/intelligence/components/Dashboard/composables/useDashboard';
import WidgetSettings from '@/intelligence/components/Dashboard/WidgetSettings.vue';
import { DEFAULT_DASHBOARD_CATEGORY, OTHER_DASHBOARD_CATEGORY } from '@/intelligence/components/Dashboard/constants';

const emit = defineEmits(['deleted', 'closeModal']);
const store = useStore();
const vrouter = useRouter();
const mq = useMq();

const {
  dashboards,
  widgets,
  deletedDashboard,
  postWidget,
  patchWidget,
  showDeleteDashboardModal,
  showDeleteWidgetModal,
  getDashboards,
  getDashboardById,
  postDashboard,
  patchDashboard,
  clearDashboard,
} = useDashboard({ store, emit });

const isEditWidgetModal = ref(false);
const isWidgetSettingsModal = ref(false);
const isDashboardModal = ref(false);
const selectedWidget = ref(null);
const selectedDashboard = ref(null);
const isVisible = ref(true);
const selectedSettings = ref({});
const dropItem = ref(null);

/** HOOKS */
onMounted(async () => {
  await getDashboards();
  await createOtherDashboard();
  if (dashboards.value.length) handleDashboardSelect(dashboards.value[0]);
});

// select first dashboard if another was deleted
watch(deletedDashboard, () => {
  if (dashboards.value.length) handleDashboardSelect(dashboards.value[0]);
});

const expandedId = ref();
const columns = computed(() => (breakpoints.mdAndDown.includes(mq.current) ? 1 : 3));

/** FUNCTIONS */
/** Create a Sales insights dashboard if it does not exist */
async function createOtherDashboard() {
  if (!dashboards.value.find(d => d.category == OTHER_DASHBOARD_CATEGORY)) {
    await postDashboard({
      category: OTHER_DASHBOARD_CATEGORY,
      description: t('place_for_your_saved_insights'),
      title: t('saved_insights')
    });
  }
}

function handleDashboardSelect(value) {
  selectedDashboard.value = value;
  getDashboardById({ id: value.id });
}

async function handleSaveWidgetSettings(settings) {
  const { id, dashboard_id } = selectedWidget.value;
  const res = await patchWidget({ id, dashboard_id, settings });
  if (res) {
    isWidgetSettingsModal.value = false;
    selectedWidget.value = null;
  }
}

async function handleSaveWidget({ id, dashboard_id, name, description, width, height, settings }) {
  let res = id
    ? patchWidget({ id, dashboard_id, name, description, width, height, settings, })
    : postWidget({ dashboard_id, name, description, width, height });
  if (res) {
    isEditWidgetModal.value = false;
    selectedWidget.value = null;
  }
}

function handleWidgetEdit(item) {
  selectedWidget.value = item;
  isEditWidgetModal.value = true;
}

function handleWidgeSettingsEdit({ widget, settings }) {
  selectedWidget.value = widget;
  selectedSettings.value = settings;
  isWidgetSettingsModal.value = true;
}

function handleCloseWidgetModal() {
  isEditWidgetModal.value = false;
  selectedWidget.value = null;
}

function pushToWidgetUrl({ id, settings }) {  
  if (settings.url) {
    const currentRoute = vrouter.resolve(settings.url);
    const mergedQuery = {
      ...currentRoute.query,
      dashboard_id: selectedDashboard.value.id,
      id: id,
    };
    const route = vrouter.resolve({
      path: currentRoute.path,
      query: mergedQuery,
    });
    window.open(route.href, '_blank');
  }
}

function handleReorder(items) {
  dropItem.value = null;
  patchDashboard({
    id: selectedDashboard.value.id,
    widgets: items.map((w, index) => ({ id: w.id, order: index })),
  });
}

function handleWidgetExpand(isExpanded, id) {
  const oldId = expandedId.value;
  expandedId.value = isExpanded ? id : undefined;
  setTimeout(() => {
    document.querySelector(`#widget_${id || oldId}`).scrollIntoView();
    scrollBy(0, -100);
  }, 100);
}

function toggleDashboard() {
  isVisible.value = !isVisible.value;
}

function handleCloseDashboardModal() {
  isDashboardModal.value = false;
}

function handleCloseWidgetSettings() {
  isWidgetSettingsModal.value = false;
  selectedWidget.value = null;
  selectedSettings.value = null;
}

function handleDashboardCreate() {
  selectedDashboard.value = { category: DEFAULT_DASHBOARD_CATEGORY };
  isDashboardModal.value = true;
}

function handleDashboardEdit(item) {
  selectedDashboard.value = item;
  isDashboardModal.value = true;
}

async function handleSaveDashboard(item) {
  let response = item.id ? await patchDashboard(item) : await postDashboard(item);
  if (response) {
    selectedDashboard.value = { id: item.id || response.id };
    if (!item.id) clearDashboard();
    handleCloseDashboardModal();
  }
}
</script>

<style lang="scss" scoped>
.dashboard-content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .title {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--spacing-2);
  }

  .toggle-btn {
    transition: transform 0.5s;

    &.rotate {
      transform: rotate(180deg);
    }
  }
}

@keyframes blinkBoxShadow {
  0%, 100% {
    box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-colour);
  }
  50% {
    box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--colour-utility-action);
  }
}

.is-drop {
  animation: blinkBoxShadow 1s ease-in-out infinite; /* Adjust duration for faster/slower blinking */
}
</style>
