<template>
  <div>
    <Field
      :id="props.id"
      required
      :error-message="t('please_choose_an_area')"
      type="select"
      :options="filterOptions"
      :value="props.value.startsWith('customsection') ? 'customsection' : props.value"
      :label="t('filter_by')"
      @input="emit('input', $event)"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import Field from '@/shared/components/Form/Field.vue';
import { tCrm as t } from '@sales-i/utils';

const props = defineProps({
  id: {
    type: String,
    default: ''
  },
  value: {
    type: String,
    default: ''
  },
});

const emit = defineEmits(['input']);

const filterOptions = computed(() => [
  {
    text: t('interactions'),
    value: 'interaction',
  },
  {
    text: t('contacts'),
    value: 'contact',
  },
  {
    text: t('location'),
    value: 'address',
  },
  {
    text: t('custom_schema'),
    value: 'customsection',
  },
].filter(Boolean));
</script>