<template>
  <div class="single-select-container">
    <label :for="inputId">
      {{ label }}
    </label>
    <div class="input-wrapper">
      <input
        :id="inputId"
        type="text"
        :placeholder="placeholder"
        :disabled="disabled"
        :value="searchTerm"
        @focus="handleFocus"
        @input="handleOnSearch"
      >
      <IconButton 
        class="clear-icon"
        :icon-name="searchTerm ? 'close-line': 'chevron-down'"
        purpose="transparent"
        @click="clearField"
      />
    </div>
    <div
      v-if="showDropdown"
      class="dropdown"
    >
      <ul v-if="filteredItems.length">
        <li
          v-for="(item, index) in filteredItems"
          :key="index"
          role="button"
          tabindex="0"
          @click.prevent="selectItem(item)"
          @keydown.enter.prevent="selectItem(item)"
        >
          {{ item[labelField] }}
        </li>
      </ul>
      <ul v-else>
        <li>
          {{ t('no_results_were_found') }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { IconButton } from '@sales-i/dsv3';
import { tCrm as t, uuid } from '@sales-i/utils';

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: [String, Number, Object],
    default: null,
  },
  items: {
    type: Array,
    default: () => [],
  },
  labelField: {
    type: String,
    default: 'description',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  searchFunc: {
    type: Function,
    default: (t,l) => { throw `getSearchResults(${t}, ${l}) NOT IMPLEMENTED`; },
  },
});

const emit = defineEmits(['update:modelValue','change']);

const inputId = uuid('search-input-');
const internalItems = ref(props.items);
const searchTerm = ref('');
const showDropdown = ref(false);
const userCleared = ref(false);

watch(() => props.items, (newVal) => {
  internalItems.value = newVal;
}, { immediate: true });

watch(() => searchTerm.value, () => {
  onSearch();
}, { immediate: true });

const placeholder = computed(() => (showDropdown.value ? t('search') : t('please_select')));

const filteredItems = computed(() => {
  return userCleared.value ? [] : internalItems.value;
});

function handleOnSearch(e) {
  searchTerm.value = e?.target.value;
}

async function onSearch() {
  const id = props.id;
  const q = searchTerm.value;
  const response = await props.searchFunc({ id, q });

  Array.isArray(response) ? internalItems.value = response : internalItems.value = [];
}

function handleFocus() {
  userCleared.value = false;
  showDropdown.value = true;
}

function selectItem(item) {
  emit('update:modelValue', item);
  emit('change', item);
  searchTerm.value = item[props.labelField];
  showDropdown.value = false;
}

function clearField() {
  searchTerm.value = '';
  userCleared.value = true;
  showDropdown.value = false;
  emit('update:modelValue', null);
  emit('change', null);
}
</script>

<style lang="scss" scoped>
.single-select-container {
  position: relative;
  width: 100%;
}

.dropdown {
  position: absolute;
  min-width: 160px;
  max-height: 300px;
  overflow: auto;
  margin: 0;
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-colour);
  background-color: var(--colour-panel-g-0);
  width: 100%;
  z-index: 2;
}

.dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    padding: var(--spacing-1) var(--spacing-2);
    cursor: pointer;
  } 
}

.input-wrapper {
  position: relative;
}

.clear-icon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
