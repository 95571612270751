<template>
  <CustomModal
    v-show="!confirmingSave"
    :class="{ 'extended': isAnalysisContainerVisible }"
    class="modal-container"
    :title="title"
    show-modal
    :show-unload-warning="isFormDirty"
    :max-width="isAnalysisContainerVisible ? 1085 : 800"
    @close-modal="closeModal"
  >
    <AddEditProspectForm
      v-if="fields.length > 0"
      :formdata="fields"
      :actions="actions"
      :saved="saved"
      @confirming-save="updateConfirmingSave"
      @show-unload-warning="handleIsFormDirty"
      @toggle-class="toggleClass"
    >
      <template #savedContent>
        <div
          v-if="saved"
          class="complete"
        >
          <div class="icon-container">
            <IconBase
              icon-name="download"
              icon-color="#c69a09"
              class="icon"
              :height="44"
              :width="44"
            />
          </div>
          <h2>{{ t('saved') }}</h2>
          <h2 class="header">
            {{ savedData.name }}
          </h2>
          <h4
            v-if="false"
            class="subheader"
          >
            {{
              t('prospect_profile_complete_percentage', { percentage: percentComplete })
            }}
          </h4>
        </div>
      </template>
    </AddEditProspectForm>
    <template v-if="fields.length === 0">
      <div class="modal-loader">
        <BufferImage :size="100" />
      </div>
    </template>
  </CustomModal>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import AddEditProspectForm from '@/crm/components/Prospect/AddEditProspectForm.vue';
import { BufferImage, CustomModal, IconBase } from '@sales-i/dsv3';
import { CLEAR_FIELDS } from '@/shared/store/actionType';
import { tCrm as t } from '@sales-i/utils';

const store = useStore();

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  fields: {
    type: Array,
    default: () => [],
  },
  saved: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['closeModal', 'close', 'submit', 'edit']);

const submittedFields = ref({});
const confirmingSave = ref(false);
const isFormDirty = ref(false);
const isAnalysisContainerVisible = ref(false);

const prospect = computed(() => store.state.crm.prospects.selected);
  
const actions = computed(() => [
  {
    label: t('edit_details'),
    purpose: 'reversed',
    small: true,
    onClick: editDetails,
    display: props.saved,
  },
  {
    label: t('save'),
    purpose: 'reversed',
    small: true,
    onClick: save,
    display: !props.saved,
    isSubmit: true,
  },
  {
    label: t('im_done'),
    purpose: 'action',
    small: true,
    onClick: closeModal,
    display: props.saved,
  },
]);
const percentComplete = computed(() => prospect.value.data.percent_complete);
const savedData = computed(() => ({
  name: prospect.value.data.name,
}));

const clearFields = () => store.dispatch(`crm/prospects/${CLEAR_FIELDS}`);

const closeModal = () => {
  clearFields();
  emit('close');
  isFormDirty.value = false;
};
const save = async(payload, setSubmitting) => {
  submittedFields.value = payload;
  emit('submit', payload, setSubmitting);
};
const editDetails = () => {
  emit('edit');
};
const updateConfirmingSave = val => {
  confirmingSave.value = val;
};
function handleIsFormDirty(value) {
  isFormDirty.value = value;
}
function toggleClass() {
  isAnalysisContainerVisible.value = !isAnalysisContainerVisible.value;
}
</script>

<style lang="scss" scoped>
.modal-loader {
  min-width: 300px;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    min-width: 600px;
  }
}

.modal-container {
  :deep(.show-modal) {
    overflow: visible;
  }
}
</style>
