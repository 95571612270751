<template>
  <div class="mainpage flow">
    <!-- TARGETS
    ==================================-->
    <TargetsBanner v-if="isDemo" />

    <section class="homepage-container container container--fluid relative pb-6">
      <MainHeader />

      <!-- MY DAY/TEAM'S DAY
      ==================================-->
      <Today />
    </section>

    <!-- DASHBOARDS
    ==================================-->
    <section>
      <DashboardsView
        v-if="hasAccess(intelligence_insights)"
      >
        <template #header>
          <header class="padded dashboards-heading">
            <h2>{{ t('your_dashboards') }}</h2>
            <p>{{ t('save_your_favourite_insights_as_a_dashboard_with_widgets') }}</p>
          </header>
        </template>
      </DashboardsView>
    </section>

    <section class="container padded">
      <CustomerOpportunitiesView />
    </section>

    <!-- SIGNPOSTING
    ==================================-->
    <section class="signposting">
      <!-- People -->
      <InsightSignPost
        v-if="isDemo && canAccessPeopleInsights"
        section="people"
        :heading="t('people_insights')"
        :description="t('analyse_sales_performance_and_results')"
        icon-name="dimensions"
        :insight-links="filteredInsights(REPORT_AREA_PERFORMANCE)"
      />

      <!-- Performance -->
      <InsightSignPost
        v-if="!isDemo && hasAccessToPerformanceInsights"
        section="performance"
        :heading="t('performance_insights')"
        :description="t('analyse_sales_performance_and_results')"
        icon-name="dimensions"
        :insight-links="filteredInsights(REPORT_AREA_PERFORMANCE)"
      />

      <!-- Products -->
      <InsightSignPost
        v-if="hasAccessToProductInsights"
        section="products"
        :heading="t('product_insights')"
        :description="t('analyse_sales_performance_and_results')"
        icon-name="product"
        :insight-links="filteredInsights(REPORT_AREA_PRODUCT)"
      />

      <!-- Customers -->
      <InsightSignPost
        v-if="canAccessCustomers && hasAccessToCustomerInsights"
        section="customer"
        :heading="t('customer_insights')"
        :description="t('analyse_sales_performance_and_results')"
        icon-name="user-circle"
        :insight-links="filteredInsights(REPORT_AREA_CUSTOMER)"
      />
    </section>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import InsightSignPost from '@/crm/views/Main/Insights/InsightSignPost.vue';
import TargetsBanner from '@/crm/views/Main/TargetsBanner.vue';
import MainHeader from '@/crm/views/Main/MainHeader.vue';
import Today from '@/crm/views/Main/Today.vue';
import { REPORT_AREA_PRODUCT, REPORT_AREA_CUSTOMER, REPORT_AREA_PERFORMANCE } from '@/intelligence/store/data/areas';
import { tCrm as t } from '@sales-i/utils';
import { customers, intelligence_insights } from '@/shared/store/data/policies';
import usePermissions from '@/shared/composables/usePermissions';
import DashboardsView from '@/intelligence/views/Dashboard/DashboardsView.vue';
import CustomerOpportunitiesView from '@/intelligence/views/CustomerOpportunities/CustomerOpportunitiesView.vue';
import useSystem from '@/shared/composables/useSystem';

// Setup variables
const store = useStore();
const { isDemo } = useSystem({ store });

const { hasAccessToPerformanceInsights, hasAccessToProductInsights, hasAccessToCustomerInsights, hasAccess } = usePermissions();

// Get insight reports
const getInputArea = area => store.getters['intelligence/shared/getInputArea'](area);

// Function to pull in each getInputArea function and filter the results based on the user's permissions
const filteredInsights = area => {
  const filteredInsights = getInputArea(area).filter(insight => {
    return hasAccess(insight.policy) || insight.policy === undefined;
  });
  return filteredInsights.slice(0, 3);
};

const canAccessPeopleInsights = computed(() => true);
const canAccessCustomers = computed(() => hasAccess(customers));

</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.mainpage {
  h2 {
    display: inline-flex;
  }

  .highlight {
    background-color: var(--colour-panel-alert-base);
  }

  .intelBtn {
    display: inline-flex;
    text-decoration: underline;
    font-weight: var(--font-weight-semibold);
  }

  .insight {
    display: flex;
    flex-flow: column nowrap;
    align-self: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    @media #{map-get($display-breakpoints, 'md-and-down')} {
      margin-bottom: var(--spacing-1);
    }
    img {
      max-width: 200px;
      flex: 1;
    }
    .insight-text {
      flex: 2;
      h3 {
        font-weight: var(--font-weight-bold);
      }
      button {
        margin: 0 auto;
      }
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        min-width: 100%;
        margin-top: var(--spacing-1);
      }
    }
  }
}

.homepage-container::after {
  content: '';
  background-color: var(--colour-panel-alert-base);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  position: absolute;
  z-index: -1;
}


.signposting > .insights-wrapper {
  margin-bottom: var(--spacing-half);
}

.crm-card-grid {
  width: 100%;
  margin: 0 auto;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 800px;
    column-gap: var(--spacing-3);
    row-gap: var(--spacing-2);
  }
}

.dashboards-heading {
  padding-top: var(--spacing-5);
  padding-bottom: var(--spacing-3);
  text-align: center;

  h2 {
    font-size: var(--font-size-1);
  }

  p {
    font-size: var(--font-size-4);
    padding-top: var(--spacing-2);
    padding-bottom: var(--spacing-2);
  }
}
</style>
