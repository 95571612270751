<template>
  <CustomCard
    purpose="reversed"
    class="opportunity-timeline p-0 pb-1"
  >
    <div class="header">
      <h5 class="stage">
        {{
          t('stage_variable', {
            variable: stageIndex + 1,
          })
        }}
      </h5>
      <h3>{{ stageText }}</h3>
    </div>
    <div class="decision">
      <div class="timeline-item-wrapper">
        <div class="dot-container">
          <div class="dot" />
        </div>
        <div class="timeline-item-container">
          <span>{{ t('decision_date') }}</span>
          <h4>{{ dates.format(opportunity.decision_date, 'long') }}</h4>
          <div class="mt-half">
            <span>
              {{ t('next_steps') }}:<br>
              <strong>{{ opportunity.next_step || '' }}</strong>
            </span>
          </div>

          <CustomButton
            purpose="text"
            small
            :href="`${crmBaseUrl/interactionsArea}`"
            @click.prevent="navigateToUrl(`${crmBaseUrl/interactionsArea}`)"
          >
            {{ t('view_in_interactions') }}
          </CustomButton>
        </div>
      </div>
    </div>
    <div
      v-for="(item, index) in sortedHistory"
      :key="index"
      class="timeline-item"
    >
      <div class="timeline-item-wrapper">
        <div class="dot-container">
          <div
            v-if="item.action === 'Create'"
            class="half-border"
          />
          <div class="dot" />
        </div>

        <!-- Callouts -->
        <div
          v-if="item.action === 'Update'"
          class="timeline-item-container"
        >
          <CustomCallout
            conversation="left"
            body-bg-colour="var(--colour-utility-white)"
          >
            <h5
              v-if="isNewStage(index)"
              class="stage-title mb-1"
            >
              {{ t('stage_updated_to_stagename', { stagename: item.stage }) }}
            </h5>
            <div
              class="author-details"
              :class="{ 'no-border': !isNewStage(index) }"
            >
              <span>
                {{ t('modified') }}:
                <strong>{{ dates.format(item.updated_date, 'shortwtime') }}</strong>
              </span>
              <br>
              <span>{{ t('by') }} <strong>{{ item.updated_by }}</strong></span>
            </div>
          </CustomCallout>
        </div>

        <div
          v-if="item.action === 'Create'"
          class="timeline-item-container"
        >
          <div class="author-details no-border">
            <span>
              {{ t('date_created') }}:
              <strong>{{ dates.format(item.created_date, 'shortwtime') }}</strong>
            </span>
            <br>
            <span>{{ t('by') }} <strong>{{ item.created_by }}</strong></span>
          </div>
        </div>
      </div>
    </div>
    <template v-if="!sortedHistory || !sortedHistory.length">
      <div class="timeline-item">
        <div class="timeline-item-wrapper">
          <div class="dot-container">
            <div class="half-border" />
            <div class="dot" />
          </div>
          <div class="timeline-item-container">
            <div class="author-details no-border">
              <span>
                {{ t('no_history_found') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </CustomCard>
</template>

<script setup>
import { computed } from 'vue';
import { CustomCard, CustomButton, CustomCallout } from '@sales-i/dsv3';
import { dates, tCrm as t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';
import { baseUrl as crmBaseUrl, interactionsArea } from '@/crm/router/urlBits';

const props = defineProps({
  opportunity: {
    type: Object,
    default: () => ({}),
  },
  history: {
    type: Array,
    default: () => [],
  },
  stageText: {
    type: String,
    default: '',
  },
  stageIndex: {
    type: Number,
    default: -1,
  },
});

const sortedHistory = computed(() => [...props.history].sort((a, b) =>
  a.updated_date >= b.updated_date || !b.updated_date /* created */ ? -1 : 0
));

function isNewStage(index) {
  return (
    index < sortedHistory.value.length - 1 &&
    sortedHistory.value[index].stage_id != sortedHistory.value[index + 1].stage_id
  );
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.content {
  color: var(--colour-utility-base);
  font-style: italic;
  margin-top: 0;
  font-weight: var(--font-weight-medium);
}

.header {
  padding: var(--spacing-3);
  padding-left: var(--spacing-4);
  background-color: var(--colour-panel-alert-base);

  h5 {
    color: var(--colour-utility-base);
    font-weight: var(--font-weight-regular);
  }

  h3 {
    font-weight: var(--font-weight-bold);
  }
}

.stage-title {
  position: relative;
  width: 100%;
  display: block;
  font-weight: var(--font-weight-bold);

  .icon {
    margin: 2px var(--spacing-1) 0 0;
    float: left;
    background-color: var(--colour-brand-de-york);
    border-radius: 100px;
  }

  .stage-badge {
    margin: 0;
    position: absolute;
    right: 0px;
    top: 0px;
    font-size: 0.7rem;
    font-style: normal;
    color: var(--colour-panel-g-56);

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      display: none;
    }
  }
}

.timeline-item,
.decision {
  padding: 0 var(--spacing-8) 0 var(--spacing-8);
  margin-top: 0;

  .timeline-item-wrapper {
    display: flex;
    margin: 0;
    padding: 0;
  }

  .dot-container {
    position: relative;
    border-left: 1px solid var(--colour-panel-g-24);
    .dot {
      position: absolute;
      height: 9px;
      width: 9px;
      background-color: var(--colour-panel-g-48);
      border-radius: 50%;
      top: 50%;
      left: -5px;
      transform: translateY(-50%);
    }
    .half-border {
      position: absolute;
      top: 50%;
      left: -1px;
      width: 1px;
      height: 50%;
      background-color: var(--colour-panel-g-0);
    }
  }

  .timeline-item-container {
    flex: 1;
    padding: var(--spacing-1);
    padding-right: 0;

    .author-details {
      position: relative;
      border-top: 1px solid var(--colour-panel-g-8);
      padding-top: var(--spacing-half);
      font-size: var(--font-size-small);
      span {
        margin-right: var(--spacing-1);
        color: var(--colour-utility-base);
      }
      .more-menu {
        display: inline-block;
        position: relative;
        top: -1px;
        left: calc(var(--spacing-2) * -1);
      }
    }

    .no-border {
      border: none;
    }
  }
}

.decision {
  padding-top: var(--spacing-3);
  background-color: var(--colour-panel-alert-base);
  .dot-container {
    .dot {
      top: 0%;
    }
  }

  .timeline-item-container {
    padding: 0 var(--spacing-2) var(--spacing-2) var(--spacing-2);
    margin-top: -24px;

    h4 {
      font-weight: var(--font-weight-bold);
    }

    :deep(span.mention) {
      color: var(--colour-utility-base);
      font-weight: var(--font-weight-regular);
      font-style: italic;
    }
  }

  span {
    color: var(--colour-utility-base);
    font-size: var(--font-size-small);
    display: inline-block;
  }

  .stage {
    color: var(--colour-utility-base);
    font-weight: var(--font-weight-regular);
  }

  h2 {
    margin-bottom: var(--spacing-8);
  }
}
</style>
