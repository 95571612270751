<template>
  <div>
    <!-- Form fields -->
    <div
      v-for="(section, sectionIndex) in formData"
      :key="sectionIndex"
    >
      <div class="flow">
        <h3 class="fw-sbld">
          {{ t(section.sectionTitle) }}
        </h3>

        <div class="location-form-wrapper">
          <div
            v-for="(field, fieldIndex) in section.fields"
            :key="field.id"
            class="field"
          >
            <Field
              v-bind="field"
              :ref="field.id"
              v-model="formData[sectionIndex].fields[fieldIndex].value"
              :class="field.id"
              @valid="handleFieldValid"
              @keydown="handleKeydown"
              @keypress="handleKeypress"
              @keyup="handleKeyup"
            />
          </div>
        </div>
      </div>
    </div>

    <div :class="formActions">
      <CustomButton
        v-if="!isSubmitting"
        v-scroll-to-top
        :label="t('generate_interactions')"
        :disabled="loading"
        class="action"
        @on-click="generateInteractions"
      />
      <template v-if="isSubmitting">
        <BufferImage :size="40" />
      </template>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { BufferImage } from '@sales-i/dsv3';
import { tCrm as t } from '@sales-i/utils';
import { PUSH_ROUTE, SET_CONFIRMATION_MODAL } from '@/shared/store/actionType';
import { CLEAR_GENERATE_INTERACTIONS, POST_GENERATE_INTERACTIONS } from '@/crm/store/actionType';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits';
import { useGenerateInteractionValidation } from './composables/useGenerateInteractionValidation';
import { CustomButton } from '@sales-i/dsv3';
import Field from '@/shared/components/Form/Field.vue';

const emit = defineEmits(['stageUpdate']);
const store = useStore();

const selectedIndex = ref(3);
const isSubmitting = ref(false);


const {
  formActions,
  formData,
  handleKeydown,
  handleKeypress,
  handleKeyup,
  handleFieldValid,
} = useGenerateInteractionValidation(emit, selectedIndex, '');

const error = computed(() => store.state.crm.interactions.generateInteractions.error);
const loading = computed(() => store.state.crm.interactions.generateInteractions.loading);
const hasSuccessRoute = computed(() => store.state.system?.route?.success);

const generatedCount = computed(() => store.getters['crm/interactions/generatedCount']);

onMounted(() => {
  emit('stageUpdate', 'Add an interaction note');
});

const showConfirmationModal = params => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);
const generate = params => store.dispatch(`crm/interactions/${POST_GENERATE_INTERACTIONS}`, params);
const clear = () => store.dispatch(`crm/interactions/${CLEAR_GENERATE_INTERACTIONS}`);
const pushRoute = routeObject => store.dispatch(`system/${PUSH_ROUTE}`, routeObject);

const generateInteractions = async() => {
  const description = formData?.value[0]?.fields[0]?.value;
  await generate({ description });

  // Function to close the modal and redirect to the calendar
  const redirectAfterGenerate = isSuccess => {
    clear();
    pushRoute({
      defaultUrl: `${baseUrl}/${interactionsArea}`,
      success: isSuccess,
    });
    return true;
  };

  if (!error.value) {
    showConfirmationModal({
      heading: t('plural_quantity_interactions_generated', { quantity: generatedCount.value }),
      saveLabel: hasSuccessRoute.value ? t('return_to_report') : t('go_to_calendar'),
      cancelLabel: hasSuccessRoute.value ? t('go_to_calendar') : '',
      message: '',
      iconName: 'check',
      iconColor: 'var(--colour-data-de-york)',
      closeOnUpdate: true,
      updateFunction: () => true,
      closeFunction: () => redirectAfterGenerate(hasSuccessRoute.value ? true : false),
      cancelFunction: () => redirectAfterGenerate(false),
    });
  }
};
</script>

<style lang="scss" scoped></style>
