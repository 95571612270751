<template>
  <div>
    <div>
      <CustomSelect
        :label="t('viewing_opportunities_at')"
        :value="type_filter"
        :items="filters"
        @on-change="changeOpportunityLevel"
      />
    </div>

    <div
      v-if="getPaginatedOpportunities().length === 0"
      class="no-results"
    >
      <h3>{{ t('no_type_opportunities_to_follow_up_on', { type: type.toLowerCase() }) }}</h3>
    </div>

    <div
      v-if="getPaginatedOpportunities().length"
      role="list"
      class="list list-card"
    >
      <AiOpportunityCard
        v-for="(item, index) in getPaginatedOpportunities()"
        :id="`opportunity-card-${index + 1}`"
        :key="index"
        :can-create-interactions="canCreateInteractions"
        :item="item"
        @open-item="openItem"
      />
    </div>
    <div class="pt-4 flex justify-centre">
      <div class="pagination">
        <Pagination
          :offset="offset"
          :limit="limit"
          :records-count="opportunities.length"
          @page-changed="changePage"
        />
      </div>
    </div>

    <MissedPotentialOpportunityModal
      v-if="selectedOpportunity"
      :can-create-interactions="canCreateInteractions"
      :selected-opportunity="selectedOpportunity"
      :dismissed-items="dismissedItems"
      :modal-open="modalOpen"
      @close-modal="closeModal"
      @add-feedback="showDismissModal"
      @pursueas-interaction="pursueItem"
    />
  </div>
</template>

<script>
import { CustomSelect } from '@sales-i/dsv3';
import AiOpportunityCard from '@/crm/components/Opportunity/AiOpportunityCard.vue';
import MissedPotentialOpportunityModal from '@/crm/components/Opportunity/MissedPotentialOpportunityModal.vue';
import { GET_ALL, GET_BY_ENTITY, GET_BY_ID, SET_ENTITY, CLEAR_BY_ID, PUT_BY_ID } from '@/shared/store/actionType';
import { SET_OPPORTUNITY, SET_FURTHER_DETAILS } from '@/crm/store/actionType';
import { mapActions, mapState, mapGetters } from 'vuex';
import Pagination from '@/shared/components/Pagination/Pagination.vue';
import paginationMixin from '@/shared/components/Pagination/mixins/paginationMixin';
import usePermissions from '@/shared/composables/usePermissions';
import { interactions } from '@/shared/store/data/policies';
import { tCrm as t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits';

export default {
  name: 'OpportunitiesDetail',
  components: {
    AiOpportunityCard,
    MissedPotentialOpportunityModal,
    CustomSelect,
    Pagination,
  },
  mixins: [paginationMixin],
  props: {
    type: {
      type: String,
      default: '',
    },
    typeFilter: {
      type: String,
      default: 'item_level',
      validator: function (value) {
        return ['item_level', 'group_level'].includes(value);
      },
    },
    reload: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: [String, Number],
      default: '',
    },
    isRolledUp: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['opportunitiesLoaded', 'changeOpportunityLevel', 'openItem', 'pursueItem', 'dismiss'],
  data() {
    return {
      offset: 0,
      limit: 6,
      modalOpen: false,
      selectedOpportunity: null,
      dismissedItems: {},
      dismissOptions: {
        dismiss_for: '',
        dismiss_reason: '',
      },
    };
  },
  computed: {
    ...mapState({
      customer: state => state.crm.customers.selected,
      prospect: state => state.crm.prospects.selected,
      opportunities: state => state.intelligence.opportunities.data,
    }),
    canCreateInteractions() {
      const { hasAccess } = usePermissions();
      return hasAccess(interactions, 'create');
    },
    filters() {
      return [
        {
          text: t('item_level'),
          value: 'item_level',
        },

        {
          text: t('group_level'),
          value: 'group_level',
        },
      ];
    },
    type_filter() {
      return this.typeFilter;
    },
  },
  watch: {
    async reload() {
      if (this.reload === true) {
        this.modalOpen = false;
        await this.loadOpportunities();
        this.changePage(0);
      }
    },
    async type_filter() {
      this.loadOpportunities();
    },
  },
  mounted() {
    this.loadOpportunities();
    this.getFieldsForInteractions();
  },
  methods: {
    t,
    ...mapActions({
      getOpportunities: `intelligence/opportunities/${GET_ALL}`,
      getFields: `admin/fields/${GET_BY_ENTITY}`,
      getCustomer: `crm/customers/${GET_BY_ID}`,
      clearById: `crm/interactions/${CLEAR_BY_ID}`,
      setOpportunity: `crm/interactions/${SET_OPPORTUNITY}`,
      setEntity: `crm/interactions/${SET_ENTITY}`,
      setFurtherDetails: `crm/interactions/${SET_FURTHER_DETAILS}`,
      updateOpportunity: `crm/opportunities/${PUT_BY_ID}`,
    }),
    ...mapGetters({
      getSortedOpportunities: 'intelligence/opportunities/getSortedOpportunities',
    }),
    async loadOpportunities() {
      let typeSuffix = '';
      if (this.type_filter === 'group_level') {
        typeSuffix = 'GRP';
      }
      this.getOpportunities({
        type: `${this.type}${typeSuffix}`,
        sort: 'value:desc,decision_date:asc',
        limit: this.limit,
        offset: this.offset,
        ...(!!this.customerId && { entity_id: this.customerId }),
        rolldown: this.isRolledUp,
      });

      this.$emit('opportunitiesLoaded');
    },
    changeOpportunityLevel(level) {
      this.$emit('changeOpportunityLevel', level);
    },
    getFieldsForInteractions() {
      this.getFields({ entity: 'interactions' });
    },
    changePage(page) {
      this.offset = page;
      this.loadOpportunities();
    },
    getPaginatedOpportunities() {
      return this.getSortedOpportunities();
    },
    getUnabridgedProducts(products, type) {
      const items = [];
      for (let i = 0; i < products.length; i++) {
        if (products[i].prod_category === type) {
          items.push(products[i]);
        }
      }
      return items;
    },
    openItem(item) {
      this.$emit('openItem', item);

      // const opportunity = this.opportunities.find(o => o.id === item.id);

      this.selectedOpportunity = item;
      this.modalOpen = true;
    },
    async pursueItem(item) {
      this.$emit('pursueItem', item);

      // Make sure we are working with a clean slate
      this.clearById();

      const opportunity = { ...this.opportunities.find(o => o.id === item.id) };

      await this.getCustomer({
        id: opportunity.entity_id,
      });
      this.setEntity({
        entityType: opportunity.entity_type,
        entity: {
          ...this.customer.data,
          header: this.customer.data.name,
          subHeader: this.customer.data.account_number,
        },
      });

      // Generate the interaction note
      let interactionNote = '';
      if (opportunity.type.toLowerCase() !== 'potential') {
        interactionNote += 'Because this client purchased: ';
        const antecedents = this.getUnabridgedProducts(opportunity.products, 'Antecedent');
        const consequents = this.getUnabridgedProducts(opportunity.products, 'Consequent');

        antecedents.forEach(ante => {
          interactionNote += `${ante.product_name} (${ante.prod_code}) `;
        });

        interactionNote += '\r\n'; // eslint-disable-line
        interactionNote += 'We recommend they also purchase: ';
        consequents.forEach(cons => {
          interactionNote += `${cons.product_name} (${cons.prod_code}) `;
        });
      } else {
        const potentials = this.getUnabridgedProducts(opportunity.products, 'POTENTIAL');
        interactionNote += 'We recommend they purchase: ';
        potentials.forEach(cons => {
          interactionNote += `${cons.product_name} (${cons.prod_code}) `;
        });
      }

      this.setFurtherDetails({
        interactionNote,
      });

      this.setOpportunity({
        opportunity,
      });

      // Push them to the interactions
      navigateToUrl(`${baseUrl}/${interactionsArea}/add?clean=false`);
    },
    closeModal() {
      this.modalOpen = false;
    },
    showDismissModal(dismissedItems) {
      this.dismissedItems = dismissedItems;
      this.$emit('dismiss', {
        opportunity: this.selectedOpportunity,
        options: this.dismissOptions,
        products: this.dismissedItems,
      });
    },

    getRolledUpValue(item) {
      let value = 0;
      if ('products' in item) {
        const productsLength = item.products.length;

        for (let x = 0; x < productsLength; x++) {
          value += item.products[x].value;
        }
      }

      return value;
    },
    getHighestProbability(item) {
      let value = 0;

      if ('products' in item) {
        item.products.forEach(_ref => {
          if (_ref.confidence > value) {
            value = _ref.confidence;
          }
        });
      }

      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.list-card.list) {
  gap: var(--spacing-4);
}
.no-results {
  h3 {
    margin: var(--spacing-16) auto;
    text-align: center;
  }
}

div.actions {
  padding: 0;

  .details {
    margin-top: 0;
    padding: var(--spacing-3);
    background-color: var(--colour-panel-action);
    text-align: right;

    button {
      margin-left: var(--spacing-1);
      margin-right: var(--spacing-1);
    }
  }
}

.btn-group {
  display: flex;

  button {
    margin: 0 var(--spacing-1);
  }
}

.modal-content {
  table {
    width: 100%;
    margin: var(--spacing-2) 0;

    th,
    td {
      padding: var(--spacing-2);
    }
  }

  .association-selection {
    margin-bottom: 0;
  }
}

.pagination {
  text-align: right;

  a {
    margin: 0 2px;
    padding: 2px 10px;
    cursor: pointer;
  }
  a.active {
    background-color: black;
    border-radius: calc(var(--border-radius-1) * 2);
    color: white;
  }
  span::after {
    content: '|';
  }
  span:last-child::after {
    content: '';
  }
}

.dismiss-modal {
  h3 {
    text-align: center;
    font-size: 1.5rem;
    margin: 0 0 var(--spacing-2) 0;
  }
  p,
  label {
    font-size: 1rem;
    margin: var(--spacing-2) 0;
  }
  div.svg-container {
    margin: auto;
  }
}
</style>

<style lang="scss">
.dismiss-modal {
  .modal {
    max-height: 610px;
  }
}
</style>
