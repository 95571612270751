<template>
  <div v-if="fetchedAnalysisFields.length > 0">
    <h3>{{ t('analysis_fields') }}</h3>
    <div
      v-for="(field) in fetchedAnalysisFields"
      :key="field.id"
      class="field text-black text-5 search-analysis-field"
    >
      <p>{{ t(field?.bubble_label) }}</p>

      <SearchField
        :id="field.id"
        :search-func="searchAnalysisFields"
        :value="selectedFields"
        @change="(result) => handleResult(result, field.id)"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { tCrm as t } from '@sales-i/utils';
import SearchField from './SearchField.vue';
import { useProspect } from '@/crm/composables/useProspect';

const { fetchAnalysisFields, searchAnalysisFields } = useProspect();

const emit = defineEmits(['updateAnalysisFields']);

const selectedFields = ref([]); // Array with all changes for BE request (PUT)
const fetchedAnalysisFields = ref([]);

onMounted(async () => {
  const data = await fetchAnalysisFields();
  if (data) fetchedAnalysisFields.value = data;
});

watch(
  selectedFields, (newVal) => {
    emit('updateAnalysisFields', newVal);
  }, { deep: true }
);

function handleResult(selectedItem, analysisFieldId) {
  if (!selectedItem) {
    // => user cleared => remove from array
    selectedFields.value = selectedFields.value.filter(
      (item) => item.analysis_field_id !== analysisFieldId
    );
    return;
  }

  // Otherwise, user selected value, now needs to be combined from 2 different BE responses
  const newSelection = {
    analysis_field_lookup_id: selectedItem.id,
    analysis_field_id: analysisFieldId,
  };

  const existingIndex = selectedFields.value.findIndex(
    (x) => x.analysis_field_id === analysisFieldId
  );

  existingIndex !== -1 ?
    selectedFields.value[existingIndex] = newSelection // => update existing
    : selectedFields.value.push(newSelection);       // => add new
 
}
</script>

<style lang="scss" scoped>
.search-analysis-field {
  align-items: baseline;
  max-width: 400px;
  margin-bottom: var(--spacing-2);
}
</style>
