<template>
  <div class="post-view">
    <IconButton
      variant="round"
      purpose="action"
      icon-color="var(--colour-utility-white)"
      icon-name="plus"
      icon-align="left"
      :icon-size="24"
      class="mb-1"
      @click="addComment"
    />
    <IconButton
      class="back-to-posts"
      :label="t('posts')"
      purpose="transparent"
      text-color="var(--colour-utility-black)"
      icon-name="arrow-left"
      icon-color="var(--colour-utility-black)"
      icon-align="left"
      :icon-size="24"
      @click="goBackToPosts"
    />
    <Post
      :id="post.data.id"
      :entity-id="$route.params.id"
      :title="post.data.title"
      :created-by="post.data.created_by"
      :created-at="post.data.created_date"
      :content="post.data.text"
      :num-of-comments="numOfComments"
      is-open
      :is-loaded="post.loaded"
      @item-clicked="goBackToPosts"
      @edit-post="editPost"
      @deleted-post="goBackToPosts"
    />
    <template v-if="post.loading || comments.loading">
      <BufferImage
        color="var(--colour-utility-black)"
        float="center"
      />
    </template>
    <template v-if="comments.loaded">
      <Timeline
        :timeline-data="comments.data"
        @deleted-comment="handleDeletedComment"
      />
    </template>
    <router-view :post-title="post.data.title" />
  </div>
</template>

<script>
import Post from '@/shared/components/Posts/Post.vue';
import Timeline from '@/crm/components/Timeline/Timeline.vue';
import { BufferImage, IconButton } from '@sales-i/dsv3';
import { mapState, mapActions } from 'vuex';
import { GET_ALL, CLEAR_ALL, GET_BY_ID, CLEAR_BY_ID, CLEAR_UPDATED_FLAG } from '@/shared/store/actionType';
import { baseUrl, postsSection } from '@/crm/router/urlBits';
import { tCrm as t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'PostView',
  components: {
    IconButton,
    BufferImage,
    Post,
    Timeline,
  },
  props: {},
  data() {
    return {
      deleted: false,
    };
  },
  computed: {
    ...mapState({
      entity: state => state.system.entity,
      post: state => state.posts.selected,
      comments: state => state.comments.all,
      comment: state => state.comments.selected,
    }),
    numOfComments() {
      if (!this.post.data) return '0';
      let countMetadata = (this.post.data.metadata || []).find(m => m.key === 'comment_count');
      return countMetadata ? countMetadata.value : '0';
    },
  },
  watch: {
    $route(to, from) {
      const shouldUpdate = to.params.post_id && to.params.post_id !== from.params.post_id;
      this.post_id = this.$route.params.post_id;
      const postUpdated = (this.post.updated && !this.deleted) || shouldUpdate;
      const commentsUpdated = this.comment.updated || shouldUpdate;

      if (to.params.post_id && (postUpdated || commentsUpdated)) {
        this.clearPostUpdated();
        this.getPost();
      }
      if (to.params.post_id && commentsUpdated) {
        this.clearCommentsUpdated();
        this.getComments();
      }
    },
  },
  mounted() {
    this.post_id = this.$route.params.post_id;
    this.getPost();
    this.getComments();
  },
  unmounted() {
    this.clearPostById();
    this.clearAllComments();
  },
  methods: {
    t,
    ...mapActions({
      getPostById: `posts/${GET_BY_ID}`,
      clearPostById: `posts/${CLEAR_BY_ID}`,
      clearPostUpdated: `posts/${CLEAR_UPDATED_FLAG}`,
      getAllComments: `comments/${GET_ALL}`,
      clearAllComments: `comments/${CLEAR_ALL}`,
      clearCommentsUpdated: `comments/${CLEAR_UPDATED_FLAG}`,
    }),
    getPost() {
      this.getPostById({
        entity: this.entity,
        id: this.$route.params.id,
        post_id: this.$route.params.post_id,
      });
    },
    getComments() {
      this.getAllComments({
        post_id: this.$route.params.post_id,
        params: {
          sort: 'created_date:asc',
        },
      });
    },
    handleDeletedComment() {
      this.getPost();
      this.getComments();
    },
    addComment() {
      let { id, post_id } = this.$route.params;
      navigateToUrl(`${baseUrl}/${this.entity}/${id}/${postsSection}/${post_id}/add`);
    },
    editPost(post_id) {
      let { id } = this.$route.params;
      navigateToUrl(`${baseUrl}/${this.entity}/${id}/${postsSection}/${post_id}/edit`);
    },
    goBackToPosts() {
      this.deleted = true; // needed to prevent dupe call
      let { id } = this.$route.params;
      navigateToUrl(`${baseUrl}/${this.entity}/${id}/${postsSection}`);
    },
  },
};
</script>

<style lang="scss">
.post-view {
  padding-bottom: var(--spacing-2);
}

.back-to-posts {
  &.icon-button {
    font-weight: var(--font-weight-regular);
  }
  margin-bottom: var(--spacing-1);
}
</style>
