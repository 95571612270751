<template>
  <div class="field">
    <template v-if="['date', 'text', 'password', 'number', 'email', 'url'].includes(computedType)">
      <CustomInput
        :id="id"
        :for="id"
        :label="label"
        :placeholder="placeholder"
        :type="computedType"
        :min="min"
        :max="max"
        :min-length="minLength"
        :max-length="maxLength"
        :required="required"
        :disabled="disabled"
        :value="controlledValue"
        :error="error ? true : null"
        :error-message="errorMessage || minMaxErrorMessage"
        :icon-name="icon.name"
        :icon-color="icon.color"
        :icon-align="icon.align"
        :icon-outside="icon.outside"
        :icon-width="icon.size"
        :icon-height="icon.size"
        :icon-function="icon.func"
        :icon-disabled="!!icon.disabled"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
      />
    </template>
    <template v-if="['textarea'].includes(computedType)">
      <CustomTextarea
        :id="id"
        :for="id"
        :label="label"
        :placeholder="placeholder"
        :type="computedType"
        :rows="rows"
        :required="required"
        :max-length="maxLength"
        :disabled="disabled"
        :value="controlledValue"
        :error="!!error ? true : null"
        :error-message="errorMessage"
        :icon-name="icon.name"
        :icon-color="icon.color"
        :icon-align="icon.align"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
      />
    </template>
    <template v-if="['mentionable'].includes(computedType)">
      <Mentionable
        :id="id"
        :for="id"
        :label="label"
        :placeholder="placeholder || ''"
        :type="computedType"
        :rows="rows"
        :max-length="maxLength"
        :required="required"
        :disabled="disabled"
        :value="controlledValue"
        :error="!!error ? true : null"
        :error-message="errorMessage"
        :icon="icon"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
      />
    </template>
    <!-- <template v-if="['file'].includes(type)">
      <File
        :id="id"
        :for="id"
        :label="label"
        :required="required"
        :disabled="disabled"
        :error="error"
        :error-message="errorMessage"
        :icon-name="icon.name"
        :icon-color="icon.color"
        :icon-align="icon.align"
        :truncate-length="2"
      />
    </template> -->
    <template v-if="['select'].includes(computedType)">
      <CustomSelect
        :id="id"
        :placeholder="t('please_select')"
        :for="id"
        :label="label"
        :items="computedOptions"
        :value="controlledValue"
        :required="required"
        :disabled="disabled"
        :error="error ? true : null"
        :error-message="errorMessage"
        :icon-name="icon.name"
        :icon-color="icon.color"
        :icon-outside="icon.outside"
        :key-field="keyField"
        :label-field="labelField"
        :emit-key="emitKey"
        :subtitle="subtitle"
        @input="handleInput"
      />
    </template>
    <template v-if="['filter'].includes(computedType)">
      <MenuFilter
        :id="id"
        tabindex="0"
        :items="options"
        :label="label"
        :selected-value="controlledValue"
        :menu-position="menuPosition"
        @on-change="handleInput"
      />
    </template>
    <template v-if="['switch'].includes(computedType)">
      <ToggleSwitch
        :id="id"
        tabindex="0"
        :small="small"
        :model-value="controlledValue"
        @update:model-value="handleInput"
      >
        <template #label>
          <slot name="label">
            {{ label }}
          </slot>
        </template>
      </ToggleSwitch>
    </template>
    <template v-if="['checkbox'].includes(computedType)">
      <CustomCheckbox
        :id="id"
        :label="label"
        :value="value"
        :disabled="disabled"
        :small="small"
        @input="handleInput"
      />
    </template>
    <template v-if="['radio'].includes(computedType)">
      <CustomRadio
        :id="id"
        :value="id"
        :checked="checked"
        :name="name"
        :label="label"
        @on-change="handleInput"
      />
    </template>
    <template v-if="['multiselect'].includes(computedType)">
      <MultiSelect
        :id="id"
        :placeholder="placeholder || t('please_select')"
        :label="label"
        :items="options"
        :value="controlledValue"
        :required="required"
        :disabled="disabled"
        :error="error ? true : null"
        :error-message="errorMessage"
        :show-selected="showSelected"
        :opened="opened"
        :options-to-show="optionsToShow"
        :no-options-message="noOptionsMessage"
        :t="t"
        @input="handleInput"
      />
    </template>
    <template v-if="['customersearch'].includes(computedType)">
      <CustomerSearch
        :id="id"
        :placeholder="placeholder || t('search')"
        :label="label"
        :items="options"
        :value="value"
        :required="required"
        :disabled="disabled"
        :error="error ? true : null"
        :error-message="errorMessage"
        :select-all="selectAll"
        :search-func="searchFunc"
        :close-after-selecting="closeAfterSelecting"
        :highlight="highlight"
        :icon-size="iconSize"
        :btn-size="btnSize"
        :page-size="pageSize"
        @input="handleInput"
      />
    </template>
    <template v-if="['prospectsearch'].includes(computedType)">
      <ProspectSearch
        :id="id"
        :placeholder="placeholder || t('search')"
        :label="label"
        :items="options"
        :value="value"
        :required="required"
        :disabled="disabled"
        :error="error ? true : null"
        :error-message="errorMessage"
        :select-all="selectAll"
        :search-func="searchFunc"
        :close-after-selecting="closeAfterSelecting"
        :highlight="highlight"
        :icon-size="iconSize"
        :btn-size="btnSize"
        :page-size="pageSize"
        @input="handleInput"
      />
    </template>
    <template v-if="['tag'].includes(computedType)">
      <TagInput
        :id="id"
        :placeholder="placeholder"
        :label="label"
        :required="required"
        :disabled="disabled"
        :error="error ? true : null"
        :icon="icon"
        :entity="tagProps.entity"
        :entity-id="tagProps.entityId"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        @keydown="handleKeydown"
        @keypress="handleKeypress"
        @keyup="handleKeyup"
      />
    </template>
  </div>
</template>

<script>
import { CustomCheckbox, CustomInput, CustomSelect, MenuFilter, MultiSelect, CustomRadio, CustomTextarea, ToggleSwitch } from '@sales-i/dsv3';
import Mentionable from '@/shared/components/Mention/Mentionable.vue';
import CustomerSearch from '@/shared/components/Search/CustomerSearch.vue';
import ProspectSearch from '@/shared/components/Search/ProspectSearch.vue';
import TagInput from '@/shared/components/Tags/TagInput.vue';
import { dates, tCrm as t } from '@sales-i/utils';

export default {
  name: 'Field',
  components: {
    CustomInput,
    CustomSelect,
    MenuFilter,
    ToggleSwitch,
    CustomCheckbox,
    CustomTextarea,
    Mentionable,
    CustomerSearch,
    ProspectSearch,
    MultiSelect,
    CustomRadio,
    TagInput,
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
      default: 'id',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Boolean, Array, Object],
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    minLength: {
      type: Number,
      default: null,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 4,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    externalError: {
      type: Boolean,
      default: false,
    },
    regex: {
      type: [RegExp, String, Boolean],
      default: false,
    },
    icon: {
      type: Object,
      default: () => ({}),
    },
    showSelected: {
      type: Boolean,
      default: true,
    },
    opened: {
      type: Boolean,
      default: false,
    },
    optionsToShow: {
      type: Number,
      default: 6,
    },
    noOptionsMessage: {
      type: String,
      default: '',
    },
    selectAll: {
      type: Boolean,
      default: true,
    },
    searchFunc: {
      type: Function,
      default: (t, l) => this.getSearchResults(t, l),
    },
    closeAfterSelecting: {
      type: Boolean,
      default: true,
    },
    highlight: {
      type: Boolean,
      default: true,
    },
    iconSize: {
      type: Number,
      default: 32,
    },
    btnSize: {
      type: Number,
      default: 24,
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    twoway: {
      type: Boolean,
      default: false,
    },
    tagProps: {
      type: Object,
      default: () => ({}),
    },
    keyField: {
      type: String,
      default: 'value',
    },
    labelField: {
      type: String,
      default: 'text',
    },
    emitKey: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: true
    },
    nullable: {
      type: Boolean,
      default: false,
    },
    menuPosition: {
      type: String,
      default: 'right',
      validator: function (value) {
        // The value must match one of these strings
        return ['left', 'right'].indexOf(value) !== -1;
      },
    },
    subtitle: {
      type: String,
      default: ''
    },
    allowZeroValue: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['input', 'valid', 'focus', 'blur', 'keydown', 'keypress', 'keyup'],
  data() {
    return {
      initialBlurOccurred: false,
      focused: false,
      internalError: false,
      editedValue: this.cleanValue(this.value),
      emitAllErrorOnInputFor: ['multisearch', 'customersearch'],
    };
  },
  computed: {
    controlledValue: {
      get() {
        let val = this.twoway ? this.value : this.editedValue; // support for two-way binding
        return val && this.type === 'date' ? val.substr(0, 10) : val; // date input workaround
      },
      set(v) {
        this.editedValue = v;
      },
    },
    computedType() {
      if (this.type === 'hyperlink') {
        return 'url';
      }
      if (this.type === 'textlong') {
        return 'textarea';
      }
      if (this.type === 'time') {
        return 'date';
      }
      return this.type;
    },
    computedOptions() {
      if (!this.required && this.type === 'select') {
        return [
          {
            text: t('please_select'),
            value: '',
          },
          ...this.options,
        ];
      }
      return this.options;
    },
    error() {
      if (!this.initialBlurOccurred && !this.externalError) return false;
      return this.internalError || this.externalError || !this.isMinMaxValid;
    },
    errorMin() {
      return this.min === +this.min && this.cleanValue(this.controlledValue) < this.min;
    },
    errorMax() {
      return this.max === +this.max && this.cleanValue(this.controlledValue) > this.max;
    },
    isMinMaxValid() {
      return !['number'].includes(this.type) || (!this.errorMin && !this.errorMax);
    },
    minMaxErrorMessage() {
      if (this.isMinMaxValid) return '';
      if (this.errorMin && this.errorMax)
        return t('value_must_be_in_range_min_max', {
          min: this.min,
          max: this.max,
        });
      if (this.errorMin)
        return t('min_value_is_value', { value: this.min });
      return t('max_value_is_value', { value: this.max });
    },
  },
  watch: {
    value: function (value) {
      // A fix for two way binding, and setting the value outside of
      // editing the field directly
      if (this.twoway) {
        this.editedValue = value;
      }
    },
  },
  methods: {
    t,
    cleanValue(v) {
      switch (this.type) {
      case 'number':
        if (this.nullable) {
          return v === null || v === '' ? null : +v;
        }  
        return +v;
      case 'switch':
      case 'radio':
      case 'checkbox':
        return !!v || false;
      case 'date':
        return dates.formatAsISO(v) || '';
      case 'select':
        return v;
      default:
        return v || '';
      }
    },
    handleInput(value) {
      this.controlledValue = value;
      this.$emit('input', this.cleanValue(value));
      let error = !this.regexTest(value) || !this.isMinMaxValid;
      this.internalError = error;
      this.$emit('valid', {
        id: this.id,
        value: this.cleanValue(value),
        isValid: error === false,
      });
    },
    handleFocus() {
      this.$emit('focus');
      this.focused = true;
    },
    handleBlur() {
      this.$emit('blur');
      this.initialBlurOccurred = true;
      // If the field is not required, and the value is empty (not edited), then it is valid and clears any internal error state. Otherwise it validates the value against the regex and updates the internal error state.
      if (!this.required && !this.editedValue) {
        this.internalError = false;
      } else {
        this.internalError = !this.regexTest(this.editedValue);
      }
      this.$emit('valid', {
        id: this.id,
        value: this.cleanValue(this.editedValue),
        isValid: this.error === false,
      });
    },
    handleKeydown(event) {
      this.$emit('keydown', event);
    },
    handleKeypress(event) {
      this.$emit('keypress', event);
    },
    handleKeyup(event) {
      this.$emit('keyup', event);
    },
    regexTest(value) {
      if (this.allowZeroValue && value === 0) {
        return true;
      }

      if (this.minLength || this.maxLength) {
        const minLength = this.minLength || 0;
        const maxLength = this.maxLength || 1000000;
        if (value.length < minLength || value.length > maxLength) {
          return false;
        }
      }
      if (this.regex && value) {
        return new RegExp(this.regex).test(value);
      }
      if (this.required && !value) {
        return false;
      }
      if (this.required && !this.regex) {
        return !!value;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.field.title {
  font-size: var(--font-size-5) !important;
  font-family: EuclidCircularA !important;
  letter-spacing: 0 !important;
  line-height: var(--spacing-3);
  font-weight: 400;
  text-align: initial;
}
</style>
