<template>
  <CRMCard
    :title="item.header || item.full_name"
    :chips="chips"
    :links="contactLinks"
    show-footer
    class="simple-contact-card"
    @click.stop="isViewEmails ? undefined : handleItemClicked"
    @edit="handleEdit"
  >
    <template #info>
      <section class="list">
        <div>{{ item.subHeader }}</div>
        <div>{{ phone }}</div>
        <div>{{ item.email }}</div>
      </section>
    </template>
    <template #tags>
      <Tag
        :id="item.id"
        entity="contact"
      />
    </template>
    <template
      v-if="isShowMail"
      #footer
    >
      <ContactEmails
        v-model:expanded="expanded"
        :contact="item"
      />
    </template>
  </CRMCard>
</template>

<script setup>
import { computed } from 'vue';
import usePermissions from '@/shared/composables/usePermissions';
import { contacts } from '@/shared/store/data/policies';
import { CRMCard } from '@sales-i/dsv3';
import { tCrm as t } from '@sales-i/utils';
import Tag from '@/shared/components/Tags/Tag.vue';
import ContactEmails from '@/shared/components/Mail/ContactEmails.vue';
import useMail from '@/shared/composables/useMail';
import useMailAuth from '@/shared/composables/useMailAuth';

const emit = defineEmits(['edit', 'itemClicked']);

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
});

const { isValid } = useMailAuth();
const { expanded, isViewEmails } = useMail();

const isShowMail = computed(() => props.item.email && isValid.value && isViewEmails.value);

const { getPermissions } = usePermissions();
const permissions = getPermissions(contacts);
const chips = computed(() => []);
const phone = computed(() => (props.item || {}).mobile_phone || (props.item || {}).business_phone);

const contactLinks = computed(() => {
  let links = [];
  if (permissions.update && props.item && props.item.id)
    links.push({
      text: t('edit'),
      id: props.item.id,
      emit: 'edit',
    });
  if (phone.value)
    links.push({
      text: phone.value ? t('call') : '',
      type: 'a',
      href: getTelephoneLink(phone.value),
    });
  if (props.item && props.item.email)
    links.push({
      text: props.item.email ? t('email') : '',
      type: 'a',
      href: getMailtoLink(props.item.email),
    });
  return links;
});

function handleItemClicked() {
  emit('itemClicked', props.item);
}
function handleEdit() {
  emit('edit', props.item);
}

/**
 * These functions were the subject of the review by SonarCloud, because a\
 * person could potentially inject something into the item.value and it would
 * be executed by the application, unescaped by Vue
 */
function getTelephoneLink(phone) {
  return `tel:${phone}`;
}

function getMailtoLink(email) {
  return `mailto:${email}`;
}
</script>

<style lang="scss" scoped>
.simple-contact-card {
  :deep(.mini-mail) {
    border-top: 1px solid var(--shadow-colour);
  }
  
  .list {
    display: flex;
    flex-flow: column;
  }
  
  :deep(.c-card--footer) {
    margin: 0;
    padding: 0;
    width: 100%;
  }
}
</style>
