<template>
  <div class="sidebar-wrapper flow">
    <div v-if="isQuery">
      <div class="btn-container">
        <CustomButton
          class="new-alert-btn"
          purpose="text"
          @click="openNewAlertModal"
        >
          {{ t('new_alert') }}
        </CustomButton>
      </div>

      <CustomSelect
        id="saved-alerts"
        label="Alert"
        placeholder="Select"
        :items="mapQueriesToSelect"
        :value="selectedQueryId"
        @on-change="handleChange"
      />
      <CustomerCount />

      <p class="alert-metadata">
        {{ t('created_by') }} <strong>{{ createdBy }}</strong> {{ t('on_date', { date: createdDate }) }}
      </p>
    </div>

    <div v-else>
      <div class="filters-titles">
        <h3>{{ t('no_alerts') }}</h3>
        <div class="flow">
          <p>{{ t('build_a_list_of_customers_based_on_the_most_important_components_within_your_sales_data') }}.</p>
          <CustomButton
            class="new-alert-btn"
            purpose="text"
            @click="openNewAlertModal"
          >
            {{ t('new_alert') }}
          </CustomButton>
        </div>
      </div>
    </div>

    <section v-if="formattedSalesFilter.length > 0">
      <h3>{{ t('sales_filters') }}</h3>
      <div class="flow">
        <template
          v-for="(item, index) in formattedSalesFilter"
          :key="index"
        >
          <AlertBuilderFilter
            :id="item.id"
            :type="item.type"
            filter-section="sales"
            :show-delete-icon="showDeleteIcon"
            :show-tab-switch="index > 0"
            @on-change="clauseChange"
          >
            <p>{{ item.field }} {{ item.operator }} {{ item.value }}</p>
          </AlertBuilderFilter>
        </template>
      </div>
    </section>

    <section v-if="formattedCRMFilters.length > 0">
      <h3>{{ t('c_r_m_filters') }}</h3>
      <div class="flow">
        <template
          v-for="(item, index) in formattedCRMFilters"
          :key="index"
        >
          <AlertBuilderFilter
            :id="item.id"
            :clause="item.clause"
            :entity="item.entity"
            filter-section="crm"
            :show-delete-icon="showDeleteIcon"
            :show-tab-switch="index > 0"
            @on-change="clauseChange"
          >
            <p>{{ item.field }} {{ item.value }}</p>
          </AlertBuilderFilter>
        </template>
      </div>
    </section>

    <section v-if="productFilterData?.length > 0">
      <h3>{{ t('product_filters') }}</h3>
      <div class="flow">
        <template
          v-for="(item, index) in productFilterData"
          :key="index"
        >
          <AlertBuilderFilter
            :id="item.id"
            :operator="item.operator"
            :entity="item.entity"
            filter-section="products"
            :product-type="buyingProduct"
            :show-delete-icon="showDeleteIcon"
            :show-tab-switch="index > 0"
            @on-change="clauseChange"
          >
            {{ strings.capitalize(item.status, { onlyFirstLetter: true }) }} {{ item.level }}
          </AlertBuilderFilter>
        </template>
      </div>
    </section>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { tCrm as t, dates, strings } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';
import { CustomButton, CustomSelect } from '@sales-i/dsv3';
import AlertBuilderFilter from '@/intelligence/components/AlertBuilder/AlertBuilderFilter.vue';
import CustomerCount from '@/intelligence/components/AlertBuilder/AlertWizard/CustomerCount.vue';
import { alertBuilderScope, alertsScope } from '@/intelligence/router/urlBits';
import { useAlertBuilder } from '@/intelligence/composables/useAlertBuilder';
import useFilterSummary from '@/intelligence/composables/useFilterSummary';
import useEnquiries from '@/intelligence/composables/useEnquiries';

// Setup
const store = useStore();
const { formattedCRMFilters, formattedSalesFilter } = useFilterSummary();

const { applyFiltersToQuery } = useEnquiries({ store });
const { alertBuilderBaseUrl, productFilterData, clearAllFilters } = useAlertBuilder({ store });

const props = defineProps({
  queries: {
    type: Array,
    default: () => [],
  },
  selectedQueryId: {
    type: [Number, String],
    default: null,
  },
});
const emit = defineEmits(['queryChange', 'clauseChange']);

const isQuery = computed(() => props.queries.length > 0);
const selectedQuery = computed(() => props.queries.find(q => q.id === props.selectedQueryId));
const createdBy = computed(() => selectedQuery.value?.created_by);
const createdDate = computed(() => dates.format(selectedQuery.value?.created_date));

// Add the three sets of filters together (formattedCRMFilters, formattedSalesFilter, products) to get the total filters
const totalFilters = computed(() => [
  ...formattedCRMFilters.value,
  ...formattedSalesFilter.value,
  ...productFilterData.value,
]);
const showDeleteIcon = computed(() => totalFilters.value.length > 1);

// Map properties and order them by created data
const mapQueriesToSelect = computed(() => {
  return [...props.queries]
    .sort((a, b) => new Date(b.created_date) - new Date(a.created_date))
    .map(query => ({
      id: query.id,
      text: query.query_name.split('|~~|')[0],
      value: query.id,
      created_date: query.created_date,
    }));
});

async function handleChange(selectedValue) {
  emit('queryChange', selectedValue);
}

const clauseChange = () => {
  emit('clauseChange');
};

function openNewAlertModal() {
  clearAllFilters();
  navigateToUrl(`${alertBuilderBaseUrl.value}/${alertsScope}/${alertBuilderScope}`);
  applyFiltersToQuery();
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.customers-count-wrapper {
  display: flex;
  gap: var(--spacing-half);
  align-items: center;
}

.sidebar-wrapper {
  --flow-space: var(--spacing-5);
}

h3 {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-semibold);
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--spacing-2);
}

.new-alert-btn {
  font-weight: var(--font-weight-bold);
}

.alert-metadata {
  font-size: var(--font-size-small);
  margin-top: var(--spacing-1);
}
</style>
