<template>
  <CustomModal
    show-modal
    :title="t('select_date_range')"
    @close-modal="emit('closeModal')"
  >
    <main class="flow px-3">
      <h5 class="mt-0">
        {{ dates.format(selectedRange.start) }} - {{ dates.format(selectedRange.end) }} ({{
          t('number_days', { number: diffDays } )
        }})
      </h5>
      <div class="cal-wrapper">
        <VueCal
          key="1"
          class="vuecal--date-picker"
          hide-view-selector
          :time="false"
          :transitions="false"
          active-view="month"
          xsmall
          :disable-views="['years', 'year', 'week', 'day']"
          :selected-date="selectedRange.start"
          @cell-click="selectedRange.start = $event"
        />
        <div class="sep">
          ...
        </div>
        <VueCal
          key="2"
          class="vuecal--date-picker"
          hide-view-selector
          :time="false"
          :transitions="false"
          :locale="getCalendarLocale()"
          active-view="month"
          xsmall
          :disable-views="['years', 'year', 'week', 'day']"
          :selected-date="selectedRange.end"
          @cell-click="selectedRange.end = $event"
        />
      </div>
    </main>

    <template #footer>
      <ButtonGroup
        name="opportunity-actions"
        class="btn-group opportunity-actions"
      >
        <CustomButton
          purpose="reversed"
          small
          @click="emit('closeModal')"
        >
          {{ t('cancel') }}
        </CustomButton>
        <CustomButton
          purpose="action"
          small
          @click="handleSelectClick"
        >
          {{ t('select') }}
        </CustomButton>
      </ButtonGroup>
    </template>
  </CustomModal>
</template>

<script setup>
import { DateTime } from 'luxon';
import { CustomModal, CustomButton } from '@sales-i/dsv3';
import VueCal from 'vue-cal';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { computed, ref, watch } from 'vue';
import { dates, tCrm as t, getCalendarLocale } from '@sales-i/utils';

watch(
  () => ({ ...props.modelValue }),
  () => {
    const { start, end } = props.modelValue;
    selectedRange.value = { start, end };
  }
);

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({
      start: DateTime.local().startOf('day'),
      end: DateTime.local().startOf('day'),
    }),
  },
});

const selectedRange = ref(props.modelValue);
const emit = defineEmits(['update:modelValue', 'closeModal']);

const diffDays = computed(() => dates.getDiff(selectedRange.value.end, selectedRange.value.start, ['days', 'hours']).days);

function handleSelectClick() {
  const start = dates.getDateTime(selectedRange.value.start).startOf('day');
  const end = dates.getDateTime(selectedRange.value.end).startOf('day');
  emit('update:modelValue', { start, end });
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.cal-wrapper {
  display: flex;
  flex-flow: row nowrap;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    flex-direction: column;
  }
}

.sep {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Styles for the day 'discs' for the date-picker calendar
.vuecal--date-picker:not(.vuecal--day-view) .vuecal__cell--today .vuecal__cell-content {
  border-color: var(--colour-utility-black);
}

.vuecal--date-picker:not(.vuecal--day-view) .vuecal__cell--selected .vuecal__cell-content {
  background-color: var(--colour-utility-black);
}
</style>
