export const defaultOrder = [
  {
    key: 'addresses',
    label: 'address',
  },
  {
    key: ['tel_1', 'mobile_phone'],
    label: 'telephone',
  },
  {
    key: ['tel_2', 'business_phone', 'office_phone', 'home_phone'],
    label: 'alt_telephone',
  },
  {
    key: 'fax',
    label: 'fax',
  },
  {
    key: 'email',
    label: 'email',
  },
  {
    key: 'www',
    label: 'web_address',
  },
  {
    key: 'created_date',
    label: 'created',
  }
];

export default function mapCustomerContactDetails(details = {}, ordering = defaultOrder) {
  let displayArr = [];
  ordering.forEach((item) => {
    if (Array.isArray(item.key)) {
      let pushedValue = false;
      item.key.forEach(value => {
        if (details[value]) {
          if (!pushedValue) {
            displayArr.push({
              label: item.label,
              value: details[value]
            });
          }
          pushedValue = true;
        }
      });
    } else if (details[item.key]) {
      if (item.key === 'addresses') {
        if (details.addresses.length > 0) {
          let {
            address_line_1,
            address_line_2,
            address_line_3,
            address_line_4,
            town,
            county,
            post_code,
            country,
            latitude,
            longitude,
          } = details[item.key][0];
          let address = {
            address_line_1,
            address_line_2,
            address_line_3,
            address_line_4,
            town,
            county,
            post_code,
            country,
            latitude,
            longitude,
          };
          displayArr.push({
            label: item.label,
            value: address
          });
        }
      } else {
        displayArr.push({
          label: item.label,
          value: details[item.key]
        });
      }
    }
  });
  return displayArr;
}
