<template>
  <CustomModal
    show-modal
    :show-unload-warning="isFormDirty"
    :max-width="750"
    @close-modal="closeModal"
  >
    <template #title>
      <div class="date-picker-header">
        <h3 class="fw-sbld text-2">
          {{ isToday ? t('select_time') : t('select_time_and_date') }}
        </h3>
        <p
          v-if="isToday"
          class="text-base"
        >
          {{ formatDate(todaysDate, 'medium') }}
        </p>
      </div>
    </template>
    <div class="date-time-wrapper">
      <div class="cal-wrapper">
        <!-- Date picker -->
        <div
          v-if="!isToday"
          class="cal-picker"
        >
          <vue-cal
            style="height: 260px"
            class="vuecal--date-picker"
            hide-view-selector
            :time="false"
            :transitions="false"
            active-view="month"
            xsmall
            :locale="getCalendarLocale()"
            :disable-views="['week', 'day']"
            :selected-date="interactionDate"
            :max-date="maxDate"
            :min-date="minDate"
            @cell-click="selectedDate = $event"
          />
        </div>

        <div class="time-input flow">
          <div class="start-time">
            <h3 class="text-3 fw-sbld">
              {{ t('start_time') }}
            </h3>
            <div class="flex align-center">
              <div class="hour">
                <label
                  class="text-base"
                  for="start_hours"
                >
                  {{ t('hour') }}
                  <select
                    id="start_hours"
                    v-model="startHours"
                    class="without-value icon-right"
                    @blur="updateDate"
                  >
                    <option
                      v-for="option in hourOptions"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="px-2 pt-1">
                <strong class="text-2">:</strong>
              </div>
              <div class="min">
                <label
                  class="text-base"
                  for="start_minutes"
                >
                  {{ t('minute') }}
                  <select
                    id="start_minutes"
                    v-model="startMinutes"
                    class="without-value icon-right"
                    @blur="updateDate"
                  >
                    <option
                      v-for="option in minuteOptions"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
          </div>
          <div
            v-if="!isGenerate"
            class="duration"
          >
            <h3 class="text-3 fw-sbld">
              {{ t('duration') }}
            </h3>
            <div class="flex align-center">
              <div class="hour">
                <label
                  class="text-base"
                  for="dur_hours"
                >
                  {{ t('hour') }}
                  <select
                    id="dur_hours"
                    v-model="durationHours"
                    class="without-value icon-right"
                  >
                    <option
                      v-for="option in durationHourOptions"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="px-2 pt-1">
                <strong class="text-2">:</strong>
              </div>
              <div class="min">
                <label
                  class="text-base"
                  for="dur_minutes"
                >
                  {{ t('minute') }}
                  <select
                    id="dur_minutes"
                    v-model="durationMinutes"
                    :disabled="is24Hours"
                    class="without-value icon-right"
                  >
                    <option
                      v-for="option in durationMinuteOptions"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="schedule === 'personal'"
        class="description-field"
      >
        <Field
          id="description"
          :value="description"
          type="textarea"
          :label="t('description')"
          :max-length="280"
          @valid="updateDescription"
        />
      </div>
    </div>

    <template #footer>
      <CustomButton
        v-scroll-to-top="scrollToTop ? true : undefined"
        small
        :disabled="!isValid"
        @click="selectDateTime"
      >
        {{ t('continue') }}
      </CustomButton>
    </template>
  </CustomModal>
</template>
<script>
import VueCal from 'vue-cal';
import { CustomButton, CustomModal } from '@sales-i/dsv3';
import Field from '@/shared/components/Form/Field';
import { dates, isEqualObjects, tCrm as t, getCalendarLocale } from '@sales-i/utils';
import { timeOptions } from '@/shared/constants/timeOptions/timeOptions';

export default {
  name: 'AddInteractionDatePickerModal',
  components: {
    Field,
    CustomButton,
    CustomModal,
    VueCal,
  },
  props: {
    isGenerate: {
      type: Boolean,
      default: false,
    },
    interactionId: {
      type: String,
      default: '',
    },
    schedule: {
      type: String,
      default: 'schedule',
      validator: function (value) {
        return ['', 'schedule', 'backdated', 'personal'].includes(value);
      },
    },
    startTime: {
      type: [Date, Boolean],
      default: false,
    },
    duration: {
      type: Object,
      default: () => ({}),
    },
    descProp: {
      type: String,
      default: '',
    },
    scrollToTop: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['closeModal', 'select'],
  data() {
    return {
      description: this.descProp,
      hourOptions: timeOptions.hourOptions,
      minuteOptions: timeOptions.minuteOptions,
      durationHourOptions: timeOptions.durationHourOptions,
      durationMinuteOptions: timeOptions.durationMinuteOptions,
      selectedDate: new Date(),
      startHours: '',
      startMinutes: '',
      durationHours: this.duration.hours || '',
      durationMinutes: this.duration.minutes || '',
      todaysDate: dates.format(new Date(), 'D MMMM YYYY'),
      initialFormValues: {},
    };
  },
  computed: {
    interactionDate() {
      // If startTime is false then use the selectedDate
      let hours = this.startHours;
      let minutes = this.startMinutes;

      // Update the interactionDate time using the currently selected startHours and startMinutes
      return new Date(this.selectedDate.setHours(hours, minutes));
    },
    isToday() {
      return this.schedule === 'today';
    },
    durationIsZero() {
      return (
        (this.durationHours === this.hourOptions[0].value && this.durationMinutes === this.minuteOptions[0].value) || // both values can't be 0
        (this.durationHours === this.hourOptions[0].value && !this.durationMinutes) ||
        (this.durationMinutes === this.minuteOptions[0].value && !this.durationHours) || // one value can't be 0 and the other undefined
        (!this.durationHours && !this.durationMinutes)
      ); // both values must be defined
    },
    endDate() {
      const startDate = this.interactionDate;
      const endDate = new Date(startDate);
      let durationHours = this.durationHours ? parseInt(this.durationHours, 10) : 0;
      let durationMinutes = this.durationMinutes ? parseInt(this.durationMinutes, 10) : 0;
      endDate.setHours(endDate.getHours() + durationHours);
      endDate.setMinutes(endDate.getMinutes() + durationMinutes);
      return endDate;
    },
    isValid() {
      // Check if the duration has a value ONLY if it's not part of the generate interaction form.
      if (!this.isGenerate) {
        if (this.durationIsZero) {
          return false;
        }
      }

      // Validation for 'Today'
      if (['today', 'schedule'].includes(this.schedule)) {
        // If the start time is less than the current time, then it is not valid
        let now = new Date();
        let startTime = new Date(this.selectedDate);
        startTime.setHours(this.startHours);
        startTime.setMinutes(this.startMinutes);
        if (startTime < now) {
          return false;
        }

        return true;
      }
      // Validation for 'Backdated'
      if (this.schedule === 'backdated') {
        let now = new Date();
        // If the user hasn't entered a start time, then it is not valid
        if (this.startHours === '' || this.startMinutes === '') {
          return false;
        }

        // If today's date is selected and the start time is greater than the current time, then it is not valid
        // If the end time is later than the current time plus one hour, then it is not valid
        return !(this.selectedDate > now || this.endDate > now.addHours(1));
      }

      return this.selectedDate ? true : false;
    },
    maxDate() {
      if (this.schedule === 'backdated') {
        return new Date().subtractDays(0).format();
      }
      return null;
    },
    minDate() {
      if (['', 'backdated', 'personal'].includes(this.schedule)) {
        return null;
      }
      return new Date().subtractDays(0).format();
    },
    is24Hours() {
      return this.durationHours === '24';
    },
    formData() {
      return {
        description: this.description,
        durationHours: this.durationHours,
        durationMinutes: this.durationMinutes,
        selectedDate: this.selectedDate,
        startHours: this.startHours,
        startMinutes: this.startMinutes,
      };
    },
    isFormDirty() {
      return !isEqualObjects(this.initialFormValues, this.formData);
    },
  },
  watch: {
    // If the user selects 24 hours then reset the previously selected value for 'durationMinutes'.
    durationHours() {
      if (this.durationHours === '24') {
        this.durationMinutes = '';
      }
    },
  },
  mounted() {
    let dt, hrs, mins;
    // if today or schedule preset time to next possible time
    if (['today', 'schedule', 'personal'].includes(this.schedule)) {
      dt = new Date();
      hrs = dt.getHours();
      mins = dt.getMinutes();
      this.minuteOptions.forEach((opt, i) => {
        if (mins >= parseInt(opt.value, 10)) {
          this.startMinutes = i === 11 ? this.minuteOptions[0].value : this.minuteOptions[i + 1].value;
        }
      });
      this.hourOptions.forEach((opt, i) => {
        if (this.startMinutes !== this.minuteOptions[0].value && hrs > parseInt(opt.value, 10)) {
          this.startHours = i === 23 ? this.hourOptions[0].value : this.hourOptions[i + 1].value;
        }
        if (this.startMinutes === this.minuteOptions[0].value && hrs === parseInt(opt.value, 10)) {
          this.startHours = i === 23 ? this.hourOptions[0].value : this.hourOptions[i + 1].value;
        }
      });
    }

    if (this.startTime) {
      dt = new Date(this.startTime);
      hrs = dt.getHours();
      mins = dt.getMinutes();
      this.hourOptions.forEach(opt => {
        if (hrs === parseInt(opt.value, 10)) {
          this.startHours = opt.value;
        }
      });
      this.minuteOptions.forEach(opt => {
        if (mins === parseInt(opt.value, 10)) {
          this.startMinutes = opt.value;
        }
      });
      let { hours, minutes } = this.duration;
      if (typeof hours === 'number') {
        this.hourOptions.forEach(opt => {
          if (hours === parseInt(opt.value, 10)) {
            this.durationHours = opt.value;
          }
        });
      }
      if (typeof minutes === 'number') {
        this.minuteOptions.forEach(opt => {
          if (minutes === parseInt(opt.value, 10)) {
            this.durationMinutes = opt.value;
          }
        });
      }
    }
    this.initialFormValues = JSON.parse(
      JSON.stringify({
        description: this.description,
        durationHours: this.durationHours,
        durationMinutes: this.durationMinutes,
        selectedDate: this.selectedDate,
        startHours: this.startHours,
        startMinutes: this.startMinutes,
      })
    );
  },
  methods: {
    getCalendarLocale,
    t,
    formatDate: dates.format,
    closeModal() {
      this.$emit('closeModal');
    },
    updateDate() {
      let startHrs = this.startHours ? parseInt(this.startHours, 10) : 0;
      let startMins = this.startMinutes ? parseInt(this.startMinutes, 10) : 0;
      let newDate = new Date(this.selectedDate);
      newDate.setHours(startHrs);
      newDate.setMinutes(startMins);
      this.selectedDate = newDate;
    },
    selectDateTime() {
      if (this.isValid) {
        let date = this.selectedDate;
        date.setHours(this.startHours);
        date.setMinutes(this.startMinutes);

        this.$emit('select', {
          startHours: this.startHours,
          startMinutes: this.startMinutes,

          /*
          If the user leaves either of the duration fields blank, then the value is set to 0.
          The form validation checks that the the user has selected something for at least one of these values.
          */
          durationHours: (this.durationHours = this.durationHours || 0),
          durationMinutes: (this.durationMinutes = this.durationMinutes || 0),

          date: date,
          description: this.description,
          schedule: this.schedule,
        });
      }
    },
    updateDescription(field) {
      this.description = field.value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.date-time-wrapper {
  padding-top: var(--spacing-1);
  padding-right: var(--spacing-2);
  padding-left: var(--spacing-2);
  .description-field {
    margin-top: var(--spacing-2);
  }
}
.cal-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-5);
  max-width: 1190px;
  position: relative;
}

.time-input {
  min-width: 16rem;
}

.cal-picker {
  display: block;
  margin-left: 0;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-left: var(--spacing-4);
    max-width: 15rem;
    flex-basis: 240px;
  }
}
</style>
