/* eslint-disable no-unused-vars */
import {
  GET_BY_ID,
  GET_BY_ID_LOADING,
  GET_BY_ID_ERROR,
  CLEAR_BY_ID,

  PUT_BY_ID,
  PUT_BY_ID_ERROR,
  PUT_BY_ID_LOADING,
} from '@/shared/store/actionType';
import {
  ADD_INDUSTRY_TYPE_TO_ASSOCIATION,
  REMOVE_INDUSTRY_TYPE_FROM_ASSOCIATION,
} from '@/admin/store/actionType';
import { httpRequest, tCrm as t } from '@sales-i/utils';

// getters
export const getters = {
};

// mutations
export const industryTypeMutations = {
  [GET_BY_ID]: (state, [id, data]) => {
    state.industryTypes.data[id] = data;
    state.industryTypes.loading = false;
    state.industryTypes.loaded = false;
    state.industryTypes.error = undefined;
  },
  [GET_BY_ID_LOADING]: (state) => {
    state.industryTypes.loading = true;
    state.industryTypes.loaded = false;
    state.industryTypes.data = {};
    state.industryTypes.error = undefined;
  },
  [CLEAR_BY_ID]: (state, id) => {
    state.industryTypes.loading = false;
    state.industryTypes.loaded = false;
    state.industryTypes.data[id] = [];
    state.industryTypes.error = undefined;
  },
  [GET_BY_ID_ERROR]: (state, id) => {
    state.industryTypes.loading = false;
    state.industryTypes.loaded = false;
    state.industryTypes.data[id] = [];
    state.industryTypes.error = t('error');
  },

  [ADD_INDUSTRY_TYPE_TO_ASSOCIATION]: (state, [id, industryType]) => {
    if (id in state.industryTypes.data === false) {
      state.industryTypes.data[id] = [];
    }
    industryType.rule_id = id;
    state.industryTypes.data[id].push(industryType);
  },
  [REMOVE_INDUSTRY_TYPE_FROM_ASSOCIATION]: (state, [id, industryType]) => {
    const index = state.industryTypes.data[id].findIndex(o => o.industry_type_code === industryType.industry_type_code);

    if (index === -1) {
      return;
    }

    state.industryTypes.data[id].splice(index, 1);
  },

  [PUT_BY_ID]: (state) => {
    state.industryTypes.loaded = true;
    state.industryTypes.loading = false;
    state.industryTypes.error = undefined;
  },
  [PUT_BY_ID_LOADING]: (state) => {
    state.industryTypes.loaded = false;
    state.industryTypes.loading = true;
    state.industryTypes.error = undefined;
  },
  [PUT_BY_ID_ERROR]: (state) => {
    state.industryTypes.loaded = false;
    state.industryTypes.loading = false;
    state.industryTypes.error = t('failed_to_update_industry_types');
  },
};

// actions
export const industryTypeActions = {
  [GET_BY_ID]: async ({ commit }, id) => {
    try {
      commit(GET_BY_ID_LOADING);

      const response = await httpRequest('get', `industry-types/${id}`, {}, {
        version: '2',
        area: 'associations',
      });

      commit(GET_BY_ID, [id, response]);
    } catch (e) {
      commit(GET_BY_ID_ERROR, id);
    }
  },
  [CLEAR_BY_ID]: ({ commit, }) => {
    commit(CLEAR_BY_ID);
  },
  [ADD_INDUSTRY_TYPE_TO_ASSOCIATION]: ({commit}, [id, industryType]) => {
    commit(ADD_INDUSTRY_TYPE_TO_ASSOCIATION, [id, industryType]);
  },
  [REMOVE_INDUSTRY_TYPE_FROM_ASSOCIATION]: ({commit}, [id, industryType]) => {
    commit(REMOVE_INDUSTRY_TYPE_FROM_ASSOCIATION, [id, industryType]);
  },
  [PUT_BY_ID]: async ({ commit, state }, id) => {
    commit(PUT_BY_ID_LOADING);
    try {
      let industryTypes = [];
      if (state.industryTypes.data[id] !== undefined) {
        industryTypes = state.industryTypes.data[id];
      }
      await httpRequest('put', `industry-types/${id}`, industryTypes, {
        version: '2',
        area: 'associations'
      });

      commit(PUT_BY_ID);
    } catch (e) {
      commit(PUT_BY_ID_ERROR);
    }
  },
};
