<template>
  <div>
    <CustomButton
      class="export-button"
      purpose="action"
      :disabled="disabled"
      small
      @click="modalTrigger"
    >
      {{ title }}
    </CustomButton>
    <CustomModal
      id="export-modal"
      :title="title"
      :show-modal="isModalVisible"
      @close-modal="closeModal"
    >
      <p class="subtitle">
        {{ t('export_as') }}
      </p>
      <CustomSelect
        :value="selectedFileFormat"
        :items="fileFormats"
        @on-change="onFileFormatChange"
      />
      <div>
        <CustomButton
          v-if="!downloading"
          purpose="action"
          @click="exportHandler"
        >
          {{ t('export') }}
        </CustomButton>
        <BufferImage
          v-else
          color="black"
          float="center"
          class="loading-spinner"
        />
      </div>
    </CustomModal>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { tCrm as t, exportSnapshotByAnalysisField } from '@sales-i/utils';
import { CustomModal, CustomButton, CustomSelect, BufferImage } from '@sales-i/dsv3';
import useCurrency from '@/shared/composables/useCurrency';
import useNotification from '@/shared/composables/useNotification';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  dateParams: {
    type: Object,
    default: () => ({}),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  snapshotBubbleId: {
    type: String,
    default: '',
  },
  snapshotBubbleValue: {
    type: String,
    default: '',
  },
  snapshotBubbleName: {
    type: String,
    default: '',
  },
});

const store = useStore();

const { cs } = useCurrency();
const { sendSuccessAlert } = useNotification({ store });
  
const selectedFileFormat = ref('PDF');
const isModalVisible = ref(false);
const downloading = ref(false);
const fileFormats = [
  {
    text: 'PDF',
    value: 'PDF',
  },
  {
    text: 'PPTX',
    value: 'PPTX',
  },
];

const userDetails = computed(() => store.state.userDetails);

function modalTrigger() {
  isModalVisible.value = true;
}
    
function closeModal() {
  isModalVisible.value = false;
}

async function exportHandler() {
  downloading.value = true;
  const reporter = `${userDetails.value.data.first_name} ${userDetails.value.data.last_name}`;
  const { date_from, date_to, date_from2, date_to2 } = props.dateParams;

  try {    
    const success = await exportSnapshotByAnalysisField(
      {
        file_type: selectedFileFormat.value,
        reporter,
        customer: props.snapshotBubbleName,
        customer_address: '',
        currency_symbol: cs,
      },
      date_from,
      date_to,
      date_from2,
      date_to2,
      props.snapshotBubbleId,
      props.snapshotBubbleValue
    );
    if (success) sendSuccessAlert(t('we_will_notify_you_when_your_download_is_ready'));
  } catch (err) {
    console.error('Export failed or restricted tenant user', err);
  } finally {
    downloading.value = false;
  }
}

function onFileFormatChange(format) {
  selectedFileFormat.value = format;
}
</script>

<style lang="scss">
@import '@/shared/assets/scss/_variables';

#export-modal {
  .subtitle {
    margin-bottom: var(--spacing-1);
    color: var(--colour-utility-base);
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    min-width: initial;
    max-width: 360px;
  }

  .content-wrapper .content {
    padding: 0 var(--spacing-3);
  }
}

.export-button {
  width: 100%;
}
</style>
