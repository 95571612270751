import { GET_SUMMARY } from '@/shared/store/actionType';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { tCrm as t } from '@sales-i/utils';

export const useInteractionsSummary = ({store = useStore() } = {}) => {
  const summary = computed(() => store.state.crm.interactions.summary.data);
  const openCount = computed(() => summary.value.open || 0);
  const missedCount = computed(() => summary.value.missed || 0);
  const completedCount = computed(() => summary.value.completed || 0);

  const summaryFormatted = computed(() => {
    let summaryArr = [];
    const days = summary.value.days;
    if (days) {
      days.forEach(day => {
        const eventStart = DateTime.fromISO(day.date).toFormat('yyyy-MM-dd HH:mm:ss ZZ');
        const eventEnd = DateTime.fromISO(day.date).plus({ hours: 1 }).toFormat('yyyy-MM-dd HH:mm:ss ZZ');
        if (day.open) {
          for (let i = 0, len = day.open; i < len; i++) {
            summaryArr.push({
              title: t('interaction'),
              start: eventStart,
              end: eventEnd,
              open: true,
            });
          }
        }
        if (day.missed) {
          for (let i = 0, len = day.missed; i < len; i++) {
            summaryArr.push({
              title: t('interaction'),
              start: eventEnd,
              end: eventEnd,
              open: false,
            });
          }
        }
        if (day.Completed) {
          for (let i = 0, len = day.Completed; i < len; i++) {
            summaryArr.push({
              title: t('Interaction'),
              start: eventEnd,
              end: eventEnd,
              open: false,
            });
          }
        }
      });
    }
    return summaryArr;
  });
  
  const getSummary = params => store.dispatch(`crm/interactions/${GET_SUMMARY}`, params);

  return {
    openCount,
    missedCount,
    completedCount,
    summary,
    summaryFormatted,
    getSummary
  };
};
