<template>
  <CustomCard
    purpose="reversed"
    footer-color="grey"
    class="interaction-card"
  >
    <header>
      <h2>{{ t('sales_comparison') }}</h2>
      <p>{{ t('analysing_your_performance') }}</p>
    </header>
    <BufferImage
      v-if="dataLoading"
      class="loading-spinner"
      color="var(--colour-utility-black)"
      float="center"
    />
    <MultilineChart
      v-if="!dataLoading"
      :chart-data="chartData"
      :format-func="cPrice"
      :legend-labels="legendLabels"
      class="trends-chart-container"
    />
    <template #footer>
      <ButtonGroup>
        <CustomButton
          small
          purpose="action"
          @click="goSalesComparison"
        >
          {{ t('view_more') }}
        </CustomButton>
      </ButtonGroup>
    </template>
  </CustomCard>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { tCrm as t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';
import { BufferImage, CustomCard, CustomButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import MultilineChart from '@/intelligence/components/Dashboard/Elements/MultilineChart';
import useTrends from '@/intelligence/composables/useTrends';
import useCurrency from '@/shared/composables/useCurrency';
import useCalendar from '@/intelligence/composables/useCalendar';

const reportId = ref(undefined);
const { chartData, dataLoading, legendLabels, loadData } = useTrends({ reportId });
const { fetchDates } = useCalendar({ reportId });
const { cPrice } = useCurrency();

onMounted(async () => {
  // full this year and full last year
  const fetchedDates = await fetchDates({
    date_from: 'today/y',
    date_to: 'today/y+1y-1d',
    date_from2: 'today/y-1y',
    date_to2: 'today/y-1d',
  });
  reportId.value = await loadData({ 
    ...fetchedDates,
    snapshotBubbleValue: '' 
  });
});

function goSalesComparison() {
  navigateToUrl('/comparison');
}

</script>

<style lang="scss" scoped>
:deep(.interaction-card) > .c-card--body {
  .flow {
    display: flex;
    flex-direction: column;
  }
}

.interaction-card {
  position: relative;
}

.loading-spinner {
  position: absolute;
  top: calc(50% - 50px);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}
</style>
