import BaseRoutes from './BaseRoutes';
import TemplateReportRoute from './TemplateReport';
import MatrixRoute from './Matrix';
import TrendsRoute from './Trends';
import SalesVsGapsRoute from './SalesVsGaps';
import VarianceRoute from './Variance';
import FullPictureRoute from './FullPicture';
import OpportunitiesRoute from './Opportunities';
import SnapshotReportRoute from './SnapshotReport';
import SalesOpportunityRoute from './SalesOpportunity';
import CommunitiesRoute from './Communities';
import AssociationsRoute from './Associations';
import InsightsRoute from './Insights';
import ActivityReportRoute from './ActivityReport';
import MarketPenetrationRoute from './MarketPenetration';
import SnapshotByAnalysisFieldRoute from './SnapshotByAnalysisField';

export default [
  ...BaseRoutes,
  ...TemplateReportRoute,
  ...MatrixRoute,
  ...TrendsRoute,
  ...SalesVsGapsRoute,
  ...VarianceRoute,
  ...FullPictureRoute,
  ...OpportunitiesRoute,
  ...SnapshotReportRoute,
  ...SalesOpportunityRoute,
  ...CommunitiesRoute,
  ...AssociationsRoute,
  ...InsightsRoute,
  ...ActivityReportRoute,
  ...MarketPenetrationRoute,
  ...SnapshotByAnalysisFieldRoute,
];
