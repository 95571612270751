<template>
  <div>
    <table>
      <thead>
        <th />
        <th
          v-for="number in 2"
          :key="number"
        >
          <div class="calendar-element">
            <span class="indicator">{{ number }}:</span>
            <IconBase
              icon-name="calendar"
              :height="24"
              :width="24"
            />
          </div>
        </th>
        <th />
      </thead>
      <tbody>
        <tr
          v-for="(row, index) in dataRows"
          :key="index"
        >
          <td>
            <p>{{ row.dimensions[0].dimensions[0] }}</p>
            <p>{{ row.dimensions[0].dimensions[1] }}</p>
          </td>
          <td
            v-for="number in 2"
            :key="number"
          >
            <span class="indicator">{{ number }}:</span>
            <IconBase
              class="calendar-icon"
              icon-name="calendar"
              :height="24"
              :width="24"
            />
            {{ currency.number(Number(row.quantity.values[number - 1])) }}
          </td>
          <td>
            <CustomChip
              small
              class="value-chip"
              :class="getChipClass(row.quantity.variance)"
              :title="currency.number(Number(row.quantity.variance))"
            >
              <IconBase
                v-if="row.quantity.variance != 0"
                icon-name="arrow-up"
                icon-color="var(--colour-utility-white)"
                :height="14"
                :width="14"
                class="chip-arrow-icon"
              />
              {{ currency.number(Number(row.quantity.variance)) }}
            </CustomChip>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- hidden for iFrame until next revision -->
    <CustomButton
      v-if="!includeChildCustomerData && showViewMore"
      class="view-more"
      purpose="text"
      :label="t('view_more')"
      @click="emit('viewMore')"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { currency, tCrm as t } from '@sales-i/utils';
import { IconBase, CustomChip, CustomButton } from '@sales-i/dsv3';
import useShared from '@/intelligence/composables/useShared';

const store = useStore();
const emit = defineEmits(['viewMore']);

const props = defineProps({
  reportId: {
    type: Number,
    default: null,
  },
  includeChildCustomerData: {
    type: Boolean,
    default: false,
  },
  showViewMore: {
    type: Boolean,
    default: true
  }
});

const { getReportData } = useShared({ store });

const enquiryData = computed(() => getReportData(props.reportId));
const dataRows = computed(() => enquiryData.value && enquiryData.value.rows.slice(0, 5));
const getChipClass = (value) => value > 0 ? 'green' : value < 0 ? 'red' : '';

</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.indicator {
  display: block;
  width: var(--spacing-1);
  font-weight: var(--font-weight-bold);
  margin-right: var(--spacing-half);
}

.icon-calendar {
  display: inline-flex;
  margin-right: var(--spacing-1);
  vertical-align: bottom;
}

table {
  width: 100%;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-top: var(--spacing-3);
  }

  th {
    padding: 0;
    padding-bottom: 2px;
    vertical-align: bottom;
    width: 100%;
    background: none;
    border: none;
    min-width: unset;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      display: none;
    }

    .calendar-element {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  tr {
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      display: block;
      text-align: center;
    }

    td {
      border-left: none;
      border-right: none;
      text-align: center;
      vertical-align: middle;
      font-weight: var(--font-weight-medium);
      color: var(--colour-utility-black);
      min-width: 80px;

      .calendar-icon,
      .indicator {
        display: none;
      }

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        width: fit-content;
        display: inline-flex;
        border: none;
        justify-content: center;
        align-items: center;

        .calendar-icon {
          display: inline-flex;
        }
        .indicator {
          display: block;
        }
      }

      &:first-child {
        text-align: left;
        padding-left: 0;

        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          width: 100%;
          display: block;
          border-top: 1px solid var(--colour-panel-g-24);
          text-align: center;
          padding-left: var(--spacing-1);
          padding-bottom: 0;
        }

        p {
          font-weight: var(--font-weight-regular);

          &:first-child {
            font-size: var(--font-size-body);
            font-weight: var(--font-weight-semibold);
          }
        }
      }
    }
  }
}

.value-chip {
  font-size: var(--font-size-body);
  box-shadow: 0 0 var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);
  background: none;
  height: 24px;
  font-size: var(--font-size-small);
  white-space: nowrap;

  .chip-arrow-icon {
    background: var(--colour-data-de-york-label);
    border-radius: 50%;
    margin-right: var(--spacing-half);
  }

  &.green {
    color: var(--colour-data-de-york-label);
  }

  &.red {
    color: var(--colour-data-mandy-label);

    .chip-arrow-icon {
      transform: rotate(180deg);
      background-color: var(--colour-data-mandy-label);
    }
  }
}

.view-more {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-weight-semibold);
  margin-top: var(--spacing-2);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin: var(--spacing-1) auto;
  }
}
</style>
