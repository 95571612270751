<template>
  <nav
    class="pagination"
    aria-label="Pagination"
  >
    <ul class="pagination__list">
      <li>
        <button
          class="pagination__item"
          type="button"
          aria-label="Previous"
          :disabled="isInFirstPage"
          @click="onClickPreviousPage"
        >
          {{ t('previous') }}
        </button>
      </li>

      <li>
        <button
          class="pagination__item"
          type="button"
          :aria-label="t('next')"
          :disabled="isInLastPage"
          @click="onClickNextPage"
        >
          {{ t('next') }}
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
import { tCrm as t } from '@sales-i/utils';

export default {
  props: {
    limit: {
      type: Number,
      default: undefined,
    },
    offset: {
      type: Number,
      default: 0,
    },
    recordsCount: {
      type: Number,
      default: 0,
    },
  },
  emits: ['pageChanged'],
  computed: {
    isInFirstPage() {
      return this.offset === 0;
    },
    isInLastPage() {
      return !this.recordsCount || this.recordsCount !== this.limit;
    },
  },
  methods: {
    t,
    onClickPreviousPage() {
      // Decrease the page count by the set limit
      this.$emit('pageChanged', this.offset - this.limit);
    },
    onClickNextPage() {
      // Increase the page count by the set limit
      this.$emit('pageChanged', this.offset + this.limit);
    },
    isPageActive(offset) {
      return this.offset === offset;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination__list {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: center;
}

.pagination__item {
  border-radius: 900px;
  color: var(--colour-brand-deluge);
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  padding: var(--spacing-half) var(--spacing-1);
  text-decoration: none;
  transition: background 0.2s;

  // Apply to standard pagination button but not to the button if it's in the disabled state.
  &:hover:not(:disabled) {
    background-color: var(--colour-utility-black);
    color: var(--colour-utility-white);
  }
}

.pagination__item[aria-current='true'] {
  background-color: black;
  border-radius: 100px;
  color: var(--colour-utility-white);
}

button[disabled] {
  color: var(--colour-panel-g-24);
  cursor: auto;
}
</style>
