<template>
  <TableWrapper
    table-height="510px"
    :offset-y="offset"
    enable-lazy-load
    disable-shades
    @set-offset="loadMoreData"
  >
    <thead>
      <tr>
        <th
          v-for="dataType in inputData.visualInput"
          :id="dataType.key"
          :key="dataType.key"
          :class="{'sorting-header': props.currentSort === dataType.key}"
          @click="applySort(dataType.key)"
        >
          {{ dataType.name }}
          <IconBase
            v-if="props.currentSort === dataType.key"
            class="sort-icon"
            :icon-name="props.sortDirection === REPORTS_SORT_DESCENDING_KEY ? 'arrow-down' : 'arrow-up'"
            :height="24"
            :width="24"
          />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(unit, i) in props.reportData"
        :key="i"
      >
        <td
          v-for="(dataType, index) in inputData.visualInput"
          :key="dataType.key"
          :class="{negative: unit[dataType.key] < 0}"
          :title="unit[dataType.key]"
        >
          <a
            v-if="unit.id && index < 2"
            :href="getCustomerLink(unit.id)"
            target="_blank"
          > {{ !index ? unit.name : unit.account_number }} </a>
          <div v-if="index > 1 || !unit.id">
            {{ dataType.prefix ? cPrice(unit[dataType.key]) : cNumber(unit[dataType.key]) }} {{ dataType.suffix }}
          </div>
        </td>
      </tr>
    </tbody>
  </TableWrapper>
</template>

<script setup>
import { ref, computed } from 'vue';
import useCurrency from '@/shared/composables/useCurrency';
import { IconBase } from '@sales-i/dsv3';
import TableWrapper from '@/shared/components/Tables/TableWrapper';
import { baseUrl as crmBaseUrl, customersArea } from '@/crm/router/urlBits';
import { REPORTS_SORT_DESCENDING_KEY } from '@/intelligence/store/data/apiInput';
import { CUSTOMER_ACTIVITY } from '@/intelligence/store/data/reportTypes';
import useShared from '@/intelligence/composables/useShared';

const { cPrice, cNumber } = useCurrency();

const props = defineProps({
  currentSort: {
    type: String,
    default: '',
  },
  sortDirection: {
    type: String,
    default: '',
  },
  reportData: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['applyNewSort', 'loadMoreData']);

const offset = ref(0);
const { getInputData, applyOffset, requestParameters } = useShared();
const inputData = computed(() => getInputData(CUSTOMER_ACTIVITY));

function applySort(headerKey) {
  emit('applyNewSort', headerKey);
}
function getCustomerLink(id) {
  return `${crmBaseUrl}/${customersArea}/${id}`;
}
function loadMoreData() {
  if (requestParameters.value.limit + offset.value > props.reportData.length) return;
  offset.value += requestParameters.value.limit;
  applyOffset(offset.value);
  emit('loadMoreData');
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.table-wrapper {
  overflow: auto;
  height: 330px;
  position: relative;
}

table {
  position: relative;
  width: 100%;
}

tr {
  background: var(--colour-panel-g-0);

  &:nth-child(even) {
    background: var(--colour-panel-g-2);
  }
}

th,
td {
  color: var(--colour-utility-black);
  padding: var(--spacing-2);
  border: none;
  font-weight: var(--font-weight-semibold);
}

th {
  font-size: var(--font-size-body);
  background: var(--colour-panel-action);
  color: var(--colour-utility-action);
  position: sticky;
  top: 0;
  text-align: left;
  min-height: 70px;
  min-width: 180px;
  vertical-align: middle;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
  padding: var(--spacing-3);

  &.sorting-header {
    background: var(--colour-panel-g-2);
    color: var(--colour-utility-black);
    font-weight: var(--font-weight-medium);
    text-decoration: none;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: var(--font-size-5);
    padding: var(--spacing-2) var(--spacing-1);
    min-width: 100px;
  }
}

td {
  font-size: var(--font-size-small);
  padding: var(--spacing-1) var(--spacing-3);
  line-height: var(--spacing-2);
  text-align: left;
}

.sort-icon {
  display: inline-block;
  position: absolute;
}

.negative {
  color: var(--colour-data-mandy-label);
}
</style>
