export const baseUrl = '/intelligence';
export const reportScope = 'report';

// higher scopes
export const performanceScope = 'performance';
export const productsScope = 'products';
export const customerScope = 'customer';
export const enquiryScope = 'enquiry';
export const opportunitiesScope = 'opportunities';
export const communitiesScope = 'communities';
export const associationsScope = 'associations';
export const insightsScope = 'insights';
export const productSearchScope = 'product-search';
export const bubbleScope = 'bubbles';
export const queryScope = 'query-builder';

// ALert builder
export const alertsScope = 'alerts';
export const alertBuilderScope = 'alert-builder';

// snapshot reports
export const snapshotReportScope = 'snapshot';
export const branchScope = 'branch';
export const snapshotByAnalysisField = 'snapshot-by-analysis-field';

// Generic 'People' page for demo
export const peopleScope = 'people';
