<template>
  <div class="enquiries-baseview-container">
    <SignPost
      v-if="permissions.read"
      :heading="t('enquiries')"
      :description="t('manage_your_custom_schema_sections_and_fields')"
      color="var(--colour-utility-action)"
      background-color="var(--colour-panel-action)"
      :links="enquiriesLinks"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { tCrm as t } from '@sales-i/utils';
import SignPost from '@/admin/views/SignPost.vue';
import usePermissions from '@/shared/composables/usePermissions';
import { intelligence_enquiries } from '@/shared/store/data/policies';
import { baseUrl, enquiryScope } from '@/intelligence/router/urlBits';
import { FULL_PICTURE, MATRIX, SALES_VS_GAPS, TRENDS, VARIANCE } from '@/intelligence/store/data/reportTypes';

const { getPermissions } = usePermissions();
const permissions = getPermissions(intelligence_enquiries);

const enquiriesLinks = computed(() => {
  const arr = [];
  arr.push({
    title: t('full_picture'),
    description: t('see_the_full_picture'),
    href: `${baseUrl}/${enquiryScope}/${FULL_PICTURE}?cc=0`,
  });
  arr.push({
    title: t('variance'),
    description: t('gauge_variance_between_two_periods_in_up_to_four_k_p_is_at_once'),
    href: `${baseUrl}/${enquiryScope}/${VARIANCE}?cc=0`,
  });
  arr.push({
    title: t('matrix'),
    description: t('two_dimensions_are_better_than_one'),
    href: `${baseUrl}/${enquiryScope}/${MATRIX}?cc=0`,
  });
  arr.push({
    title: t('trends'),
    description: t('compare_year_on_year_trends'),
    href: `${baseUrl}/${enquiryScope}/${TRENDS}?cc=0`,
  });
  arr.push({
    title: t('sales_vs_gaps'),
    description: t('analyse_gaps_in_your_sales_over_time'),
    href: `${baseUrl}/${enquiryScope}/${SALES_VS_GAPS}?cc=0`,
  });
  return arr;
});
</script>

<style lang="scss" scoped>
.enquiries-baseview-container {
  padding: var(--spacing-4);
  > div {
    border-radius: var(--border-radius-1);
  }
}
</style>
