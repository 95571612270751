<template>
  <TableWrapper
    v-if="items.length"
    class="entity-list-view-table"
    table-height="510px"
  >
    <thead v-if="!props.hiddenHeader">
      <tr>
        <th
          v-for="(heading, i) in headings"
          :key="i"
          :class="{
            [heading.value]: true,
            sort: heading.value === props.sortAttribute,
            sortable: props.sortAttribute && sortableFields.includes(heading.value),
          }"
          @click="changeSorting(heading.value)"
        >
          <div class="table-header-cell">
            {{ t(heading.text) }}
            <IconBase
              v-if="heading.value === props.sortAttribute"
              :width="30"
              :height="30"
              :icon-name="sortOrderIconName"
              icon-color="var(--colour-utility-action)"
            />
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, rowIndex) in items"
        :key="rowIndex"
      >
        <td
          v-for="(heading, headingIndex) in headings"
          :key="headingIndex"
          :class="heading.value"
        >
          <template v-if="heading.value === 'entity_name'">
            <EntityListViewCell 
              :value="row[heading.value]"
              type="link"
              @link-click="navigateToUrl(`${openLink(row.entity_id, row.entity_type)}/${opportunitiesArea}`)"
            />
          </template>
          <template v-else-if="heading.value === 'address'">
            <AddressField :addresses="[row.addresses[0]]" />
          </template>
          <template v-else-if="heading.value === 'created_date'">
            <EntityListViewCell 
              :value="row[heading.value]"
              type="date" 
            />
          </template>
          <template v-else-if="heading.value === 'decision_date'">
            <EntityListViewCell 
              :value="dates.format(row[heading.value])"
              type="link"
              @link-click="handleButtonClick(row.id, 'open', row)" 
            />
          </template>
          <template v-else-if="heading.value === 'value'">
            <EntityListViewCell 
              :value="row[heading.value]"
              type="price" 
            />
          </template>
          <template v-else-if="heading.value === 'next_step'">
            <EntityListViewCell 
              :value="row[heading.value]"
              type="link"
              @link-click="handleButtonClick(row.id, 'open', row)" 
            />
          </template>
          <template v-else-if="heading.value === 'ai_next_step'">
            <EntityListViewCell 
              :value="`${t('you_could_sell')} ${getProductCodes(row)}`"
              type="link"
              @link-click="handleButtonClick(row.id, 'open_modal', row)" 
            />
          </template>
          <template v-else-if="heading.value === 'probability' && props.entity === 'opportunity'">
            <EntityListViewCell 
              :value="row[heading.value]"
              type="percent" 
            />
          </template>
          <template v-else-if="heading.value === 'allocated_users' && props.entity === 'opportunity'">
            <OpportunityUserCell 
              :item="row" 
              is-clickable
            />
          </template>
          <template v-else-if="heading.value === 'tags'">
            <Tag
              :id="row.id"
              :entity="props.entity"
              show-as-direct-link
            />
          </template>
          <template v-else-if="heading.value === 'links'">
            <a
              v-if="row.id && props.entity === 'customer'"
              :href="openLink(row.id)"
              target="_blank"
            >
              {{ row.name }}
            </a>
            <div
              v-if="row.id && ['prospect', 'opportunity'].includes(props.entity)"
              class="links-container"
            >
              <CustomButton
                v-for="(link, i) in links"
                :key="i"
                purpose="text"
                @click="handleButtonClick(row.id, link.action, row)"
              >
                {{ link.title }}
              </CustomButton>
            </div>
          </template>
          <template v-else-if="heading.value === 'menu_links'">
            <CustomDropdown
              purpose="reversed"
              menu-position="left"
              close-on-selection
              :items="links"
              icon-color="var(--colour-utility-black)"
              @click="handleButtonClick(row.id, $event.action, row)"
            />
          </template>
          <template v-else>
            <EntityListViewCell :value="row[heading.value]" />
          </template>
        </td>
      </tr>
    </tbody>
  </TableWrapper>
  <p 
    v-else
    class="no-results"
  >
    {{ t('no_results') }} 
  </p>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { dates, tCrm as t } from '@sales-i/utils';
import TableWrapper from '@/shared/components/Tables/TableWrapper';
import AddressField from '@/crm/components/Common/AddressField.vue';
import Tag from '@/shared/components/Tags/Tag.vue';
import { CustomButton, CustomDropdown, IconBase } from '@sales-i/dsv3';
import { baseUrl, customersArea, prospectsArea, opportunitiesArea, interactionsArea } from '@/crm/router/urlBits';
import OpportunityUserCell from '@/crm/views/Opportunities/OpportunityUserCell.vue';
import EntityListViewCell from '@/shared/components/Tables/EntityListViewCell.vue';
import usePermissions from '@/shared/composables/usePermissions';
import { prospects } from '@/shared/store/data/policies';
import { useOpportunity } from '@/crm/composables/useOpportunity';
import { navigateToUrl } from 'single-spa';
import { useInteraction } from '@/crm/composables/useInteraction';
import { useProspect } from '@/crm/composables/useProspect';
import useSystem from '@/shared/composables/useSystem';
import { REPORTS_SORT_ASCENDING_KEY, REPORTS_SORT_DESCENDING_KEY } from '@/intelligence/store/data/apiInput';
import { useCustomer } from '@/crm/composables/useCustomer';

const emit = defineEmits(['closeModal', 'deleted', 'opportunityDeleted', 'edit', 'pursueInteraction', 'sort', 'openModal']);

const vroute = useRoute();
const store = useStore();

const { getPermissions } = usePermissions();
const permissions = getPermissions(prospects);

const { setRoute } = useSystem({ store });
const { deleteProspect } = useProspect({ store });
const { selectedCustomer, getCustomerById } = useCustomer({ store });
const { showDeleteOpportunityModal } = useOpportunity({ store, emit });
const { 
  setEntity, 
  setFurtherDetails, 
  clearCurrentlyEditing, 
  setOpportunity,
  showConfirmationModal,
} = useInteraction({ store, emit });

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  headings: {
    type: Array,
    default: () => [],
  },
  entity: {
    type: String,
    default: '',
  },
  status: {
    type: String,
    default: '',
  },
  hiddenHeader: {
    type: Boolean,
    default: false,
  },
  sortMethod: {
    type: String,
    default: REPORTS_SORT_DESCENDING_KEY,
  },
  sortAttribute: {
    type: String,
    default: '',
  },
});

const sortableFields = ['decision_date', 'probability', 'value', 'entity_name'];

const sortOrderIconName = computed(() => (props.sortMethod === REPORTS_SORT_ASCENDING_KEY ? 'chevron-up' : 'chevron-down'));
const links = computed(() => {
  const baseLinks = [];
  if (props.entity === 'prospect') {
    // Only show edit and delete links for prospects
    if (permissions.update) {
      baseLinks.push({ title: t('edit'), action: 'edit' });
    }
    if (permissions.delete) {
      baseLinks.push({ title: t('delete'), action: 'delete' });
    }
  } else if (props.entity === 'opportunity' && props.status === 'open') {
    // Only show edit and delete links for won opportunities
    if (permissions.update) {
      baseLinks.push({ title: t('edit'), action: 'edit' });
    }
    if (permissions.delete) {
      baseLinks.push({ title: t('delete'), action: 'delete' });
    }
  } else if (props.entity === 'opportunity' && props.status === 'potential') {
    baseLinks.push({ title: t('create_linked_interaction'), action: 'pursue_interaction' });
    baseLinks.push({ title: t('dismiss'), action: 'delete' });
    return baseLinks;
  }
  baseLinks.unshift({ title: t('open'), action: 'open' });
  return baseLinks;
});

function changeSorting(newSortAttribute) {
  if (!sortableFields.includes(newSortAttribute)) return;
  let newSortMethod;
  if (newSortAttribute === props.sortAttribute) {
    newSortMethod = props.sortMethod === REPORTS_SORT_DESCENDING_KEY ? REPORTS_SORT_ASCENDING_KEY : REPORTS_SORT_DESCENDING_KEY;
  } else {
    newSortMethod = REPORTS_SORT_DESCENDING_KEY;
  }

  emit('sort', { newSortMethod, newSortAttribute, status: props.status });
}

function openLink(id, entity_type) {
  if (entity_type === 'prospect') {
    return `${baseUrl}/${prospectsArea}/${id}`;
  }
  return `${baseUrl}/${customersArea}/${id}`;
}

function handleProspect(id, purpose, prospect) {
  switch (purpose) {
  case 'open':
    navigateToUrl(`${baseUrl}/${prospectsArea}/${id}`);
    break;
  case 'edit':
    setRoute({
      success: `${baseUrl}/${prospectsArea}/${id}`,
      cancel: vroute.fullPath,
    });
    navigateToUrl(`${baseUrl}/${prospectsArea}/${id}/edit`);
    break;
  case 'delete':
    showConfirmationModal({
      message: t('do_you_really_want_to_delete_prospect_variable', { variable: prospect.name }),
      updatedMessage: t('you_have_deleted_prospect_name', { variable: prospect.name }),
      updateFunction: () => confirmDelete(id),
      finishFunction: () => finishDelete(id),
    });
  }
}

function finishDelete() {
  emit('closeModal');
}

async function confirmDelete(id) {
  try {
    let response = await deleteProspect(id);
    if (response) emit('deleted');
    return response;
  } catch (e) {
    console.error(e);
    return false;
  }
}

function handleOpportunity(id, purpose, oppo) {
  switch (purpose) {
  case 'open':
    navigateToUrl(`${baseUrl}/${opportunitiesArea}/${id}`);
    break;
  case 'edit':
    emit('edit', oppo);
    break;
  case 'delete':
    showDeleteOpportunityModal(oppo);
    emit('opportunityDeleted', oppo);
    break;
  case 'pursue_interaction':
    emit('pursueInteraction', oppo);
    pursueItem(oppo);
    break;
  case 'open_modal':
    emit('openModal', oppo);
    break;
  default:
    // eslint-disable-next-line no-console
    console.warn(`Unhandled purpose: ${purpose}`);
    break;
  }
}

function handleButtonClick(id, purpose, row) {
  if (props.entity === 'prospect') {
    handleProspect(id, purpose, row);
  } else if (props.entity === 'opportunity') {
    handleOpportunity(id, purpose, row);
  }
}

function getUnabridgedProducts(products, type) {
  return products.filter(x => x.prod_category === type) || [];
}

async function pursueItem(opportunity) {
  // Make sure we are working with a clean slate
  clearCurrentlyEditing();
  await getCustomerById({ id: opportunity.entity_id });
  setEntity({
    entityType: opportunity.entity_type,
    entity: {
      ...selectedCustomer.value,
      header: selectedCustomer.value.name,
      subHeader: selectedCustomer.value.account_number,
    },
  });
  // Generate the interaction note
  let interactionNote = '';
  if (opportunity.type.toLowerCase() !== 'potential') {
    interactionNote += 'Because this client purchased: ';
    const antecedents = getUnabridgedProducts(opportunity.products, 'Antecedent');
    const consequents = getUnabridgedProducts(opportunity.products, 'Consequent');

    antecedents.forEach(ante => {
      interactionNote += `${ante.product_name} (${ante.prod_code}) `;
    });

    interactionNote += '\r\n'; // eslint-disable-line
    interactionNote += 'We recommend they also purchase: ';
    consequents.forEach(cons => {
      interactionNote += `${cons.product_name} (${cons.prod_code}) `;
    });
  } else {
    const potentials = getUnabridgedProducts(opportunity.products, 'POTENTIAL');
    interactionNote += 'We recommend they purchase: ';
    potentials.forEach(cons => {
      interactionNote += `${cons.product_name} (${cons.prod_code}) `;
    });
  }
  setFurtherDetails({ interactionNote });
  setOpportunity({ opportunity });
  // Push them to the interactions
  navigateToUrl(`${baseUrl}/${interactionsArea}/add?clean=false`);
}

function getProductCodes(item) {
  const items = [];
  let extraCount = 0;
  let appendEllipsis = false;

  if (item?.products) {
    const { products } = item;
    for (let i = 0; i < products.length; i++) {
      if (items.length > 0) {
        extraCount++;
        appendEllipsis = true;
        continue;
      }
      items.push(products[i].product_name);
    }
  }
  return items.join(', ') + (appendEllipsis ? `, +${extraCount} more` : '');
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

table {
  position: relative;
  width: 100%;
}

thead {
  position: relative;
  z-index: 1;
}

tr {
  background: var(--colour-panel-g-0);
  &:nth-child(even) {
    background: var(--colour-panel-g-2);
  }
}

th,
td {
  color: var(--colour-utility-black);
  padding: var(--spacing-2);
  border: none;
  min-width: initial;
}

th {
  font-size: var(--font-size-body);
  background: var(--colour-panel-action);
  color: var(--colour-utility-action);
  position: sticky;
  top: 0;
  text-align: left;
  min-height: 70px;
  vertical-align: middle;
  padding: var(--spacing-3);

  &.sortable {
    cursor: pointer;
    text-decoration: underline;
    white-space: nowrap;

    .table-header-cell {
      display: flex;
      align-items: center;
    }
  }
}

td {
  font-size: var(--font-size-small);
  padding: var(--spacing-1) var(--spacing-3);
  line-height: var(--spacing-2);
  text-align: left;
  vertical-align: middle;

  :deep(.button) {
    text-align: left;
  }
}

.no-results {
  text-align: center;
  padding: var(--spacing-4);
}

.links-container {
  .button.text {
    display: inline-block;
    margin-right: var(--spacing-1);
  }
}

.menu_links {
  min-width: 50px;
  text-align: right;
}
</style>