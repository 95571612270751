import { DATE_FILTER_OPTIONS } from './dateFilters';
import { tCrm as t } from '@sales-i/utils';

// Template reports filter values
export const PERCENT_FROM_FILTER_OPTIONS = [
  { value: 0, text: '0%' },
  { value: 10, text: '10%' },
  { value: 20, text: '20%' },
  { value: 30, text: '30%' },
  { value: 40, text: '40%' },
  { value: 50, text: '50%' },
  { value: 60, text: '60%' },
  { value: 70, text: '70%' },
  { value: 80, text: '80%' },
  { value: 90, text: '90%' },
  { value: 100, text: '100%' }
];
export const LIMIT_FILTER_OPTIONS = [
  { value: 10, text: t('count_customers', { count: 10 }) },
  { value: 20, text: t('count_customers', { count: 20 }) },
  { value: 50, text: t('count_customers', { count: 50 }) },
  { value: 100, text: t('count_customers', { count: 100 }) },
];
export const PERIODS_FILTER_OPTIONS = [
  { value: 2, text: t('number_months', { number: 2 }) },
  { value: 3, text: t('number_months', { number: 3 }) },
  { value: 4, text: t('number_months', { number: 4 }) },
  { value: 5, text: t('number_months', { number: 5 }) },
  { value: 6, text: t('number_months', { number: 6 }) },
  { value: 7, text: t('number_months', { number: 7 }) },
  { value: 8, text: t('number_months', { number: 8 }) },
  { value: 9, text: t('number_months', { number: 9 }) },
  { value: 10, text: t('number_months', { number: 10 }) },
  { value: 11, text: t('number_months', { number: 11 }) },
  { value: 12, text: t('number_months', { number: 12 }) },
  { value: 13, text: t('number_months', { number: 13 }) },
];

// Template reports filter sets
export const REPORT_DATE_FILTER = {
  queryParamKey: 'dateType',
  values: DATE_FILTER_OPTIONS,
  isNumber: false,
  // TODO: check if this is correct or should be 'today/y'
  default: 'ytd',
  type: 'date'
};

export const REPORT_PERCENT_FROM_FILTER = {
  queryParamKey: 'percent_from',
  values: PERCENT_FROM_FILTER_OPTIONS,
  isNumber: true,
  default: 40,
  type: 'select'
};

export const REPORT_LIMIT_FILTER = {
  queryParamKey: 'limit',
  values: LIMIT_FILTER_OPTIONS,
  isNumber: true,
  default: 50,
  type: 'select'
};

export const REPORT_PERIODS_FILTER = {
  queryParamKey: 'periods',
  values: PERIODS_FILTER_OPTIONS,
  isNumber: true,
  default: 6,
  type: 'select'
};

export const REPORT_REVENUE_RANGE_FILTER_FROM = {
  queryParamKey: 'prev_year_from',
  label: t('revenue_from'),
  values: null,
  isNumber: true,
  default: 1000,
  type: 'input'
};

export const REPORT_REVENUE_RANGE_FILTER_TO = {
  queryParamKey: 'prev_year_to',
  label: t('revenue_to'),
  values: null,
  isNumber: true,
  default: 2000,
  type: 'input'
};

// Sales Opportunities filter sets
export const OPPORTUNITY_FILTERS = [
  {
    text: t('open_opportunities'),
    value: 'open',
  },
  {
    text: t('won_opportunities'),
    value: 'won',
  },
  {
    text: t('lost_opportunities'),
    value: 'lost',
  },
];

export const SALES_OPORTUNITY_ENTITY = [
  { text: t('customer'), value: 'cust' },
  { text: t('representative'), value: 'rep' }
];

export const SALES_OPORTUNITY_ORDER_FILTER = [
  { text: t('highest'), value: 'high' },
  { text: t('lowest'), value: 'low' }
];

// Interactions filter
export const INTERACTIONS_FILTER_OPTIONS = [
  {
    text: t('interaction_type'),
    value: 'interaction_type',
  },
  {
    text: t('interaction_outcome'),
    value: 'interaction_outcome',
  },
  {
    text: t('next_action'),
    value: 'next_action',
  },
  {
    text: t('user'),
    value: 'user',
  },
];

export const INTERACTION_SPEND_SUMMARY_FILTERS = [
  {
    text: t('interaction_type'),
    value: 'interaction_type',
    isMultipleChoice: true
  },
  {
    text: t('user'),
    value: 'user',
    isMultipleChoice: true
  },
  {
    text: t('analysis_field'),
    value: 'analysis_field_id',
    isMultipleChoice: false
  },
];

export const INTERACTION_SPEND_SUMMARY_REPORTS = [
  {
    text: t('customer_view'),
    value: 'customer',
  },
  {
    text: t('sales_rep'),
    value: 'rep',
  },
  {
    text: t('parent_account'),
    value: 'parent',
  },
];

export const TEMPLATE_REPORT_CUSTOMER_FILTERS = [
  { text: t('customer'), value: 'Customer' },
  { text: t('product'), value: 'Product' }
];

// AND/OR clause options
export const AND_OR_OPTIONS = [
  {
    title: t('a_n_d'),
    value: 'AND',
  },
  {
    title: t('o_r'),
    value: 'OR',
  },
];