<template>
  <div class="container container--fluid bg">
    <div class="padded">
      <EntityList
        :filters="sortOptions"
        :selected-filter="selectedFilter"
        :page-limit="limit"
        :page-offset="offset"
        :records-count="recordsCount"
        :is-button="true"
        :links="entityLinks"
        :show-pagination="!noContacts"
        :loading="contacts.loading"
        @filter-selected="filterSelected"
        @page-changed="handlePageChanged"
      >
        <GroupContactsList
          :items="contacts.data"
          @item-clicked="handleItemClicked"
          @edit="handleEdit"
        />
        <template v-if="noContacts || noMoreRecords">
          <div class="entity-list-no-items">
            <h4 class="entity-list-no-items--heading">
              {{ noContacts ? t('you_currently_have_no_contacts') : t('there_are_no_more_contacts_to_display') }}
            </h4>
            <CustomButton
              v-if="permissions.create"
              :label="t('add_contact')"
              @on-click="add"
            />
          </div>
        </template>
        <template
          v-if="isValid"
          #headeractions
        >
          <ViewEmailsSwitches
            class="ml-2"
            :is-only-unread="isOnlyUnread"
            :is-view-emails="isViewEmails"
            :disabled="loading"
            @update:is-only-unread="setOnlyUnread"
            @update:is-view-emails="handleSetViewEmails($event, contacts.data)"
          />
        </template>
      </EntityList>
      <router-view @updated="loadPageData" />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute, } from 'vue-router';
import { CustomButton } from '@sales-i/dsv3';
import EntityList from '@/crm/components/EntityList/EntityList.vue';
import GroupContactsList from '@/crm/components/Contacts/GroupContactsList.vue';
import { usePagination, paginationProps } from '@/shared/composables/usePagination';
import { baseUrl, contactsSection } from '@/crm/router/urlBits';
import usePermissions from '@/shared/composables/usePermissions';
import { contacts as contPerm } from '@/shared/store/data/policies';
import { useStore } from 'vuex';
import { SET_ROUTE } from '@/shared/store/actionType';
import { GET_CONTACTS, CLEAR_CONTACTS } from '@/crm/store/actionType';
import { tCrm as t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';
import useMail from '@/shared/composables/useMail';
import useMailAuth from '@/shared/composables/useMailAuth';
import ViewEmailsSwitches from '@/shared/components/Mail/ViewEmailsSwitches.vue';
import useSystem from '@/shared/composables/useSystem';

const vroute = useRoute();
const emit = defineEmits([]);
const store = useStore();

const { entity } = useSystem({ store });

const props = defineProps(paginationProps);
const { limit, offset, handlePageChanged } = usePagination(props, loadPageData, emit);
const { isValid } = useMailAuth();
const { isOnlyUnread, isViewEmails, setOnlyUnread, loadThreadsSummary, handleSetViewEmails } = useMail();

const itemSelected = ref(undefined);
const sortOptions = [
  {
    text: t('first_name_a_s_c_d_e_s'),
    value: 'first_name:asc,surname:asc',
  },
  {
    text: t('first_name_d_e_s_a_s_c'),
    value: 'first_name:desc,surname:asc',
  },
  {
    text: t('last_name_a_s_c_d_e_s'),
    value: 'surname:asc,first_name:asc',
  },
  {
    text: t('last_name_d_e_s_a_s_c'),
    value: 'surname:desc,first_name:asc',
  },
];

const selectedFilter = ref('first_name:asc,surname:asc');
const contact = computed(() => store.state.crm.contacts.selected);

const { getPermissions } = usePermissions();
const permissions = getPermissions(contPerm);

const entityLinks = computed(() =>
  permissions.create
    ? [
      {
        label: t('add_contact'),
        small: true,
        function: add,
      },
    ]
    : []
);

const contacts = computed(() => (entity.value ? store.state.crm[entity.value].contacts : {}));
const recordsCount = computed(() => contacts.value.data.reduce((prev, curr) => prev + curr.items.length, 0) || 0);
const noContacts = computed(() => contacts.value.loaded && contacts.value.data.length <= 0 && offset.value === 0);
const noMoreRecords = computed(() => offset.value !== 0 && contacts.value.data.length <= 0);
const contactsPath = computed(() => `${baseUrl}/${entity.value}/${vroute.params.id}/${contactsSection}`);

watch(
  () => vroute.params.id,
  async (toId, fromId) => {
    if (!toId || !vroute.path.includes(contactsSection)) {
      clearContacts();
    } else if (contact.value.updated || toId !== fromId) {
      await clearContacts();
      loadPageData();
    }
  }
);

onMounted(() => {
  loadPageData();
});

onUnmounted(() => clearContacts());

const getCustomerContacts = params => store.dispatch(`crm/customers/${GET_CONTACTS}`, params);
const clearCustomerContacts = params => store.dispatch(`crm/customers/${CLEAR_CONTACTS}`, params);
const getProspectContacts = params => store.dispatch(`crm/prospects/${GET_CONTACTS}`, params);
const clearProspectContacts = params => store.dispatch(`crm/prospects/${CLEAR_CONTACTS}`, params);
const setRoute = params => store.dispatch(`system/${SET_ROUTE}`, params);

async function handleItemClicked(contact) {
  setRoute({ success: vroute.fullPath, cancel: vroute.fullPath });
  navigateToUrl(`${contactsPath.value}/${contact.id}`);
  itemSelected.value = contact;
}

const fetchFunc = computed(() => (entity.value === 'prospects' ? getProspectContacts : getCustomerContacts));

async function handleEdit(contact) {
  setRoute({ success: vroute.fullPath, cancel: vroute.fullPath });
  navigateToUrl(`${contactsPath.value}/${contact.id}/edit`);
  itemSelected.value = contact;
}

async function filterSelected({ value }) {
  selectedFilter.value = value;
  await clearContacts();
  loadPageData();
}

async function loadPageData() {
  await fetchFunc.value({
    id: vroute.params.id,
    parameters: {
      limit: limit.value,
      offset: offset.value,
      sort: selectedFilter.value,
    },
  });

  const contactIds = contacts.value.data.reduce((ids, group) => {
    return ids.concat(group.items.map(contact => contact.id));
  }, []);

  loadThreadsSummary(contactIds);
}

async function clearContacts() {
  clearCustomerContacts();
  clearProspectContacts();
}
function add() {
  navigateToUrl(`${contactsPath.value}/add`);
}

</script>

<style lang="scss" scoped>
:deep(.divider) {
  border-width: 0 0 2px;
}
.bg {
  background-color: var(--colour-panel-g-2);
  flex: 1;
}
</style>
