<template>
  <header class="padded pt-4">
    <div class="encouragement-headings">
      <h1 class="shoutout-heading">
        {{ pageTitle }}.
      </h1>
      <p class="period-heading">
        {{
          t('name_welcome_to_sales_ai', { name })
        }}
      </p>
    </div>
  </header>
</template>

<script setup>
import { computed } from 'vue';
import { tCrm as t } from '@sales-i/utils';
import { useStore } from 'vuex';
import { useHead } from '@unhead/vue';

// Create a non-reactive variable to store the page title
const pageTitle = t('sell_smart_every_day');
useHead({
  title: pageTitle,
});

const store = useStore();
const name = computed(() => store.state.userDetails.data.first_name);
</script>

<style lang="scss" scoped>
.homepage-container::after {
  content: '';
  background-color: var(--colour-panel-alert-base);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  position: absolute;
  z-index: -1;
}
.encouragement-headings {
  text-align: center;
}
.shoutout-heading {
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-semibold);
}

.period-heading {
  font-size: var(--font-size-3);
  line-height: 1.4;
}
</style>
