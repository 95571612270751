<template>
  <div class="template-report-content">
    <template v-if="isReportDataLoaded">
      <h4 class="subtitle fw-sbld mb-1">
        {{ subtitle }}
      </h4>
      <template v-if="reportData?.length || reportDataForTable?.length">
        <TemplateReportCharts
          v-if="isChart"
          class="sm"
          :unique-id="item?.id || reportId"
          :class="item?.reportType"
          :report-data="reportData"
          :chart-inputs="chartInput"
          :settings="settings"
          :sort-option="inputData.defaultSortOption"
          @selected="handleSelected"
        />
        <ReportTable
          v-if="settings.isTableShown"
          class="report-table"
          :report-data="reportDataForTable"
          :visual-input="inputData?.visualInput"
        />
        <p
          v-if="!(isChart && settings.isChartShown) && !settings.isTableShown"
          class="p-2"
        >
          {{ item.description }}
        </p>
      </template>
      <p
        v-else
        class="m-3"
      >
        {{ t('no_results_were_found') }}
      </p>
    </template>
    <BufferImage
      v-if="!isReportDataLoaded"
      class="loading-spinner mt-4"
      color="var(--colour-utility-black)"
      float="center"
    />
  </div>
</template>

<script setup>
// TemplateReportContent
import { computed, onMounted, ref, onBeforeUnmount, watch } from 'vue';
import { BufferImage } from '@sales-i/dsv3';
import { tCrm as t } from '@sales-i/utils';
import TemplateReportCharts from '@/intelligence/components/TemplateReport/TemplateReportCharts.vue';
import ReportTable from '@/intelligence/components/Dashboard/Elements/ReportTable.vue';
import { useStore } from 'vuex';
import { CLEAR_REPORT_DATA, FETCH_REPORT_DATA, } from '@/intelligence/store/actionType';
import { DEFAULT_WIDGET_SETTINGS, BAR_CHART, PIE_CHART, LINE_CHART } from '@/intelligence/components/Dashboard/constants';
import { useWidget } from '@/intelligence/components/Dashboard/composables/useWidget';
import useFilters from '@/intelligence/composables/useFilters';
import useEnquiries from '@/intelligence/composables/useEnquiries';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  settings: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['update:menuOptions']);

onMounted(() => {
  loadReport();
  emit('update:menuOptions', menuOptions.value);
});

onBeforeUnmount(() => {
  clearReportData();
});

const store = useStore();
const {
  fetchDates,
  setLoading,
  getDateFilterTitle,
  getDateParams,
  chartAndTableMenuOptions,
  currentChartIndex,
} = useWidget({ store, props, options: { chartTypes: [BAR_CHART, PIE_CHART, LINE_CHART] }});
const { applyFilterFromUrl } = useFilters();
const { applyFiltersToQuery, bubbleData, fetchBubbleData } = useEnquiries({ store });

const dateParams = computed(() => getDateParams(filters.value));
const dateFilterTitle = computed(() => getDateFilterTitle(dateParams.value));

watch(
  () => props.settings,
  () => {
    emit('update:menuOptions', menuOptions.value);
  },
  { deep: true }
);

watch(
  () => props.settings.limit,
  () => {
    loadReport();
  },
);

const subtitle = computed(() =>
  reportData.value && reportData.value.length ? `${dateFilterTitle.value} ${reportDateBoundaries.value}` : ''
);

const isReportDataLoaded = ref(false);

const isChart = computed(() => inputData.value && !!chartInput.value.length && props.settings.isChartShown);
const filters = computed(() => props.item.requestParams);

const reportId = ref(0);
const inputData = computed(() => store.getters['intelligence/shared/getInputData'](props.item.reportType));
const reportData = computed(() => store.getters['intelligence/shared/getReportData'](reportId.value));
const reportDataForTable = computed(
  () => (Array.isArray(reportData.value) ? reportData.value : reportData.value.customers) || []
);
const chartInput = computed(() => (inputData.value.visualInput || []).filter(item => item.includesChart));

const reportDateBoundaries = computed(() => {
  if (!reportData.value) return '';

  const reportDataKeys = Object.keys(reportData);
  if (
    !reportDataKeys.length ||
    !reportDataKeys.includes('date_range') ||
    (reportDataKeys.includes('customers') && !reportData.value.customers.length)
  )
    return '';
  const dateBoundaries = reportData.value.date_range;
  return `${new Date(dateBoundaries.start).toLocaleDateString('en-GB')} - ${new Date(
    dateBoundaries.end
  ).toLocaleDateString('en-GB')}`;
});

const customParams = ref({});

const menuOptions = computed(() => chartAndTableMenuOptions.value.filter(m => m.type !== 'chart' || chartInput.value?.length ));
const fetchReportData = params => store.dispatch(`intelligence/shared/${FETCH_REPORT_DATA}`, params);
const clearReportData = params => store.dispatch(`intelligence/shared/${CLEAR_REPORT_DATA}`, params);

async function loadReport(completeRefresh = false) {
  if (!inputData.value) return;
  isReportDataLoaded.value = false;
  // apply the data from URL
  await setReportParams();
  setLoading(true);
  // start fetching data from API
  applyFiltersToQuery();
  reportId.value = await fetchReportData({
    reportType: props.item.reportType,
    completeRefresh,
    customParams: customParams.value
  });
  isReportDataLoaded.value = true;
}

async function setReportParams() {
  if (filters.value.filter !== undefined) {
    const { filter } = filters.value;

    if (!bubbleData.value || !Object.keys(bubbleData.value).length) {
      await fetchBubbleData();
    }

    const filtersArray = Array.isArray(filter) ? filter : [filter];
    for (const [index, filterItem] of filtersArray.entries()) {
      await applyFilterFromUrl(filterItem, index);
    }
  }

  inputData.value.filters.forEach(filter => {
    const queryParamKey = filter.queryParamKey;
    let queryParamValue = filters.value[queryParamKey] || filter.default;
    
    if (filter.isNumber) {
      queryParamValue = parseInt(queryParamValue);
    }
    if (filter.type === 'date') {
      customParams.value.date_from = filters.value.date_from;
      customParams.value.date_to = filters.value.date_to;
    } else {
      customParams.value[queryParamKey] = queryParamValue;
    }
  });
  
  // dates
  const fetchedDates = await fetchDates(dateParams.value);
  customParams.value = { ...customParams.value, ...fetchedDates };
  
  // limit
  customParams.value.limit = props.settings.limit || DEFAULT_WIDGET_SETTINGS.limit;

  // sort
  customParams.value.currentSortHeader = filters.value.sort || inputData.value.defaultSortHeader;
}

function handleSelected(index) {
  currentChartIndex.value = index;
  emit('update:menuOptions', menuOptions.value);
}
</script>

<style lang="scss" scoped>
.subtitle {
  text-align: center;
}

.report-table {
  margin-bottom: var(--spacing-1);
  max-height: 260px;
  /** todo: improve this later - it's temporary solution to not let chart be on top of the table */
  // z-index: 1;
}
</style>
