<template>
  <div class="period-earnings">
    <div>
      <IconBase
        class="left-caret"
        icon-name="caret-left"
        icon-color="var(--colour-utility-black)"
        :height="32"
        :width="32"
      />
      <div
        v-for="item in earningsHeadings"
        :key="item.heading"
      >
        <p class="earnings-heading">
          {{ item.heading }}
        </p>
        <p
          :class="{
            'earnings-value': true,
            [item.rag]: item.rag,
          }"
        >
          <strong>
            {{ item.strongContent }}
          </strong>
          {{ item.content }}
          <RagChip
            v-for="(chip, i) in item.chips"
            :key="i"
            :value="chip.value"
            :green="400000"
            :amber="200000"
            :prefix="cs"
            abbr
            small
          />
        </p>
      </div>
    </div>
    <div class="progress-bar">
      <div>
        <div class="fade-bar" />
        <div class="fill-bar" />
      </div>
    </div>
    <div class="disclaimer">
      <div>
        <p>** {{ t('based_on_sales_data_from') }} 07/02/2023</p>
        <p>* {{ t('based_on_revenue') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import useCurrency from '@/shared/composables/useCurrency';
import { abbr, currency, tCrm as t } from '@sales-i/utils';
import { RagChip, IconBase } from '@sales-i/dsv3';

export default {
  name: 'EarningsBar',
  components: {
    IconBase,
    RagChip,
  },
  setup() {
    let { cs } = useCurrency();
    return { cs };
  },
  computed: {
    earningsHeadings() {
      return [
        {
          heading: 'Period',
          strongContent: '10',
          content: ' of 12',
          rag: false,
        },
        {
          heading: 'Target **',
          strongContent: `${currency.leftFormat(this.cs, abbr.float(3000000))}`,
          content: '',
          rag: false,
        },
        {
          heading: 'Achieved *',
          strongContent: `${currency.leftFormat(this.cs, abbr.float(1900000))}`,
          content: ' - 90%',
          rag: 'g',
        },
        {
          heading: 'Deficit',
          strongContent: `${currency.leftFormat(this.cs, abbr.float(1100000))}`,
          content: '',
          rag: 'g',
        },
        {
          heading: 'Deadline',
          strongContent: '31/12/2023',
          content: ' - 61 Days left',
          rag: false,
        },
        {
          heading: 'Best Day',
          strongContent: '23/01/2023',
          content: '',
          rag: false,
          chips: [
            {
              value: 31750,
            },
          ],
        },
      ];
    },
  },
  methods: { t }
};
</script>

<style lang="scss" scoped>
.period-earnings {
  position: relative;
  top: 12px;
  margin: var(--spacing-2) auto 0;

  > div:not(.disclaimer) {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-4);
    justify-content: center;
  }
  .left-caret {
    align-self: end;
    position: relative;
    left: 24px;
  }
}
.earnings-heading {
  font-size: 16px;
}
.earnings-value {
  position: relative;
  > strong {
    font-size: 24px;
    font-weight: 600;
  }
  font-size: 16px;
  &.g {
    color: var(--colour-data-de-york-label);
  }
}

.progress-bar {
  margin-top: var(--spacing-2);
  > div {
    position: relative;
    width: 100%;
    max-width: 880px;
    height: 27px;
    border-radius: calc(var(--border-radius-1) * 2);
    overflow: hidden;
  }
  .fade-bar {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--colour-data-de-york-light);
  }
  .fill-bar {
    position: absolute;
    height: 100%;
    width: 90%;
    border-top-right-radius: calc(var(--border-radius-1) * 2);
    border-bottom-right-radius: calc(var(--border-radius-1) * 2);
    background-color: var(--colour-utility-success);
  }
}

.disclaimer {
  margin-top: var(--spacing-2);
  font-size: 12px;
  text-align: center;
  position: absolute;
  width: 100%;
  > div {
    display: inline-block;
    text-align: left;
  }
}
.chip {
  position: absolute;
  top: -21px;
  right: 0; // small fix to preserve chip inside of container
}
</style>
