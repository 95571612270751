/* eslint-disable no-unused-vars */
import {
  GET_MENU_OPTIONS,
  CLEAR_MENU_OPTIONS,
} from '@/admin/store/actionType';
import { product_associations_admin, product_blacklist } from '@/shared/store/data/policies';
import { tCrm as t } from '@sales-i/utils';

// getters
export const getters = {
};

// mutations
export const menuMutations = {
  [GET_MENU_OPTIONS]: (state, data) => {
    state.menu.data = data;
    state.menu.loaded = true;
    state.menu.loading = false;
    state.menu.errors = undefined;
  },
  [CLEAR_MENU_OPTIONS]: (state) => {
    state.menu.data = [];
    state.menu.loaded = false;
    state.menu.loading = true;
    state.menu.errors = undefined;
  },
};

// actions
export const menuActions = {
  [GET_MENU_OPTIONS]: async ({ commit, dispatch, rootState }, parameters) => {
    const permissions = rootState.pbac.permissionsGranted;
    let data = [];
    if (permissions[product_associations_admin] && permissions[product_associations_admin].read) {
      data.push({
        id: 666,
        name: t('products'),
      });
    }
    if (permissions[product_blacklist] && permissions[product_blacklist].read) {
      data.push({
        id: 888,
        name: t('exception_list'),
        path: 'blacklist',
      });
    }
    commit(GET_MENU_OPTIONS, data);
  },
  [CLEAR_MENU_OPTIONS]: ({ commit, }) => {
    commit(CLEAR_MENU_OPTIONS);
  },
};
