<template>
  <div>
    <InteractionOpportunityUpdateForm class="form-content py-4" />

    <div class="form-actions">
      <CustomButton
        v-scroll-to-top
        purpose="action"
        @click="furtherDetailsRoute"
      >
        {{ t('continue') }}
      </CustomButton>
    </div>
  </div>
</template>

<script>
import { tCrm as t } from '@sales-i/utils';
import { CustomButton } from '@sales-i/dsv3';
import InteractionOpportunityUpdateForm from '@/crm/components/Calendar/AddInteraction/InteractionOpportunityUpdateForm.vue';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'AddInteractionOpportunityUpdate',
  components: {
    CustomButton,
    InteractionOpportunityUpdateForm,
  },
  emits: ['stageUpdate'],
  mounted() {
    this.$emit('stageUpdate', 'Update opportunity');
  },
  methods: {
    t,
    furtherDetailsRoute() {
      navigateToUrl(`${baseUrl}/${interactionsArea}/add/further-details`);
    },
  },
};
</script>
