<template>
  <div class="container">
    <div class="intro-wrapper flow">
      <h2>{{ t('where_do_you_want_to_start') }}?</h2>
      <p>{{ t('to_get_things_started_select_the_main_reason_for_your_alert_sales_i_will_walk_you_through_the_process') }}.</p>
    </div>

    <div class="calendar-icon">
      <Field
        class="mb-0 rangetype"
        type="select"
        :options="dateFilterOptions"
        :value="dateFilterValue"
        :label="t('date_range')"
        twoway
        @input="handleDateFilterChange"
      />
      <DateRange
        v-if="dateFilterValue === 'custom'"
        :model-value="dateRangeModelValue"
        :show-range-type="false"
        @update:model-value="handleDateRangeChange($event, index)"
      />
    </div>
    <div class="calendar-icon">
      <CustomCheckbox
        :label="t('compare_to_previous_period')"
        :value="isUsingVarianceOutput"
        @input="toggleVarianceOutput"
      />
    </div>
    <div
      v-if="isUsingVarianceOutput"
      class="calendar-icon"
    >
      <Field
        class="mb-0 rangetype"
        type="select"
        :options="dateFilterOptions2"
        :value="dateFilterValue2"
        :label="t('compare_to')"
        twoway
        @input="handleDateFilter2Change"
      />
      <DateRange
        v-if="dateFilterValue2 === 'custom'"
        :show-range-type="false"
        :model-value="dateRangeModelValue2"
        @update:model-value="handleDateRange2Change($event, index)"
      />
    </div>

    <div class="grid">
      <CustomCard
        purpose="reversed"
      >
        <div class="flow alert-wizard-filter-card">
          <h3>{{ t('sales_data') }}</h3>
          <p>
            {{
              t('begin_by_looking_at_customers_who_are_up_or_down_in_key_metrics_in_currencysymbol_or_percentage_terms', {
                currencysymbol: cs
              } )
            }}
          </p>
          
          <CustomButton
            small
            @click="handleSalesDataFilter"
          >
            {{ t('build_alert') }}
          </CustomButton>
        </div>
      </CustomCard>

      <CustomCard
        purpose="reversed"
      >
        <div class="flow alert-wizard-filter-card">
          <h3>{{ t('c_r_m_data') }}</h3>
          <p>{{ t('begin_by_looking_at_customers_who_have_been_contacted_are_located_in_a_particular_place') }}...</p>
          
          <CustomButton
            small
            @click="handleCrmDataFilter"
          >
            {{ t('build_alert') }}
          </CustomButton>
        </div>
      </CustomCard>

      <CustomCard
        purpose="reversed"
      >
        <div class="flow alert-wizard-filter-card">
          <h3>{{ t('product_data') }}</h3>
          <p>{{ t('begin_with_customers_who_buy_certain_products_product_categories_or_not') }}</p>
          
          <CustomButton
            small
            @click="handleProductDataFilter"
          >
            {{ t('build_alert') }}
          </CustomButton>
        </div>
      </CustomCard>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { CustomButton, CustomCard, CustomCheckbox } from '@sales-i/dsv3';
import Field from '@/shared/components/Form/Field.vue';
import { tCrm as t } from '@sales-i/utils';
import { DateTime } from 'luxon';
import { alertBuilderScope, alertsScope } from '@/intelligence/router/urlBits';
import { QUERY_BUILDER_VARIANCE_OUTPUT } from '@/intelligence/store/actionType';
import useCurrency from '@/shared/composables/useCurrency';
import DateRange from '@/intelligence/components/Date/DateRange';
import { DATE_FILTER_OPTIONS_V2, PREVIOUS_DATE_FILTER_OPTIONS_V2 } from '@/intelligence/store/data/dateFilters';
import { navigateToUrl } from 'single-spa';
import { useAlertBuilder } from '@/intelligence/composables/useAlertBuilder';
import useCalendar from '@/intelligence/composables/useCalendar';

const emit = defineEmits(['setDate', 'refreshCount']);

const store = useStore();
let { cs } = useCurrency();
const { alertBuilderBaseUrl, formatDateForAlertBuilder } = useAlertBuilder({ store });

const props = defineProps({
  dateParams: {
    type: Object,
    default: () => ({}),
  }
});

onMounted(() => {
  refreshCount();
});

const dateFilterOptions = ref(DATE_FILTER_OPTIONS_V2);
const dateFilterOptions2 = ref(PREVIOUS_DATE_FILTER_OPTIONS_V2);

const { fetchedDates, fetchDates } = useCalendar({ store });
const isUsingVarianceOutput = computed(() => store.state.intelligence.queryBuilder.varianceOutput);
const setVarianceOutput = flag => store.dispatch(`intelligence/queryBuilder/${QUERY_BUILDER_VARIANCE_OUTPUT}`, flag);

const dateRangeModelValue = computed(() => ({
  date_from: fetchedDates.value.date_from,
  date_to: fetchedDates.value.date_to,
  value: 'custom-datepicker'
}));

const dateRangeModelValue2 = computed(() => ({
  date_from: fetchedDates.value.date_from2,
  date_to: fetchedDates.value.date_to2,
  value: 'custom-datepicker'
}));

const dateFilterValue = computed(() => dateFilterOptions.value.find(o => 
  o.value === props.dateParams.date_from)?.value || 
  (props.dateParams.date_from || props.dateParams.date_to ? 'custom' : ''));

const dateFilterValue2 = computed(() => dateFilterOptions2.value.find(o => 
  o.value === props.dateParams.date_from2)?.value || 
  (props.dateParams.date_from2 || props.dateParams.date_to2 ? 'custom' : ''));

function handleSalesDataFilter() {
  navigateToUrl(`${alertBuilderBaseUrl.value}/${alertsScope}/${alertBuilderScope}/sales-data`);
}

function handleCrmDataFilter() {
  navigateToUrl(`${alertBuilderBaseUrl.value}/${alertsScope}/${alertBuilderScope}/crm-data`);
}

function handleProductDataFilter() {
  navigateToUrl(`${alertBuilderBaseUrl.value}/${alertsScope}/${alertBuilderScope}/product-data`);
}

function refreshCount() {
  emit('refreshCount');
}

async function handleDateFilterChange(rangeType) {
  if (rangeType === '') {
    emit('setDate', { date_from: '', date_to: '' });
    return;
  }
  if (rangeType === 'custom') {
    const { date_from, date_to } = await fetchDates(props.dateParams);
    emit('setDate', { date_from, date_to });
    return;
  }
  const { date_from, date_to } = dateFilterOptions.value.find(o => o.value === rangeType);
  const fullDate = { 
    date_from, 
    date_to 
  };
  emit('setDate', fullDate);
}

async function handleDateFilter2Change(rangeType) {
  if (rangeType === '') {
    emit('setDate', { date_from2: '', date_to2: '' });
    return;
  }
  if (rangeType === 'custom') {
    const { date_from2, date_to2 } = await fetchDates(props.dateParams);
    emit('setDate', { date_from2, date_to2 });
    return;
  }
  const { date_from, date_to } = dateFilterOptions2.value.find(o => o.value === rangeType);
  const fullDate = {
    date_from2: date_from,
    date_to2: date_to,
  };
  emit('setDate', fullDate);
}

const toggleVarianceOutput = ($event) => {
  setVarianceOutput($event);
};

async function handleDateRangeChange(date) {
  // Get date and convert to an ISO date
  emit('setDate', {
    date_from: formatDateForAlertBuilder(date?.date_from || DateTime.now()),
    date_to: formatDateForAlertBuilder(date?.date_to || DateTime.now()),
  });
}

async function handleDateRange2Change(date) {
  // Get date and convert to an ISO date
  emit('setDate', {
    date_from2: formatDateForAlertBuilder(date?.date_from || DateTime.now()),
    date_to2: formatDateForAlertBuilder(date?.date_to || DateTime.now()),
  });
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

// Annoying override for the calendar icon margin
:deep(.button .button-container:not(.button-icon) .icon.left) {
  margin-right: 0;
}
.calendar-icon {
  margin: var(--spacing-2);
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  gap: var(--spacing-1);
}

.rangetype {
  min-width: 250px;
}

.intro-wrapper {
  margin-inline: auto;
  max-width: 40rem;
  text-align: center;
  margin-bottom: var(--spacing-5);
}
.grid {
  --min: 16rem;
  padding-block-end: var(--spacing-3);
}

  h3 {
    font-weight: var(--font-weight-semibold);
  }
  .alert-wizard-filter-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
</style>
