<template>
  <div
    class="entities flow"
    :class="mq.current"
  >
    <header v-if="heading">
      <h3 class="fw-sbld">
        {{ heading }}
      </h3>
    </header>

    <div class="filters">
      <SearchInput
        v-if="searchable"
        id="search"
        class="search"
        :placeholder="props.searchPlaceholder"
        :value="props.searchValue"
        @search="handleSearch"
      />
      <TabSwitch
        v-if="showTabSwitch"
        :selected-value="currentViewMode"
        :items="viewTabs"
        @switch-selected="switchMode"
      />
    </div>
    <div
      v-if="recordsCount > 0"
      class="entity-row"
    >
      <div
        v-if="filters && filters.length > 0"
        class="entity-row__filter"
      >
        <MenuFilter
          tabindex="0"
          :items="filters"
          :selected-value="selectedFilter"
          :menu-props="menuProps"
          menu-position="right"
          @on-change="filterSelected"
        />
      </div>
      <EntityLinks
        v-if="links.length"
        :is-button="props.isButton"
        :links="links"
      />
      <slot name="headeractions" />
    </div>
    <div class="entities__list">
      <BufferImage
        v-if="loading"
        class="buffer"
        color="var(--colour-utility-black)"
        float="center"
      />
      <template v-else>
        <slot />
        <div
          v-if="showPagination && limit"
          class="pt-4 flex justify-centre"
        >
          <Pagination
            :offset="offset"
            :limit="limit"
            :records-count="recordsCount"
            @page-changed="handlePageChanged"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
// EntityList
import { useMq } from 'vue3-mq';
import { BufferImage, MenuFilter, TabSwitch } from '@sales-i/dsv3';
import SearchInput from '@/dsv3/Forms/SearchInput.vue';
import EntityLinks from './EntityLinks.vue';
import Pagination from '@/shared/components/Pagination/Pagination.vue';
import { usePagination, paginationProps } from '@/shared/composables/usePagination';
import { computed } from 'vue';
import { tCrm as t } from '@sales-i/utils';

const mq = useMq();

const props = defineProps({
  ...paginationProps,
  isButton: {
    type: Boolean,
    default: false,
  },
  heading: {
    type: String,
    default: '',
  },
  filters: {
    type: Array,
    default: () => [],
  },
  selectedFilter: {
    type: [String, Number],
    default: 0,
  },
  links: {
    type: Array,
    default: () => [],
  },
  menuProps: {
    type: [String, Object],
    default: 'auto',
  },
  recordsCount: {
    type: Number,
    default: 0,
  },
  showPagination: {
    type: Boolean,
    default: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  searchPlaceholder: {
    type: String,
    default: '',
  },
  searchable: {
    type: Boolean,
    default: false,
  },
  searchValue: {
    type: String,
    default: undefined,
  },
  currentViewMode: {
    type: String,
    default: 'list'
  },
  showTabSwitch: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['filterSelected', 'pageChanged', 'search', 'switchViewMode']);
const { limit, offset, handlePageChanged } = usePagination(props, undefined, emit);

const viewTabs = computed(() => {
  const gridTab = {
    title: t('grid'),
    value: 'grid-view',
  };
  const listTab = {
    title: t('list'),
    value: 'list',
  };
  return [gridTab, listTab];
});
function handleSearch(value) {
  emit('search', value);
}
function filterSelected(item) {
  emit('filterSelected', item);
}
function switchMode(item) {
  emit('switchViewMode', item);
}
</script>

<style lang="scss">
// Intentionally global styles. These are used in different child components of this view.
.entity-list-no-items {
  display: grid;
  justify-items: center;
  margin-top: var(--spacing-6);
}

.entity-list-no-items--heading {
  font-size: var(--font-size-3);
  margin-bottom: var(--spacing-3);
}
</style>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.filters {
  display: flex;
  justify-content: space-between;

  &:empty {
    display: none;
  }
  .search {
    min-width: 150px;
    max-width: 600px;
    margin-bottom: 0;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      max-width: 62%;
    }

    :deep(input) {
      border-radius: 100px;
    }
  }
}

.input__control {
  width: auto;
}

.entity-row {
  align-items: center;
  display: flex;
  gap: var(--spacing-1);

  &:empty {
    display: none;
  }
}
.entity-row__filter {
  display: flex;
}
.entities {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: var(--spacing-2);

  header {
    display: flex;
    flex-direction: column;
    flex-direction: row;
    padding-top: var(--spacing-2);
    padding-bottom: var(--spacing-2);
  }

  &.xl,
  &.lg,
  &.md {
    .entities__list {
      position: relative;
      margin-bottom: inherit;
      padding-bottom: inherit;
    }
  }

  .card-list {
    padding: 0px;
    background-color: transparent;
  }

  &.xs,
  &.sm {
    .card-list {
      padding: var(--spacing-2);
      margin-left: calc(var(--spacing-2) * -1);
      margin-right: calc(var(--spacing-2) * -1);
    }
  }
  .button-icon-container {
    display: flex;
    padding: 0px;
  }
}
.inline-flex {
  display: inline-flex;
}

.filters {
  :deep(.switch) {
    align-self: flex-end;
  }
}
</style>
