import { getAllMutations, getAllActions } from './getAll';
import { deleteByIdMutations, deleteByIdActions } from './deleteById';
import { tCrm as t } from '@sales-i/utils';

const state = () => ({
  all: {
    data: [],
    fields: [],
    loaded: false,
    loading: false,
    error: undefined,
  },
  selected: {
    data: {},
    loaded: false,
    loading: false,
    updated: false,
    error: undefined,
  },
  sorting: [
    {
      text: t('customer_prospect_asc_des'),
      value: 'entity_name:asc',
    },
    {
      text: t('customer_prospect_des_asc'),
      value: 'entity_name:desc',
    },
    {
      text: t('value_asc_des'),
      value: 'value:asc',
    },
    {
      text: t('value_des_asc'),
      value: 'value:desc',
    },
    {
      text: t('decision_date_asc_des'),
      value: 'decision_date:asc',
    },
    {
      text: t('decision_date_des_asc'),
      value: 'decision_date:desc',
    },
    {
      text: t('probability_asc_des'),
      value: 'probability:asc',
    },
    {
      text: t('probability_des_asc'),
      value: 'probability:desc',
    },
  ],
});

// getters
const getters = {};

// mutations
const mutations = {
  ...getAllMutations,
  ...deleteByIdMutations,
};

// actions
const actions = {
  ...getAllActions,
  ...deleteByIdActions,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
