<template>
  <div class="date-range">
    <div class="description">
      <h3>{{ t('yearly_comparison') }}</h3>
      <div>
        <IconBase
          icon-name="calendar"
          :height="48"
          :width="48"
        />
      </div>
      <h4>{{ t('set_years') }}</h4>
    </div>
    <div class="yearly-comparison">
      <div class="year1-filter">
        <p class="year-text">
          {{ t('year') }} 1
        </p>
        <MenuFilter
          :items="options1"
          :selected-value="subtractedYears1"
          menu-position="right"
          @on-change="applyYear($event, 1)"
        />
      </div>
      <div class="year2-filter">
        <p class="year-text">
          {{ t('year') }} 2
        </p>
        <MenuFilter
          :items="options2"
          :selected-value="subtractedYears2"
          @on-change="applyYear($event, 2)"
        />
      </div>
      <div class="submit-container">
        <CustomButton
          class="submit"
          action="submit"
          label="Submit"
          @click="submitDate"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { MenuFilter, CustomButton, IconBase } from '@sales-i/dsv3';
import { tCrm as t } from '@sales-i/utils';
import useCalendar from '@/intelligence/composables/useCalendar';

const emit = defineEmits(['setActiveDate']);

const subtractedYears1 = ref(0);
const subtractedYears2 = ref(1);

const { fetchedCalendar } = useCalendar();

const options1 = computed(() => {
  return Array.from({ length: 10 }, (_, i) => ({
    text: getYearToDate(i),
    value: i
  }));
});

const options2 = computed(() => {
  return Array.from({ length: 10 }, (_, i) => ({
    text: getYearToDate(i + 1),
    value: i + 1
  }));
});

watch(subtractedYears1, (newValue) => {
  if (newValue >= subtractedYears2.value) subtractedYears2.value = newValue + 1;
});

watch(subtractedYears2, (newValue) => {
  if (newValue <= subtractedYears1.value) subtractedYears1.value = newValue - 1;
});

function getFormattedDateRangesObject() {
  const { start, end } = fetchedCalendar.value.current_fiscal_year;
  const yearFrom1 = Number(start.slice(0, 4)) - subtractedYears1.value;
  const yearTo1 = Number(end.slice(0, 4)) - subtractedYears1.value;
  const yearFrom2 = Number(start.slice(0, 4)) - subtractedYears2.value;
  const yearTo2 = Number(end.slice(0, 4)) - subtractedYears2.value;
  
  return {
    date_from: `${yearFrom1}${start.slice(4)}`,
    date_to: `${yearTo1}${end.slice(4)}`,
    date_from2: `${yearFrom2}${start.slice(4)}`,
    date_to2: `${yearTo2}${end.slice(4)}`
  };
}

function getYearToDate(subtractedYears = 0) {
  return new Date().getFullYear() - subtractedYears;
}

function applyYear(year, number) {
  if (number === 1) {
    subtractedYears1.value = year.value;
  } else {
    subtractedYears2.value = year.value;
  }
}

function submitDate() {
  emit('setActiveDate', getFormattedDateRangesObject());
}
</script>

<style lang="scss" scoped>
.yearly-comparison {
  display: flex;
  flex-wrap: wrap;
  .year1-filter,
  .year2-filter {
    flex-grow: 1;
  }
}
.submit-container {
  background: var(--colour-panel-action);
  padding: var(--spacing-2);
  margin-left: calc(-1 * var(--spacing-2));
  margin-right: calc(-1 * var(--spacing-2));
  flex-basis: 360px;
  margin-top: var(--spacing-2);
}
.submit {
  padding: var(--spacing-1) 0;
  width: 100%;
}
.date-range {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.description {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: var(--spacing-4) 0;
  h3,
  h4 {
    font-weight: var(--font-weight-semibold);
    flex-basis: 100%;
  }
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
      font-size: var(--font-size-3);
      font-weight: var(--font-weight-semibold);
      color: var(--colour-utility-black);
      margin-bottom: 0;
    }
  }
}
</style>
