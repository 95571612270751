<template>
  <div class="field mb-0">
    <MenuFilter
      v-if="showRangeType"
      class="mb-1"
      tabindex="0"
      :items="DATE_FILTER_OPTIONS"
      :menu-props="{ bottom: true }"
      menu-position="right"
      :selected-value="selectedRangeType"
      @on-change="handleModelUpdate('value', $event)"
    />
    <div class="date-range form-group">
      <label
        for="date_from"
        class="mb-0 mr-1"
      >
        <span>{{ t('date_from') }}</span>
        <div class="input-container mt-1">
          <input
            id="date_from"
            tabindex="0"
            :value="datesOnlyObj.date_from"
            :type="inputType"
            name="date_from"
            :max="datesOnlyObj.date_to || maxDateFormatted"
            @blur="handleModelUpdate('date_from', $event.target.value)"
          >
          <BufferImage
            v-if="dateLoading && inputType === 'text'"
            color="black"
            float="left"
            class="loading-spinner"
            :size="12"
          />
          <span
            v-else-if="fetchedDates?.date_from && inputType === 'text'"
            class="result"
          >
            {{ dates.format(fetchedDates?.date_from) }}
          </span>
        </div>
      </label>
      <label
        for="date_to"
        class="mb-0"
      >
        <span>{{ t('date_to') }}</span>
        <div class="input-container mt-1">
          <input
            id="date_to"
            tabindex="0"
            :value="datesOnlyObj.date_to"
            :type="inputType"
            name="date_to"
            :min="datesOnlyObj.date_from"
            :max="maxDateFormatted"
            @blur="handleModelUpdate('date_to', $event.target.value)"
          >
          <BufferImage
            v-if="dateLoading && inputType === 'text'"
            color="black"
            float="left"
            class="loading-spinner"
            :size="12"
          />
          <span
            v-else-if="fetchedDates?.date_to && inputType === 'text'"
            class="result"
          >
            {{ dates.format(fetchedDates?.date_to) }}
          </span>
        </div>
      </label>
    </div>
  </div>
</template>

<script setup>
import { dates, tCrm as t } from '@sales-i/utils';
import { CUSTOM_ABS_DATES_OPTION_VALUE, DATE_FILTER_OPTIONS, checkDateRegex, whichDateOption } from '@/intelligence/store/data/dateFilters';
import { computed, watch } from 'vue';
import { BufferImage, MenuFilter } from '@sales-i/dsv3';
import useCalendar from '@/intelligence/composables/useCalendar';
import { DateTime } from 'luxon';

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({
      date_from: 'today/y',
      date_to: 'today',
      value: 'today/y', // we need  to provide dropdown value in case of empty dates, it can be any of custom pickers
    }),
  },
  showRangeType: {
    type: Boolean,
    default: true
  },
});

const emit = defineEmits(['update:modelValue']);

const { fetchedDates, dateLoading, fetchDates } = useCalendar();

const maxDateFormatted = computed(() => DateTime.now().toISODate());
const datesOnlyObj = computed(() => ({ 
  date_from: props.modelValue.date_from?.split('T')[0], 
  date_to: props.modelValue.date_to?.split('T')[0] 
}));
const selectedRangeType = computed(() => props.modelValue.value || whichDateOption(props.modelValue));
const inputType = computed(() => selectedRangeType.value === CUSTOM_ABS_DATES_OPTION_VALUE ? 'date' : 'text');

watch(datesOnlyObj, 
  () => {
    // TODO: check for possible side effects due to it re-fetch the dates
    // save new dates if they have changed or clear
    if (checkDateRegex('text', datesOnlyObj.value.date_from) && 
      checkDateRegex('text', datesOnlyObj.value.date_to)) 
      fetchDates(datesOnlyObj.value);
  },
  { immediate: true }
);

function handleModelUpdate(fieldName, fieldValue) {
  // nothing changed on blur
  if (datesOnlyObj.value[fieldName] == fieldValue) return;
  // we do not emit incorrect relative dates, so on blur it will reset to previous value
  if (fieldName === 'value') {
    emit('update:modelValue', fieldValue);
  } else if (checkDateRegex(inputType.value, fieldValue)) {
    let date_from = fieldName === 'date_from' ? fieldValue : datesOnlyObj.value.date_from;
    let date_to = fieldName === 'date_to' ? fieldValue : datesOnlyObj.value.date_to;
    let value = whichDateOption({ date_from, date_to }).value || props.modelValue.value;
    emit('update:modelValue', { value, date_from, date_to });
  }
}
</script>

<style lang="scss" scoped>
.date-range {
  display: flex;
  flex-flow: row wrap;
}

input[type='text'],
input[type='date'] {
  padding: var(--spacing-1) var(--spacing-half) var(--spacing-1) var(--spacing-1);
  color: var(--colour-utility-black);
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-regular);
  letter-spacing: 0;
  max-width: 140px;
  height: 44px;

  &:disabled {
    background-color: var(--colour-panel-g-8);
    color: var(--colour-panel-g-48);
    cursor: not-allowed;
  }
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  top: 0;
  right: 0;
  width: 20px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.result {
  margin-top: var(--spacing-half);
  color: var(--colour-panel-g-32);
  font-weight: var(--font-weight-bold);
  display: block;
}

.loading-spinner {
  width: var(--spacing-2);
  height: var(--spacing-2);
  margin: var(--spacing-half) 0 0;
}
</style>
