<template>
  <CustomModal
    :title="file.file_name || t('not_found')"
    show-modal
    :max-width="600"
    class="view-file"
    position="center"
    @close-modal="closeModal"
  >
    <div class="container padded">
      <template v-if="file && file.id">
        <div class="row">
          <div class="mr-3">
            <small>{{ t('added') }}</small>
            <h6>{{ formatDate(file.created_date, 'long') }}</h6>
          </div>
          <div class="mr-3">
            <small>{{ t('added_by') }}</small>
            <h6>{{ file.created_by }}</h6>
          </div>
          <div class="mr-3">
            <small>{{ t('file_size') }}</small>
            <h6>{{ abbrBytes(file.file_size) }}</h6>
          </div>
          <div>
            <Tag
              :id="file.id"
              entity="file"
              class="ml-1"
            />
          </div>
        </div>

        <div class="row">
          <div class="col col-12 col-sm-12">
            <Field
              v-bind="fields.file_name"
              :value="file.file_name"
              :required="false"
              disabled
            />
          </div>
        </div>

        <div v-if="downloading">
          <BufferImage
            float="center"
            color="black"
          />
        </div>
      </template>
      <template v-else>
        {{ t('not_found') }}
      </template>
    </div>
    <template #footer>
      <div class="row">
        <CustomButton
          :disabled="!file.id"
          purpose="neutral"
          icon-name="download"
          :icon-height="iconHeight"
          :icon-width="iconWidth"
          :icon-color="iconColor"
          class="p-0 pr-1 pl-1 download"
          aria-label="download"
          @on-click="onDownloadClick"
        />
        <CustomButton
          v-if="canDelete"
          :disabled="!file.id"
          purpose="neutral"
          icon-name="trash"
          :icon-height="iconHeight"
          :icon-width="iconWidth"
          :icon-color="iconColor"
          class="p-0 pr-1 pl-1 delete"
          aria-label="delete"
          @click="onDeleteClick"
        />
      </div>
    </template>
  </CustomModal>
</template>

<script>
import Tag from '@/shared/components/Tags/Tag.vue';
import { CustomButton, CustomModal, BufferImage } from '@sales-i/dsv3';
import Field from '@/shared/components/Form/Field.vue';
import { mapState, mapActions } from 'vuex';
import {
  DOWNLOAD_BY_ID,
  // UPDATE_ENTITY_FILE,
} from '@/crm/store/actionType';
import { DELETE_BY_ID, CLEAR_BY_ID, SET_CONFIRMATION_MODAL, GET_BY_ID } from '@/shared/store/actionType';
import { abbr, dates, tCrm as t } from '@sales-i/utils';

export default {
  name: 'FileDetails',
  components: {
    CustomModal,
    CustomButton,
    Field,
    BufferImage,
    Tag,
  },
  props: {
    file: {
      type: Object,
      default: () => {},
      required: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['fileDeleted', 'closeModal'],
  data() {
    return {
      iconHeight: 32,
      iconWidth: 32,
      iconColor: '#7360A4',
    };
  },
  computed: {
    ...mapState({
      downloading: state => state.crm.files.selected.downloading,
    }),
    // valid(){
    //   return this.fileName &&
    //     this.file.id &&
    //     this.file.entity_type &&
    //     this.file.entity_id &&
    //     this.fileName != this.file.file_name;
    // },
    fields() {
      return {
        file_name: {
          id: 'alias',
          type: 'text',
          label: 'File name',
          placeholder: 'Please input file name',
          errorMessage: 'File name is required',
          required: true,
          twoway: true,
        },
      };
    },
  },
  methods: {
    t,
    formatDate: dates.format,
    abbrBytes: abbr.bytes,
    ...mapActions({
      clearFile: `crm/files/${CLEAR_BY_ID}`,
      getFile: `crm/files/${GET_BY_ID}`,
      // updateFile: `crm/files/${UPDATE_ENTITY_FILE}`,
      deleteFile: `crm/files/${DELETE_BY_ID}`,
      downloadFile: `crm/files/${DOWNLOAD_BY_ID}`,
      showConfirmationModal: `confirmationModal/${SET_CONFIRMATION_MODAL}`,
    }),
    closeModal() {
      this.$emit('closeModal');
    },
    // onInputFileName(value) {
    //   // console.log('onInputFile', value);
    //   this.fileName = value;
    // },
    // onUpdateClick() {
    //   if (!this.valid) return;
    //   this.updateFile({
    //     ...this.file,
    //     file_name: this.fileName,
    //   });
    // },
    onDeleteClick() {
      if (!this.canDelete) return;
      this.showConfirmationModal({
        message: t('do_you_really_want_to_delete_file_variable', { variable: this.file.file_name }),
        updatedMessage: t('you_have_deleted_file_variable', { variable: this.file.file_name }),
        updateFunction: async () => {
          try {
            const result = await this.deleteFile({ file: this.file });
            if (result) this.$emit('fileDeleted', this.file);
            return !!result;
          } catch (e) {
            console.error(e);
            return false;
          }
        },
        closeFunction: this.closeModal,
      });
    },
    onDownloadClick() {
      this.downloadFile({ file: this.file });
    },
  },
};
</script>

<style lang="scss" scoped>
.added,
.updated {
  padding: var(--spacing-2) var(--spacing-4);
}

.updated {
  background-color: var(--colour-panel-alert-base);
}

.type {
  padding: 0px var(--spacing-4);
}

form {
  padding: var(--spacing-3) var(--spacing-4);
  background-color: var(--colour-panel-g-2);
  .form {
    display: none;
    &.show {
      display: block;
    }
  }
}
</style>
