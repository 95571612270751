<template>
  <CustomCard
    purpose="reversed"
    footer-color="grey"
    class="interaction-card"
  >
    <header>
      <h2>{{ t('your_next_interaction') }}</h2>
      <p>{{ nextInteraction.name }} {{ nextInteractionTime }}</p>
    </header>
    <BufferImage
      v-if="!isChartEnabled"
      class="loading-spinner"
      color="var(--colour-utility-black)"
      float="center"
    />
    <LineBarChart
      v-if="isChartEnabled && !hasCurrentAnalysisDataEmpty"
      :report-id="reportId"
      class="mx-1"
    />
    <p 
      v-if="hasCurrentAnalysisDataEmpty && isChartEnabled"
      class="no-data"
    >
      {{ t('no_report_data_available_for_this_customer') }}
    </p>
    <div 
      v-if="isChartEnabled && showSlider"
      :class="{ 'bottom' : hasCurrentAnalysisDataEmpty }"
      class="interactions-slider"
    >
      <IconButton
        icon-name="arrow-left"
        icon-color="white"
        small
        :disabled="!isPrevActive"
        @on-click="handlePrev"
      />
      <IconButton
        icon-name="arrow-right"
        icon-color="white"
        small
        :disabled="!isNextActive"
        @on-click="handleNext"
      />
    </div>
    <template #footer>
      <ButtonGroup>
        <CustomButton
          small
          purpose="action"
          @click="goToSnapshotSummary"
        >
          {{ t('get_full_snapshot') }}
        </CustomButton>
      </ButtonGroup>
    </template>
  </CustomCard>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { BufferImage, CustomCard, CustomButton, IconButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import LineBarChart from '@/intelligence/components/SnapshotReport/Charts/LineBarChart';
import { dates, tCrm as t } from '@sales-i/utils';
import { SNAPSHOT_SUMMARY } from '@/intelligence/store/data/reportTypes';
import { navigateToUrl } from 'single-spa';

const props = defineProps({
  isChartEnabled: {
    type: Boolean,
    default: false,
  },
  nextInteraction: {
    type: Object,
    default: () => ({}),
  },
  reportId: {
    type: Number,
    default: null,
  },
  currentIndex: {
    type: Number,
    required: true
  },
  totalInteractions: {
    type: Number,
    required: true
  },
  hasCurrentAnalysisDataEmpty: {
    type: Boolean,
    required: true
  },
});

const emit = defineEmits(['navigate']);
const vrouter = useRouter();

const nextInteractionTime = computed(() => {
  if (!props.isChartEnabled) return '';
  const dateTime = new Date(props.nextInteraction.date);
  return `${dates.getStartHours(dateTime)}:${dates.getStartMinutes(dateTime)}`;
});

const isPrevActive = computed(() => props.currentIndex > 0);
const isNextActive = computed(() => props.currentIndex < props.totalInteractions - 1);
const showSlider = computed(() => props.totalInteractions > 1);

const goToSnapshotSummary = () => {
  const route = vrouter.resolve({ 
    name: SNAPSHOT_SUMMARY, 
    query: { customer: props.nextInteraction.name }
  });
  navigateToUrl(route.href);
};

const handlePrev = () => {
  if (isPrevActive.value) {
    emit('navigate', 'prev');
  }
};

const handleNext = () => {
  if (isNextActive.value) {
    emit('navigate', 'next');
  }
};
</script>

<style lang="scss" scoped>
.interaction-card {
  position: relative;
  &:deep(.c-card--body) {
    height: auto;
    min-height: 600px;
    overflow-y: auto;
  }
}

.loading-spinner {
  position: absolute;
  top: calc(50% - 50px);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}

.interactions-slider {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: var(--spacing-3);
}

.no-data {
  text-align: center;
  padding-top: 50%;
}

.bottom {
  padding-top: 50%;
}
</style>
