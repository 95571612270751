import TemplateReportView from '@/intelligence/views/TemplateReport/TemplateReportView.vue';
import CustomerActivityView from '@/intelligence/views/TemplateReport/CustomerActivityView.vue';
import { baseUrl, productsScope, customerScope, insightsScope } from '@/intelligence/router/urlBits';
import { REPORT_AREA_CUSTOMER, REPORT_AREA_PRODUCT } from '@/intelligence/store/data/areas';
import { ReportTypes } from '@sales-i/utils';

const { 
  CUSTOMER_ACTIVITY,
  PRODUCT_TEMPLATE_REPORT_TYPES,
  PRODUCT_GROUP_TEMPLATE_REPORT_TYPES,
  CUSTOMER_TEMPLATE_REPORT_TYPES,
} = ReportTypes;

const printProductsReports = () => [...PRODUCT_TEMPLATE_REPORT_TYPES, ...PRODUCT_GROUP_TEMPLATE_REPORT_TYPES].map(rep => ({
  name: rep,
  path: `${baseUrl}/${insightsScope}/${productsScope}/${rep}`,
  component: TemplateReportView,
  props: { reportArea: REPORT_AREA_PRODUCT }
}));
const printCustomerReports = () => CUSTOMER_TEMPLATE_REPORT_TYPES.map(rep => ({
  name: rep,
  path: `${baseUrl}/${insightsScope}/${customerScope}/${rep}`,
  component: TemplateReportView,
  props: { reportArea: REPORT_AREA_CUSTOMER }
}));

export default [
  ...printProductsReports(),
  ...printCustomerReports(),
  {
    name: CUSTOMER_ACTIVITY,
    path: `${baseUrl}/${insightsScope}/${customerScope}/${CUSTOMER_ACTIVITY}`,
    component: CustomerActivityView,
  },
];
