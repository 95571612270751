<template>
  <CustomModal
    title-as-text
    title="Your comment"
    show-modal
    :show-unload-warning="isFormDirty"
    :max-width="600"
    @close-modal="closeModal"
  >
    <Form
      v-if="fields.length > 0"
      class="minus-padding-top"
      :formdata="fields"
      :actions="actions"
      :saved="saved"
      :col-span="12"
      @show-unload-warning="handleIsFormDirty"
    >
      <template #savedContent>
        <div
          v-if="saved"
          :class="{
            complete: true,
          }"
        >
          <h2>{{ t('saved') }}</h2>
        </div>
      </template>
    </Form>
    <BufferImage
      v-else
      color="var(--colour-utility-black)"
      float="center"
    />
  </CustomModal>
</template>

<script>
import { BufferImage, CustomModal } from '@sales-i/dsv3';
import Form from '@/shared/components/Form/Form.vue';
import { tCrm as t } from '@sales-i/utils';

import { mapActions, mapState } from 'vuex';
import store from '@/shared/store';
import { SET_ITEMS, GET_FIELDS, CLEAR_FIELDS, CLEAR_BY_ID, POST_BY_ENTITY } from '@/shared/store/actionType';
import { baseUrl } from '@/crm/router/urlBits';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'AddCommentView',
  components: {
    BufferImage,
    CustomModal,
    Form,
  },
  props: {
    postTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      saved: false,
      isFormDirty: false,
    };
  },
  computed: {
    ...mapState({
      entity: state => state.system.entity,
      fields: state => state.comments.fields,
    }),
    actions() {
      return [
        {
          label: t('add_comment'),
          small: true,
          onClick: this.save,
          display: !this.saved,
          isSubmit: true,
        },
      ];
    },
    getCommentURL() {
      return `${baseUrl}/${this.entity}/${this.$route.params.id}/posts/${this.$route.params.post_id}`;
    },
  },
  mounted() {
    this.clearById();
    this.getFields();
  },
  unmounted() {
    this.clearFields();
  },
  methods: {
    t,
    ...mapActions({
      getFields: `comments/${GET_FIELDS}`,
      clearFields: `comments/${CLEAR_FIELDS}`,
      clearById: `comments/${CLEAR_BY_ID}`,
      addComment: `comments/${POST_BY_ENTITY}`,
    }),
    async save(payload, setSubmitting) {
      setSubmitting(true);
      let commentAndPath = {
        ...payload,
        path: this.getCommentURL,
      };
      try {
        await this.addComment({
          id: this.$route.params.post_id,
          payload: commentAndPath,
        });
        store.dispatch(`alerts/${SET_ITEMS}`, {
          type: 'success',
          message: t('comment_added_to_title', { title: this.postTitle }),
          selfDismiss: true,
        });
        setSubmitting(false);
        this.closeModal();
      } catch (e) {
        console.error(e);
        setSubmitting(false);
      }
    },
    closeModal() {
      let { id, post_id } = this.$route.params;
      navigateToUrl(`${baseUrl}/${this.entity}/${id}/posts/${post_id}`);
      this.isFormDirty = false;
    },
    handleIsFormDirty(value) {
      this.isFormDirty = value;
    }
  },
};
</script>

<style lang="scss">
.minus-padding-top .form-body {
  padding-top: 0;
}
</style>
