<template>
  <AddEditContact
    :title="t('edit_a_contact')"
    :entity-id="entityId"
    :saved="saved"
    :fields="fields"
    @submit="save"
    @edit="edit"
    @close="close"
  />
</template>

<script>
import AddEditContact from '@/crm/components/Contacts/AddEditContact.vue';
import { mapState, mapActions } from 'vuex';
import { GET_FIELDS, CLEAR_FIELDS, GET_BY_ID, PUT_BY_ID, PUSH_ROUTE } from '@/shared/store/actionType';
import { baseUrl, contactsSection } from '@/crm/router/urlBits';
import { tCrm as t } from '@sales-i/utils';

export default {
  name: 'EditContactView',
  components: {
    AddEditContact,
  },
  props: {},
  emits: ['closeModal', 'updated'],
  data() {
    return {
      entityId: this.$route.params.id,
      saved: false,
    };
  },
  computed: {
    ...mapState({
      contact: state => state.crm.contacts.selected,
      customer: state => state.crm.customers.selected.data,
      entity: state => state.system.entity,
      fields: state => state.crm.contacts.fields,
      prospect: state => state.crm.prospects.selected.data,
    }),
  },
  mounted() {
    if (this.contact.data.id) {
      let { contact_id } = this.$route.params;
      this.getContactById({ id: contact_id });
    }
    this.getFields();
  },
  methods: {
    t,
    ...mapActions({
      getContactById: `crm/contacts/${GET_BY_ID}`,
      putContactById: `crm/contacts/${PUT_BY_ID}`,
      getContactFields: `crm/contacts/${GET_FIELDS}`,
      clearContactFields: `crm/contacts/${CLEAR_FIELDS}`,
      pushRoute: `system/${PUSH_ROUTE}`,
    }),
    getFields() {
      if ((this.customer.name || this.prospect.name) && this.contact.data.id) {
        let { id } = this.$route.params;
        this.getContactFields({ entityId: id, setDefault: false });
      } else {
        setTimeout(() => {
          this.getFields();
        }, 100);
      }
    },
    async save(payload, setSubmitting) {
      setSubmitting(true);
      let { id: entity_id, contact_id } = this.$route.params;
      let { id } = this.contact.data;
      await this.putContactById({
        contact_id,
        payload: { id, ...payload },
        entity: this.entity,
        entity_id,
      });
      setSubmitting(false);
      if (this.contact.loaded) {
        this.saved = true;
        this.$emit('updated');
      }
    },
    close() {
      let { id, contact_id } = this.$route.params;
      this.clearContactFields();
      this.pushRoute({
        defaultUrl: `${baseUrl}/${this.entity}/${id}/${contactsSection}/${contact_id}`,
        success: true,
      });
      // ViewContact takes care of clearing selected contact
      // navigateToUrl(`/crm/${this.entity}/${id}/contacts/${contact_id}`);
    },
    edit() {
      this.saved = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
