import SnapshotByAnalysisFieldView from '@/intelligence/views/SnapshotByAnalysisField/SnapshotByAnalysisFieldView.vue';
import { baseUrl, insightsScope, performanceScope, snapshotByAnalysisField } from '@/intelligence/router/urlBits';
import SnapshotReport from '@/intelligence/components/SnapshotReport/SnapshotReport.vue';

export default [
  {
    path: `${baseUrl}/${insightsScope}/${performanceScope}/${snapshotByAnalysisField}`,
    component: SnapshotByAnalysisFieldView,
    name: 'SnapshotByAnalysisField',
    children: [{
      path: '',
      component: SnapshotReport
    }]
  }
];
