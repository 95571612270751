<template>
  <div class="entity-search-wrapper">
    <InlineTabs
      class="wrapper"
      :items="navigationTabs"
      :selected-index="selectedTabIndex"
      :selected-group-name="selectedGroupName"
      :entity-type="entityType"
      @nav-clicked="navClicked"
    />
    <div class="entity-search wrapper">
      <form
        class="flow"
        autocomplete="off"
      >
        <div class="three-col-equal-height">
          <!-- Customer Search -->
          <div
            v-if="canAccessCustomers && selectedGroupName === 'customer'"
            class="flow"
          >
            <div class="entity-search__header">
              <IconBase
                icon-name="organisation"
                height="32"
                width="32"
              />
              <label
                for="interaction_customer_search"
                class="entity-search__title"
              >
                {{ t('customer') }}
              </label>
            </div>
            <CustomerSearch
              v-if="!selected.header"
              id="interaction_customer_search"
              class="filtersearch"
              label=""
              @input="onSearchInput($event, 'customer')"
            />

            <CRMCard
              v-if="selected.header"
              class="entity-crm-card"
              :title="selected.header"
              :links="getCardLinks()"
              @change="changeItem('customer')"
            >
              <template #info>
                <span>{{ selected.subHeader }}</span>
              </template>
            </CRMCard>
          </div>


          <!-- Prospect search -->
          <div
            v-if="selectedGroupName === 'prospect'"
            class="flow"
          >
            <div class="entity-search__header">
              <IconBase
                icon-name="prospect"
                height="32"
                width="32"
              />
              <label
                for="interaction_prospect_search"
                class="entity-search__title"
              >
                {{ t('prospect') }}
              </label>
            </div>
            <ProspectSearch
              v-if="!selected.header && !entityType"
              id="interaction_prospect_search"
              class="filtersearch"
              label=""
              @input="onSearchInput($event, 'prospect')"
            />
            <CRMCard
              v-if="selected.header"
              :currency="currency"
              class="entity-crm-card"
              :title="selected.header"
              :chips="getChips(selected, 'prospect')"
              :links="getCardLinks()"
              @change="changeItem('prospect')"
            >
              <template #info>
                <span>{{ selected.subHeader }}</span>
              </template>
            </CRMCard>
          </div>

          <!-- Contact search -->
          <div
            v-if="canAccessContacts"
            class="flow"
          >
            <div class="entity-search__header">
              <IconBase
                icon-name="user-circle"
                height="32"
                width="32"
              />
              <label
                for="interaction_contact_search"
                class="entity-search__title"
              >
                {{ t('contact') }}
              </label>
            </div>
            <ContactSearch
              v-if="!selectedContact.header"
              id="interaction_contact_search"
              class="filtersearch"
              label=""
              :disabled="!entityType"
              :entity="currentlyEditing.entityType"
              :entity-id="currentlyEditing.entity.id"
              @input="onSearchInput($event, 'contact')"
            />
            <CRMCard
              v-if="selectedContact.header"
              :currency="currency"
              class="entity-crm-card"
              :title="selectedContact.header"
              :chips="getChips(selectedContact, 'contact')"
              :links="getCardLinks('contact')"
              @change="changeItem('contact')"
            >
              <template #info>
                <span>{{ selectedContact.subHeader }}</span>
              </template>
              <template #tags>
                <div
                  v-if="selectedContact.is_default_contact"
                  class="default-ribbon"
                >
                  {{ t('default_contact') }}
                </div>
              </template>
            </CRMCard>
          </div>      
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { CRMCard, IconBase } from '@sales-i/dsv3';
import InlineTabs from '@/shared/components/Tabs/InlineTabs.vue';
import CustomerSearch from '@/shared/components/Search/CustomerSearch.vue';
import ContactSearch from '@/shared/components/Search/ContactSearch.vue';
import ProspectSearch from '@/shared/components/Search/ProspectSearch.vue';
import usePermissions from '@/shared/composables/usePermissions';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits';
import { contacts, customers, prospects } from '@/shared/store/data/policies';
import { mapState, mapActions } from 'vuex';
import { PUT_BY_ID, SET_ENTITY, CLEAR_ENTITY, GET_BY_ID, CLEAR_BY_ID } from '@/shared/store/actionType';
import { tCrm as t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'AddInteractionEntity',
  components: {
    CRMCard,
    IconBase,
    InlineTabs,
    CustomerSearch,
    ContactSearch,
    ProspectSearch,
  },
  props: {
    interactionId: {
      type: String,
      default: '',
    },
    isEditInteraction: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stageUpdate', 'valid'],
  data() {
    return {
      selectedTabIndex: 0,
      selectedGroupName: 'customer',
    };
  },
  computed: {
    ...mapState({
      currency: state => state.userDetails.currencyName,
      customer: state => state.crm.customers.selected.data,
      prospect: state => state.crm.prospects.selected.data,
      contact: state => state.crm.contacts.selected.data,
      currentlyEditing: state => state.crm.interactions.currentlyEditing,
    }),
    hasAccess() {
      const { hasAccess } = usePermissions();
      return hasAccess;
    },
    canAccessCustomers() {
      return this.hasAccess(customers, 'read');
    },
    canAccessContacts() {
      return this.hasAccess(contacts, 'read');
    },
    selected() {
      return this.currentlyEditing.entity;
    },
    selectedContact() {
      return this.currentlyEditing.contact;
    },
    entityType() {
      return this.currentlyEditing.entityType;
    },
    canAccessProspects() {
      return this.hasAccess(prospects, 'read');
    },
    isAimlOpportunity() {
      const { opportunities } = this.currentlyEditing;
      return opportunities.length ? opportunities.findIndex(o => ['missed', 'potential', 'missedgrp', 'potentialgrp'].indexOf(o.type.toLowerCase())) !== -1 : false;
    },
    navigationTabs() {
      let arr = [];
      if(this.canAccessCustomers) {
        arr.push({
          title: t('customer'),
          value: 'customer',
        });
      }
      if(this.canAccessProspects) {
        arr.push({
          title: t('prospect'),
          value: 'prospect',
        });
      }
      return arr;
    },
    valid() {
      return !!this.entityType;
    },
  },
  watch: {
    valid() {
      // Emit the 'valid' event when the value changes
      this.$emit('valid', this.valid);
    },
  },  
  async mounted() {
    this.$emit('stageUpdate', 'Customer & Contact');
    // Only add the default contact if there is no existing contact selected
    if (!this.selectedContact.header && this.currentlyEditing.entity.default_contact_id) {
      const selectedContact = await this.getDefaultContact(this.currentlyEditing.entity.default_contact_id);
      this.setEntity({
        contact: selectedContact,
      });
    }
    this.$emit('valid', this.valid);  
    
    // Show the correct tab based on the entity type
    if (this.entityType) {
      this.selectedTabIndex = this.navigationTabs.findIndex(tab => tab.value === this.entityType);
      this.selectedGroupName = this.entityType;
    }
  },
  methods: {
    t,
    ...mapActions({
      getCustomer: `crm/customers/${GET_BY_ID}`,
      clearCustomer: `crm/customers/${CLEAR_BY_ID}`,
      getContact: `crm/contacts/${GET_BY_ID}`,
      clearContact: `crm/contacts/${CLEAR_BY_ID}`,
      getProspect: `crm/prospects/${GET_BY_ID}`,
      clearProspect: `crm/prospects/${CLEAR_BY_ID}`,
      setEntity: `crm/interactions/${SET_ENTITY}`,
      clearEntity: `crm/interactions/${CLEAR_ENTITY}`,
      putInteraction: `crm/interactions/${PUT_BY_ID}`,
    }),
    async getDefaultContact(default_contact_id) {
      await this.getContact({
        id: default_contact_id,
      });
      return {
        ...this.contact,
        header: this.contact.full_name,
        subHeader: this.contact.relationship.name,
      };
    },
    async onSearchInput(data, type) {
      if (!data) return;
      let setContact = false;
      let selectedContact = {};
      if (type !== 'contact') {
        this.setEntity({
          entityType: type,
          entity: data,
        });
        if (data.default_contact_id) {
          selectedContact = await this.getDefaultContact(data.default_contact_id);
          setContact = true;
        }
      } else {
        setContact = true;
        selectedContact = data;
        if (!this.selected.header) {
          let { id, record_type } = selectedContact.relationship;
          if (record_type === 'customer') {
            await this.getCustomer({
              id,
            });
            this.setEntity({
              entityType: record_type,
              entity: {
                ...this.customer,
                header: this.customer.name,
                subHeader: this.customer.account_number,
              },
            });
          } else {
            await this.getProspect({
              id,
            });
            this.setEntity({
              entityType: record_type,
              entity: {
                ...this.prospect,
                header: this.prospect.name,
                subHeader: this.prospect.account_number,
              },
            });
          }
        }
      }
      if (setContact) {
        this.setEntity({
          contact: selectedContact,
        });
      }
    },
    getChips() {
      // let value = 0;
      // if (type === 'contact') {
      //   value = item.percent_complete;
      // }
      // return [{
      //   value: value,
      //   suffix: '%',
      //   green: 67,
      //   amber: 33,
      // }];
      return [];
    },
    getCardLinks(type) {
      // Hide the 'change' link for customers and prospects when in edit mode. The contact will still be editable.
      if (!this.isEditInteraction || type === 'contact') {
        return [
          {
            text: t('change'),
            emit: 'change',
          },
        ];
      }
    },
    changeItem(type) {
      if (type !== 'contact') {
        if (this.customer.id) {
          this.clearCustomer();
        }
        if (this.prospect.id) {
          this.clearProspect();
        }
        this.clearEntity({
          all: true,
        });
      }
      this.clearEntity({
        contact: true,
      });
      if (this.contact.id) {
        this.clearContact();
      }
    },
    async saveAndClose() {
      await this.putInteraction({ id: this.$route.params.id });
      navigateToUrl(`${baseUrl}/${interactionsArea}/`);
    },
    nextStep() {
      if (this.isAimlOpportunity) {
        return navigateToUrl(`${baseUrl}/${interactionsArea}/add/update-opportunity`);
      }
      if (this.selected.header && !this.interactionId) {
        return navigateToUrl(`${baseUrl}/${interactionsArea}/add/opportunity`);
      }
      if (this.selected.header && this.interactionId) {
        return navigateToUrl(`${baseUrl}/${interactionsArea}/${this.interactionId}/edit/opportunity`);
      }
    },
    navClicked(data) {
      const { index, item } = data;
      this.selectedTabIndex = index;
      this.selectedGroupName = item.value;

      // When the user selects a different tab, clear the selected entity and contact
      this.clearEntity({
        all: true,
      });
    }

  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.wrapper {
  padding-inline: clamp(var(--spacing-2), 2.5vw, var(--spacing-4));
}

.entity-search {
  background-color: var(--colour-panel-alert-base);
  margin-top: 0;
  padding-block: var(--spacing-2);
}
  .entity-search__header {
    align-items: center;
    display: flex;
    gap: var(--spacing-1);
  }

  .entity-search__title {
    color: var(--colour-utility-black);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-3);
    line-height: 1;
    margin-bottom: 0;
  }
.form-grid {
  padding: var(--spacing-2) var(--spacing-1);
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: var(--spacing-4) var(--spacing-5);
  }
}
// deep allows us to target a scoped element in order to apply an override to just this instance of the component styles. Ultimately this may be better as a component with a gap prop
:deep(.three-col-equal-height) {
  --gap-space: var(--spacing-3);
}

:deep(.search .input input) {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
}
.recent-list {
  --flow-space: var(--spacing-half);

  li {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
    padding: var(--spacing-half) 0;
  }
}
.entity-crm-card {
  height: 100%;
  position: relative;
  .default-ribbon {
    position: absolute;
    left: 0;
    background-color: var(--colour-panel-alert-base);
    padding: var(--spacing-half) var(--spacing-1) var(--spacing-half) var(--spacing-2);
    border-top-right-radius: var(--border-radius-half);
    border-bottom-right-radius: var(--border-radius-half);
  }
}

</style>
