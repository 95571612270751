<template>
  <CustomModal
    v-if="formData.length > 0 && props.permissions.entity.read && props.permissions.schema.read"
    show-modal
    :title="formData[0].stage"
    :loading="loading"
    @close-modal="closeModal"
  >
    <Form
      v-if="mappedFormData.length > 0"
      :formdata="mappedFormData"
      :actions="actions"
      :saved="saved"
      :col-span="12"
    >
      <template #savedContent>
        <div
          v-if="saved"
          class="complete"
        >
          <h2>{{ t('saved') }}</h2>
        </div>
      </template>
    </Form>
  </CustomModal>
</template>

<script setup>
// CustomSection
import { tCrm as t } from '@sales-i/utils';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { baseUrl, customSectionsSection } from '@/crm/router/urlBits';
import { CustomModal } from '@sales-i/dsv3';
import Form from '@/shared/components/Form/Form.vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { GET_CUSTOM_SECTION, PUT_BY_ID, CLEAR_CUSTOM_SECTION } from '@/shared/store/actionType';
import { mapCustomSectionToForm } from '@/shared/utils/customSchema';
import { navigateToUrl } from 'single-spa';

const props = defineProps({
  permissions: {
    type: Object,
    default: () => ({}),
  },
  storeArea: {
    type: String,
    default: 'crm',
  },
  sectionsTitles: {
    type: Array,
    default: () => [],
  },
});

onMounted(async () => {
  savedEntity.value = entity.value;
  await getCustomSection({
    entity: entity.value,
    id: entity.value === 'contacts' ? vroute.query.contactId : vroute.params.id,
    section_id: vroute.params.sectionId,
  });
});

onUnmounted(() => {
  clearCustomSection(savedEntity.value);
});

const store = useStore();
const vroute = useRoute();

const saved = ref(false);
const savedEntity = ref('');

const entity = computed(() => vroute.query.entity === 'contacts' ? 'contacts' : vroute.path.split('/')[2]);
const sectionValues = computed(() => store.state.crm[entity.value].customSection.data);
const sectionSchema = computed(() => props.sectionsTitles.find(st => st.id == vroute.params.sectionId) || {});
const formData = computed(() => [mapCustomSectionToForm(sectionSchema.value, sectionValues.value)]);
const loading = computed(() => store.state.crm[entity.value].customSection.loading);
const mappedFormData = computed(() => {
  const values = sectionValues.value.values;
  let mappedForm = [];
  if (formData?.value[0].fields?.length && values?.length) {
    mappedForm = [
      ...formData.value,
    ];

    // this map is to set the names of the options so that they display correctly
    mappedForm.map(s => s.fields.map(f => {
      if (f.options) {
        f.options = f.options.map(v => {
          let value = v.value;          
          const listOptionId = getListOptionIdForSection(f.id, v.value);
          
          if (listOptionId !== null) {
            value = listOptionId;
          }

          return {
            text: v.value,
            value: value,
          };
        });
      }

      const valueField = values.find(v => v.field_id === parseInt(f.id.split('-')[2], 10));

      if (valueField !== undefined && 'list_option_id' in valueField && valueField.list_option_id !== 0) {
        f.value = valueField.list_option_id;
      } else if (valueField !== undefined && 'value' in valueField) {
        f.value = valueField.value;
      }
      return f;
    }));
  }
  return mappedForm;
});

const actions = computed(() => [
  {
    label: t('save'),
    small: true,
    onClick: submitCustomSection,
    disabled: saved.value || !(props.permissions.entity.canUpdateCustomSchema ?? true),
    isSubmit: true,
  },
]);

const getListOptionIdForSection = (fieldKey, value) => {
  const realFieldKey = fieldKey.split('-').slice(1, 3).join('-');
  const field = Object.values(sectionSchema.value?.fields || {}).find(f => f.id === realFieldKey);
  return field?.list_options?.find(o => o.value === value)?.id || null;
};

const getListOptionIdForSelection = (fieldKey, value) => {
  const realFieldKey = fieldKey.split('-').slice(1, 3).join('-');
  const field = Object.values(sectionSchema.value?.fields || {}).find(f => f.id === realFieldKey);
  return field?.list_options?.find(o => o.id === value)?.id || null;
};

const submitCustomSection = async(params, setSubmitting) => {
  setSubmitting(true);
  const requestBody = Object.keys(params).map(key => {
    // Extract the numeric part of the key for field_id
    const fieldId = parseInt(key.split('-')[2], 10);
    const listOptionId = getListOptionIdForSelection(key, parseInt(params[key], 10));
    let parameters = {};
    parameters['field_id'] = fieldId;
    
    if (listOptionId !== null) {
      parameters['list_option_id'] = listOptionId;
    } else if (params[key] !== null && params[key] !== '') {
      parameters['value'] = params[key];
    }

    return parameters;
  }).filter(p => p.value || p.list_option_id);

  await updateCustomSection({
    id: entity.value === 'contacts' ? vroute.query.contactId : vroute.params.id,
    section_id: vroute.params.sectionId,
    entity: entity.value,
    requestBody
  });
  saved.value = true;
  setSubmitting(false);
};

const getCustomSection = params => store.dispatch(`${props.storeArea || 'crm'}/${entity.value}/${GET_CUSTOM_SECTION}`, params);
const clearCustomSection = (entityValue) => store.dispatch(`${props.storeArea || 'crm'}/${entityValue}/${CLEAR_CUSTOM_SECTION}`);
const updateCustomSection = params => store.dispatch(`customSections/${PUT_BY_ID}`, params);

function closeModal() {
  const base = entity.value === 'contacts' ? `${baseUrl}/${vroute.path.split('/')[2]}/${vroute.params.id}` : `${baseUrl}/${entity.value}/${vroute.params.id}`;
  navigateToUrl(`${base}/${customSectionsSection}`);
}
</script>

<style lang="scss" scoped></style>
