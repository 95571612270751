<template>
  <CustomModal
    v-if="!id ? permissions.create : permissions.update"
    show-modal
    :show-unload-warning="isFormDirty"
    :max-width="800"
    :title="!id ? t('add_opportunity') : t('edit_opportunity')"
    :loading="!isMounted"
    class="editopportunity"
    @close-modal="close"
  >
    <EditOpportunityForm
      v-if="stageOptions.length && statusOptions.length && isMounted"
      :value="item"
      :stage-options="stageOptions"
      :business-area-options="businessAreaOptions"
      :status-options="statusOptions"
      :allocated-to-options="usersOptions"
      :contact-options="contactOptions"
      :is-open="isOpen"
      :entity-preselected="entityPreselected"
      @input="onFormInput"
      @valid="isValid"
      @entity-change="onEntityChange"
    />

    <template #footer>
      <ButtonGroup>
        <CustomButton
          v-if="!loading"
          :label="t('save')"
          purpose="action"
          small
          :disabled="!isOpen"
          :class="`save ${valid ? '' : 'disabled'}`"
          @on-click="save"
        />
      </ButtonGroup>
    </template>
  </CustomModal>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import EditOpportunityForm from '@/crm/components/Opportunity/EditOpportunityForm.vue';
import { CustomModal, CustomButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { isEqualObjects, tCrm as t } from '@sales-i/utils';

import { GET_ALL, GET_BY_ID, UPDATE_SELECTED, SET_SELECTED, CLEAR_BY_ID } from '@/shared/store/actionType';
import { GET_CONTACTS, CLEAR_CONTACTS } from '@/crm/store/actionType';
import { GET_CUSTOM_SCHEMA } from '@/admin/store/actionType';

import usePermissions from '@/shared/composables/usePermissions';
import { opportunities } from '@/shared/store/data/policies';
import { useUsers } from '@/shared/composables/useUsers';
import { useOppStages } from '@/crm/composables/useOppStages';

const store = useStore();

const { usersOptions } = useUsers(store);

const props = defineProps({
  id: {
    type: [String, Number],
    default: '',
  },
  entityId: {
    type: Number,
    default: 0,
  },
  entityType: {
    type: String,
    default: 'customer',
  },
  entityName: {
    type: String,
    default: undefined,
  },
  status: {
    type: String,
    default: 'open',
  },
  entityPreselected: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['closeModal', 'valid', 'close', 'saved', 'entityChange']);

const { getPermissions } = usePermissions();
const { fetchStages } = useOppStages();

const isvalid = ref(false);
const isOpen = ref(true);
const isMounted = ref(false);
const initialFormValues = ref({});

const loading = computed(() => !!store.state.crm.opportunities.selected.loading);

const isItemInStoreValid = computed(() => store.getters['crm/opportunities/isValid']);
const businessAreaOptions = computed(() => store.getters['crm/businessAreas/businessAreaOptions']);
const customersContactOptions = computed(() => store.getters['crm/customers/contactOptions']);
const prospectsContactOptions = computed(() => store.getters['crm/prospects/contactOptions']);
const stageOptions = computed(() => store.getters['crm/opportunities/stageOptions']);
const itemInStore = computed(() => store.getters['crm/opportunities/item'] || {});

const item = computed({
  // getter
  get() {
    return itemInStore.value;
  },
  // setter
  async set(data) {
    await setItem(data);
  }
});
const isFormDirty = computed(() => !isEqualObjects(initialFormValues.value, item.value));
const valid = computed(() => isvalid.value && isItemInStoreValid.value);
const permissions = computed(() => getPermissions(opportunities));
const statusOptions = computed(() => [
  {
    text: t('open'),
    value: 'open',
  },
  {
    text: t('lost'),
    value: 'lost',
  },
  {
    text: t('won'),
    value: 'won',
  },
]);

const contactOptions = computed(() => item.value.entity_type === 'customer' ? customersContactOptions.value : prospectsContactOptions.value);

onMounted(async() => {
  await getOppsSchema({
    entity: 'opportunities',
    refreshCall: true
  });
  await fetchStages();
  await fetchBusinessAreas();
  await clearItem();
  if (props.id) {
    await fetchItem({ id: props.id });
    isOpen.value = item.value.status === 'open';
  }
  await setDefaults();
  await clearCustomerContacts();
  await clearProspectContacts();
  await fetchContactOptions(item);
  isMounted.value = true;
  initialFormValues.value = { ...item.value };
});

const setItem = params => store.dispatch(`crm/opportunities/${SET_SELECTED}`, params);
const clearItem = params => store.dispatch(`crm/opportunities/${CLEAR_BY_ID}`, params);
const updateItem = params => store.dispatch(`crm/opportunities/${UPDATE_SELECTED}`, params);
const fetchItem = params => store.dispatch(`crm/opportunities/${GET_BY_ID}`, params);
const fetchBusinessAreas = params => store.dispatch(`crm/businessAreas/${GET_ALL}`, params);
const fetchCustomerContacts = params => store.dispatch(`crm/customers/${GET_CONTACTS}`, params);
const fetchProspectContacts = params => store.dispatch(`crm/prospects/${GET_CONTACTS}`, params);
const getOppsSchema = params => store.dispatch(`admin/schema/${GET_CUSTOM_SCHEMA}`, params);
const clearCustomerContacts = params => store.dispatch(`crm/customers/${CLEAR_CONTACTS}`, params);
const clearProspectContacts = params => store.dispatch(`crm/prospects/${CLEAR_CONTACTS}`, params);

const setDefaults = () => {
  item.value = {
    id: undefined,
    entity_id: props.entityId || undefined,
    entity_name: props.entityName || undefined,
    entity_type: props.entityType || 'customer',
    stage_id: stageOptions.value.length ? stageOptions.value[0].value : undefined,
    ...itemInStore.value,
  };
};

const fetchContactOptions = async(item) => {
  if (!item.entity_id) return;
  if (item.entity_type === 'customer') {
    await fetchCustomerContacts({ id: item.entity_id });
  } else {
    await fetchProspectContacts({ id: item.entity_id });
  }
};

const onEntityChange = async(changedItem) => {
  emit('entityChange', changedItem);
  await clearCustomerContacts();
  await clearProspectContacts();
  await fetchContactOptions(changedItem);
  let contact_id = contactOptions.value.length ? contactOptions.value[0].value : undefined;
  // assigning new values and new contact_id
  item.value = {
    ...changedItem,
    ...{ contact_id: changedItem.contact_id || contact_id },
  };
};

const onFormInput = async(newItem) => {
  item.value = newItem;
};

const isValid = isFormValid => {
  isvalid.value = !!isFormValid;
  emit('valid', valid);
};

const close = () => {
  emit('close');
};

const save = async() => {
  if (valid.value) {
    const success = await updateItem();

    if (success) {
      emit('saved', item.value.id);
    }
  } else {
    // this.showInvalidFields(); can't get working atm ¯\_(ツ)_/¯
  }
};
</script>

<style lang="scss" scoped>
.modal-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  min-width: 300px;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1) center center no-repeat;
  display: flex;
}
</style>
