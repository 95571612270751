import { consolidateTagsMutations, consolidateTagsActions,  } from './putConsolidateTags';
import { getReportMutations, getReportActions } from './getReport';

// initial state
const state = () => ({
  menu: {
    data: [],
    loaded: false,
    loading: false,
    errors: undefined,
  },
  report: {
    data: [],
    loaded: false,
    loading: false,
  },
  consolidateTags: {
    original_tag: [],
    to_tag: [],
    loaded: false,
    loading: false,
  },
  putConsolidateTags: {
    data: [],
    loaded: false,
    loading: false,
  }
});

// getters
const getters = {
};

// mutations
const mutations = {
  ...consolidateTagsMutations,
  ...getReportMutations,
};

// actions
const actions = {
  ...consolidateTagsActions,
  ...getReportActions,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
