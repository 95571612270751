<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <CustomModal
      show-modal
      :title="t(title)"
      :max-width="500"
      @close-modal="closeModal"
    >
      <div v-if="showConfirmation">
        <div class="confirm-message-wrapper text-centre">
          <IconBase
            :icon-color="iconColor"
            :icon-name="iconName"
            :width="80"
            :height="80"
          />

          <h1 class="fw-sbld text-2">
            {{ t(heading) }}
          </h1>
          <p
            v-if="message"
            class="message-text"
            v-html="t(message)"
          />
          <slot />
        </div>
      </div>

      <div v-if="updated">
        <div class="confirm-message-wrapper text-centre">
          <IconBase
            icon-name="check"
            :width="80"
            :height="80"
            icon-color="var(--colour-data-de-york)"
          />

          <h1 class="fw-sbld text-2">
            {{ t(updatedHeading) }}
          </h1>
          <p
            class="message-text"
            v-html="t(updatedMessage)"
          />
        </div>
      </div>

      <div v-if="error">
        <div class="confirm-message-wrapper text-centre">
          <h1 class="fw-sbld text-2">
            {{ t(errorHeading) }}
          </h1>
          <p
            class="message-text"
            v-html="t(errorMessage)"
          />
        </div>
      </div>

      <template #footer>
        <div
          v-if="loading"
          class="buttons-container"
        >
          <BufferImage :size="40" />
        </div>

        <ButtonGroup v-if="!loading && showConfirmation">
          <CustomButton
            v-if="cancelLabel !== ''"
            purpose="reversed"
            :label="t(cancelLabel)"
            small
            class="cancel"
            @click="doCancel"
          />
          <CustomButton
            v-bind="saveButtonOptions"
            :label="t(saveLabel)"
            small
            class="confirm"
            @click="doUpdate"
          />
        </ButtonGroup>

        <ButtonGroup v-if="!loading && updated">
          <CustomButton
            :label="t(updatedLabel)"
            small
            class="ok"
            @click="finishUpdate"
          />
        </ButtonGroup>

        <ButtonGroup v-if="!loading && error">
          <CustomButton
            purpose="reversed"
            :label="t(errorSaveLabel)"
            small
            class="cancel"
            @click="doUpdate"
          />
          <CustomButton
            :label="t(errorCancelLabel)"
            small
            class="confirm"
            @click="closeModal"
          />
        </ButtonGroup>
      </template>
    </CustomModal>
  </div>
</template>

<script>
import { BufferImage, CustomModal, CustomButton, IconBase } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import store from '@/shared/store';
import { CLEAR_CONFIRMATION_MODAL } from '@/shared/store/actionType';
import { tGlobal as t } from '@sales-i/utils';

export default {
  components: {
    ButtonGroup,
    CustomButton,
    IconBase,
    BufferImage,
    CustomModal,
  },
  props: {
    heading: {
      type: String,
      default: 'are_you_sure',
    },
    message: {
      type: String,
      default: 'is_this_really_what_you_want_to_do',
    },
    updatedHeading: {
      type: String,
      default: 'you_did_it',
    },
    updatedMessage: {
      type: String,
      default: 'too_late_now_to_change_your_mind',
    },
    errorHeading: {
      type: String,
      default: 'something_went_wrong',
    },
    errorMessage: {
      type: String,
      default: 'we_recommend_trying_again_later',
    },
    cancelLabel: {
      type: String,
      default: 'cancel',
    },
    saveLabel: {
      type: String,
      default: 'yep_do_it',
    },
    updatedLabel: {
      type: String,
      default: 'ok',
    },
    errorSaveLabel: {
      type: String,
      default: 'try_again',
    },
    errorCancelLabel: {
      type: String,
      default: 'not_now',
    },
    iconColor: {
      type: String,
      default: 'var(--colour-data-mandy)',
    },
    iconName: {
      type: String,
      default: 'warning',
    },
    closeOnUpdate: {
      type: Boolean,
      default: false,
    },
    closeOnCancel: {
      type: Boolean,
      default: true,
    },
    updateFunction: {
      type: Function,
      default: () => true,
    },
    finishFunction: {
      type: Function,
      default: () => true,
    },
    cancelFunction: {
      type: Function,
      default: () => true,
    },
    closeFunction: {
      type: Function,
      default: () => true,
    },
    saveButtonOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      error: false,
      showConfirmation: true,
      updated: false,
    };
  },
  computed: {
    title() {
      return this.error ? t('error') : undefined;
    },
  },
  methods: {
    t,
    doCancel() {
      if (this.cancelFunction) {
        this.cancelFunction();
      }
      if (this.closeOnCancel) {
        this.closeModal();
      }
    },
    closeModal() {
      if (this.closeFunction) {
        this.closeFunction();
      }
      store.dispatch(`confirmationModal/${CLEAR_CONFIRMATION_MODAL}`);
    },
    async doUpdate() {
      this.loading = true;
      if (this.updateFunction) {
        let result = await this.updateFunction();
        this.loading = false;
        if (result) {
          if (this.closeOnUpdate) {
            this.closeModal();
            return;
          }
          this.error = false;
          this.showConfirmation = false;
          this.updated = true;
        } else {
          this.error = true;
          this.showConfirmation = false;
          this.updated = false;
        }
      }
    },
    finishUpdate() {
      if (this.finishFunction) {
        this.finishFunction();
      }
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.confirm-message-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-3) var(--spacing-4);

  p.message-text {
    max-width: 100%;
    word-break: break-word;
  }
}

.buttons-container {
  display: flex;
  gap: var(--spacing-2);
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
</style>
