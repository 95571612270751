<template>
  <div class="tabs-layout">
    <slot name="before" />
    <header
      :class="mq.current"
      class="container container--fluid padded"
    >
      <div class="header-sidecol" />
      <div class="header-midcol">
        <slot name="title">
          <h1
            v-if="title"
            :class="mq.current"
          >
            {{ title }}
          </h1>
        </slot>
        <slot name="subtitle">
          <h3
            v-if="subtitle"
            class="subtitle"
            :class="mq.current"
          >
            {{ subtitle }}
          </h3>
        </slot>
      </div>
      <div class="header-sidecol cta-wrap">
        <slot name="cta">
          <div v-if="showCta">
            <CustomButton
              small
              purpose="action"
              class="cta mt-1"
              @click="$emit('ctaClick')"
            >
              {{ ctaTitle }}
            </CustomButton>
          </div>
        </slot>
        <CustomButton
          v-if="showAddInteraction"
          class="add-interaction-button cta mt-1"
          purpose="action"
          small
          @click="addInteraction"
        >
          {{ t('add_interaction') }}
        </CustomButton>
      </div>
    </header>
    <div
      :class="mq.current"
      class="header-bottom container container--fluid padded"
    >
      <slot name="headerBottom" />
    </div>
    <div class="tabs-wrap mt-3">
      <MqResponsive :target="breakpoints.smAndDown">
        <slot name="tags">
          <Tag
            v-if="showTags && entityId"
            :id="entityId"
            class="tag-sm"
            :entity="entity"
          />
        </slot>
      </MqResponsive>
      <slot name="tabs">
        <Tabs
          :items="tabs"
          :selected-index="tabSelectedIndex"
          @nav-clicked="navClicked"
        >
          <template #actions>
            <MqResponsive :target="breakpoints.mdAndUp">
              <slot name="tags">
                <Tag
                  v-if="showTags && entityId"
                  :id="entityId"
                  class="mt-1"
                  :entity="entity"
                />
              </slot>
            </MqResponsive>
          </template>
        </Tabs>
      </slot>
    </div>
    <slot name="content">
      <router-view />
    </slot>
    <slot name="after" />
  </div>
</template>

<script setup>
import { watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import breakpoints from '@/shared/utils/breakpoints';
import { useMq } from 'vue3-mq';
import { MqResponsive } from 'vue3-mq';
import Tabs from '@/shared/components/Tabs/Tabs.vue';
import Tag from '@/shared/components/Tags/Tag.vue';
import { CustomButton } from '@sales-i/dsv3';
import { useHead } from '@unhead/vue';
import { tCrm as t, pluralise } from '@sales-i/utils';
import { useInteraction } from '@/crm/composables/useInteraction';
import useSystem from '@/shared/composables/useSystem';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits';
import { navigateToUrl } from 'single-spa';

const mq = useMq();
const vroute = useRoute();
const store = useStore();
const {
  setEntity,
  clearCurrentlyEditing,
} = useInteraction({ store, vroute });

const { setRoute } = useSystem({ store });

const props = defineProps({
  entity: {
    type: String,
    default: '',
  },
  entityId: {
    type: [String, Number],
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  tabs: {
    type: Array,
    default: () => [],
  },
  /** must be provided for two-way work */
  tabSelectedIndex: {
    type: [String, Number],
    default: 0,
    required: true,
  },
  ctaTitle: {
    type: String,
    default: '',
  },
  showCta: {
    type: Boolean,
    default: undefined,
  },
  showTags: {
    type: Boolean,
    default: true,
  },
  showAddInteraction: {
    type: Boolean,
    default: false,
  }
});

useHead({
  title: props.title,
});

const entityData = computed(() => store.state.crm[pluralise.string(props.entity)].selected.data);

watch(
  () => props.title,
  newTitle => {
    useHead({
      title: newTitle,
    });
  }
);

const emit = defineEmits(['ctaClick', 'tabSelected']);

function navClicked(data) {
  emit('tabSelected', data);
}

async function addInteraction() {
  await clearCurrentlyEditing();

  await setEntity({
    entityType: props.entity,
    entity: {
      ...entityData.value,
      header: entityData.value.name,
      subHeader: entityData.value.account_number,
    },
  });

  if(entityData.value.record_type === 'customer' || entityData.value.record_type === 'prospect') {
    // Set the current route to the system/route object in the store.
    // This will be used to redirect the user to the page that called the interaction modal when the modal is closed.
    setRoute({
      success: vroute.fullPath,
      cancel: vroute.fullPath,
    });
  }
  navigateToUrl(`${baseUrl}/${interactionsArea}/add?clean=false`);
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.tabs-layout {
  flex: 1;
  display: flex;
  flex-flow: column;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-bottom: 0;
  }
}

.tag-sm {
  display: flex;
  justify-content: flex-end;
  padding: var(--spacing-1) var(--spacing-2);
}

.tabs-wrap {
  position: relative;
}

.tabs {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

header {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;

  &:empty {
    display: none;
  }
  .header-sidecol {
    position: relative;
    display: flex;
    min-width: 20%;
  }
  .header-midcol {
    width: 100%;
  }
  .cta-wrap {
    align-items: flex-start;
    justify-content: flex-end;
    gap: var(--spacing-2);
  }
  .cta {
    margin: 0;
    white-space: nowrap;
  }
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    overflow-wrap: anywhere; // temporary solution for the data issues (ReplaceSpaceWithNBSP.vue component)
    .header-sidecol {
      width: 100%;
    }
    h1,
    .subtitle {
      padding-inline: var(--spacing-2);
    }
    .cta-wrap {
      justify-content: center;
    }
  }
  .subtitle {
    font-size: var(--font-size-3);
    color: var(--colour-utility-base);
    padding-top: var(--spacing-1);
    flex-wrap: wrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      display: flex;
      flex-flow: column nowrap;
      padding-top: var(--spacing-half);
    }
    .link-button {
      font-size: var(--font-size-5);
      font-weight: var(--font-weight-medium);
    }
  }
}

.header-bottom:empty {
  display: none;
}
</style>