<template>
  <div>
    <div
      :id="uniqueId"
      ref="containerRef"
      class="pie-chart-container"
    >
      <svg
        v-if="clearedData.length"
        class="pie-chart"
      />
      <slot
        v-else
        name="incorrect"
      >
        <p class="p-4">
          {{ t('pie_charts_are_not_suitable_for_displaying_negative_values_use_bar_chart_instead') }}
        </p>
      </slot>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import * as d3 from 'd3';
import { tCrm as t } from '@sales-i/utils';
import useCharts from '@/intelligence/composables/useCharts';

// Props
const props = defineProps({
  // [{value, name}]
  chartData: {
    type: Array,
    default: () => [],
  },
  columnKey: {
    type: String,
    default: 'value'
  },
  colour: {
    type: String,
    default: 'var(--colour-data-de-york)'
  },
  formatFunc: {
    type: Function,
    default: (value) => value
  },
  showValues: {
    type: Boolean,
    default: false,
  },
});

const containerRef = ref(null);
const { uniqueId, handleChartResize } = useCharts({ containerRef, generateChart });

const clearedData = computed(() => props.chartData?.filter(x => x[props.columnKey] >= 0.0));

const valueSummary = computed(() => {
  return clearedData.value.reduce((total, v) => total + Math.max(0.0, v[props.columnKey]), 0.0);
});


// Watchers
watch(
  props.chartData, 
  handleChartResize, 
  { deep: true }
);

function generateChart() {
  const formatValue = props.formatFunc || (value => value);
  const isDesktop = containerRef.value?.clientWidth > 900;

  // dimensions and margins of the chart (mobile and desktop)
  const svgWidth = containerRef.value?.clientWidth || 220;
  const svgHeight = isDesktop ? 30 * Math.max(props.chartData.length, 15) : 400;
  const margin = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  };

  const radius = Math.min(svgWidth, svgHeight) / Math.max(2, props.chartData.length / 20);
  const colorSelected = props.colour.includes('var(')
    ? props.colour.replace(')', '-label)') 
    : 'var(--colour-data-puerto-rico-label)';

  const colorMain = props.colour.includes('var(')
    ? props.colour.replace(')', '-dark)') 
    : 'var(--colour-data-puerto-rico-dark)';
  const verticalPositionsArray = [];

  const svg = d3
    .select(`#${uniqueId} svg.pie-chart`)
    .attr('width', svgWidth - margin.left - margin.right)
    .attr('height', svgHeight - margin.top - margin.bottom)
    .append('g')
    .attr(
      'transform',
      `translate(${svgWidth / 2 + margin.left - margin.right}, ${svgHeight / 2 + margin.top - margin.bottom})`
    );

  const pie = d3
    .pie()
    .value(d => d?.[props.columnKey]);

  const piedata = pie(clearedData.value);

  const arc = d3
    .arc()
    .outerRadius(isDesktop ? radius * 0.55 : radius * 0.9)
    .innerRadius(isDesktop ? radius * 0.3 : radius * 0.5);

  const outerArc = d3
    .arc()
    .innerRadius(radius * 0.65)
    .outerRadius(radius * 0.65);

  // SLICES
  renderSlices();

  // OUTER CIRCLE
  svg
    .append('circle')
    .attr('cx', 0)
    .attr('cy', 0)
    .attr('r', isDesktop ? radius * 0.57 : radius * 0.92)
    .attr('stroke', 'var(--colour-panel-g-16)')
    .attr('fill', 'none')
    .style('filter', 'drop-shadow( 0 0 2px var(--colour-panel-g-16))');

  // create groups per slice
  const labelGroup = svg
    .selectAll('g')
    .data(piedata)
    .enter()
    .append('g')
    .attr('class', d => `label-group label-group-${d.index}`);

  const radiusMultiplier = radius * 0.66;
  
  // CHIPS
  const chipContainer = labelGroup.append('g').attr('class', 'piechart-chips');
  const positionMultiplier = 1.34;
  
  if (isDesktop) {
    chipContainer
      .append('text')
      .text(d => formatValue(d.data?.[props.columnKey]))
      .attr('class', d => `chart-label-value chip-value-${d.index}`)
      .attr('dominant-baseline', 'middle')
      .style('text-anchor', d => (midAngle(d) < Math.PI ? 'start' : 'end'))
      .attr('transform', d => {
        const pos = outerArc.centroid(d);
        pos[0] = (radiusMultiplier + 13) * (midAngle(d) < Math.PI ? 1 : -1);
        pos[1] = processVerticalPosition(pos[1]);
        return `translate(${pos})`;
      });
  }
  else if (props.showValues){
    const positionMultMobile = 1.2;
    chipContainer
      .append('text')
      .text(d => formatValue(d.data?.[props.columnKey]))
      .attr('class', d => `chart-label-value chip-value-${d.index}`)
      .attr('dominant-baseline', 'middle')
      .style('text-anchor', 'middle')
      .attr('transform', d => `translate(${outerArc.centroid(d)[0] * positionMultMobile} ${outerArc.centroid(d)[1] * positionMultMobile})`);
  }

  // processing additional top margin
  const lastItemVerticalPosition = verticalPositionsArray[verticalPositionsArray.length - 1];
  if (lastItemVerticalPosition < -svgHeight / 2) {
    margin.top += Math.abs(lastItemVerticalPosition + svgHeight / 2 - 15);
  }
  svg.attr(
    'transform',
    `translate(${svgWidth / 2 + margin.left - margin.right}, ${svgHeight / 2 + margin.top - margin.bottom + 15})`
  );
    
  if (isDesktop) {
    // TEXT LABELS
    labelGroup
      .append('text')
      .attr('text-anchor', d => (midAngle(d) < Math.PI ? 'start' : 'end'))
      .attr('transform', d => {
        const pos = outerArc.centroid(d);
        pos[0] = (radiusMultiplier + getChipWidth(d.index) + 8) * (midAngle(d) < Math.PI ? 1 : -1);
        pos[1] = verticalPositionsArray[d.index];
        return `translate(${pos})`;
      })
      .attr('dominant-baseline', 'middle')
      .text(d => truncateName(d.data.name));
    
    // POLYLINES
    labelGroup
      .append('polyline')
      .attr('class', 'label-polyline')
      .attr('points', d => {
        const pointStart = [arc.centroid(d)[0] * positionMultiplier, arc.centroid(d)[1] * positionMultiplier],
          pointMiddle = [outerArc.centroid(d)[0], verticalPositionsArray[d.index]],
          pointFinish = [radiusMultiplier * (midAngle(d) < Math.PI ? 1 : -1), verticalPositionsArray[d.index]];
        return [pointStart, pointMiddle, pointFinish];
      })
      .attr('fill', 'none')
      .attr('stroke', colorMain)
      .style('stroke-width', '1px');

    // DOTS
    labelGroup
      .append('circle')
      .attr('cx', d => arc.centroid(d)[0] * positionMultiplier)
      .attr('cy', d => arc.centroid(d)[1] * positionMultiplier)
      .attr('r', 4)
      .attr('stroke', colorMain)
      .attr('fill', colorMain)
      .attr('class', 'pie-chart-dot');
  }

  // SUMMARY CHIP
  const summaryChip = svg.append('g').attr('class', 'piechart-summary-chip');
  summaryChip
    .append('text')
    .attr('class', 'summary-value')
    .attr('dominant-baseline', 'middle')
    .attr('text-anchor', 'middle')
    .text(formatValue(valueSummary.value));
  summaryChip
    .insert('rect', 'text')
    .attr('ry', 32)
    .attr('rx', 32)
    .attr('height', 64)
    .attr('class', 'chip')
    .attr('fill', 'var(--colour-utility-white')
    .each(setSummaryChipWidthPosition);

  // UTILS
  function renderSlices() {
    svg
      .selectAll('path')
      .data(piedata)
      .enter()
      .append('path')
      .attr('fill', props.colour)
      .attr('fill-opacity', d => (piedata.length - d.index) / piedata.length)
      .attr('d', arc)
      .attr('class', d => `pie-chart-slice slice-${d.index}`)
      .attr('stroke', 'var(--colour-utility-white)')
      .style('stroke-width', 2)
      .style('cursor', 'pointer')
      .on('click', onSliceClick);
  }

  function getChipWidth(index) {
    return svg.select(`text.chip-value-${index}`).node()?.getComputedTextLength() + 20;
  }

  function truncateName(name) {
    const nameSplit = name.split(' ');
    return nameSplit.length > 1 ? `${nameSplit[0]} ${nameSplit[1] || ''}...` : nameSplit[0];
  }

  function midAngle(d) {
    return d.startAngle + (d.endAngle - d.startAngle) / 2;
  }

  function processVerticalPosition(position) {
    verticalPositionsArray.forEach(el => {
      if (position > el - 20 && position < el + 20) {
        position = el + (position > 0 ? 26 : -26);
      }
    });
    verticalPositionsArray.push(position);
    return position;
  }

  function setSummaryChipWidthPosition() {
    let textLength = d3.select(this.parentNode).select('text').node().getComputedTextLength() + 46;
    d3.select(this)
      .attr('width', textLength)
      .attr('transform', `translate(-${textLength / 2},-35)`);
  }

  function setMobileChipWidthPosition() {
    let textLength = d3.select(this.parentNode).select('text').node().getComputedTextLength() + 26;
    d3.select(this)
      .attr('width', textLength)
      .attr('transform', `translate(-${textLength / 2},-22)`);
  }

  function onSliceClick(e, d, context) {
    const self = context || this;

    // reset all colors
    svg
      .selectAll('.pie-chart-slice')
      .data(piedata)
      .attr('fill', props.colour)
      .attr('fill-opacity', d => (piedata.length - d.index) / piedata.length);
    svg.selectAll('circle.pie-chart-dot').attr('stroke', colorMain).attr('fill', colorMain);
    svg.selectAll('polyline.label-polyline').attr('stroke', colorMain);
    svg.selectAll('text.chart-label-value').attr('fill', 'var(--colour-utility-black)');
    svg.selectAll('rect.chip').attr('fill', 'none');

    // reset selection
    if (!d3.select(self).attr('selected')) {
      svg.selectAll('path.pie-chart-slice').attr('selected', null);
      // fill slice with selected color
      d3.select(self).attr('fill', colorSelected).attr('fill-opacity', 1).attr('selected', true);
      const labelGroup = d3.select(self).select(`g.label-group-${d.index}`);
      labelGroup.select('circle').attr('fill', colorSelected).attr('stroke', colorSelected);
      labelGroup.select('polyline').attr('stroke', colorSelected);
      labelGroup.select('text.chart-label-value').attr('fill', 'var(--colour-utility-white)');
      labelGroup.select('rect.chip').attr('fill', colorSelected);

      svg.select('g.piechart-mobile').remove();
      // mobile element
      const mobileElement = svg.append('g').attr('class', 'piechart-mobile');
      // inner circle
      mobileElement
        .append('circle')
        .attr('fill', colorSelected)
        .attr('r', isDesktop ? radius * 0.3 : radius * 0.5)
        .attr('class', 'pie-chart-inside-circle');
      // chip with value
      mobileElement
        .append('text')
        .attr('class', 'mobile-value-text')
        .attr('dominant-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .text(formatValue(d.data?.[props.columnKey]));
      mobileElement
        .insert('rect', 'text')
        .attr('ry', 20)
        .attr('rx', 20)
        .attr('height', 40)
        .attr('class', 'chip')
        .attr('fill', 'var(--colour-utility-white')
        .each(setMobileChipWidthPosition);
      // name label
      mobileElement
        .append('text')
        .attr('class', 'mobile-label-text')
        .attr('dominant-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .text(d.data.name)
        .attr('transform', 'translate(0, -40)')
        .each(truncateLabel);

      // arrow icons:
      const arrowIconsData = [
        {
          step: 1,
          position: 'translate(16,40)',
          arrowTransform: 'translate(13, 13) scale(1.5) rotate(180)',
        },
        {
          step: -1,
          position: 'translate(-16,40)',
          arrowTransform: 'translate(-13, -13) scale(1.5)',
        },
      ];

      arrowIconsData.forEach(icon => {
        // arrow group
        const arrowIcon = mobileElement
          .append('g')
          .attr('class', 'mobile-icon')
          .attr('transform', icon.position)
          .on('click', () => handleArrowClick(icon));
          // arrow background
        arrowIcon
          .append('circle')
          .attr('cx', 0)
          .attr('cy', 0)
          .attr('r', 12)
          .attr('fill', 'var(--colour-utility-white)');
        // just arrow
        arrowIcon
          .append('path')
          .attr(
            'd',
            'M5.87249994,8.25 L8.55750024,5.55749997 L7.5,4.5 L3,9 L7.5,13.5 L8.55749881,12.4425001 L5.87249994,9.75 L15,9.75000143 L15,8.25000143 L5.87249994,8.25 Z'
          )
          .attr('fill', 'var(--colour-utility-action)')
          .attr('stroke-width', 1)
          .attr('transform', icon.arrowTransform);
      });
    } else {
      svg.selectAll('.pie-chart-slice').attr('selected', null);
      svg.select('g.piechart-mobile').remove();
    }

    /** helper functions  */
    function handleArrowClick(icon) {
      svg.selectAll('.pie-chart-slice').attr('selected', null);
      const nextIndex = d.index + icon.step < 0 ? piedata.length - 1 : (d.index + icon.step) % piedata.length;
      const nextSliceData = piedata.find(e => e.index === nextIndex);
      onSliceClick(undefined, nextSliceData, svg.select(`.slice-${nextIndex}`).node());
    }

    function truncateLabel() {
      const self = d3.select(this);
      const text = self.text();
      self.text(text.length > 18 ? text.slice(0, 18) + '...' : text);
    }
  }
}
</script>

<style lang="scss">
.pie-chart-container {
  display: flex;
  justify-content: center;

  text {
    font-size: var(--font-size-small);
  }
  .chart-label-value {
    font-weight: var(--font-weight-semibold);
  }
  .summary-value {
    font-size: var(--font-size-2);
    font-weight: var(--font-weight-semibold);
  }
  .mobile-value-text {
    font-size: var(--font-size-body);
    font-weight: var(--font-weight-semibold);
  }
  .mobile-label-text {
    fill: var(--colour-utility-white);
  }
  svg text {
    user-select: none;
  }
  svg text::selection {
    background: none;
  }
}
</style>
