<template>
  <div class="segments">
    <div
      v-for="item in filteredSegments"
      :key="item.metric_type_id"
      class="segment-container"
    >
      <p class="chip-title">
        {{ t(item.metric_name) }}:
      </p>
      <CustomChip
        class="segment"
      >
        {{ t(item.curr_metric_category_description) }}
      </CustomChip>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { GET_CUSTOMER_SEGMENTS } from '@/aiml/store/actionType';
import { CustomChip } from '@sales-i/dsv3';
import { tCrm as t } from '@sales-i/utils';

const props = defineProps({
  customerId: {
    type: Number,
    default: 1,
  },
});

const store = useStore();
const segments = computed(() => store.state.aiml.churn.data);

const filteredSegments = computed(() => {
  return (segments.value || []).filter(o => {
    return ['mixed', 'equal'].indexOf(o.curr_metric_category_description.toLowerCase()) === -1;
  });
});

const getCustomerSegments = async () => {
  await store.dispatch(`aiml/churn/${GET_CUSTOMER_SEGMENTS}`, props.customerId);
};

onMounted(getCustomerSegments);
</script>

<style lang="scss" scoped>
.segments {
  margin: var(--spacing-2) 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
  background-color: var(--colour-panel-alert-base);
  border-radius: var(--border-radius-1);
  padding-bottom: var(--spacing-2);

  .chip-title {
    font-size: var(--font-size-small);
    text-align: center;
  }

  .segment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: var(--spacing-2);

    .segment {
      margin: var(--spacing-1);
      text-align: center;
      box-shadow: 0 0 var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);
      background: var(--colour-utility-white);
      height: var(--spacing-5);
      font-size: var(--font-size-5);
      white-space: nowrap;
    }
  }
}
</style>
