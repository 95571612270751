import { prospects } from '@/shared/store/data/policies';
import { fieldsMutations, fieldsActions } from './fields';
import { getAllMutations, getAllActions } from './getAll';
import { getByIdMutations, getByIdActions  } from './getById';
import { deleteByIdMutations, deleteByIdActions } from './deleteById';
import { postProspectMutations, postProspectActions } from './post';
import { getContactsMutations, getContactsActions, getContactsGetters } from './getContacts';
import { getSelectedGetters } from './getSelected';
import { getSetUpdatedMutations, getSetUpdatedActions } from './getSetUpdated';
import { putByIdMutations, putByIdActions } from './putById';
import { mergeActions } from './merge';
import { getSectionMutations, getSectionActions  } from '@/shared/store/modules/customSections/getCustomSection';
import { tCrm as t } from '@sales-i/utils';

// initial state
const state = () => ({
  permissionSet: prospects,
  id: undefined,
  all: {
    data: [],
    fields: [],
    percentage: 0,
    loaded: false,
    loading: false,
  },
  children: {
    data: [],
    loading: false,
    loaded: false,
    updated: false,
  },
  contacts: {
    data: [],
    loaded: false,
    loading: false,
  },
  selected: {
    data: {},
    loaded: false,
    loading: false,
    updated: false,
  },
  customSection: {
    data: {},
    loaded: false,
    loading: false,
  },
  sorting: [
    {
      text: t('a_z_name'),
      value: 'name:asc',
    },
    {
      text: t('z_a_name'),
      value: 'name:desc',
    },
    {
      text: t('date_created_oldest_first'),
      value: 'created_date:asc',
    },
    {
      text: t('date_created_newest_first'),
      value: 'created_date:desc',
    },
    {
      text: t('date_updated_oldest_first'),
      value: 'updated_date:asc',
    },
    {
      text: t('date_updated_newest_first'),
      value: 'updated_date:desc',
    },
  ],
  fields: [],
  entity: 'prospects',
});

// getters
const getters = {
  ...getContactsGetters,
  ...getSelectedGetters,
};

// mutations
const mutations = {
  ...getAllMutations,
  ...getByIdMutations,
  ...getContactsMutations,
  ...postProspectMutations,
  ...putByIdMutations,
  ...fieldsMutations,
  ...deleteByIdMutations,
  ...getSectionMutations,
  ...getSetUpdatedMutations
};

// actions
const actions = {
  ...getAllActions,
  ...getByIdActions,
  ...getContactsActions,
  ...postProspectActions,
  ...putByIdActions,
  ...fieldsActions,
  ...deleteByIdActions,
  ...getSectionActions,
  ...getSetUpdatedActions,
  ...mergeActions
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
