import {
  APPLY_NEW_SORT,
  APPLY_NEW_FILTER,
  FETCH_BUBBLE_DATA,
  SAVE_BUBBLE_DATA,
  SET_SNAPSHOT_BUBBLE_VALUE,
  SET_SNAPSHOT_BUBBLE_ID,
} from '@/intelligence/store/actionType';

import { tCrm as t } from '@sales-i/utils';

import { 
  CUSTOMER_LAST_COMPLETE_PERIODS_BUT_NOT_BOUGHT_TITLE, 
  CUSTOMER_NOT_BUYING_TOP_PRODUCTS_TITLE, 
  CUSTOMER_WITH_ZERO_SPEND_TITLE 
} from '@/intelligence/store/data/reportTypes';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default function useTemplateReport({ store = useStore() } = {}) {
  const getCustomerAccountNoBubbleId = computed(() => store.getters['intelligence/templateReport/getCustomerAccountNoBubbleId']);
  const getProductBubbleId = computed(() => store.getters['intelligence/templateReport/getProductBubbleId']);

  const fetchBubbleIds = (params) => store.dispatch(`intelligence/templateReport/${FETCH_BUBBLE_DATA}`, params);
  const applyNewSort = (params) => store.dispatch(`intelligence/templateReport/${APPLY_NEW_SORT}`, params);
  const applyNewFilter = (params) => store.dispatch(`intelligence/templateReport/${APPLY_NEW_FILTER}`, params);
  const saveBubbleData = (params) => store.dispatch(`intelligence/templateReport/${SAVE_BUBBLE_DATA}`, params);
  const setSnapshotBubbleValue = (params) => store.dispatch(`intelligence/templateReport/${SET_SNAPSHOT_BUBBLE_VALUE}`, params);
  const setSnapshotBubbleId = (params) => store.dispatch(`intelligence/templateReport/${SET_SNAPSHOT_BUBBLE_ID}`, params);

  const getProcessedReportTitle = (title) => {
    const sharedStore = store.state.intelligence.shared;
    switch (title) {
    case CUSTOMER_LAST_COMPLETE_PERIODS_BUT_NOT_BOUGHT_TITLE:
      title = t('last_variableperiod_complete_periods_but_not_yet_bought_this_month', { 
        variableperiod: sharedStore.requestParameters.periods 
      });
      break;
    case CUSTOMER_NOT_BUYING_TOP_PRODUCTS_TITLE:
      title = t('top_variablelimit_accounts_that_have_not_bought_our_most_profitable_products_in_the_last_variableperiod_months',
        { 
          variablelimit: sharedStore.requestParameters.limit,
          variableperiod: sharedStore.requestParameters.periods
        }
      );
      break;
    case CUSTOMER_WITH_ZERO_SPEND_TITLE:
      title = t('customers_with_zero_spend_in_the_past_variableperiod_months_lost_accounts', { 
        variableperiod: sharedStore.requestParameters.periods 
      });
      break;
    }
    return title;
  };

  return {
    getCustomerAccountNoBubbleId,
    getProductBubbleId,
    applyNewSort,
    applyNewFilter,
    getProcessedReportTitle,
    fetchBubbleIds,
    saveBubbleData,
    setSnapshotBubbleValue,
    setSnapshotBubbleId,
  };
}