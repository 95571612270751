<template>
  <div
    v-if="!props.varianceSummaryLoading"
    class="kpi-section-container"
  >
    <div class="legends">
      <div class="kpi-icons">
        <div
          v-for="number in 2"
          :key="number"
          :class="['calendar-element', `calendar-element-${number}`]"
        >
          <span class="indicator">{{ number }}:</span>
          <IconBase
            class="calendar-icon"
            icon-name="calendar"
            :height="24"
            :width="24"
          />
        </div>
      </div>

      <p class="variance-title">
        {{ t('variance') }}
      </p>
    </div>
    <div class="kpi-columns">
      <div
        v-for="kpi in convertedKpiData"
        :key="kpi.key"
        class="single-kpi"
      >
        <BarChartSimple
          :colour="['var(--colour-data-viking)', 'var(--colour-panel-g-32)']"
          :bar-width="14"
          :bar-gap="6"
          :column-key="['value1', 'value2',]"
          :chart-data="[kpi]"
          :svg-height="130"
        />

        <h4>{{ kpi.title }}</h4>
        <RagChip
          :currency="currency"
          class="big-chip date-1"
          :value="Number(kpi.value1)"
          :prefix="kpi.prefix && cs"
          :suffix="kpi.suffix"
          abbr
        />

        <RagChip
          :currency="currency"
          :value="Number(kpi.value2)"
          :prefix="kpi.prefix && cs"
          :suffix="kpi.suffix"
          class="big-chip date-2"
          abbr
        />

        <RagChip
          :value="kpi.variance"
          :green="1"
          :amber="-1"
          abbr
          class="big-chip"
        >
          {{ kpi.formattedVariance }} / {{ kpi.formattedPercentage }}
        </RagChip>      
      </div>
    </div>
  </div>
  <BufferImage
    v-else
    class="loading-spinner"
    color="var(--colour-utility-black)"
    float="center"
  />
</template>

<script setup>
import { computed } from 'vue';
import BarChartSimple from '@/intelligence/components/Dashboard/Elements/BarChartSimple.vue';
import useCurrency from '@/shared/composables/useCurrency';
import { IconBase, BufferImage, RagChip } from '@sales-i/dsv3';
import { tCrm as t, abbr } from '@sales-i/utils';

const { cs, currency, cPrice } = useCurrency();

// props
const props = defineProps({
  varianceSummaryData: {
    type: Array,
    required: true
  },
  varianceSummaryLoading: {
    type: Boolean,
    required: true
  }
});

// Convert value1 and value2 to numbers in the parent component
const convertedKpiData = computed(() => {
  return props.varianceSummaryData.map(kpi => ({
    ...kpi,
    value1: Number(kpi.value1),
    value2: Number(kpi.value2),

    // If the key is quantity or cost use the value as is.
    formattedVariance: formatVariance(kpi.variance, { key: kpi.key }),
    formattedPercentage: formatVariance(kpi.variance_percent, { percentage: true })
  }));
});

function formatVariance(value, options = {}) {
  const { key, currency, percentage = false, abbrType = 'float', positiveSign = '+', negativeSign = '-', positiveSymbol = '↑', negativeSymbol = '↓' } = options;

  // The actual value comes back with multiple decimal places so checking for 0 won't work. Instead, check if the value is less than 0.000001.
  const isZero = Math.abs(value) < 0.000001;

  // Render the number using a currency prefix unless the key is 'gross_profit'. Render the number as a percentage.
  const formattedValue = percentage || key === 'gross_profit'
    ? abbr.int(value.replace('-', '')) + '%'
    : key === 'quantity' || key === 'count'
      ? abbr[abbrType](value.replace('-', ''))
      : cPrice(value, currency, true).replace('-', '');

  // Determine the sign or symbol to use.
  const sign = value > 0 ? positiveSign : negativeSign;
  const symbol = value > 0 ? positiveSymbol : negativeSymbol;

  // Return the formatted value with appropriate sign or symbol.
  return isZero ? formattedValue : `${percentage ? symbol : sign} ${formattedValue} `;
}
</script>

<style lang="scss" scoped>
.kpi-section-container {
  display: grid;
  grid-template-columns: 5rem 1fr;
  overflow-x: auto;
  padding-block-end: var(--spacing-2);
  padding-inline: var(--spacing-4);
}

  .legends {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;
    margin-block-end: var(--spacing-half);
    margin-inline-end: var(--spacing-half);
  }

  .kpi-icons {
    margin-inline-start: var(--spacing-4);
    margin-block-end: var(--spacing-4);
  }
    .calendar-element {
      display: flex;
      justify-content: center;
      align-items: center;

      &.calendar-element-1 {
        margin-block-end: var(--spacing-4);
      }
    }

  .variance-title {
    font-weight: var(--font-weight-semibold);
  }

.kpi-columns {
  display: inline-flex;
  justify-content: space-between;
  gap: var(--spacing-half);
  position: relative;

  background-image: 
    // Drawing the lines
    linear-gradient(to bottom, #979797 1px, transparent 1px),
    linear-gradient(to bottom, #979797 1px, transparent 1px),
    linear-gradient(to bottom, #979797 1px, transparent 1px);

  background-size: 
    // Spacing between lines. First value brings it in form the right-hand side
    97% 50px,
    97% 100px,
    97% 150px;

  background-position:
    // Positions of the lines from the bottom and left
    bottom -31px left 0,
    bottom -25px left 0,
    bottom -19px left 0;

  background-repeat: no-repeat;
}


.single-kpi {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 2;

  h4 {
    font-weight: var(--font-weight-semibold);
    margin-bottom: var(--spacing-1);
  }

  .big-chip {
    padding: calc(var(--spacing-1) + var(--spacing-half)) var(--spacing-2);
    white-space: nowrap;
  }

  .date-1, .date-2 {
    margin-bottom: var(--spacing-2);
  }

  .date-1 {
    background: var(--colour-data-viking);
  }

  .date-2 {
    background: var(--colour-panel-g-32);
  }
}

.indicator {
  display: block;
  width: 10px;
  font-weight: var(--font-weight-bold);
  margin-right: var(--spacing-half);
  font-size: var(--font-size-small);
  text-align: right;
}
</style>